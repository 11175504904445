import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../admin-helper";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
function ManageTeacher() {
  const Navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { teacherid, id } = useParams();
  const [classData, setClassData] = useState([])
  const [teachers, setTeachers] = useState({
    ids: id,
    teacherid: teacherid,
    Name: "",
    dob: "",
    gender: "",
    salary: "",
    emailid: "",
    mobileno: "",
    bloodgroup: "",
    qualfication: "",
    experience: "",
    city: "",
    country: "",
    zipcode: "",
    address: "",
    remark: "",
    doj: "",
    dor: "",
    current_position: "",
    incharge_class: "",
    subject_handling: "",
    working_hour: "",
    password: "",
    picture: "",
  });

  //Get Data
  useEffect(() => {
    // Fetch existing data if in edit mode
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/Admin/GetTeachersDetail`, {
          params: {
            teacherid,
            id,
          },
        }
        );
        const teacher = response.data.Payload[0];
        setTeachers({
          ids: id,
          teacherid: teacherid,
          Name: teacher.name,
          dob: teacher.dob,
          gender: teacher.gender,
          salary: teacher.salary,
          emailid: teacher.emailid,
          mobileno: teacher.mobileno,
          bloodgroup: teacher.bloodgroup,
          qualfication: teacher.qualfication,
          experience: teacher.experience,
          city: teacher.city,
          country: teacher.country,
          zipcode: teacher.zipcode,
          address: teacher.address,
          remark: teacher.remark,
          doj: teacher.doj,
          dor: teacher.dor,
          current_position: teacher.current_position,
          incharge_class: teacher.incharge_class,
          subject_handling: teacher.subject_handling,
          working_hour: teacher.working_hour,
          password: teacher.password,
          picture:teacher.picture,
        });

      } catch (error) {
        // toast.error('Authentication failed!');
        window.location.href = "/";
      }
    };

    if (id) {
      fetchData();
    }
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [id, teacherid]);

  const handleInputChange = (e) => {
    setTeachers({
      ...teachers,
      [e.target.name]: e.target.value,
    });
  };

  // image upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64-encoded image string to the state
        const filebase64 = reader.result.split("base64,");
        // setTeachers({ ...setTeachers, picture: filebase64[1] });
        setTeachers({ ...teachers, picture: filebase64[1] });

      };
      reader.readAsDataURL(file);
    }
  };

  //class data
  const fetchClassData = async () => {
    try {
      const response = await axiosInstance.get("/Master/GetClass");
      setClassData(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
      window.location.href = "/";
    }
  };

  const handleTeacherSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    let url = "/Admin/TeacherRegistration";
    if (id) {
      url = "/Admin/Updateteacherdetail";
    }
    try {
      const response = await axiosInstance.post(url, teachers);
      console.log('response',response);
      if (response.data.Status === "success") {
        toast.success(
          `${id ? "Teacher updated" : "Teacher added"} successfully!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setButtonLoading(false);
        if (!id) {
          setTeachers({
            ids: null,
            teacherid: null,
            Name: "",
            dob: "",
            gender: "",
            salary: "",
            emailid: "",
            mobileno: "",
            bloodgroup: "",
            qualfication: "",
            experience: "",
            city: "",
            country: "",
            zipcode: "",
            address: "",
            remark: "",
            doj: "",
            dor: "",
            current_position: "",
            incharge_class: "",
            subject_handling: "",
            working_hour: "",
            password: "",
            picture:"",
          });
        }
        Navigate("/admin/teachers");
      } else {
        throw new Error(`${id ? "Update" : "Add"} teacher failed`);
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error(`${id ? "Update" : "Add"} teacher failed`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const path = document.location.pathname;

  useEffect(()=>{
    fetchClassData()
  },[])
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title"> {path === "/admin/teachers/create"
              ? "Add Teacher"
              : "Edit Teacher"}</h4>
          </div>
          <div class="col-lg-12">
            {loading ? (
              <div
                className="container text-center"
                style={{ paddingTop: "200px" }}
              >
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <form onSubmit={handleTeacherSubmit}>
                <div class="row">
                  <div class="col-lg-12">
                    <div className="card custom-card mb-3">
                      <div className='card-header justify-content-between'>
                        <div class="card-title">Personal Details</div>
                      </div>
                      <div class="card-body py-3 px-3 ">
                        <div class="row form-material">
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Full Name</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter full name.."
                              pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                              name="Name"
                              defaultValue={teachers.Name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Email-id</label>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Enter your email..."
                              name="emailid"
                              defaultValue={teachers.emailid}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Mobile No</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter your mobile..."
                              name="mobileno"
                              maxLength={10}
                              pattern="[6789][0-9]{9}"
                              defaultValue={teachers.mobileno}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">DOB</label>
                            <input
                              type="date"
                              class="form-control"
                              name="dob"
                              defaultValue={teachers.dob}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Gender</label>
                            <select
                              class="form-control"
                              id="autoSizingSelect"
                              name="gender"
                              value={teachers.gender}
                              onChange={handleInputChange}
                            >
                              <option selected>Choose...</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Blood Group</label>
                            <select
                              class="form-control"
                              id="autoSizingSelect"
                              name="bloodgroup"
                              value={teachers.bloodgroup}
                              onChange={handleInputChange}
                            >
                              <option selected>Choose...</option>
                              <option value="A+">A+</option>
                              <option value="A-">A-</option>
                              <option value="B+">B+</option>
                              <option value="B-">B-</option>
                              <option value="O+">O+</option>
                              <option value="O-">O-</option>
                            </select>
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Qualification</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter qualification..."
                              name="qualfication"
                              defaultValue={teachers.qualfication}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Experience</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter experience..."
                              name="experience"
                              defaultValue={teachers.experience}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">City</label>
                            <input
                             pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                              type="text"
                              class="form-control"
                              placeholder="Enter city..."
                              name="city"
                              defaultValue={teachers.city}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Country</label>
                            <input
                             pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                              type="text"
                              class="form-control"
                              placeholder="Enter country..."
                              name="country"
                              defaultValue={teachers.country}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Zipcode</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter zipcode..."
                              name="zipcode"
                              title="xxxxxx"
                              pattern="[1-9][0-9]{5}"
                              maxLength={6}
                              defaultValue={teachers.zipcode}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Address</label>
                            <input
                             type="text"
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="2"
                              name="address"
                              defaultValue={teachers.address}
                              onChange={handleInputChange}
                            />
                          </div>
                         <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Teacher's Photo</label>
                            <input
                              type="file"
                              class="form-control"
                              name="picture"
                              onChange={handleImageChange}
                            />
                            {id ? (
                              <img
                                className="rounded "
                                src={`https://schoolapi.mnbsoft.co.in/UploadImage/${teachers.picture}`}
                                style={{ height: "50px", width: "100%" }}
                                alt="teacher Photo"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="col-xl-3 col-xxl-8 col-md-6 mb-3">
                            <label class="form-label">Remark</label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="2"
                              name="remark"
                              defaultValue={teachers.remark}
                              onChange={handleInputChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div className="card custom-card mb-3">
                      <div className='card-header justify-content-between'>
                        <div class="card-title">School Details</div>
                      </div>
                      <div class="card-body py-3 px-3 ">
                        <div class="row form-material">
                          <div class="col-xl-3 col-xxl-4 col-md-3 mb-3">
                            <label class="form-label">Date of joining</label>
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Enter your nationality..."
                              name="doj"
                              defaultValue={teachers.doj}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">
                              Date of releaving
                            </label>
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Enter Area..."
                              name="dor"
                              defaultValue={teachers.dor}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Current Position</label>
                            <input
                              type="text"
                              pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                              class="form-control"
                              placeholder="Enter current position..."
                              name="current_position"
                              defaultValue={teachers.current_position}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Assign Class</label>
                            <select
                              type="text"
                              class="form-control"
                              placeholder="Enter class incharge..."
                              name="incharge_class"
                              defaultValue={teachers.incharge_class}
                              onChange={handleInputChange}
                            >
                              <option>Choose...</option>
                              {classData.map((c)=><option>{c.sclass}</option>)}</select>
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Subject Handling</label>
                            <input
                              type="text"
                              pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                              class="form-control"
                              placeholder="Enter subject handling..."
                              name="subject_handling"
                              defaultValue={teachers.subject_handling}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Working Hours</label>
                            <input
                              type="text"
                              // pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                              class="form-control"
                              placeholder="Enter working hours..."
                              name="working_hour"
                              defaultValue={teachers.working_hour}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Salary</label>
                            <input
                              type="text"
                              pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                              class="form-control"
                              placeholder="Enter salary..."
                              name="salary"
                              defaultValue={teachers.salary}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Password</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter password..."
                              name="password"
                              defaultValue={teachers.password}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button
                    type="submit"
                    class="btn btn-primary fw-bold mb-3"
                    style={{ width: "100px" }}
                  >
                    {buttonLoading ? (
                      <span
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : id ? (
                      "Edit"
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageTeacher;
