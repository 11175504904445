import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../teacher-helper";
import { toast } from "react-toastify";
function Login() {
  const Navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({});
  console.log('data',data);
  const [mobileno, setMobileno] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(false);
    try {
      const response = await axiosInstance.post("/Teacher/SchoolTeacherLogin", {
        mobileno,
        password
      });
      if (response.data.Status === "success") {
        const { TeacherName, teachertoken } = response.data;
        localStorage.setItem("teacherData", TeacherName);
        localStorage.setItem("token", teachertoken);
        window.location.href = "/teacher/dashboard";

      } else {
        toast.error("Authentication failed !");
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(true);
      console.error("Error during authentication", error);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    // <div className="authincation">
    <div className="container">
      <div className="row justify-content-center align-items-center hidden mt-5">
        <div className="col-md-5 mt-5">
          <div className="authincation-content animate__animated animate__zoomIn">
            <div className="auth-form">
              <div className="text-center mb-3">
                <Link to="" className="brand-logo">
                  <img src="../images/1467.png" height="50" />
                </Link>
              </div>
              <h3 className="text-center mb-2">Teacher's Login</h3>
              <p className="text-center mb-4">MNB Public School</p>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className=" form-label text-default mb-1">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    value={mobileno}
                    onChange={(e) => setMobileno(e.target.value)}
                    placeholder="Please enter username"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className=" form-label text-default mb-1">
                    Password
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Please enter password"
                      required
                    />
                    <div className="input-group-text">
                      <span onClick={togglePasswordVisibility}>
                        <i
                          className={`bi fs-4 ${showPassword ? "bi-eye-slash" : "bi-eye"
                            }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-between mt-4 mb-2"></div>
                <div className="text-center">

                  <button
                    type="submit"
                    className="btn btn-sm btn-primary text-white w-100"
                  >
                    {buttonLoading ? (
                      <span
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default Login;
