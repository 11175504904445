import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../admin-helper";
import { useNavigate, useParams } from "react-router-dom";
function View_Teacher() {
  const Navigate = useNavigate()
  const [teacherDetail, setTeacherDetail] = useState();
  const { teacherid } = useParams();
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        const response = await axiosInstance.get(
          `/Admin/GetTeachersDetail?teacherid=${teacherid}`
        );
        const teacher = response.data.Payload;
        setTeacherDetail(teacher);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        Navigate('/')
      }
    };
    fetchTeacher();
  }, [teacherid]);
  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div class="card-header">
              <h4 class="card-title">Teacher Profile</h4>
            </div>
          </div>
          <div class="row pt-4">
            <div class=" col-md-12">
              {
                loading ?
                  (
                    <div
                      className="container text-center"
                      style={{ paddingTop: "100px" }}
                    >
                      <div class="spinner-grow text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-danger" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-warning" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="spinner-grow text-dark" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) :
                  (<>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="card">
                          {teacherDetail ? (
                            teacherDetail.map((row) => {
                              return (
                                <>
                                  <div class="card-header border-0 flex-wrap align-items-start">
                                    <div class="col-md-8">
                                      <div class="user d-sm-flex d-block pe-md-5 pe-0">
                                        <img src={row.picture ? `https://schoolapi.mnbsoft.co.in/UploadImage/${row.picture}` : '/images/user.png'} alt="" />
                                        <div class="ms-sm-3 ms-0 me-md-5 md-0">
                                          <h5 class="mb-1 font-w500 fs-4 text-black" >{row.name}</h5>
                                          <div class="listline-wrapper mb-2">
                                            <span class="item"><i class="text-primary far fa-id-badge"></i>Teacher Id  : {row.teacherid} </span>
                                            <span class="item"><i class="text-primary far fa-envelope"></i>Class Incharge : {row.incharge_class}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-12 text-end">
                                      <div class="mt-3">
                                        <h6 class="text-start">Attendance
                                          <span class="float-end">85%</span>
                                        </h6>
                                        <div class="progress ">
                                          <div class="progress-bar bg-danger progress-animated" role="progressbar">
                                            <span class="sr-only">60% Complete</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-body pt-0">
                                    <h4 class="card-title">Description</h4>
                                    <div class="row">
                                      <div class="col-xl-6 col-md-6">
                                        <ul class="list-style-1">
                                          <li><label class="custom-label-2 mb-0">Date of Birth :</label>{row.dob}</li>
                                          <li><label class="custom-label-2 mb-0">Gender :</label>{row.gender}</li>
                                          <li><label class="custom-label-2 mb-0">Email id :</label>{row.emailid}</li>
                                          <li><label class="custom-label-2 mb-0">Mobile No :</label>{row.mobileno}</li>
                                          <li><label class="custom-label-2 mb-0">Qualification :</label>{row.qualfication}</li>
                                          <li><label class="custom-label-2 mb-0">Blood Group :</label>{row.bloodgroup}</li>
                                          <li><label class="custom-label-2 mb-0">Salary :</label>{row.salary}</li>
                                          <li><label class="custom-label-2 mb-0">Date of Joining :</label>{row.doj}</li>
                                          <li><label class="custom-label-2 mb-0">Date of Releaving : </label>{row.dor}</li>
                                        </ul>
                                      </div>
                                      <div class="col-xl-6 col-md-6">
                                        <ul class="list-style-1">
                                          <li><label class="custom-label-2 mb-0">Experience </label>{row.experience}</li>
                                          <li><label class="custom-label-2 mb-0">Current Position : </label>{row.current_position}</li>
                                          <li><label class="custom-label-2 mb-0">Subject Handling : </label>{row.subject_handling}</li>
                                          <li><label class="custom-label-2 mb-0">Working Hrs : </label>{row.working_hour}</li>
                                          <li><label class="custom-label-2 mb-0">Password  :</label>{row.password}</li>
                                          <li><label class="custom-label-2 mb-0">Address :</label>{row.address}</li>
                                          <li><label class="custom-label-2 mb-0">City :</label>{row.city}</li>
                                          <li><label class="custom-label-2 mb-0">Country :</label>{row.country}</li>
                                          <li><label class="custom-label-2 mb-0">Zipcode :</label>{row.zipcode}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-footer d-flex flex-wrap justify-content-between align-items-center">
                                    <div>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <>No data Available</>
                          )}
                        </div>
                      </div>
                    </div>
                  </>)
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default View_Teacher;
