import React from "react";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../admin-helper";
function Student() {
    // const [data, setData] = useState({});
    // const [loading, setLoading] = useState(true);
    // const fetchData = async () => {
    //     try {
    //         const response = await axiosInstance.get("/Parent/Getstudentdetail")
    //         setData(response.data)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }
    // useEffect(() => {
    //     fetchData();
    //     const loaderTimeout = setTimeout(() => {
    //         setLoading(false);
    //     }, 3000);
    //     return () => clearTimeout(loaderTimeout);
    // }, []);

    // const [dataa, setDataa] = useState([]);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axiosInstance.get(`/Parent/GetStudentClass`);
    //             setDataa(response.data.Payload);
    //         } catch (error) {
    //             window.location.href = "/parent";
    //         }
    //     };
    //     fetchData();
    //     const loaderTimeout = setTimeout(() => {
    //         setLoading(false);
    //     }, 3000);
    //     return () => clearTimeout(loaderTimeout);
    // }, []);
    return (<>
        <div className="container-fluid">
            <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Profile</h4>
                </div>
                {/* {loading ? (
                    <div
                        className="container text-center"
                        style={{ paddingBottom: "150px", paddingTop: "200px" }}
                    >
                        <div class="spinner-grow text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-info" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : ( */}
                    <>
                        <div class="col-md-3">
                            <div class="card custom-card mb-3">
                                <div class="card-body text-black fw-bold fs-6">
                                    <div class="row">
                                        <div class="text-center pt-3">
                                            <img src="" style={{ width: '150px', background: "#c7e2b8" }} class="rounded" alt="..." />
                                        </div>
                                        <div class="text-center pt-4">
                                            <p>Name : Sanjay Kumar</p>
                                            <p>DOB : 02-10-1994</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="card custom-card mb-3">
                                <div class="card-header justify-content-between"><div class="card-title">Profile</div></div>
                                <div class="card-body text-black fw-bold fs-6">
                                    <div class="row">
                                        <div class="col-md-3 col-lg-6 fs-15">

                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-4 col-form-label">Father's Name</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" disabled value="Sanjay" />
                                                </div>
                                            </div>
                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-4 col-form-label">Mother's Name</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" disabled value="" />
                                                </div>
                                            </div>
                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-4 col-form-label">Email ID</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" disabled value="" />
                                                </div>
                                            </div>
                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-4 col-form-label">Mobile No</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" disabled value="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-lg-6 fs-15">
                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-4 col-form-label">Academic Session</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" disabled value="" />
                                                </div>
                                            </div>
                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-4 col-form-label">Class</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" disabled value="" />
                                                </div>
                                            </div>
                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-4 col-form-label">Roll No</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" disabled value="" />
                                                </div>
                                            </div>
                                            <div class="mb-3 row">
                                                <label for="inputPassword" class="col-sm-4 col-form-label">Section</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" disabled value="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                {/* )} */}
            </div>
        </div>
    </>);
}

export default Student;