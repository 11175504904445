import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
function Section() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [SubmitData, setSubmitData] = useState({
    section: "",
  });
  const [SectionData, setSectionData] = useState({
    id: "",
    section: "",
  });
  const [sectionLoading, setSectionLoading] = useState(false);
  const history = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSectionLoading(true);
    try {
      const response = await axiosInstance.post(
        "/Master/AddSection",
        SubmitData
      );
      // console.log(response.data);
      if (!response.data.Status === "success") {
        throw new Error("Section Added Failed");
      }

      toast.success("Section Added Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
      setSectionLoading(false);
      setSubmitData({ section: "" });
    } catch (error) {
      setSectionLoading(false);
      toast.error("Section Added failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // Table Data
  const fetchData = async () => {
    setTableLoading(true)
    try {
      const response = await axiosInstance.get(`/Master/GetSection`);
      setData(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    }finally{
      setTableLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Edit table data..
  const handleEditClick = (id) => {
    setEditingId(id);
  };
  // Edit data save..
  const handleSaveClick = async (e) => {
    e.preventDefault();
    const sessionPattern = /^[A-Za-z0-9\s'-]+$/;
    if (!sessionPattern.test(SectionData.section)) {
      toast.error("Special characters are not allowed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setEditingId(null);
    try {
      const response = await axiosInstance.post(
        "/Master/UpdateSection",
        SectionData
      );
      if (!response.data.Status === "successs") {
        throw new Error("Section updated failed");
      }
      toast.success("Section updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      history("/admin/master/manage-section");
    } catch (error) {
      toast.error("Section updated failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleCancelClick = () => {
    setEditingId(null);
  };
  const handleInputChange = (e, id, key) => {
    const newData = data.map((item) =>
      item.id === id ? { ...item, [key]: e.target.value } : item
    );
    setSectionData((prev) => ({ ...prev, id: id, section: e.target.value }));
    setData(newData);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Section</h4>
          </div>
          <div class="col-lg-4">
            <form onSubmit={handleSubmit}>
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between">
                  <div class="card-title">Add Section</div>
                </div>
                <div class="card-body">
                  <div class=" form-material ">
                    <label class="form-label">
                      Section<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                      placeholder="Enter section name.."
                      name="section"
                      value={SubmitData.section}
                      onChange={(e) =>
                        setSubmitData({
                          ...SubmitData,
                          section: e.target.value,
                        })
                      }
                      required
                    />

                    <div className="text-end mt-3">
                      <button
                        type="submit"
                        class="btn btn-primary mb-3 btn-block"
                      >
                        {sectionLoading ? (
                          <span
                            class="spinner-border spinner-border-sm me-2 shadow bg-body-tertiary rounded"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-8">
            <div class="card custom-card mb-3">
              <div class="card-header justify-content-between">
                <div class="card-title">Section List</div>
              </div>
              <div class="card-body py-3 px-3">
                {tableLoading ? (
                  <div class="table-responsive">
                    <table class="table text-nowrap table-hover border table-bordered">
                      <thead class="">
                        <tr>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Section</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data ? (
                            data.map((row,index) => (
                              <tr className="text-black fs-5" key={row.id}>
                                <td>{index+1}</td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      defaultValue={row.section}
                                      className="form-control"
                                      pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "section")
                                      }
                                    />
                                  ) : (
                                    row.section
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <>
                                      <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick}><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                      <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                    </>
                                  ) : (
                                    <span
                                      class="btn btn-primary shadow btn-xs sharp "
                                      onClick={() => handleEditClick(row.id)}
                                    >
                                      <i class="fas fa-pencil-alt"></i>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <p>No data available</p>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section;
