import React from "react";
import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../parent-helper";
import Loader from "../../../Loader";
const MonthAttendance = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/Parent/GetMonthlyAttendance`);
                setData(response.data.Payload);
            } catch (error) {
                window.location.href = "/parent";
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    return (
        <div>

            <div className="container-fluid">
                <div className="row">
                    <div class="card-header">
                        <h4 class="card-title">Attendance Report</h4>
                    </div>
                    <div class="col-lg-12">
                        <div class="card custom-card mb-3">
                            <div class="card-header justify-content-between"><div class="card-title">Monthly Attendance</div></div>
                            <div class="card-body py-3 px-3">
                                <div class="table-responsive">
                                    {loading ? (
                                        <Loader />
                                    ) : (
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Month</th>
                                                    <th>Name</th>
                                                    <th>Present</th>
                                                    <th>Absent</th>
                                                    <th>Month Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data ?
                                                    data.map((row, index) => (
                                                        <tr className="text-black fs-5" key={row.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{row.monthname}</td>
                                                            <td>{row.name}</td>
                                                            <td>
                                                                <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                    <div class="progress-bar bg-success fw-bold" style={{ width: `${Math.round((row.present / (row.present + row.absent)) * 100)}%` }}>{Math.round((row.present / (row.present + row.absent)) * 100)}%</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                    <div class="progress-bar bg-danger fw-bold" style={{ width: `${Math.round((row.absent / (row.present + row.absent)) * 100)}%` }}>{Math.round((row.absent / (row.present + row.absent)) * 100)}%</div>
                                                                </div>
                                                            </td>
                                                            <td><Link to={`/parent/report/attendance/view-attendance/${row.monthno}`} class="btn btn-xs btn-primary">View Day Wise</Link></td>
                                                        </tr>
                                                    )) :
                                                    <p>No Data Available</p>
                                                }
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MonthAttendance;