import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

function PaymentTransaction() {
    const Navigate = useNavigate();
    const [data, setData] = useState({});
    const { studentid, transactionId } = useParams();
    const [loading, setLoading] = useState(true)
    const [SubmitData, setSubmitData] = useState({
        studentid: studentid,
        transactionId: transactionId,
        totalamount: data.totalpaidamount ?? 0,
        paymentmode: 'cash',
        upiId: '',
    });
    console.log('SubmitData is ', SubmitData)
    const [loadSubmit, setLoadSubmit] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadSubmit(true)
        try {
            const response = await axiosInstance.post('/ManageFees/CreatePaymentTransaction', SubmitData);
            console.log('Response:', response.data);
            if (!response.data.Status === 'success') {
                throw new Error('Fee transaction failed');
            }
            toast.success('Fee transaction successfully!', {
                position: toast.POSITION.TOP_RIGHT,
            });
            setLoadSubmit(false)
           Navigate(`/admin/students/detail/${studentid}`);

        } catch (error) {
            setLoadSubmit(false)
            toast.error('authentication fee failed', {
                position: toast.POSITION.TOP_RIGHT,
            });
            // window.location.href="/";
        }
    };

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`/ManageFees/GetAmountByTransactionId?transno=${transactionId}`);
            setData(response.data);
            setSubmitData({ ...SubmitData, totalamount: response.data.totalamount });
        } catch (error) {
            toast.error("Authentication failed !");
            // window.location.href="/";
        }
    };
    console.log('data', data);

    useEffect(() => {
        fetchData();
        const loaderTimeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(loaderTimeout);
    }, []);

    const [selectedOption, setSelectedOption] = useState('cash');
    const handleRadioChange2 = (event) => {
        setSelectedOption(event.target.value);
        setSubmitData({ ...SubmitData, paymentmode: event.target.value });

    };
    useEffect(() => {
        const loaderTimeout = setTimeout(() => {
            setLoading(false)
        }, 3000)
        return () => clearTimeout(loaderTimeout)
    }, []);
    return (
        <>
            <div>
                <div className="container-fluid">
                    <div className="row pt-4">
                        {/* {/ <h3 class="text-center">Payment Transaction</h3> /} */}
                        <div class="col-lg-3 pt-4"></div>
                        <div class="col-lg-6 pt-4">
                            {
                                loading ?
                                    (<div className="container text-center" style={{ paddingTop: '200px' }}>
                                        <div class="spinner-grow text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-secondary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-success" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-danger" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-info" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-light" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-dark" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div></div>)
                                    : (<div class="card">
                                        <div class="modal-header text-white text-center btn-primary" style={{height: "50px" }}>
                                            <h4 className='card-title text-white text-center'>Payment Transaction</h4>
                                        </div>
                                        <div class="card-body text-black fw-bold fs-6">
                                            <form onSubmit={handleSubmit}>
                                                <div class="row form-material">
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Total Amount</label>
                                                        <input type="text" class="form-control" name="totalamount" value={data.totalpaidamount} onChange={(e) => setSubmitData({ ...SubmitData, totalamount: e.target.value })} disabled />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-3 mb-3">
                                                        <label class="form-label">Mode of payment</label><br />
                                                        <input type="radio" class="form-check-input" name="paymentmode" value="cash" checked={selectedOption === 'cash'} onChange={handleRadioChange2} />{' '}
                                                        <label class="form-label">Cash</label>
                                                        <input type="radio" class="form-check-input ms-4" name="paymentmode" value="upi" checked={selectedOption === 'upi'} onChange={handleRadioChange2} />{' '}
                                                        <label class="form-label">UPI</label>
                                                    </div>
                                                    {selectedOption === 'upi' && (
                                                        <div class="col-xl-3 col-xxl-12 col-md-6">
                                                            <label class="form-label">UPI Number</label>
                                                            <input class="form-control" type="text" placeholder="Enter UPI number" name="upiId" value={SubmitData.upiId} onChange={(e) => setSubmitData({ ...SubmitData, upiId: e.target.value })} />
                                                        </div>
                                                    )}
                                                    <div className="text-end pt-4">
                                                        <button type="submit" className="btn btn-primary" style={{ width: '100px' }}>
                                                            {
                                                                loadSubmit ? (<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>) : "Pay"
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    )}
                        </div>
                        <div class="col-lg-3 pt-4"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentTransaction;