import React from 'react';
// import jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
//	Public 
import Login from './Components/Login';
// Admin
import Registration from './Components/registration';
import ManageRegister from './Components/registration/manage-registration';
import CreateRegister from './Components/registration/create';
import FeeReport from './Components/report/fee-report';
import Dashboard from './Components/Dashboard';
import Enquiry from './Components/enquiry/Enquiry';
import ManageEnquiry from './Components/enquiry/New';
import OuterEnquiry from './Components/enquiry/New';
import View from './Components/enquiry/View';
import Applications from './Components/application/List';
import NewStudents from './Components/student/new';
import Students from './Components/student/List';
import FeeHistory from './Components/student/fee-history';
import ManageStudent from './Components/student/manage-student';
import BlockStudent from './Components/student/block-list';
import ManageAcademic from './Components/student/manage-academic';
import ManageFather from './Components/student/manage-father';
import ManageMother from './Components/student/manage-mother';
import ManageGardian from './Components/student/manage-gardian';
import StudentDocument from './Components/student/student-document';
import MedicalDetail from './Components/student/medical-detail';
import OtherDetail from './Components/student/other-detail';
import PaymentTransaction from './Components/student/payment-transaction';
import ManageTransport from './Components/student/manage-transport';
import ViewStudent from './Components/student/View';
import Payment from './Components/student/payment';
import MonthPayment from './Components/student/monthly-payment';
import FeeDetail from './Components/student/fee-detail';
import AdminProfile from './Components/profile/admin-profile';
import UnpaidFees from './Components/report/unpaid-fees';
import NewApplication from './Components/application/New';
import ViewApplication from './Components/application/View';
import ViewApplicationFees from './Components/application/Fees';
import Teacher from './Components/teacher/List';
import CreateTeacher from './Components/teacher/manage-teacher';
import BlockTeacher from './Components/teacher/block-list';
import IdCardList from './Components/IdCard/IdCardList';
// import ViewTeacher from './Components/teacher/View';
import Branch from './Components/branch/List';
import ManageBranch from './Components/branch/manage-branch';
import ViewBranch from './Components/branch/View';
import ManageFees from './Components/fees/manage-fees';
import Monthlyfee from './Components/fees/monthly-fee';
import Class from './Components/masters/manage-class';
import CreateFeeType from './Components/masters/create-fee-type';
import Transport from './Components/masters/manage-transport';
import Vendor from './Components/masters/manage-vendor';
import Fee from './Components/masters/manage-fee';
import FeeType from './Components/masters/fee-type';
import Section from './Components/masters/manage-section';
import Slab from './Components/masters/manage-slab';
import Library from './Components/masters/manage-library';
import LateFee from './Components/masters/manage-late-fee';
import Error404 from './Components/Error404';
import Receipt from './Components/student/receipt';
import FeeStructure from './Components/report/fee-structure';
import FeeSummaryReport from './Components/report/fee-summary';
import TodayAttendance from './Components/attendance/mark-attendance';
import TodayAttendanceAdmin from './Components/attendance/today-attendance';
import MonthAttendance from './Components/attendance/monthly-attendance';
import ViewAttendanceAdmin from './Components/attendance/view-attendance';
import ManageRequest from './Components/library/request-list';
import BookInfo from './Components/library/book-info';
import BookList from './Components/library/book-list';
import Book from './Components/masters/manage-book/manage-book-system';
import Academic from './Components/masters/manage-academic';
import ManageNotice from './Components/masters/manage-notice';
import ChangePassword from './Components/setting/change-password';
import ManageSiblings from './Components/masters/manage-siblings';
import ManageExpense from './Components/expense/manage-expense';
import AddExpense from './Components/expense/add-expense';
import ExpenseReceipt from './Components/expense/expense-receipt';
import Calendar from './Components/attendance/calendar';
import StudentLeave from './Components/student/leave/student-leave';
import TeacherLeave from './Components/student/leave/teacher-leave';


//parents
import ParentLogin from './Components/parent/parent-login';
import ParentDashboard from './Components/parent/Dashboard';
import ParentStudent from './Components/parent/Student';
import Assignment from './Components/parent/Assignment';
import AssignTeacher from './Components/parent/Teacher';
import TeacherDetail from './Components/parent/View-Teacher';
import LeaveApply from './Components/parent/leave/leave-apply';
import PFeeStructure from './Components/parent/report/fee-structure';
import FeeSummary from './Components/parent/report/fee-summary';
import AttendanceMonthly from './Components/parent/attendance/monthly-attendance';
import ViewMonthly from './Components/parent/attendance/view-attendance';
import ApplyBook from './Components/parent/library/apply-book';
//Teacher
import TeacherLogin from './Components/teacher/teacher-login';
import TeacherDashboard from './Components/teacher/Dashboard';
import MarkAttendance from './Components/teacher/attendance/mark-attendance';
import MonthlyAttendance from './Components/teacher/attendance/monthly-attendance';
import AttendanceToday from './Components/teacher/attendance/today-attendance';
import MonthViewAttendance from './Components/teacher/attendance/view-attendance';
import ManageAssignment from './Components/teacher/manage-assignment';
import ManageClass from './Components/teacher/manage-class';
import ManageSubject from './Components/teacher/manage-subject';
import MyProfile from './Components/teacher/View';
import StudentLeaveApp from './Components/teacher/leave/student-leave';
import SuperAdmin from './Components/super-admin/Admins.tsx';
import AdminReg from './Components/super-admin/ManageAdmin.tsx';
import SuperAdminLogin from './Components/super-admin/Login.tsx';
import View_Teacher from './Components/teacher/View';
import TeacherLeaveApp from './Components/teacher/leave/leave-apply';
import Complaint from './Components/parent/complaint/complaint.jsx';
import ParentComplaint from './Components/student/complaint/parent-complaint.jsx';
import Home from './Outlet/Home.jsx';

//check path
const path = document.location.pathname;
let layout = 'public';

if (/^\/admin/i.test(path)) {
    layout = 'admin';
} else if (/^\/parent/i.test(path)) {
    layout = 'parent';
} else if (/^\/teacher/i.test(path)) {
    layout = 'teacher';
} else if (/^\/super-admin/i.test(path)) {
    layout = 'super-admin';
}

//  Check if user is logged in
let user = {};
const token = localStorage.getItem('token') || '';
if (token) {
        const decoded = jwtDecode(token);
		if (decoded.exp && Date.now() <= decoded.exp * 1000 ) {
            user = decoded;
            user.token = token;
        }
}

const Routes = [
	{
		path: '/',
		element: <Home />
	},
	{
		path: '/login',
		element: <Login layout={layout} user={user} />
	},
	{
		path: '/enquiry',
		element: <OuterEnquiry layout={layout} user={user} />
	},
	// Super Admin
	{
		path: '/super-admin',
		children: [
			{
				path: '',
				element: <SuperAdminLogin layout={layout} user={user} />
			},
			{
				path: 'admins',
				element: <SuperAdmin layout={layout} user={user} />
			},
			{
				path: 'create/admin',
				element: <AdminReg layout={layout} user={user} />
			},
			{
				path: 'edit/admin/:userID',
				element: <AdminReg layout={layout} user={user} />
			},
		],
	},
	//	Teachers Paths
	{
		path: '/teacher',
		children: [
			{
				path: '',
				element: <TeacherLogin layout={layout} user={user} />
			},
			{
				path: 'dashboard',
				element: <TeacherDashboard layout={layout} user={user} />
			},
			{
				path: 'manage-class',
				element: <ManageClass layout={layout} user={user} />
			},
			{
				path: 'manage-subject',
				element: <ManageSubject layout={layout} user={user} />
			},
			{
				path: 'manage-assignment',
				element: <ManageAssignment layout={layout} user={user} />
			},
			{
				path: 'attendance/mark-attendance',
				element: <MarkAttendance layout={layout} user={user} />
			},
			{
				path: 'leave/student-leave',
				element: <StudentLeaveApp layout={layout} user={user} />
			},
			{
				path: 'attendance/today-attendance',
				element: <AttendanceToday layout={layout} user={user} />
			},
			{
				path: 'attendance/month-attendance',
				element: <MonthlyAttendance layout={layout} user={user} />
			},
			{
				path: 'attendance/month-attendance/view-attendance',
				element: <MonthViewAttendance layout={layout} user={user} />
			},
			{
				path: 'my-profile',
				element: <MyProfile layout={layout} user={user} />
			},
			{
				path: 'leave/leave-apply',
				element: <TeacherLeaveApp layout={layout} user={user} />
			},
		],
	},
	//	Parents Paths
	{
		path: '/parent',
		children: [
			{
				path: '',
				element: <ParentLogin layout={layout} user={user} />
			},
			{
				path: 'dashboard',
				element: <ParentDashboard layout={layout} user={user} />
			},
			{
				path: 'student',
				element: <ParentStudent layout={layout} user={user} />
			},
			{
				path: 'assignment',
				element: <Assignment layout={layout} user={user} />
			},
			{
				path: 'assign-teacher',
				element: <AssignTeacher layout={layout} user={user} />
			},
			{
				path: 'teacher-detail/:teacherid',
				element: <TeacherDetail layout={layout} user={user} />
			},
			{
				path: 'leave/leave-apply',
				element: <LeaveApply layout={layout} user={user} />
			},
			{
				path: 'report/fee-structure',
				element: <PFeeStructure layout={layout} user={user} />
			},
			{
				path: 'report/fee-summary',
				element: <FeeSummary layout={layout} user={user} />
			},
			{
				path: 'complaint',
				element: <Complaint layout={layout} user={user}/>
			},
			{
				path: 'report/attendance/monthly-attendance',
				element: <AttendanceMonthly layout={layout} user={user} />
			},
			{
				path: 'report/attendance/view-attendance/:monthno',
				element: <ViewMonthly layout={layout} user={user} />
			},
			{
				path: 'library/apply-book',
				element: <ApplyBook layout={layout} user={user} />
			},
		],
	},
	//	Admin Paths
	{
		path: '/admin',
		children: [
			{
				path: 'registration',
				element: <Registration layout={layout} user={user} />
			},
			{
				path: 'dashboard',
				element: <Dashboard layout={layout} user={user} />
			},
			{
				path: 'enquiry',
				element: <Enquiry layout={layout} user={user} />
			},
			{
				path: 'enquiries',
				element: <Enquiry layout={layout} user={user} />
			},
			{
				path: 'enquiry/create',
				element: <ManageEnquiry layout={layout} user={user} />
			},
			{
				path: 'enquiry/:id',
				element: <View layout={layout} user={user} />
			},
			{
				path: 'applications',
				element: <Applications layout={layout} user={user} />
			},
			{
				path: 'application/create',
				element: <NewApplication layout={layout} user={user} />
			},
			{
				path: 'application/convert',
				element: <NewApplication layout={layout} user={user} />
			},
			{
				path: 'application/edit/:id',
				element: <NewApplication layout={layout} user={user} />
			},
			{
				path: 'application/view/:id',
				element: <ViewApplication layout={layout} user={user} />
			},
			{
				path: 'application/fees',
				element: <ViewApplicationFees layout={layout} user={user} />
			},
			{
				path: 'master/manage-class',
				element: <Class layout={layout} user={user} />
			},
			{
				path: 'master/manage-academic',
				element: <Academic layout={layout} user={user} />
			},
			{
				path: 'master/manage-notice',
				element: <ManageNotice layout={layout} user={user} />
			},
			{
				path: 'master/create-fee-type',
				element: <CreateFeeType layout={layout} user={user} />
			},
			{
				path: 'master/manage-transport',
				element: <Transport layout={layout} user={user} />
			},
			{
				path: 'master/manage-vendor',
				element: <Vendor layout={layout} user={user} />
			},
			{
                path: 'master/manage-book/manage-book-system',
                element: <Book layout={layout} user={user} />
            },
			{
				path: 'master/manage-fee',
				element: <Fee layout={layout} user={user} />
			},
			{
				path: 'master/fee-type',
				element: <FeeType layout={layout} user={user} />
			},
			{
				path: 'master/manage-section',
				element: <Section layout={layout} user={user} />
			},
			{
				path: 'master/manage-slab',
				element: <Slab layout={layout} user={user} />
			},
			{
				path: 'master/manage-library',
				element: <Library layout={layout} user={user} />
			},
			{
				path: 'master/manage-late-fee',
				element: <LateFee layout={layout} user={user} />
			},
			{
				path: 'master/manage-siblings',
				element: <ManageSiblings layout={layout} user={user} />
			},
			{
				path: 'registration/manage-registration',
				element: <ManageRegister layout={layout} user={user} />
			},
			{
				path: 'registration/create',
				element: <CreateRegister layout={layout} user={user} />
			},
			{
				path: 'registration/create/:studentid/:id',
				element: <CreateRegister layout={layout} user={user} />
			},
			{
				path: 'students/manage-student',
				element: <ManageStudent layout={layout} user={user} />
			},
			{
				path: 'students/block-list',
				element: <BlockStudent layout={layout} user={user} />
			},
			{
				path: 'students/manage-student/:studentid/:id',
				element: <ManageStudent layout={layout} user={user} />
			},
			{
				path: 'students/manage-academic/:studentid',
				element: <ManageAcademic layout={layout} user={user} />
			},
			{
				path: 'students/manage-academic/:studentid/:id',
				element: <ManageAcademic layout={layout} user={user} />
			},
			{
				path: 'students/manage-father/:studentid',
				element: <ManageFather layout={layout} user={user} />
			},
			{
				path: 'students/manage-father/:studentid/:id',
				element: <ManageFather layout={layout} user={user} />
			},
			{
				path: 'students/manage-mother/:studentid',
				element: <ManageMother layout={layout} user={user} />
			},
			{
				path: 'students/manage-mother/:studentid/:id',
				element: <ManageMother layout={layout} user={user} />
			},
			{
				path: 'students/manage-gardian/:studentid',
				element: <ManageGardian layout={layout} user={user} />
			},
			{
				path: 'students/manage-gardian/:studentid/:id',
				element: <ManageGardian layout={layout} user={user} />
			},
			{
				path: 'students/student-document/:studentid',
				element: <StudentDocument layout={layout} user={user} />
			},
			{
				path: 'students/student-document/:studentid/:id',
				element: <StudentDocument layout={layout} user={user} />
			},
			{
				path: 'students/medical-detail/:studentid',
				element: <MedicalDetail layout={layout} user={user} />
			},
			{
				path: 'students/medical-detail/:studentid/:id',
				element: <MedicalDetail layout={layout} user={user} />
			},
			{
				path: 'students/other-detail/:studentid',
				element: <OtherDetail layout={layout} user={user} />
			},
			{
				path: 'students/manage-transport/:studentid',
				element: <ManageTransport layout={layout} user={user} />
			},
			{
				path: 'students/manage-transport/:studentid/:id',
				element: <ManageTransport layout={layout} user={user} />
			},
			{
				path: 'students/other-detail/:studentid/:id',
				element: <OtherDetail layout={layout} user={user} />
			},
			{
				path: 'students/list',
				element: <Students layout={layout} user={user} />
			},
			{
				path: 'students/fee-history/:studentid',
				element: <FeeHistory layout={layout} user={user} />
			},
			{
				path: 'students/create',
				element: <NewStudents layout={layout} user={user} />
			},
			{
				path: 'students/fee-detail',
				element: <FeeDetail layout={layout} user={user} />
			},
			{
				path: 'profile/admin-profile',
				element: <AdminProfile layout={layout} user={user} />
			},
			{
				path: 'students/detail/:studentid',
				element: <ViewStudent layout={layout} user={user} />
			},
			{
				path: 'students/payment/:studentid',
				element: <Payment layout={layout} user={user} />
			},
			{
				path: 'students/monthly-payment/:studentid',
				element: <MonthPayment layout={layout} user={user} />
			},
			{
				path: 'students/payment-transaction/:studentid/:transactionId',
				element: <PaymentTransaction layout={layout} user={user} />
			},
			{
				path: 'students/fee-receipt',
				element: <Receipt layout={layout} user={user} />
			},
			{
				path: 'teachers',
				element: <Teacher layout={layout} user={user} />
			},
			{
				path: 'teachers/create',
				element: <CreateTeacher layout={layout} user={user} />
			},
			{
				path: 'teachers/edit/:teacherid/:id',
				element: <CreateTeacher layout={layout} user={user} />
			},
			{
				path: 'teachers/block-list',
				element: <BlockTeacher layout={layout} user={user} />
			},
			{
				path: 'teachers/view/:teacherid',
				element: <View_Teacher layout={layout} user={user} />
			},
			
			{
				path: 'branch',
				element: <Branch layout={layout} user={user} />
			},
			{
				path: 'branch/create',
				element: <ManageBranch layout={layout} user={user} />
			},
			{
				path: 'branch/edit/:id',
				element: <ManageBranch layout={layout} user={user} />
			},
			{
				path: 'branch/detail/:id',
				element: <ViewBranch layout={layout} user={user} />
			},
			{
				path: 'manage-fee',
				element: <ManageFees layout={layout} user={user} />
			},
			{
				path: 'fees/manage-fee/:id',
				element: <Monthlyfee layout={layout} user={user} />
			},
			{
				path: 'report/fee-structure',
				element: <FeeStructure layout={layout} user={user} />
			},
			{
				path: 'attendance/mark-attendance',
				element: <TodayAttendance layout={layout} user={user} />
			},
			{
				path: 'attendance/today-attendance',
				element: <TodayAttendanceAdmin layout={layout} user={user} />
			},
			{
				path: 'attendance/monthly-attendance',
				element: <MonthAttendance layout={layout} user={user} />
			},
			{
				path: 'attendance/view-attendance/:studentid/:month',
				element: <ViewAttendanceAdmin layout={layout} user={user} />
			},
			{
				path: 'report/fee-report',
				element: <FeeReport layout={layout} user={user} />
			},
			{
				path: 'report/fee-summary/:studentid',
				element: <FeeSummaryReport layout={layout} user={user} />
			},
			{
				path: 'report/unpaid-fee',
				element: <UnpaidFees layout={layout} user={user} />
			},
			{
				path: 'library/manage-request',
				element: <ManageRequest layout={layout} user={user} />
			},
			{
				path: 'library/manage-request/book-info/:studentid',
				element: <BookInfo layout={layout} user={user} />
			},
			{
				path: 'library/book-list',
				element: <BookList layout={layout} user={user} />
			},
			{
                path: 'IDCard/IdCard-list',
                element: <IdCardList layout={layout} user={user} />
            },
			{
				path: 'expense/manage-expense',
				element: <ManageExpense layout={layout} user={user} />
			},
			{
				path: 'expense/add-expense',
				element: <AddExpense layout={layout} user={user} />
			},
			{
				path: 'expense/expense-receipt/:expenid',
				element: <ExpenseReceipt layout={layout} user={user} />
			},
			{
				path: 'attendance/calendar/:studentid/:month',
				element: <Calendar layout={layout} user={user} />
			},
			{
				path: 'leave/student-leave',
				element: <StudentLeave layout={layout} user={user} />
			},
			{
				path: 'leave/teacher-leave',
				element: <TeacherLeave layout={layout} user={user} />
			},
			{
				path: 'parent-complaint',
				element: <ParentComplaint layout={layout} user={user} />
			},
			{
				path: 'setting/change-password',
				element: <ChangePassword layout={layout} user={user} />
			}
		]
	},
	{
		path: '*',
		element: <Error404 layout={layout} user={user} />
	}
];

export default Routes;