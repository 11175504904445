import React from 'react';
import { Link } from 'react-router-dom';
const MonthAttendance = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row pt-4">
                    <h3 class="text-center">Monthly Attendance</h3>
                    <div class="col-lg-12 pt-4">
                        <form class="form-valide-with-icon needs-validation">
                            <div class="card">
                                <div class="card-body text-black fw-bold fs-6">
                                    <h4 className='card-title'>Select Criteria</h4><hr />
                                    <div class="row form-material">
                                        <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                            <label class="form-label">Class</label>
                                            <select class="form-control" id="autoSizingSelect">
                                                <option selected>Select class...</option>
                                                <option value="Nursery">Nursery</option>
                                                <option value="LKG">LKG</option>
                                                <option value="UKG">UKG</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                            <label class="form-label">Section</label>
                                            <select class="form-control" id="autoSizingSelect">
                                                <option selected>Select section...</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                            <label class="form-label">Month</label>
                                            <select class="form-control" id="autoSizingSelect">
                                                <option selected>Select month...</option>
                                                <option value="Jan">Jan</option>
                                                <option value="Feb">Feb</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="Jun">Jun</option>
                                                <option value="July">July</option>
                                                <option value="Aug">Aug</option>
                                                <option value="Sept">Sept</option>
                                                <option value="Oct">Oct</option>
                                                <option value="Nov">Nov</option>
                                                <option value="Dec">Dec</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                            <label class="form-label">Year</label>
                                            <select class="form-control" id="autoSizingSelect">
                                                <option selected>Select year...</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                            </select>
                                        </div>
                                        <div className="text-end">
                                            <button type="button" class="bg-black text-white fw-bold mb-3" style={{ width: "100px" }}><i class="bi bi-search"></i> Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-12 pt-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row form-material">
                                    <div class="col-xl-3 col-xxl-10 col-md-6 mb-3">
                                        <h3 class="card-title">Monthly Attendance List</h3>
                                    </div>
                                    <div class="col-xl-3 col-xxl-2 col-md-6 mb-3 text-end">
                                        <h3 class="card-title badge badge-xs light badge-secondary"><i class="bi bi-calendar-check"></i> Dec-2023</h3>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Roll No.</th>
                                                <th>Name</th>
                                                <th>Present</th>
                                                <th>Absent</th>
                                                <th>Month Detail</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>1</td>
                                                <td>100001</td>
                                                <td>Ronak</td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success fw-bold" style={{ width: '75%' }}>75%</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-danger fw-bold" style={{ width: '25%' }}>25%</div>
                                                    </div>
                                                </td>
                                                <td><Link to="/teacher/attendance/month-attendance/view-attendance" class="bg-black text-white btn-sm fw-bold fs-5"><i class="bi bi-emoji-frown fs-5"></i> Attendance</Link></td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>2</td>
                                                <td>100002</td>
                                                <td>Ankit</td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success fw-bold" style={{ width: '65%' }}>65%</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-danger fw-bold" style={{ width: '35%' }}>35%</div>
                                                    </div>
                                                </td>
                                                <td><Link to="/teacher/attendance/month-attendance/view-attendance" class="bg-black text-white btn-sm fw-bold fs-5"><i class="bi bi-emoji-frown fs-5"></i> Attendance</Link></td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>3</td>
                                                <td>100003</td>
                                                <td>Rohit</td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success fw-bold" style={{ width: '55%' }}>55%</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-danger fw-bold" style={{ width: '45%' }}>45%</div>
                                                    </div>
                                                </td>
                                                <td><Link to="/teacher/attendance/month-attendance/view-attendance" class="bg-black text-white btn-sm fw-bold fs-5"><i class="bi bi-emoji-frown fs-5"></i> Attendance</Link></td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>4</td>
                                                <td>100004</td>
                                                <td>Abhi</td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success fw-bold" style={{ width: '70%' }}>70%</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-danger fw-bold" style={{ width: '30%' }}>30%</div>
                                                    </div>
                                                </td>
                                                <td><Link to="/teacher/attendance/month-attendance/view-attendance" class="bg-black text-white btn-sm fw-bold fs-5"><i class="bi bi-emoji-frown fs-5"></i> Attendance</Link></td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>5</td>
                                                <td>100005</td>
                                                <td>Kiyara</td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success fw-bold" style={{ width: '75%' }}>75%</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-danger fw-bold" style={{ width: '25%' }}>25%</div>
                                                    </div>
                                                </td>
                                                <td><Link to="/teacher/attendance/month-attendance/view-attendance" class="bg-black text-white btn-sm fw-bold fs-5"><i class="bi bi-emoji-frown fs-5"></i> Attendance</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <a class="page-link">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonthAttendance;