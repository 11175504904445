import React, { useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../admin-helper";
import html2pdf from 'html2pdf.js';

const IdCardList = () => {
  const [loading, setLoading] = useState(true);
  const [studentIdDetail, setStudentIdDetail] = useState([]);
  // fetching ID Card details
  const fetchStudentDetail = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/Admin/GetallStudentIdCard");
      setStudentIdDetail(response.data.Payload);
      setLoading(false);
    } catch (error) {
      console.error("message Error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchStudentDetail();
  }, []);

  // pagination code
  const dataPerPage = 12;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPage = Math.ceil(studentIdDetail?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const generatePDF = () => {
    const content = document.getElementById('printableCard');
    const pdfOptions = { filename: 'receipt.pdf' };
    html2pdf(content, pdfOptions);
  };

  return (
    <>
      {
        loading ?
          (
            <div
              className="container text-center"
              style={{ paddingTop: "100px" }}
            >
              <div class="spinner-grow text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) :
          (<>
            <div className="container-fluid pt-5">
              <div id="printableCard"
                className="row w-100"
              >
                {studentIdDetail ? (studentIdDetail.slice(
                  currentPage * dataPerPage,
                  (currentPage + 1) * dataPerPage
                ).map((row) => (
                  <div class="team-card" key={row.studentid}>
                    <div class="photo">
                      <img src={row.student_pic ? `https://schoolapi.mnbsoft.co.in/UploadImage/${row.student_pic}` : `/images/user.png`} />
                    </div>
                    <div class="name" id="content">
                      <span id="ctl00_ContentPlaceHolder1_lblName">{row.name}</span>
                      <p class="status">Father Name : {row.fathername}</p>
                      <p class="status"><strong>Rank: DOUBLE DIAMOND</strong></p>
                    </div>
                    <div class="post">ID :<span id="ctl00_ContentPlaceHolder1_lbluserid">{row.studentid}</span></div>
                    <div class="post">CLASS :<span id="ctl00_ContentPlaceHolder1_lbldate"> {row.sclass}</span></div>
                    <div class="post">MOBILE NO. : +91<span id="ctl00_ContentPlaceHolder1_lblmob"> {row.fathermobileno}</span></div>
                  </div>
                ))) : (<p>No Data Available</p>)}
              </div>
              <div className="card-footer text-center">
                <button onClick={generatePDF}>Print</button>
              </div>
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                  <li class="page-item">
                    <a
                      class="page-link"
                      style={{
                        pointerEvents:
                          currentPage === 0 ? "none" : "auto",
                        opacity: currentPage === 0 ? 0.5 : 1,
                      }}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {pageIndex
                    .slice(
                      Math.max(0, currentPage - 2),
                      Math.min(numberOfPage, currentPage + 2)
                    )
                    .map((page) => (
                      <li
                        class={`page-item ${page === currentPage + 1 ? "active" : ""
                          }`}
                        key={page}
                      >
                        <a
                          class="page-link"
                          style={{
                            pointerEvents:
                              page - 1 === currentPage
                                ? "none"
                                : "auto",
                            opacity: page - 1 === currentPage ? 0.5 : 1,
                          }}
                          onClick={() => handlePageChange(page - 1)}
                          href="#"
                        >
                          {page}
                        </a>
                      </li>
                    ))}
                  <li class="page-item">
                    <a
                      class="page-link"
                      style={{
                        pointerEvents:
                          currentPage === numberOfPage - 1
                            ? "none"
                            : "auto",
                        opacity:
                          currentPage === numberOfPage - 1 ? 0.5 : 1,
                      }}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </>
          )}
    </>
  );
};

export default IdCardList;
