import React, { useState } from "react";
import { toast } from 'react-toastify';
import { axiosInstance } from "../../admin-helper";
function ChangePassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonLoading(true);
        try {
            // Make API call to reset password
            const response = await axiosInstance.post('/Admin/UpdatePassword', {
                oldPassword,
                newPassword
            });
            console.log('response', response);
            if (response.data.Status === 'success') {
                toast.success('Password reset successfully!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setOldPassword('');
                setNewPassword('');
            } else {
                throw new Error('Reset password failed');
            }
            setButtonLoading(false);
        } catch (error) {
            setButtonLoading(false);
            toast.error("Reset password failed", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="card-header">
                    <h4 className="card-title"></h4>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="card custom-card mb-3">
                        <div className="card-header justify-content-between">
                            <div className="card-title">Reset Password</div>
                        </div>
                        <div className="card-body text-black fw-bold fs-6">
                            <div className="row">
                                <div className="col-md-12 fs-15">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3 row">
                                            <label htmlFor="oldPassword" className="col-sm-4 col-form-label">Old Password</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="oldPassword" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} placeholder="Enter old password"/>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="newPassword" className="col-sm-4 col-form-label">New Password</label>
                                            <div className="input-group">
                                                <input type={showPassword ? "text" : "password"} className="form-control" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="Enter new password"/>
                                                <div className="input-group-text">
                                                    <span onClick={togglePasswordVisibility}>
                                                        <i
                                                            className={`bi fs-4 ${showPassword ? "bi-eye-slash" : "bi-eye"
                                                                }`}
                                                        ></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button type="submit" className="btn btn-primary">
                                                {buttonLoading ? (
                                                    <span
                                                        class="spinner-border spinner-border-sm me-2"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    "Save Changes"
                                                )}

                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
        </div>
    );
}

export default ChangePassword;
