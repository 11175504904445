import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import Loader from "../../Loader";

function Transport() {
  const [SubmitData, setSubmitData] = useState({
    routeno: "",
    routefee: "",
    remark: "",
  });

  const [routeData, setRouteData] = useState({
    id: "",
    routeno: "",
    routefee: "",
    remark: "",
  });

  const [loadSubmit, setLoadSubmit] = useState(false);
  const history = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadSubmit(true);
    try {
      const response = await axiosInstance.post(
        "/Master/AddTransportFee",
        SubmitData
      );
      if (!response.data.Status === "success") {
        throw new Error("Transport Fee Added Failed");
      }

      toast.success("Transport Fee Added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
      setSubmitData({
        routeno: "",
        routefee: "",
        remark: "",
      });
      setLoadSubmit(false);
    } catch (error) {
      setLoadSubmit(false);
      toast.error("Add transport fee failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //Table Data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(`/Master/GetTransportFee`);
      setData(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Edit table data..
  const [editingId, setEditingId] = useState(null);
  const handleEditClick = (id) => {
    setEditingId(id);
  };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    setEditingId(null);

    try {
      const response = await axiosInstance.post(
        "/Master/Updatetransportfee",
        routeData
      );
      if (!response.data.Status === "success") {
        throw new Error("Transport Fee Updated failed");
      }
      toast.success("Transport Fee Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      history("/admin/master/manage-transport");
    } catch (error) {
      toast.error("Transport Fee Updated failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };
  const handleInputChange = (e, id, key) => {
    const { name, value } = e.target;
    const newData = data.map((item) =>
      item.id === id ? { ...item, [key]: e.target.value } : item
    );
    const EditData = data.find((val) => {
      return val.id === id;
    });
    setRouteData((prev) => ({
      ...prev,
      id: id,
      routeno: EditData.routeno,
      routefee: EditData.routefee,
      remark: EditData.remark,
    }));
    setRouteData((prev) => ({
      ...prev,
      id: id,
      [name]: value,
    }));
    setData(newData);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Transport</h4>
          </div>
          <div class="col-lg-4">
            <form onSubmit={handleSubmit}>
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Add Transport Fee</div></div>
                <div class="card-body py-3 px-3">
                  <div class="row form-material">
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                        Route No.<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter route no.."
                        name="routeno"
                         pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                        value={SubmitData.routeno}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            routeno: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                        Route Fee<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                        class="form-control"
                        placeholder="Enter route fee.."
                        name="routefee"
                        value={SubmitData.routefee}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            routefee: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">Remark</label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        name="remark"
                        value={SubmitData.remark}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            remark: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                    <div className="text-end">
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary btn-block mb-3"
                      >
                        {loadSubmit ? (
                          <span
                            class="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-8">

            <div class="card custom-card mb-3">
              <div class="card-header justify-content-between"><div class="card-title">Transport Fee List</div></div>
              <div class="card-body py-3 px-3">
                {loading ? (
                <Loader/>
                ) : (
                  <>
                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead >
                          <tr>
                            <th>#</th>
                            <th>Route No.</th>
                            <th>Route Fee</th>
                            <th>Remark</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data ? (
                            data.map((row) => (
                              <tr className="text-black fs-5" key={row.id}>
                                <td>{row.id}</td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      name="routeno"
                                      className="form-control"
                                      defaultValue={row.routeno}
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "routeno")
                                      }
                                    />
                                  ) : (
                                    row.routeno
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      name="routefee"
                                      className="form-control"
                                      defaultValue={row.routefee}
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "routefee")
                                      }
                                    />
                                  ) : (
                                    row.routefee
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      name="remark"
                                      className="form-control"
                                      defaultValue={row.remark}
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "remark")
                                      }
                                    />
                                  ) : (
                                    row.remark
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <>
                                      <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                      <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                    </>
                                  ) : (
                                    <span class="btn btn-primary shadow btn-xs sharp " onClick={() => handleEditClick(row.id)}><i class="fas fa-pencil-alt"></i></span>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <p>No data available</p>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Transport;
