import axios from "axios";

// axios instance and interceptors
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("token");
        const loginUrlPattern = /\/login$/;   // Use a regular expression to match the URL

        if (!loginUrlPattern.test(config.url)) {
          config.headers["Parent-Token"] = token;   // Modify the request configuration here
        }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);
  
axiosInstance.interceptors.response.use(
function (response) {
    const token = localStorage.getItem("token");
    if(response.status === 401 || !token){
        localStorage.clear();
        window.location.href = "/parent"
    }
    return response;
},
function (error) {
    const token = localStorage.getItem("token");
    if(error.response.status === 401 || !token){
        localStorage.clear();
        window.location.href = "/parent"
    }
    return Promise.reject(error);
}
);
  