import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
function CreateFeeType() {
  const [SubmitData, setSubmitData] = useState({
    feename: "",
    feetype: "",
    classname: [],
    feeamount: "",
  });
  console.log(SubmitData);
  const history = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(
        "/Master/AddFeeType",
        SubmitData
      );
      console.log(response.data);
      if (response.data.Status === 1) {
        throw new Error("Fee Type Added failed");
      }

      toast.success("Fee Type Added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      history("/admin/master/fee-type");
    } catch (error) {
      toast.error("Fee Type Added failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    window.location.href = "/";
  };

  //Get Class
  const [Class, setClass] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/Master/GetClass`);
        setClass(response.data.Payload);
        console.log(response.data);
      } catch (error) {
        // toast.error("Authentication failed !");
        history("/");
      }
    };
    fetchData();
  }, []);

  const options = []; // Add more options as needed
  Class.map((val) => {
    options.push(val.sclass);
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (option) => {
    if (option === "Select All") {
      setSelectAll(!selectAll);
      setSelectedOptions(selectAll ? [] : options);
      setSubmitData((prev) => ({ ...prev, classname: options }));
    } else {
      const newSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];

      setSelectAll(newSelectedOptions.length === options.length);
      setSelectedOptions(newSelectedOptions);
      setSubmitData((prev) => ({ ...prev, classname: newSelectedOptions }));
    }
  };

  return (
    <>
      <div className="mt-4">
        <div className="container-fluid">
          <div className="row">
            <h3 class="text-center">Manage Fee Type</h3>
            <div class="col-lg-12 pt-4">
              <form onSubmit={handleSubmit}>
                <div class="card">
                  <div class="card-body text-black fw-bold fs-6">
                    <h4 className="card-title">Add Fee Type</h4>
                    <hr />
                    <div class="row form-material">
                      <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                        <label class="form-label">
                          Fee Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter fee name.."
                          name="feename"
                          value={SubmitData.feename}
                          onChange={(e) =>
                            setSubmitData({
                              ...SubmitData,
                              feename: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                        <label class="form-label">Fee Type</label>
                        <select
                          class="form-control"
                          id="autoSizingSelect"
                          name="feetype"
                          value={SubmitData.feetype}
                          onChange={(e) =>
                            setSubmitData({
                              ...SubmitData,
                              feetype: e.target.value,
                            })
                          }
                          required
                        >
                          <option selected>Select</option>
                          <option value="Monthly">Monthly</option>
                          <option value="One Time">One Time</option>
                        </select>
                      </div>
                      <div className="text-end">
                        <button
                          type="submit"
                          class="bg-black text-white fw-bold mb-3"
                          style={{ width: "100px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateFeeType;
