import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { axiosInstance } from "../../../parent-helper";
import Loader from "../../../Loader";
function FeeStructure() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/Parent/GetStudentClass`);
                setData(response.data.Payload);
            } catch (error) {
                // toast.error("Authentication failed !");
            }finally{
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const [feeType, setFeeType] = useState([]);
    useEffect(() => {
        const fetchDataa = async () => {
            try {
                const response = await axiosInstance.get(`/Parent/GetStudentAddFeeType`);
                setFeeType(response.data.Payload);
            } catch (error) {
                // toast.error("Authentication failed !");
            }
        };
        fetchDataa();
    }, []);
    const [feeAmount, setfeeAmount] = useState([]);
    const fetchDataaa = async () => {
        try {
            const response = await axiosInstance.get(`/Parent/GetStduentFeeList`);
            setfeeAmount(response.data.Payload);
        } catch (error) {
            // toast.error("Authentication failed !");
            window.location.href = "/parent";
        }
    };
    useEffect(() => {
        fetchDataaa();
    }, []);
    return (<>
        <div className="container-fluid">
            <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Report</h4>
                </div>
                <div class="col-lg-12">
                    
                        <div className="card custom-card mb-3">
                            <div class="card-header justify-content-between"><div class="card-title">Fee Structure</div></div>
                            <div class="card-body py-3 px-3">
                                <div class="table-responsive">
                                {loading ? (
                       <Loader/>
                    ) : (
                                    <table class="table table-hover table-bordered">
                                        <thead >
                                            <tr>
                                                <th>Class</th>
                                                {feeType &&
                                                    feeType.map((fee) => <th>{fee.fee_name}</th>)}
                                                {!feeType && <p>No data available</p>}
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.map((val) => (
                                                    <tr className="text-black fs-5">
                                                        <td>{val.sclass}</td>
                                                        {feeAmount.map(
                                                            (row1) =>
                                                                row1.class === val.sclass ? (
                                                                    <td>
                                                                        {row1.amount}
                                                                    </td>
                                                                ) : (
                                                                    ""
                                                                )
                                                        )}
                                                    </tr>
                                                ))}
                                            {!data && <p>No data available</p>}
                                        </tbody>
                                    </table>
                                    )}
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </>);
}

export default FeeStructure;