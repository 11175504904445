import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import Loader from "../../Loader";
const ManageExpense = () => {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [finalAmount, setFinalAmount] = useState(null);
    const [totalgst, setTotalGst] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    console.log('vendorId', vendorId);
    const [image, setImage] = useState(null);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/Master/GetVender");
            setData(response.data.Payload);
        } catch (error) {
            console.log("Data error", error);
        } finally {
            setLoading(false);
        }
    }
    const [formData, setFormData] = useState([{
        receipt: null,
        finalamount: "",
        totalgst: "",
        vendername: "",
        expense: [{ price: "", itemname: "", quantity: "", gstpercentage: "", totalamount: "" }]
    }]);
    console.log(formData, formData)
    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newData = [...formData];
        newData[index][name] = value;
        if (name === 'price' || name === 'gstpercentage' || name === 'quantity') {
            const price = parseFloat(newData[index].price || 0);
            const quantity = parseFloat(newData[index].quantity || 1); // Default to 1 if qty is not provided
            const gstpercentage = parseFloat(newData[index].gstpercentage || 0);
            const totalCost = price * quantity; // Calculate total cost by multiplying cost with quantity
            const gstAmount = totalCost * gstpercentage / 100; // Calculate GST amount
            const totalamount = totalCost + gstAmount; // Apply GST amount to total cost
            newData[index].gstAmount = gstAmount.toFixed(2); // Store GST amount in newData
            newData[index].totalamount = totalamount.toFixed(2); // Round to 2 decimal places
        }
        setFormData(newData);
    };
    const totalAmount = formData.reduce((acc, curr) => acc + parseFloat(curr.totalamount || 0), 0);
    const totalGST = formData.reduce((acc, curr) => acc + parseFloat(curr.gstAmount || 0), 0);
    const addRow = () => {
        setFormData([...formData, { itemname: '', price: '', quantity: '', gstpercentage: '', totalamount: '' }]);
    };

    const removeRow = (index) => {
        const newData = [...formData];
        newData.splice(index, 1);
        setFormData(newData);
    };
    // Handle vendor selection change
    const handleVendorChange = (event) => {
        const selectedVendorName = event.target.value;
        setVendor(selectedVendorName);
        // Find vendorId based on selected vendorName
        const selectedVendor = data.find(vendor => vendor.name === selectedVendorName);
        if (selectedVendor) {
            setVendorId(selectedVendor.id);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {

            const formDataWithAmount = {
                receipt: image,
                finalamount: totalAmount.toFixed(2),
                totalgst: totalGST.toFixed(2),
                vendername: vendor,
                vendorid: vendorId,
                expense: formData.map(item => ({
                    price: item.price,
                    itemname: item.itemname,
                    quantity: item.quantity,
                    gstpercentage: item.gstpercentage,
                    totalamount: item.totalamount
                }))
            };
            const response = await axiosInstance.post("/Admin/AddExpenseDetail", formDataWithAmount);
            if (response.data.Status === "success") {
                toast.success("Expense added successfully");
            } else {
                throw new Error("Expense add failed");
            }
            Navigate('/admin/expense/manage-expense');
        } catch (error) {
            console.error("Error submitting expense:", error);
            toast.error("Failed to submit expense");
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Set the base64-encoded image string to the state
                const filebase64 = reader.result.split("base64,");
                setImage(filebase64[1]);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="card-header">
                    <h4 className="card-title">Add Expense</h4>
                </div>

                <div class="col-lg-12">
                    <div className="card custom-card mb-3">
                        <div className='card-header justify-content-between'>
                            <div className="card-title">Expense Details</div>
                            <div className="flex flex-wrap gap-2">
                                <span className="btn btn-primary shadow btn-xs sharp" onClick={addRow}> <i className="bi bi-plus"></i></span>
                            </div>
                        </div>
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="card-body py-3 px-3">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                            <div class="row g-2">
                                                <div class="col-md">
                                                    <div class="form-floating">
                                                        <select class="form-control" id="floatingSelectGrid" onChange={handleVendorChange} required>
                                                            <option selected>select vendor</option>
                                                            {data ? (
                                                                data.map((row) => (
                                                                    <option key={row.id} value={row.name}>{row.name}</option>
                                                                ))
                                                            ) : (
                                                                <p>No data available</p>
                                                            )}
                                                        </select>
                                                        <label for="floatingSelectGrid">Vendor</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                            <div class="row g-2">
                                                <div class="col-md">
                                                    <div class="form-floating">
                                                        <input type="file" class="form-control" id="floatingInputGrid" name="receipt" onChange={handleImageChange} />
                                                        <label for="floatingInputGrid">Attachment</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table  table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Cost</th>
                                                    <th>Qty</th>
                                                    <th>GST(%)</th>
                                                    <th>Total</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formData.map((data, index) => (
                                                    <tr className="text-black fs-5" key={index}>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="itemname"
                                                                value={data.itemname}
                                                                onChange={(e) => handleChange(index, e)}
                                                                required
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="price"
                                                                value={data.price}
                                                                onChange={(e) => handleChange(index, e)}
                                                                required
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="quantity"
                                                                value={data.quantity}
                                                                onChange={(e) => handleChange(index, e)}
                                                                required
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="gstpercentage"
                                                                value={data.gstpercentage}
                                                                onChange={(e) => handleChange(index, e)}
                                                                required
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="totalamount"
                                                                value={data.totalamount}
                                                                onChange={(e) => handleChange(index, e)}
                                                                required
                                                            />
                                                        </td>
                                                        <td>
                                                            <span className="btn btn-danger shadow btn-xs sharp" onClick={() => removeRow(index)}> <i className="bi bi-x"></i></span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" value={totalGST.toFixed(2)} onChange={(e) => setTotalGst(e.target.value)} disabled />
                                                <label>Total GST</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" value={totalAmount.toFixed(2)} onChange={(e) => setFinalAmount(e.target.value)} disabled />
                                                <label>Total Amount</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 text-end">
                                            <button type="submit" className="btn btn-primary fw-bold mb-3" style={{ width: "100px" }}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageExpense;
