import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../teacher-helper";
import Swal from "sweetalert2";
import Loader from "../../Loader";
function ManageAssignment() {
    const [loadSubmit, setLoadSubmit] = useState(false);
    const [classList, setClassList] = useState([]);
    const [SubmitData, setSubmitData] = useState({
        subject: "",
        sclass: "",
        remark: "",
        assignment: ""
    });

    const [editData, setEditData] = useState({
        ids: "",
        subject: "",
        sclass: "",
        remark: "",
        assignment: ""
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadSubmit(true);
        try {
            const response = await axiosInstance.post("/Teacher/UploadAssignment", SubmitData);
            if (!response.data.status === 'success') {
                throw new Error('Upload assignment failed');
            }
            toast.success('Assignment uploaded successfully!', {
                position: toast.POSITION.TOP_RIGHT,
            });
            fetchData();
            setLoadSubmit(false);
            setSubmitData({
                subject: "",
                sclass: "",
                remark: "",
                assignment: ""
            });
        } catch (error) {
            setLoadSubmit(false);
            // window.location.href = "/teacher";   
        }
    }
    // Select class
    const handleSelectChange = (e) => {
        setSubmitData({
            ...SubmitData,
            [e.target.name]: e.target.value,
        });
    };
    // Assignment upload
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const filebase64 = reader.result.split("base64,");
                setSubmitData({ ...SubmitData, assignment: filebase64[1] });
            };
            reader.readAsDataURL(file);
        }
    };
    // Get class
    const fetchClass = async () => {
        try {
            const response = await axiosInstance.get("/Teacher/GetTeacherClass");
            setClassList(response.data.Payload);
        } catch (error) {
            window.location.href = "/teacher";
        }
    }
    useEffect(() => {
        fetchClass();
    }, []);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/Teacher/GetUploadAssignment');
            setData(response.data.Payload);
        } catch (error) {
            window.location.href = "/teacher";
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    const [editingId, setEditingId] = useState(null);
    const handleEditClick = (id) => {
        setEditingId(id);
    };

    const handleSaveClick = async (e) => {
        e.preventDefault();
        setEditingId(null);
        try {
            const response = await axiosInstance.post("/Teacher/UpdateAssignment", editData);
            if (!response.data.Status === "success") {
                throw new Error("Assignment update failed");
            }
            toast.success("Assignment Updated successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            toast.error("Assignment update failed", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const handleCancelClick = () => {
        setEditingId(null);
    };

    const handleInputChange = (e, id, key) => {
        const { name, value } = e.target;

        // Update the editData state
        setEditData(prev => ({
            ...prev,
            ids: id,
            [key]: value,
        }));

        // Update the data state
        const updatedData = data.map(item =>
            item.id === id ? { ...item, [key]: value } : item
        );
        setData(updatedData);
    };

    // Delete data
    const handleDelete = async (id) => {
        const deleteData = {
            ids: id,
        };

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axiosInstance.post("/Teacher/DeleteAssignment", deleteData);
                    if (response.data.Status === 'success') {
                        setData(data.filter(assignment => assignment.id !== id));
                        toast.success("Assignment deleted successfully!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        toast.error("Failed to delete assignment", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } catch (error) {
                    console.error("Error deleting assignment:", error);
                    toast.error("Failed to delete assignment", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        });
    };
    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(data?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (<>
        <div className="container-fluid">
            <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Manage Assignment</h4>
                </div>
                <div class="col-md-4">
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>
                        <div class="card custom-card mb-3">
                            <div class="card-header justify-content-between"><div class="card-title">Upload Assignment</div></div>
                            <div class="card-body text-black fw-bold fs-6">
                                <div class="row form-material">
                                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                        <label class="form-label">Subject</label>
                                        <input type="text"  pattern="[A-Za-z]+[A-Za-z'\-\s]*" class="form-control form-control-sm" placeholder="Enter subject.." value={SubmitData.subject} onChange={(e) => setSubmitData({ ...SubmitData, subject: e.target.value })} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                        <label class="form-label">Class</label>
                                        <select
                                            class="form-control form-control-sm"
                                            id="autoSizingSelect"
                                            name="sclass"
                                            value={SubmitData.sclass}
                                            onChange={handleSelectChange}
                                            required
                                        >
                                            <option value="">Choose...</option>
                                            {classList ? (
                                                classList.map((row) => (
                                                    <option value={row.sclass}>
                                                        {row.sclass}
                                                    </option>
                                                ))
                                            ) : (
                                                <p>No data available</p>
                                            )}
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                        <label class="form-label">Assignment</label>
                                        <input type="file" class="form-control form-control-sm" placeholder="Enter fee amount.." name="assignment" onChange={handleImageChange} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                        <label class="form-label">Remark</label>
                                        <textarea class="form-control form-control-sm" id="exampleFormControlTextarea1" rows="3" name="remark" value={SubmitData.remark} onChange={(e) => setSubmitData({ ...SubmitData, remark: e.target.value })}></textarea>
                                    </div>
                                    <div className="text-end">
                                        <button
                                            type="submit"
                                            class="btn btn-sm btn-primary btn-block mb-3"
                                        >
                                            {loadSubmit ? (
                                                <span
                                                    class="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>                                        </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-8">

                    <div className="card custom-card mb-3">
                        <div className='card-header justify-content-between'>
                            <div class="card-title">Assignment List</div>
                            {/* <div class="flex flex-wrap gap-2">
                                    <input class="form-control form-control-sm" type="text" placeholder="Search Here" aria-label=".form-control-sm example" />
                                </div> */}
                        </div>
                        <div class="card-body py-3 px-3">
                            <div class="table-responsive">
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <table class="table table-hover table-bordered">
                                        <thead className="">
                                            <tr>
                                                {/* <th>#</th> */}
                                                <th>Subject</th>
                                                <th>Class</th>
                                                <th>Assignment</th>
                                                <th>Remark</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data ?
                                                data
                                                    .slice(
                                                        currentPage * dataPerPage,
                                                        (currentPage + 1) * dataPerPage
                                                    ).map((row) => (
                                                        <tr className="text-black fs-5" key={row.id}>
                                                            {/* <td>{index+1}</td> */}
                                                            <td>{editingId === row.id ? (<input type="text" class="form-control form-control-sm" value={row.subject} onChange={(e) => handleInputChange(e, row.id, 'subject')} />) : (row.subject)}</td>
                                                            <td>{row.sclass}</td>
                                                            <td><span class="btn btn-danger shadow btn-xs" ><a className='text-white' href={`https://schoolapi.mnbsoft.co.in/AssignmentDoc/${row.assignment_doc}`} download target="_blank"><i class="bi bi-cloud-arrow-down"></i> Download</a></span> </td>
                                                            <td>{editingId === row.id ? (<input type="text" class="form-control form-control-sm" value={row.remark} onChange={(e) => handleInputChange(e, row.id, 'remark')} />) : (row.remark)}</td>
                                                            <td>{editingId === row.id ? (<div class="d-flex">
                                                                <span class="btn btn-primary shadow btn-xs sharp me-1" onClick={handleSaveClick}><i class="bi bi-check2-all"></i></span>
                                                                <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span> </div>
                                                            ) : (
                                                                <span class="btn btn-primary shadow btn-xs sharp" onClick={() => handleEditClick(row.id)}><i class="fas fa-pencil-alt"></i></span>
                                                            )}&nbsp;
                                                                <span class="btn btn-danger shadow btn-xs sharp" onClick={() => handleDelete(row.id)}><i class="fas fa-trash-alt"></i></span>
                                                            </td>
                                                        </tr>
                                                    )) :
                                                <p>No Data Available</p>}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-end">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            style={{
                                                pointerEvents:
                                                    currentPage === 0 ? "none" : "auto",
                                                opacity: currentPage === 0 ? 0.5 : 1,
                                            }}
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            Previous
                                        </a>
                                    </li>
                                    {pageIndex
                                        .slice(
                                            Math.max(0, currentPage - 2),
                                            Math.min(numberOfPage, currentPage + 2)
                                        )
                                        .map((page) => (
                                            <li
                                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                    }`}
                                                key={page}
                                            >
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            page - 1 === currentPage
                                                                ? "none"
                                                                : "auto",
                                                        opacity: page - 1 === currentPage ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(page - 1)}
                                                    href="#"
                                                >
                                                    {page}
                                                </a>
                                            </li>
                                        ))}
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            style={{
                                                pointerEvents:
                                                    currentPage === numberOfPage - 1
                                                        ? "none"
                                                        : "auto",
                                                opacity:
                                                    currentPage === numberOfPage - 1 ? 0.5 : 1,
                                            }}
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            Next
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>);
}

export default ManageAssignment;