import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from '../../admin-helper';
import Loader from '../../Loader';
function Enquiry() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const fetchData = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(`/Admin/GetEnquiryDetail`);
      setData(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      window.location.href = "/";
    } finally {
      setTableLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // -- pagination Code -- //
  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPage = Math.ceil(data?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //----------------------//
  return (<>
    <div>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Enquiry</h4>
            <div class="flex flex-wrap gap-2">
              <Link className="btn btn-primary" to="/admin/enquiry/create">Create Enquiry<span class="btn-icon-end"><i class="fa fa-solid fa-plus"></i></span></Link>
            </div>
          </div>
          <div class="col-lg-12">
            <div className="card custom-card mb-3">
              <div className='card-header justify-content-between'>
                <div class="card-title">Enquiry List</div>
              </div>
              <div class="card-body py-3 px-3">
                {tableLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead className="">
                          <tr>
                            <th>#</th>
                            <th>Student's Name</th>
                            <th>Father's Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Class</th>
                            <th>Address</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data ?
                            data
                              .slice(
                                currentPage * dataPerPage,
                                (currentPage + 1) * dataPerPage
                              ).map((row,index) => (
                                <tr className="text-black fs-5">
                                 <td>{currentPage * dataPerPage + index + 1}</td>
                                  <td>{row.name}</td>
                                  <td>{row.fathername}</td>
                                  <td>{row.email}</td>
                                  <td className='text-primary'>{row.whatappno}</td>
                                  <td>{row.schoolclass}</td>
                                  <td>{row.address}</td>
                                  <td>{row.CreateDate}</td>

                                </tr>
                              ))
                            : <p className='text-red'>No data available</p>}
                        </tbody>
                      </table>
                    </div>
                  </>)}
              </div>
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                  <li class="page-item">
                    <a
                      class="page-link"
                      style={{
                        pointerEvents:
                          currentPage === 0 ? "none" : "auto",
                        opacity: currentPage === 0 ? 0.5 : 1,
                      }}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {pageIndex
                    .slice(
                      Math.max(0, currentPage - 2),
                      Math.min(numberOfPage, currentPage + 2)
                    )
                    .map((page) => (
                      <li
                        class={`page-item ${page === currentPage + 1 ? "active" : ""
                          }`}
                        key={page}
                      >
                        <a
                          class="page-link"
                          style={{
                            pointerEvents:
                              page - 1 === currentPage
                                ? "none"
                                : "auto",
                            opacity: page - 1 === currentPage ? 0.5 : 1,
                          }}
                          onClick={() => handlePageChange(page - 1)}
                        >
                          {page}
                        </a>
                      </li>
                    ))}
                  <li class="page-item">
                    <a
                      class="page-link"
                      style={{
                        pointerEvents:
                          currentPage === numberOfPage - 1
                            ? "none"
                            : "auto",
                        opacity:
                          currentPage === numberOfPage - 1 ? 0.5 : 1,
                      }}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default Enquiry;