import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { axiosInstance } from "../admin-helper";

function Login() {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [Userid, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    try {
      const response = await axiosInstance.post("/Admin/SchoolMasterLogin", {
        Userid,
        Password,
      });
      console.log(response.data);
      if (response.data.Status === "success") {
        localStorage.setItem("token", response.data.admintoken);
        window.location.href = "/admin/dashboard";
      } else {
        toast.error("Authentication failed !");
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(true);
      console.error("Error during authentication", error);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="container">
      
      <div className="row justify-content-center align-items-center hidden mt-5">
        <div className="col-md-5 mt-5">
          <div className="authincation-content animate__animated animate__zoomIn">
            
                <div className="auth-form">
                  <div className="text-center mb-3">
                    <Link to="/" className="brand-logo">
                      <img src="images/1467.png" height="50" />
                    </Link>
                  </div>
                  <h3 className="text-center mb-2">Admin Login</h3>
                  <p className="text-center mb-4"> Welcome back MNB Public School!</p>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className=" form-label text-default mb-1">
                     Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Userid"
                        value={Userid}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="enter username"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label text-default mb-1">
                       Password
                      </label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          name="Password"
                          value={Password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="enter password"
                          required
                        />
                        <div className="input-group-text">
                          <span onClick={togglePasswordVisibility}>
                            <i
                              className={`bi fs-4 ${
                                showPassword ? "bi-eye-slash" : "bi-eye"
                              }`}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-4 mb-2"></div>
                    <div className="text-center">
                      <button type="submit" class="btn btn-sm btn-primary text-white w-100">
                        {buttonLoading ? (
                          <span
                            class="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    
    // </div>
  );
}

export default Login;
