import React from 'react';
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import html2pdf from 'html2pdf.js';
function Receipt() {
    const generatePDF = () => {
        const content = document.getElementById('html-to-pdf-content');
        // console.log(content);
        const pdfOptions = { filename: 'receipt.pdf' };
        html2pdf(content, pdfOptions);
    };
    const [data, setData] = useState({});
    const { studentid, month } = useParams();
    const [loading, setLoading] = useState(true)
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`/ManageFees/GetMonthlyPaymentReciept/${studentid}/${month}`);
            setData(response.data);
        } catch (error) {
            toast.error("Authentication failed !");
            window.location.href="/";
        }
    };
    console.log('data is', data);
    useEffect(() => {
        fetchData();
        const loaderTimeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(loaderTimeout);
    }, []);

    return (<>
        <div>
            <div class="container-fluid">
                <div class="row">
                    <div class="card-header">
                        {/* <h3 class="me-auto p-2 text-center">Fee Receipt</h3> */}
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-3 col-xxl-12 ">
                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button class="btn btn-primary me-md-2" type="button" onClick={generatePDF}>Download Receipt</button>
                        </div>
                        <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="row" id="html-to-pdf-content">
                                    {/* <div class="col-xl-3"></div> */}
                                    <div class="col-xl-12" style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>
                                        <div class="card">
                                            <div class="card-body pb-4">
                                                <div class="row pt-4">
                                                    <div class="col-xl-4">
                                                        <img src="/images/MNB_logo.png" />
                                                    </div>
                                                    <div class="col-xl-4 bg-success">
                                                        <h3 class="fs-25 mb-3 pt-3 text-center">MNB Public School</h3>
                                                    </div>
                                                    <div class="col-xl-3 pt-4 text-center">
                                                        {/* <a href="" class="bg-black text-white btn-sm fw-bold fs-4">Download</a> */}
                                                    </div>
                                                </div>
                                                <div class="pt-4"><hr />
                                                    <div class="row">
                                                        <div class="col-xl-7 pt-4">
                                                            <p class="font-w500 mb-3"><span class="custom-label">Student's Name : </span><span class="custom-label"> Tanuj</span></p>
                                                            <p class="font-w500 mb-3"><span class="custom-label">Roll No. :</span><span class="custom-label"> 1001</span></p>
                                                            <p class="font-w500 mb-3"><span class="custom-label">Class :</span><span class="custom-label"> UKG</span></p>
                                                            {/* <p class="font-w500 mb-3"><span class="custom-label">Section :</span><span class="font-w400"> A</span></p> */}
                                                        </div>
                                                        <div class="col-xl-5 pt-4">
                                                            <p class="font-w500 mb-3"><span class="custom-label">Receipt No. : </span><span class="custom-label">1003423</span></p>
                                                            <p class="font-w500 mb-3"><span class="custom-label">Date : </span><span class="custom-label"> 11-Jan-2023</span></p>
                                                        </div>
                                                    </div>
                                                    <table class="table">
                                                        <thead class="text-black">
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Particulars</th>
                                                                <th scope="col">Paid Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="text-black fs-5">
                                                                <th scope="row">1</th>
                                                                <td>Tuition Fee</td>
                                                                <td>1000</td>
                                                            </tr>
                                                            <tr class="text-black fs-5">
                                                                <th scope="row">2</th>
                                                                <td>Exam Fee</td>
                                                                <td>100</td>
                                                            </tr>
                                                            <tr class="text-black fs-5">
                                                                <th scope="row">3</th>
                                                                <td>Transport Fee</td>
                                                                <td>500</td>
                                                            </tr>
                                                            <tr class="text-black fs-5">
                                                                <th scope="row">4</th>
                                                                <td>Sports Fee</td>
                                                                <td>1000</td>
                                                            </tr>
                                                            <tr class="text-black fs-5">
                                                                <th scope="row">5</th>
                                                                <td>Hostel Fee</td>
                                                                <td>0.00</td>
                                                            </tr>
                                                            <tr class="text-black fs-5">
                                                                <td></td>
                                                                <td class="table-light">Total Amount</td>
                                                                <td class="table-light">2000</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="pt-3 text-end">Signature</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-xl-3"></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Receipt;