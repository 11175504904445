import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { axiosInstance } from '../../helper';

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
    
    const [Userid, setUsername] = useState<string>('');
    const [Password, setPassword] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/Admin/SuperAdminLogin', {
                Userid,
                Password,
            });
            console.log(response.data);
            if (response.data.Status === 'success') {
                const token = response.data.refreshToken;
                localStorage.setItem('token', token);
                window.location.href = '/super-admin/admins';
            } else {
                toast.error("Authentication failed!");
            }
        } catch (error) {
            console.error('Error during authentication', error);
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center mt-5">
                <div className="col-md-6">
                    <div className="authincation-content">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <div className="text-center mb-3">
                                        <Link to="" className="brand-logo">
                                            <img src="../images/1467.png" height="80" alt="Logo" />
                                        </Link>
                                    </div>
                                    <h3 className="text-center mb-2">Super Admin Login</h3>
                                    <p className="text-center mb-4">MNB Public School</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="mb-1"><strong>Username</strong></label>
                                            <input type="text" className="form-control" name="Userid" value={Userid} onChange={e => setUsername(e.target.value)} placeholder='Please enter username' required />
                                        </div>
                                        <div className="mb-3">
                                            <label className="mb-1"><strong>Password</strong></label>
                                            <input type="password" className="form-control" name="Password" value={Password} onChange={e => setPassword(e.target.value)} placeholder='Please enter password' required />
                                        </div>
                                        <div className="row d-flex justify-content-between mt-4 mb-2">
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="bg-black text-white w-100">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;