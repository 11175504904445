import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
function View_application_Fees() {

    const [monthStates, setMonthStates] = useState({
        Jan: false,
        Feb: false,
        Mar: true,
        Apr: true,
        May: true,
        Jun: true,
        July: true,
        Aug: true,
        Sep: true,
        Oct: true,
        Nov: true,
        Dec: true,
    });

    const handleCheckboxChange = (month) => {
        // Update the state for the specific month
        setMonthStates((prevMonthStates) => ({
            ...prevMonthStates,
            [month]: !prevMonthStates[month],
        }));

        const isChecked = checkedMonths.includes(month);
        let updatedCheckedMonths;

        if (isChecked) {
            // If already checked, uncheck and enable the input
            updatedCheckedMonths = checkedMonths.filter((checkedMonth) => checkedMonth !== month);
        } else {
            // If unchecked, check and disable the input
            updatedCheckedMonths = [...checkedMonths, month];
        }

        setCheckedMonths(updatedCheckedMonths);
        updateAmountSum(updatedCheckedMonths);
    };

    const [checkedMonths, setCheckedMonths] = useState([]);
    const [amountSum, setAmountSum] = useState(0);

    const updateAmountSum = (checkedMonths) => {
        // Calculate the sum of checked amounts
        const sum = checkedMonths.reduce((total, month) => {
            // For simplicity, assuming amount is 1000 for each checked month
            return total + 1000;
        }, 0);

        setAmountSum(sum);
    };
    return (<>
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div class="card-header">
                        <h3 class="me-auto p-2 text-center">Fee Type</h3>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table header-border table-responsive-sm">
                                    <thead className="bg-black text-white">
                                        <tr>
                                            <th>Fee Type</th>
                                            <th>Fee Duration</th>
                                            <th>Fee Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Registration Fee</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Admission Fee</td>
                                            <td>One Time</td>
                                            <td>10,000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Hostel Admission Fee</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td> Security DS Fee</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Security Hostel DS Fee</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>TPC Fee</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Books Chrysalis</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Composite</td>
                                            <td>Monthly</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Hostel Fee</td>
                                            <td>Monthly</td>
                                            <td>8000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Transport Fee</td>
                                            <td>Monthly</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Meal Plan Fee</td>
                                            <td>Monthly</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Gym Fee</td>
                                            <td>Monthly</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Horse Fee</td>
                                            <td>Monthly</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Mess Fee</td>
                                            <td>Monthly</td>
                                            <td>5000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Exam Fee</td>
                                            <td>quarterly</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Stationery Fee</td>
                                            <td>One Time</td>
                                            <td>500</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Tour</td>
                                            <td>One Time</td>
                                            <td>3000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Event & Activities Fee</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Late Fee</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className="text-black fs-5">
                                            <td>Penalty Fee</td>
                                            <td>One Time</td>
                                            <td>1000</td>
                                            <td>
                                                <Link to="" data-bs-toggle="modal" data-bs-target="#exampleModal" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* One Time Fee Popup */}
                                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">Registration Fees</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <form class="form-valide-with-icon needs-validation">
                                                    <div class="row form-material">

                                                        <div class="col-xl-3 col-xxl-12 col-md-6">
                                                            <label class="form-label">Fee Amount</label>
                                                            <input type="text" class="form-control" />
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-12 col-md-6">
                                                            <label class="form-label">Discount Amount</label>
                                                            <input type="text" class="form-control" />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <Link type="button" class="btn btn-primary btn-sm me-3">Submit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Monthly Fee Popup */}
                                <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel1">Monthly Fees</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="table-responsive">
                                                                <table class="table header-border table-responsive-sm table-bordered">
                                                                    <thead className="bg-black text-white">
                                                                        <tr>
                                                                            <th>#</th>
                                                                            {Object.keys(monthStates).map((month) => (
                                                                                <th><input class="form-check-input" key={month} type="checkbox" checked={monthStates[month]} onChange={() => handleCheckboxChange(month)} /> {month}</th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr className="text-black fs-5">
                                                                            <td>Total ({amountSum})</td>
                                                                            {Object.keys(monthStates).map((month) => (
                                                                                <td>1000</td>
                                                                            ))}
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody>
                                                                        <tr className="text-black fs-5">
                                                                            <td>Pay (12000)</td>
                                                                            {Object.keys(monthStates).map((month) => (
                                                                                <td>1000</td>
                                                                            ))}
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody>
                                                                        <tr className="text-black fs-5">
                                                                            <td>Net (12000)</td>
                                                                            {Object.keys(monthStates).map((month) => (
                                                                                <td>1000</td>
                                                                            ))}
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody>
                                                                        <tr className="text-black fs-5">
                                                                            <td>Total</td>
                                                                            {Object.keys(monthStates).map((month) => (
                                                                                <td><input type="text" class="form-control" disabled={!monthStates[month]} /></td>
                                                                            ))}
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody>
                                                                        <tr className="text-black fs-5">
                                                                            <td>Discount</td>
                                                                            {Object.keys(monthStates).map((month) => (
                                                                                <td><input type="text" class="form-control" disabled={!monthStates[month]} /></td>
                                                                            ))}
                                                                        </tr>
                                                                    </tbody><br />
                                                                    <Link type="button" class="btn btn-primary btn-sm me-3">Submit</Link>
                                                                </table>
                                                            </div>
                                                            {/* <div class="modal-footer">
                                                                <Link type="button" class="btn btn-primary btn-sm me-3">Submit</Link>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default View_application_Fees;