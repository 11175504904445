import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../parent-helper";
import Loader from '../../Loader';
function Teacher() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/Parent/GetTeachersDetail`);
            setData(response.data.Payload);
        } catch (error) {
            toast.error("Failed");
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    const [dataa, setDataa] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/Parent/GetStudentClass`);
                setDataa(response.data.Payload);
            } catch (error) {
                toast.error("Failed");
            }
        };
        fetchData();
    }, []);
    return (<>
        <div className="container-fluid">
            <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Teacher</h4>
                </div>
                <div class="col-lg-12">
                    <div class="card custom-card mb-3">
                        <div class="card-header justify-content-between"><div class="card-title">Teachers List</div>
                            {/* <div class="flex flex-wrap gap-2">
                                <input class="form-control form-control-sm" type="text" placeholder="Search Here" aria-label=".form-control-sm example" />
                            </div> */}
                        </div>
                        <div class="card-body py-3 px-3">
                            <div class="table-responsive">
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Incharge Class</th>
                                                    <th>Subject Handling</th>
                                                    <th>Mobile</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data ? (
                                                    data.map((row, index) => (
                                                        <tr className="text-black fs-5" key={row.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{row.name}</td>
                                                            <td>{row.incharge_class}</td>
                                                            <td>{row.subject_handling}</td>
                                                            <td className='text-primary'>{row.mobileno}</td>
                                                            <td>
                                                                <Link to={`/parent/teacher-detail/${row.teacherid}`} class="btn btn-primary shadow btn-xs"><i class="bi bi-eye"></i> View</Link>{' '}
                                                            </td>
                                                        </tr>
                                                    ))) : (
                                                    <p>No data available</p>)}
                                            </tbody>
                                        </table>
                                    </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Teacher;