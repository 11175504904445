import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import Loader from "../../Loader";
function FeeReport() {
  const [loading, setLoading] = useState(true);
  const [getdata, setGetData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState({
    name: "",
    fname: "",
    mobile: "",
    sclass: "",
    rollno: "",
  });
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `/Student/GetSearchStudentDatail`,
        data
      );
      setGetData(response.data.Payload);
      setOriginalData(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    } finally {
      setLoading(false);
    }
  };
  const [classData, setClassData] = useState([]);
  const fetchClass = async () => {
    try {
      const response = await axiosInstance.get(`/Master/GetClass`);
      setClassData(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    }
  };
  useEffect(() => {
    fetchClass();
  }, []);

  //handle search Change
  const searchChange = (e) => {
    const { value } = e.target;
    const filteredData = originalData.filter((item) => {
      return Object.values(item).some(
        (val) => val.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    });
    setGetData(filteredData);
  };
  useEffect(() => {
    const allFieldsEmpty = Object.values(data).every((val) => val.trim() === "");
    if (data.length === 0 || allFieldsEmpty) {
      fetchData();
    } else if (Object.values(data).some((val) => val.length > 0)) {
      fetchData()
    }
  }, [data])
  useEffect(() => {
    fetchData();
  }, []);
  // -- pagination Code -- //
  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPage = Math.ceil(getdata?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div class="card-header">
              <h4 class="card-title">Fee Report</h4>
              <div class="flex flex-wrap gap-2">
                <Link className="btn btn-primary" to="/admin/report/unpaid-fee">Unpaid Student List</Link>
              </div>
            </div>
            <div class="col-lg-12">
              <div className="card custom-card mb-3">
                <div className="card-header justify-content-between">
                  <div class="card-title">Student List</div>
                  <div class="flex flex-wrap gap-2">
                    <input
                      className="form-control form-control-sm"
                      onChange={searchChange}
                      type="search"
                      placeholder="Search Here..."
                      aria-label=".form-control-sm example"
                    />
                  </div>
                </div>
                <div class="card-body py-2 px-2">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div class="table-responsive">
                        <table class="table text-nowrap table-hover border table-bordered">
                          <thead className="">
                            <tr>
                              <th>Roll No.</th>
                              <th>Class</th>
                              <th>Student Name</th>
                              <th>Father Name</th>
                              <th>Email ID</th>
                              <th>Mobile No.</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(getdata) && getdata.length > 0 ? (
                              getdata
                                .slice(
                                  currentPage * dataPerPage,
                                  (currentPage + 1) * dataPerPage
                                )
                                .map((row) => (
                                  <tr className="text-black fs-5" key={row.id}>
                                    <td>{row.rollno}</td>
                                    <td>{row.sclass}</td>
                                    <td>{row.name}</td>
                                    <td>{row.fname}</td>
                                    <td>{row.emailid}</td>
                                    <td>{row.mobileno}</td>
                                    <td>
                                      <Link
                                        to={`/admin/report/fee-summary/${row.studentid}`}
                                        class="btn btn-sm btn-primary"
                                      >
                                        Fee Summary
                                      </Link>
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <p>No data available</p>
                            )}
                          </tbody>
                        </table>
                        <nav aria-label="Page navigation example">
                          <ul class="pagination justify-content-end">
                            <li class="page-item">
                              <a
                                class="page-link"
                                style={{
                                  pointerEvents:
                                    currentPage === 0 ? "none" : "auto",
                                  opacity: currentPage === 0 ? 0.5 : 1,
                                }}
                                onClick={() => handlePageChange(currentPage - 1)}
                              >
                                Previous
                              </a>
                            </li>
                            {pageIndex
                              .slice(
                                Math.max(0, currentPage - 2),
                                Math.min(numberOfPage, currentPage + 2)
                              )
                              .map((page) => (
                                <li
                                  class={`page-item ${page === currentPage + 1 ? "active" : ""
                                    }`}
                                  key={page}
                                >
                                  <a
                                    class="page-link"
                                    style={{
                                      pointerEvents:
                                        page - 1 === currentPage
                                          ? "none"
                                          : "auto",
                                      opacity: page - 1 === currentPage ? 0.5 : 1,
                                    }}
                                    onClick={() => handlePageChange(page - 1)}
                                    href="#"
                                  >
                                    {page}
                                  </a>
                                </li>
                              ))}
                            <li class="page-item">
                              <a
                                class="page-link"
                                style={{
                                  pointerEvents:
                                    currentPage === numberOfPage - 1
                                      ? "none"
                                      : "auto",
                                  opacity:
                                    currentPage === numberOfPage - 1 ? 0.5 : 1,
                                }}
                                onClick={() => handlePageChange(currentPage + 1)}
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeeReport;
