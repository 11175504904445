import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import { axiosInstance } from "../../parent-helper";
function TeacherDetail() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const { teacherid } = useParams();
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`/Parent/GetTeachersDetailByteacherId?teacherid=${teacherid}`);
            setData(response.data);
        } catch (error) {
            // window.location.href="/parent";
        }
    }
    useEffect(() => {
        fetchData();
        const loaderTimeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(loaderTimeout);
    }, [])


    const [dataa, setDataa] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/Parent/GetStudentClass`);
                setDataa(response.data.Payload);
            } catch (error) {
                window.location.href = "/parent";
            }
        };
        fetchData();
        const loaderTimeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(loaderTimeout);
    }, []);
    return (<>
        <div className="container-fluid">
            <div class="row ">
                {loading ? (
                    <div
                        className="container text-center"
                        style={{ paddingBottom: "150px", paddingTop: "200px" }}
                    >
                        <div class="spinner-grow text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-info" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div class="card-header">
                            <h4 class="card-title">Teacher Details</h4>
                        </div>
                        <div class="col-md-12">
                            <div class="card custom-card mb-3">
                                <div class="card-header justify-content-between"><div class="card-title">Teacher Detail</div></div>
                                <div class="card-body text-black fw-bold fs-6">
                                    <div class="row">
                                        <div class="col-xl-3 col-xxl-4">
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Full Name:</h5>
                                                <span>{data.name}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Email:</h5>
                                                <span>{data.emailid}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Mobile No.:</h5>
                                                <span>{data.mobileno}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Gender:</h5>
                                                <span>{data.gender}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Date of Birth:</h5>
                                                <span>{data.dob}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-xxl-4">
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Address:</h5>
                                                <span>{data.address}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">City:</h5>
                                                <span>{data.city}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Country:</h5>
                                                <span>{data.country}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Zipcode:</h5>
                                                <span>{data.zipcode}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Employee ID:</h5>
                                                <span>{data.employee_id}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-xxl-4">
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Qualification:</h5>
                                                <span>{data.qualfication}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Current Position :</h5>
                                                <span>{data.current_position}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Incharge Class :</h5>
                                                <span>{data.incharge_class}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Subject Handling :</h5>
                                                <span>{data.subject_handling}</span>
                                            </div>
                                            <div class="mb-3 d-flex">
                                                <h5 class="mb-1 fs-14 custom-label">Working Hours :</h5>
                                                <span>{data.working_hour}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)}
            </div>
        </div>
    </>);
}

export default TeacherDetail;