import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
import Loader from "../../Loader";
function Fee() {
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/Master/GetClass`);
        setData(response.data.Payload);
      } catch (error) {
        // toast.error("Authentication failed !");
        history("/");
      }finally{
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [feeType, setFeeType] = useState([]);
  useEffect(() => {
    const fetchDataa = async () => {
      try {
        const response = await axiosInstance.get(`Master/GetAddFeeType`);
        setFeeType(response.data.Payload);
      } catch (error) {
        // toast.error("Authentication failed !");
        history("/");
      }
    };
    fetchDataa();
  }, []);
  const [feeAmount, setfeeAmount] = useState([]);
  const fetchDataaa = async () => {
    try {
      const response = await axiosInstance.get(`Master/GetFeeList`);
      setfeeAmount(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      window.location.href = "/";
    }
  };
  useEffect(() => {
    fetchDataaa();
  }, []);

  const [feeTypeData, setFeeTypeData] = useState({
    id: "",
    amount: "",
  });

  const [editingId, setEditingId] = useState(null);
  const handleEditClick = (id) => {
    setEditingId(id);
  };
  // Edit data save..
  const handleSaveClick = async (e) => {
    e.preventDefault();
    setEditingId(null);
    const feesToUpdate = feeAmount.map((item) => ({
      id: item.id,
      amount: item.amount,
    }));

    const postData = {
      update: feesToUpdate,
    };

    try {
      const response = await axiosInstance.post(
        "/Master/UpdateClassFees",
        postData
      );
      if (!response.data.Status === "successs") {
        throw new Error("Fee Master Update failed");
      }
      toast.success("Fee master updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchDataaa();
    } catch (error) {
      toast.error("Fee Master Update failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.location.href = "/";
    }
  };
  const handleCancelClick = () => {
    setEditingId(null);
  };
  const handleInputChange = (e, id, key) => {
    // const{value } = e.target;
    const newData = feeAmount.map((item) =>
      item.id === id ? { ...item, [key]: e.target.value } : item
    );
    const EditData = feeAmount.find((val) => {
      return val.id === id;
    });
    setFeeTypeData((prev) => ({
      ...prev,
      id: EditData.id,
      amount: e.target.value,
    }));
    setfeeAmount(newData);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Fee Master</h4>
          </div>
          <div class="col-lg-12">

            <div className="card custom-card mb-3">
              <div className='card-header justify-content-between'>
                <div class="card-title">Fee Master List</div>
              </div>
              <div class="card-body py-3 px-3">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead >
                          <tr>
                            <th>Class</th>
                            {feeType &&
                              feeType.map((fee) => <th>{fee.fee_name}</th>)}
                            {!feeType && <p>No data available</p>}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((val) => (
                              <tr className="text-black fs-5">
                                <td>{val.sclass}</td>
                                {feeAmount.map(
                                  (row1) =>
                                    row1.class === val.sclass ? (
                                      <td>
                                        {editingId === val.id ? (
                                          <input
                                            type="text"
                                            name="amount"
                                            className="form-control"
                                            defaultValue={row1.amount}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                row1.id,
                                                "amount"
                                              )
                                            }
                                          />
                                        ) : (
                                          row1.amount
                                        )}{" "}
                                      </td>
                                    ) : (
                                      ""
                                    )
                                  // row1.class === val.sclass && row1.fee_type_id === fee.fee_type_id ? ( <td><input type="text" value={row1.amount} onChange={(e) => handleInputChange(e, row1.id, 'amount')} /> </td>) : ''
                                )}
                                <td>
                                  {editingId === val.id ? (
                                    <>
                                      <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                      <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                    </>
                                  ) : (
                                    <span class="btn btn-primary shadow btn-xs sharp " onClick={() => handleEditClick(val.id)}><i class="fas fa-pencil-alt"></i></span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          {!data && <p>No data available</p>}
                        </tbody>
                      </table>
                    </div>
                  </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fee;
