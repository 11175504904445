import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
function MonthFee() {

    
    const [selectedOption, setSelectedOption] = useState('option1');
    const [inputValue2, setInputValue2] = useState('');

    const handleRadioChange2 = (event) => {
        setSelectedOption(event.target.value);

        // Reset input value when a different radio button is selected
      
    };

   
    const [selectedMonths, setSelectedMonths] = useState([]);
    const currentMonth = new Date().getMonth();

    const months = Array.from({ length: 12 }, (_, index) => {
        const monthIndex = index + 1; // Adjusting to one-based index
        const isActive = monthIndex < currentMonth + 1;
        return {
            index: monthIndex,
            name: new Date(0, index).toLocaleString('en-US', { month: 'long' }),
            isActive,
        };
    });

    const handleCheckboxChange = (monthIndex) => {
        const isSelected = selectedMonths.includes(monthIndex);
        if (isSelected) {
            setSelectedMonths(selectedMonths.filter((month) => month !== monthIndex));
        } else {
            setSelectedMonths([...selectedMonths, monthIndex]);
        }
    };
    return (<>

        <div>
            <div className="container-fluid">
                <div className="row pt-4">
                    <h3 class="text-center">Manage Fee</h3>
                    <div class="col-lg-12 pt-4">
                        <form class="form-valide-with-icon needs-validation">
                            <div class="card">
                                <div class="card-body text-black fw-bold fs-6">
                                    <h4 className='card-title'>Student Fee</h4><hr />
                                    <div class="row form-material">
                                        <div class="col-xl-3 col-xxl-4 col-md-6">
                                            <label class="form-label">Name</label>
                                            <input type="text" class="form-control" value="Rohan"disabled />
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6">
                                            <label class="form-label">Roll No.</label>
                                            <input type="text" class="form-control" value="10001" disabled/>
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6">
                                            <label class="form-label">Class</label>
                                            <input type="text" class="form-control" value="II" disabled/>
                                        </div>
                                        <div class="col-xl-3 col-xxl-12 col-md-6 pt-2">
                                            <label class="form-label">Select Month</label><br />
                                            {months.map((month) => (
                                                <div key={month.index} class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" checked={selectedMonths.includes(month.index)} onChange={() => handleCheckboxChange(month.index)} value="option1" />
                                                    {month.name}
                                                </div>
                                            ))}
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6 pt-2">
                                            <label class="form-label">Balance</label>
                                            <input type="text" class="form-control" value="1000" />
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6 pt-2">
                                            <label class="form-label">Tuition Fee</label>
                                            <input type="text" class="form-control" placeholder='1000'/>
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6 pt-2">
                                            <label class="form-label">Late Fee</label>
                                            <input type="text" class="form-control" placeholder='1000' />
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6 pt-2">
                                            <label class="form-label">Total Fee</label>
                                            <input type="text" class="form-control" placeholder='3000' />
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6 pt-2">
                                            <label class="form-label">Discount Fee</label>
                                            <input type="text" class="form-control" placeholder='1000'/>
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6 pt-2">
                                            <label class="form-label">Net Fee</label>
                                            <input type="text" class="form-control" placeholder='2000' />
                                        </div>
                                        <div class="col-xl-3 col-xxl-4 col-md-6 pt-2">
                                            <label class="form-label">Payable Amount</label>
                                            <input type="text" class="form-control" placeholder='2000'/>
                                        </div>
                                        <div class="col-xl-3 col-xxl-2 col-md-6 pt-5">
                                            <input type="radio" class="form-check-input" value="option1" checked={selectedOption === 'option1'} onChange={handleRadioChange2} />{' '}
                                            <label class="form-label">Cash</label>
                                            <input type="radio" class="form-check-input ms-4" value="option2" checked={selectedOption === 'option2'} onChange={handleRadioChange2} />{' '}
                                            <label class="form-label">UPI</label>
                                        </div>
                                        {selectedOption === 'option2' && (
                                            <div class="col-xl-3 col-xxl-2 col-md-6 pt-2">
                                                <label class="form-label"></label>
                                                <input class="form-control" type="text" placeholder="Enter UPI number" value={inputValue2} onChange={(e) => setInputValue2(e.target.value)} />
                                            </div>
                                        )}
                                        <div className="text-end pt-3">
                                            <button type="button" class="bg-black text-white fw-bold mb-3" style={{ width: "100px" }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default MonthFee;