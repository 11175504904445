import React, { useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../../admin-helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

export default function Book() {

  const [classdata, setClassData] = useState([]);
  const [data, setData] = useState([])
  const [bookLoading, setBookLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [bookData, setBookData] = useState({
    slab: "",
    sclass: "",
    price: [{ Bookno: "", Bookname: "", Remark: "" }],
  });
  const history = useNavigate();

  // handle Input Change for slab And Class
  const handleChange1 = (selectedOption) => {
    setSelectedOption(selectedOption);
    handleChange({ target: { name: 'slab', value: selectedOption.value } });
  };
  const handleChange2 = (selectedOption1) => {
    setSelectedOption1(selectedOption1);
    handleChange({ target: { name: 'sclass', value: selectedOption1.value } });
  };


  const options = data?.map(item => ({
    value: item.slab,
    label: item.slab
  }));


  const option = classdata.map(item => ({
    value: item.sclass,
    label: item.sclass
  }));

  const handleInputChange = (e, index) => {
    const data = { ...bookData };
    data.price[index][e.target.name] = e.target.value
    setBookData(data);
  };
  const handleChange = (e) => {
    const data = { ...bookData };
    data[e.target.name] = e.target.value
    setBookData(data);
  };
  const addMore = () => {
    const datas = { ...bookData };
    const newform = { Bookno: "", Bookname: "", Remark: "" };
    datas.price.push(newform); // it is very very imp
    setBookData(datas);
  };
  console.log(classdata);

  const submit = async (e) => {
    e.preventDefault();
    setBookLoading(true)

    const isSlabValid = data.some(
      (row) => row.slab.toLowerCase() === bookData.slab.toLowerCase()
    );
    const isClassValid = classdata.some(
      (row) => row.sclass.toLowerCase() === bookData.sclass.toLowerCase()
    );

    if (!isSlabValid || !isClassValid) {
      setBookLoading(false);
      toast.error("Invalid Slab No or ClassName Please choose a valid Slab No or ClassName", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      const response = await axiosInstance.post("/Master/AddBook", bookData);
      if (!response.data.Status === "success") {
        throw new Error("Book Add failed");
      }

      toast.success("Book added successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      history("/admin/master/manage-library");
      setBookLoading(false);
    } catch (error) {
      setBookLoading(false);
      toast.error("Book Added failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //slab data
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/Master/GetLibrarySlab");
      setData(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      history("/");
    }
  };
  useEffect(() => {
    fetchData();
    fetchClassData();
  }, []);

  //class data
  const fetchClassData = async () => {
    try {
      const response = await axiosInstance.get("/Master/GetClass");
      setClassData(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
      window.location.href = "/";
    }
  };

  

  
  return (
    <>
      <div className="container-fluid"  >
        <div className="row">
          <h3 className="text-center pt-4">Add Book Details</h3>
          <div className="col-lg-4 pt-4 w-100">
            <form
              className="form-valide-with-icon needs-validation"
              onSubmit={submit}
            >
              <div className="card">
                <div className="card-body text-black fw-bold fs-6">
                  <h4 className="card-title">Add Book Details </h4>
                  <hr />
                  <div className="row form-material">
                    <div className="row">
                      <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                        <label className="form-label">
                          Slab No<span className="text-danger">*</span>
                        </label>
                         <Select
                              value={selectedOption}
                              onChange={handleChange1}
                              options={options}
                              isSearchable={true}
                              name="slab"
                              placeholder="Search or select an option"
                            />
                      </div>
                      <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                        <label className="form-label">
                          Class<span className="text-danger">*</span>
                        </label>
                         <Select
                              value={selectedOption1}
                              onChange={handleChange2}
                              options={option}
                              isSearchable={true}
                              placeholder="Search or select an option"
                              name="sclass"
                             
                            />
                      </div>
                    </div>
                    {bookData.price.map((input, index) => {
                      return (
                        <div className="row" key={index}>
                          <div className="col-4">
                            <label className="form-label">Book No</label>
                            <input
                              type="text"
                              className="form-control"
                              name="Bookno"
                              defaultValue={input.Bookno}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Enter Book No.."
                            />
                           
                          </div>
                          <div className="col-4">
                            <label class="form-label">Book Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="Bookname"
                              defaultValue={input.Bookname}
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder="Enter Book Name.."
                            />
                          </div>
                          <div className="col-4">
                            <label className="form-label">Remark</label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              name="Remark"
                              defaultValue={input.Remark}
                              onChange={(e) => handleInputChange(e, index)}
                              Remarks="Remarks"
                              rows="2"
                            ></textarea>
                          </div>
                        </div>
                      );
                    })}
                    <div className="container ">
                      <button
                        type="button"
                        className="btn btn-primary text-white fs-4 fw-bold "
                        style={{ width: "50px" }}
                        onClick={addMore}
                      >
                        +
                      </button>
                    </div>
                    <div className="text-center mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary  text-white fw-bold mb-3"
                        style={{ width: "100px" }}
                      >
                        {bookLoading ? (
                          <span
                            class="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}