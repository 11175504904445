import React from 'react';
function ManageBranch() {
    const path = document.location.pathname;
    return (<>
        <div className="mt-5">
            <div className="container-fluid">
                <div className="row">
                    <h3 class="text-center">
                        {path === '/admin/branch/create' ? "Branch Form" : "Edit Branch"}
                    </h3>
                    <div class="col-lg-12">
                        <form class="form-valide-with-icon needs-validation">
                            <div class="row pt-4">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                            <h4 className='card-title'>Branch Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Branch Name</label>
                                                    <input type="text" class="form-control" placeholder="Enter branch name.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">State</label>
                                                    <select class="form-control" id="autoSizingSelect">
                                                        <option selected>Choose...</option>
                                                        <option value="UP">UP</option>
                                                        <option value="Delhi">Delhi</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">City</label>
                                                    <input type="text" class="form-control" placeholder='Enter city...' />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Country</label>
                                                    <input type="text" class="form-control" placeholder='Enter country...' />
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Address</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>
                                                <div className="text-end">
                                                    <button type="button" class="btn btn-primary text-white fw-bold mb-3" style={{ width: "100px" }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default ManageBranch;