import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
function Payment() {
  const [sum, setSum] = useState();
  const [loading, setLoading] = useState(true);
  const { studentid } = useParams();
  const Navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [academic, setAcademic] = useState(null);
  const [paidfees, setPaidFees] = useState([]);
  const [studentDetail, setStudentDetail] = useState([]);
  const [siblingno, setSiblingNo] = useState()
  const [discountAmt, setDiscountAmount] = useState(0)
  const [name, setName] = useState({});
  
  const fetchStudentClass = async () => {
    try {
      const response = await axiosInstance.get(
        `/student/GetStudentInformation?studentid=${studentid}`
      );
      setName(response.data.RegistrationDetail[0]);
      setAcademic(
        response.data.AcademicDetail
          ? response.data.AcademicDetail[0].sclass
          : {}
      );
      setSiblingNo(
        response.data.RegistrationDetail
          ? response.data.RegistrationDetail[0]
          : {}
      )
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const fetchStudentDetail = async () => {
    try {
      const response = await axiosInstance.get(`/Student/Getstudentdetail?studentid=${studentid}`)
      setStudentDetail(response.data?.Payload[0])

    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchStudentDetail();
  }, [studentid]);
  
  const fetchStudentClassFees = async () => {
    try {
      const response = await axiosInstance.get(
        `/Master/GetFeeAmountByClassName?clss=${academic}`
      );
      let feeData = response.data.Payload;

        //Sibling Discount fees Object
        let siblingDis = {
          fee_type_id: 678,
          fee_name:"Sibling Discount",
          fee_type:"One Time",
          class:"Any",
          amount:siblingno.siblingamount
        }
        if(siblingno.sibling > 0){
          feeData.push(siblingDis)
        }

      setPaidFees(
        feeData.map((fee) => {
          return {
            ...fee,
            disamount: 0,
            paidamount: fee.amount,
          };
        })
      );
      
    } catch (error) {
      toast.error('Please fill academic details!', {
        position: toast.POSITION.TOP_RIGHT,
    });
    }
  };

  const handleSum = () => {
    let numbers = paidfees.filter((f)=>!(f.fee_name === "Sibling Discount")).map((fee) => fee.paidamount || 0);
    console.log(numbers);
    
    const addition = numbers.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setSum(addition);
  };
  useEffect(() => {
    fetchStudentClass();
  },[]);

  useEffect(() => {
    if (academic) {
      fetchStudentClassFees();
      const loaderTimeout = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(loaderTimeout);
    }
  }, [academic]);
  useEffect(() => {
    handleSum();
  }, [paidfees,discountAmt]);

  const handleSubmit = async (e) => {
    e.preventDefault();
      setButtonLoading(true);
      const payload = {
        studentid: studentid,
        totalamount: sum - (paidfees?.find((d)=> d.fee_name === "Sibling Discount") ? paidfees.find((d)=> d.fee_name === "Sibling Discount").amount : 0) - discountAmt,
        totaldueamount:0,
        totaldiscount:discountAmt,
        totalpaidamount: sum - ( paidfees?.find((d)=> d.fee_name === "Sibling Discount") ? paidfees.find((d)=> d.fee_name === "Sibling Discount").amount : 0) - discountAmt,
        payment: paidfees
          .map((fee) => {
              return {
                feename: fee.fee_name,
                feeamount: fee.amount,
                disamount: fee.disamount,
                paidamount: fee.paidamount,
              };
          }),
      };
      try {
        const response = await axiosInstance.post(
          "/Student/CreateStudentPaymentDetail",
          payload
        );
        const transactionId = response.data.transactionId;
        if (!response.data.Status === "success") {
          throw new Error("Add Payment failed");
        }
        toast.success("Payment added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setButtonLoading(false);
        Navigate(
          `/admin/students/payment-transaction/${studentid}/${transactionId}`
        );
      } catch (error) {
        setButtonLoading(true);
        toast.error("Add Payment failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    // }
  };

  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row pt-4">
            <h3 class="text-center">Admission Fee</h3>
            <div class="col-lg-1 pt-4"></div>
            <div class="col-lg-10 pt-4">
              {loading ? (
                <div
                  className="container text-center"
                  style={{ paddingTop: "200px" }}
                >
                  <div class="spinner-grow text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-info" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-dark" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div class="card">
                  <div class="">
                    <div class="table-responsive">
                      <div className="pt-3 p-2">
                      <li class="list-group-item">
                          <span class="custom-label fs-5">&nbsp;&nbsp;<b>Name</b> : &nbsp;{name.student_name ?? ''} </span>
                          {studentDetail.rollno ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Rollno</b> : &nbsp; {studentDetail.rollno}</span> : ''}
                          {studentDetail.sclass ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Class</b> : &nbsp; {studentDetail.sclass}</span> : ''}
                          {studentDetail.section ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Section</b> : &nbsp; {studentDetail.section}</span> : ''}
                      </li>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table header-border table-responsive-sm">
                      <thead
                        className="text-white btn-primary"
                      >
                        <tr>
                          <th>Fee Name</th>
                          <th>Amount</th>
                          <th>Paid Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paidfees.map((fees_type, index) => {
                          return (
                            <tr className="text-black fs-5" key={index}>
                              <td id={fees_type.fee_type_id}>
                                {fees_type.fee_name}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={fees_type.fee_type_id}
                                  defaultValue={fees_type.amount}
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={fees_type.fee_type_id}
                                  defaultValue={fees_type.paidamount}
                                  disabled
                                />
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                        <td colspan="2" className="text-end py-2 border-0">
                              <span className="fs-6 font-w500">
                                Discount Amount
                              </span>
                            </td>
                          {/* <td colspan="2">
                          <span className="fs-6 font-w500">
                                Discount Amount
                              </span>
                            <span className="fs-3 fw-bold">Discount Amount</span>
                          </td> */}
                          <td>
                            {" "}
                            <input type="number" onChange={(e)=>setDiscountAmount(e.target.value)} />
                          </td>
                        </tr>
                        <tr>
                        <td colspan="2" className="text-end py-2 border-0">
                              <span className="fs-6 font-w500">
                            Total Amount</span>
                          </td>
                          <td>
                            {" "}
                            <input type="text" value={sum - (paidfees?.find((d)=> d.fee_name === "Sibling Discount") ? paidfees.find((d)=> d.fee_name === "Sibling Discount").amount : 0) - (discountAmt ? discountAmt : 0)} disabled/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="d-flex justify-content-end me-4 mb-3 "
                      style={{ paddingRight: "200px" }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
