import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from '../../admin-helper';
// import { useLocation } from "react-router-dom";

function New_Application() {

    const [SubmitData, setSubmitData] = useState({
        fname:'Vivek Kumar',
        dob:'15DEC2000',
        gender:'Male',
        asession:'2023-2024',
        sclass:'VI',
        rollno:'A14',
        section:'A',
        house:'Red',
        branch:'PD',
        hostel:'yes',
        area:'Noida', 
        nationality:'Indian',
        country:'India',
        state:'Delhi',
        city:'Delhi',
        pincode:'644410',
        address:'Newdelhi-delhi',
        mobile:'9999999999',
        alternateno:'7777777777',
        primarycontact:'Father',
        email:'amarkumar@gmail.com',
        mothername:'sunitadevi',
        mothermobileno:'8888888888',
        motheremail:'sunitadevi@gmail.com',
        mothernature:'Job',
        motheraadharno:'789987789987', 
        motherpanno:'DF7898',
        motherpic:'',
        fathername:'sushil kumar',
        fathermobileno:'8888888888',
        fatheremail:'sushil@gmail.com',
        fathernature:'Job',
        fatheraadharno:'789987789987', 
        fatherpanno:'DF7898',
        fatherpic:'',
        guardienrelation:'father',
        guardienname:'sushil kumar',
        guardienmobileno:'8888888888',
        guardienemail:'sushil@gmail.com',
        guardiennature:'Job',
        guardienadharno:'CV789987789', 
        guardienpanno:'DF7898',
        guardienpic:'',
        aadharno:'123456789',
        aadharpic:'',
        transfercertificateno:'TCpic3332',
        transfercertificatepic:'',
        birthcertificateno:'bc55644545',
        birthcertificatepic:'',
        studentpic:'',
        previousschool:'SMS School',
        reasonforchange:'transport problem',
        previousclass:'V',
        adecmicsession:'',
        bloodgroup:'B+',
        alergyfood:'',
        illnesshistory:'',
        memployer:'',
        msector:'',
        mdepartment:'',
        mdesignation:'Nurse',
        femployer:'',
        fsector:'',
        fdepartment:'',
        fdesignation:'',
        gemployer:'uuuuu',
        gsector:'',
        gdepartment:'',
        gdesignation:'',
        transportlab:'',
        transarea:'',
        transcity:'',
        transstate:'',
        transpin:'',
        transaddress:'',
        transremark:''     
    });


    const handleFileChange = (motherpic) => (e) => {
        const file = e.target.files[0];
        // console.log('file', file);
        if (file) {
          const reader = new FileReader();
          console.log('reader', reader);
          reader.onloadend = () => {
            setSubmitData((prevData) => ({
              ...prevData,
              ["motherpic"]: reader.result,
            }));
          };
      
          reader.readAsDataURL(file);
        }
      };
      
    const location = useNavigate();
    const handleSubmit = async (e) => {
        console.log('SubmitData', SubmitData);
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/Admin/StudentRegistration', SubmitData);
            console.log(response.data);
            if (response.data.Status === 1) {
                throw new Error('Add student failed');
            }

            toast.success('Add student successfully!', {
                position: toast.POSITION.TOP_RIGHT,
            });
            
            // location('/admin/master/fee-type');
        } catch (error) {
            toast.error('Add student failed', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    const [isSelect, setIsSelect] = useState(false);
    const checkForm = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value !== 'housewife') {
            setIsSelect(true);
        } else {
            setIsSelect(false);
        }
    }
    const [isSelect1, setIsSelect1] = useState(false);
    const checkForm1 = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value !== 'Other') {
            setIsSelect1(true);
        } else {
            setIsSelect1(false);
        }
    }

    const [isSelect2, setIsSelect2] = useState(false);
    const checkForm2 = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value !== 'other') {
            setIsSelect2(true);
        } else {
            setIsSelect2(false);
        }
    }
    // for stream
    const [isSelect3, setIsSelect3] = useState(false);
    const checkForm3 = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value == 'XI') {
            setIsSelect3(true);
        } else {
            setIsSelect3(false);
        }
    }

    //for transport
    const [isSelect4, setIsSelect4] = useState(false);
    const checkForm4 = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value !== 'Yes') {
            setIsSelect4(true);
        } else {
            setIsSelect4(false);
        }
    }
    //for payment 
    // const location = useLocation();
    const [isSelect5, setIsSelect5] = useState();
    useEffect(() => {
        if (location.pathname == '/admin/application/edit/0001') {
            setIsSelect5(true);
        } else {
            setIsSelect5(false);
        }
    });
    const path = document.location.pathname;
    //get class
    const [Class, setClass] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/Master/GetClass`);
                setClass(response.data.Payload);
                console.log(response.data);
            } catch (error) {
                toast.error("Authentication failed !");
            }
        };
        fetchData();
    }, []);
    //get section
    const [section, setSection] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/Master/GetSection');
                setSection(response.data.Payload);
                console.log(response.data);
            } catch (error) {
                toast.error("Authentication failed !");
            }
        };
        fetchData();
    }, []);
    return (<>
        <div className="mt-4">
            <div className="container-fluid">
                <div className="row">
                    <h3 class="text-center">
                    {path === '/admin/application/edit/0001' ? "Edit Student" : "Student Form"}
                    </h3>
                    <div class="col-lg-12">
                        <form  onSubmit={handleSubmit} enctype='multipart/form-data'>
                            <div class="row pt-4">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                         <h4 className='card-title'>Student Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Full Name</label>
                                                    <input type="text" class="form-control" placeholder="Enter full name.." name="fname" value={SubmitData.fname} onChange={(e) => setSubmitData({ ...SubmitData, fname: e.target.value })} required />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">DOB</label>
                                                    <input type="date" class="form-control" name="dob" value={SubmitData.dob} onChange={(e) => setSubmitData({ ...SubmitData, dob: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Gender</label>
                                                    <select class="form-control" id="autoSizingSelect" name="gender" value={SubmitData.gender} onChange={(e) => setSubmitData({ ...SubmitData, gender: e.target.value })} required>
                                                        <option disabled selected>Choose...</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Academic Session</label>
                                                    <select class="form-control" id="autoSizingSelect" name="asession" value={SubmitData.asession} onChange={(e) => setSubmitData({ ...SubmitData, asession: e.target.value })} required>
                                                        <option selected>Choose...</option>
                                                        <option value="2023-2024">2023-2024</option>
                                                        <option value="2024-2025">2024-2025</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Class</label>
                                                    <select class="form-control" id="autoSizingSelect" name="sclass" value={SubmitData.sclass} onChange={(e) => setSubmitData({ ...SubmitData, sclass: e.target.value })} required >
                                                        <option selected>Choose...</option>
                                                        {Class.map((row) => (
                                                        <option value={row.sclass}>{row.sclass}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {isSelect3 && (
                                                    <>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Stream</label>
                                                            <input type="text" class="form-control" />
                                                        </div>
                                                    </>)}
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Section</label>
                                                    <select class="form-control" id="autoSizingSelect" name="section" value={SubmitData.section} onChange={(e) => setSubmitData({ ...SubmitData, section: e.target.value })} required >
                                                        <option selected>Choose...</option>
                                                        {section.map((row) => (
                                                        <option value={row.section}>{row.section}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">House</label>
                                                    <input type="text" class="form-control"  name="house" value={SubmitData.house} onChange={(e) => setSubmitData({ ...SubmitData, house: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Branch</label>
                                                    <input type="text" class="form-control"   name="branch" value={SubmitData.branch} onChange={(e) => setSubmitData({ ...SubmitData, branch: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Hostel</label>
                                                    <select class="form-control" id="autoSizingSelect"  name="hostel" onChange={checkForm4} required>
                                                        <option selected>Choose...</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                          <h4 className='card-title'>Address Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-3 mb-3">
                                                    <label class="form-label">Nationality</label>
                                                    <input type="text" class="form-control" defaultValue="indian" readOnly  name="nationality" value={SubmitData.nationality} onChange={(e) => setSubmitData({ ...SubmitData, nationality: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Area</label>
                                                    <input type="text" class="form-control" placeholder="Enter Area.." name="area" value={SubmitData.area} onChange={(e) => setSubmitData({ ...SubmitData, area: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">City</label>
                                                    <input type="text" class="form-control" placeholder="Enter city.." name="city" value={SubmitData.city} onChange={(e) => setSubmitData({ ...SubmitData, city: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Pincode</label>
                                                    <input type="text" class="form-control" placeholder="Enter pincode.." name="pincode" title="xxxxxx" pattern="[1-9][0-9]{5}" value={SubmitData.pincode} onChange={(e) => setSubmitData({ ...SubmitData, pincode: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-3 mb-3">
                                                    <label class="form-label">State</label>
                                                    <input type="text" class="form-control" placeholder="Enter state.." name="state" value={SubmitData.state} onChange={(e) => setSubmitData({ ...SubmitData, state: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Country</label>
                                                    <input type="text" class="form-control" defaultValue="india" readOnly  name="country" value={SubmitData.country} onChange={(e) => setSubmitData({ ...SubmitData, country: e.target.value })} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Address</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"  name="address" value={SubmitData.address} onChange={(e) => setSubmitData({ ...SubmitData, state: e.target.value })} required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isSelect4 && (<>
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                         <h4 className='card-title'>Transport Details</h4><hr />
                                            <div class="row form-material">
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Transport Slab</label>
                                                        <input type="text" class="form-control" placeholder="Enter transport slab..." />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Area</label>
                                                        <input type="text" class="form-control" placeholder="Enter area..." />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">City</label>
                                                        <input type="text" class="form-control" placeholder="Enter City..." />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Pincode</label>
                                                        <input type="text" class="form-control" placeholder="Enter pincode..." title="xxxxxx" pattern="[1-9][0-9]{5}" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">State</label>
                                                        <select class="form-control" id="autoSizingSelect">
                                                            <option selected>Choose...</option>
                                                            <option value="Father">Up</option>
                                                            <option value="Mother">Delhi</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6">
                                                        <label class="form-label">Address</label>
                                                        <input type="text" class="form-control" placeholder="Enter address..." />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-12 col-md-6">
                                                        <label class="form-label">Remarks</label>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>)}
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                        <h4 className='card-title'>Student Contact Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mobile</label>
                                                    <input type="number" class="form-control" placeholder="Enter whatsapp number.." name="mobile" value={SubmitData.mobile} onChange={(e)=>setSubmitData({...SubmitData, mobile:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Alternate Mobile</label>
                                                    <input type="text" class="form-control" placeholder="Enter alternate number.." name="alternateno" value={SubmitData.alternateno} onChange={(e)=>setSubmitData({...SubmitData, alternateno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6">
                                                    <label class="form-label">Email</label>
                                                    <input type="email" class="form-control" placeholder="Enter email.." name="email" value={SubmitData.email} onChange={(e)=>setSubmitData({...SubmitData, email:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Primary Contact</label>
                                                    <select class="form-control" id="autoSizingSelect" name="primarycontact" onChange={(e)=>setSubmitData({...SubmitData, primarycontact:e.target.value})} required>
                                                        <option selected>Choose...</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Mother">Mother</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                           <h4 className='card-title'>Mother's Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Name</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's name..." name="mothername" value={SubmitData.mothername} onChange={(e)=>setSubmitData({...SubmitData, mothername:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Mobile</label>
                                                    <input type="number" class="form-control" placeholder="Enter mother's mobile..." name="mothermobileno" value={SubmitData.mothermobileno} onChange={(e)=>setSubmitData({...SubmitData, mothermobileno:e.target.value})} required />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Email</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's email..." name="motheremail" value={SubmitData.motheremail} onChange={(e)=>setSubmitData({...SubmitData, motheremail:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's occupation</label>
                                                    <select class="form-control" id="autoSizingSelect" onChange={checkForm} value={SubmitData.motheremail} required>
                                                        <option selected>Choose...</option>
                                                        <option value="Job">Job</option>
                                                        <option value="Bussiness">Bussiness</option>
                                                        <option value="Professional">Professional</option>
                                                        <option value="housewife">Housewife</option>
                                                    </select>
                                                </div>
                                                {isSelect && (
                                                    <>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Mother's Employer</label>
                                                            <select class="form-control" id="autoSizingSelect" name="memployer" value={SubmitData.memployer} onChange={(e)=>setSubmitData({...SubmitData, memployer:e.target.value})} required>
                                                                <option selected>Choose...</option>
                                                                <option value="Government">Government</option>
                                                                <option value="PSU">PSU</option>
                                                                <option value="Semi-Government">Semi-Government</option>
                                                                <option value="Private">Private</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Mother's Sector (subtype - business)</label>
                                                            <input type="text" class="form-control" placeholder="Enter sector..."  name="msector" value={SubmitData.msector} onChange={(e)=>setSubmitData({...SubmitData, msector:e.target.value})} required/>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Mother's Firm / Department</label>
                                                            <input type="text" class="form-control" placeholder="Enter firm / department..."   name="mdepartment" value={SubmitData.mdepartment} onChange={(e)=>setSubmitData({...SubmitData, mdepartment:e.target.value})} required/>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Mother's Designation / Title</label>
                                                            <input type="text" class="form-control" placeholder="Enter designation..."   name="mdesignation" value={SubmitData.mdesignation} onChange={(e)=>setSubmitData({...SubmitData, mdesignation:e.target.value})} required/>

                                                        </div>
                                                    </>)}
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Aadhar No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's aadhar no..." name="motheraadharno" value={SubmitData.motheraadharno} onChange={(e)=>setSubmitData({...SubmitData, motheraadharno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Pan No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's pan no..."  name="motherpanno" value={SubmitData.motherpanno} onChange={(e)=>setSubmitData({...SubmitData, motherpanno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Photo</label>
                                                    <input type="file" class="form-control" placeholder="Enter mother's pan no..." onChange={handleFileChange('motherpic')} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                          <h4 className='card-title'>Father's Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Name</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's name..." name="fathername" value={SubmitData.fathername} onChange={(e)=>setSubmitData({...SubmitData, fathername:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Mobile</label>
                                                    <input type="number" class="form-control" placeholder="Enter father's mobile..."  name="fathermobileno" value={SubmitData.fathermobileno} onChange={(e)=>setSubmitData({...SubmitData, fathermobileno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Email</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's email..."  name="fatheremail" value={SubmitData.fatheremail} onChange={(e)=>setSubmitData({...SubmitData, fatheremail:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's occupation</label>
                                                    <select class="form-control" id="autoSizingSelect" onChange={checkForm1} name="fathernature" required>
                                                        <option selected>Choose...</option>
                                                        <option value="Job">Job</option>
                                                        <option value="Bussiness">Bussiness</option>
                                                        <option value="Professional">Professional</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                {isSelect1 && (
                                                    <>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Father's Employer</label>
                                                            <select class="form-control" id="autoSizingSelect"  name="femployer" value={SubmitData.femployer} onChange={(e)=>setSubmitData({...SubmitData, femployer:e.target.value})} required>
                                                                <option selected>Choose...</option>
                                                                <option value="Government">Government</option>
                                                                <option value="PSU">PSU</option>
                                                                <option value="Semi-Government">Semi-Government</option>
                                                                <option value="Private">Private</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Father's Sector (subtype - business)</label>
                                                            <input type="text" class="form-control" placeholder="Enter father sector..."   name="fsector" value={SubmitData.fsector} onChange={(e)=>setSubmitData({...SubmitData, fsector:e.target.value})} required/>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Father's Firm / Department</label>
                                                            <input type="text" class="form-control" placeholder="Enter firm / department..."   name="fdepartment" value={SubmitData.fdepartment} onChange={(e)=>setSubmitData({...SubmitData, fdepartment:e.target.value})} required/>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Father's Designation / Title</label>
                                                            <input type="text" class="form-control" placeholder="Enter designation..."   name="fdesignation" value={SubmitData.fdesignation} onChange={(e)=>setSubmitData({...SubmitData, fdesignation:e.target.value})} required/>

                                                        </div>
                                                    </>)}
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Aadhar No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's aadhar no..." name="fatheraadharno" value={SubmitData.fatheraadharno} onChange={(e)=>setSubmitData({...SubmitData, fatheraadharno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Pan No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's pan no..." name="fatherpanno" value={SubmitData.fatherpanno} onChange={(e)=>setSubmitData({...SubmitData, fatherpanno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Father's Photo</label>
                                                    <input type="file" class="form-control" placeholder="Enter father's pan no..." name="fatherpic" value={SubmitData.fatherpic} onChange={(e)=>setSubmitData({...SubmitData, fatherpic:e.target.value})} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                          <h4 className='card-title'>Guardian Details (if applicable)</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian relationship</label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian relationship..." name="guardienrelation" value={SubmitData.guardienrelation} onChange={(e)=>setSubmitData({...SubmitData, guardienrelation:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Name </label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian name ..." name="guardienname" value={SubmitData.guardienname} onChange={(e)=>setSubmitData({...SubmitData, guardienname:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian's Email</label>
                                                    <input type="email" class="form-control" placeholder="Enter guardian's email ..." name="guardienemail" value={SubmitData.guardienemail} onChange={(e)=>setSubmitData({...SubmitData, guardienemail:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Mobile</label>
                                                    <input type="number" class="form-control" placeholder="Enter guardian mobile..." name="guardienmobileno" value={SubmitData.guardienmobileno} onChange={(e)=>setSubmitData({...SubmitData, guardienmobileno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian occupation</label>
                                                    <select class="form-control" id="autoSizingSelect" name="guardiennature" onChange={checkForm2} >
                                                        <option selected>Choose...</option>
                                                        <option value="Job">Job</option>
                                                        <option value="Bussiness">Bussiness</option>
                                                        <option value="Professional">Professional</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                                {isSelect2 && (
                                                    <>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Guardian Employer</label>
                                                            <select class="form-control" id="autoSizingSelect" name="gemployer" onChange={(e)=>setSubmitData({...SubmitData, gemployer:e.target.value})} required>
                                                                <option selected>Choose...</option>
                                                                <option value="Government">Government</option>
                                                                <option value="PSU">PSU</option>
                                                                <option value="Semi-Government">Semi-Government</option>
                                                                <option value="Private">Private</option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Guardian Sector (subtype - business)</label>
                                                            <input type="text" class="form-control" placeholder="Enter father sector..." name="gsector" value={SubmitData.gsector} onChange={(e)=>setSubmitData({...SubmitData, gsector:e.target.value})} required/>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Guardian Firm / Department</label>
                                                            <input type="text" class="form-control" placeholder="Enter firm / department..."  name="gdepartment" value={SubmitData.gdepartment} onChange={(e)=>setSubmitData({...SubmitData, gdepartment:e.target.value})} required/>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Guardian Designation / Title</label>
                                                            <input type="text" class="form-control" placeholder="Enter designation..."  name="gdesignation" value={SubmitData.gdesignation} onChange={(e)=>setSubmitData({...SubmitData, gdesignation:e.target.value})} required/>

                                                        </div>
                                                    </>)}
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Aadhar No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian aadhar no..."  name="guardienadharno" value={SubmitData.guardienadharno} onChange={(e)=>setSubmitData({...SubmitData, guardienadharno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Pan No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian pan no..."  name="guardienpanno" value={SubmitData.guardienpanno} onChange={(e)=>setSubmitData({...SubmitData, guardienpanno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Photo</label>
                                                    <input type="file" class="form-control" placeholder="Enter guardian pan no..."  name="guardienpic" value={SubmitData.guardienpic} onChange={(e)=>setSubmitData({...SubmitData, guardienpic:e.target.value})}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                        <h4 className='card-title'>Student Documents</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Aadhar No</label>
                                                    <input type="text" class="form-control" placeholder="Enter full aadhar no.."  name="aadharno" value={SubmitData.aadharno} onChange={(e)=>setSubmitData({...SubmitData, aadharno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Aadhar Photo</label>
                                                    <input type="file" class="form-control"   name="aadharpic" value={SubmitData.aadharpic} onChange={(e)=>setSubmitData({...SubmitData, aadharpic:e.target.value})} />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Transfer Certificate No</label>
                                                    <input type="text" class="form-control" placeholder="Enter transfer certificate no.."   name="transfercertificateno" value={SubmitData.transfercertificateno} onChange={(e)=>setSubmitData({...SubmitData, transfercertificateno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Transfer Certificate</label>
                                                    <input type="file" class="form-control"   name="transfercertificatepic" value={SubmitData.transfercertificatepic} onChange={(e)=>setSubmitData({...SubmitData, transfercertificatepic:e.target.value})} />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Birth Certificate No </label>
                                                    <input type="text" class="form-control" placeholder="Enter birth certificate no.."   name="birthcertificateno" value={SubmitData.birthcertificateno} onChange={(e)=>setSubmitData({...SubmitData, birthcertificateno:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Birth Certificate</label>
                                                    <input type="file" class="form-control"   name="birthcertificatepic" value={SubmitData.birthcertificatepic} onChange={(e)=>setSubmitData({...SubmitData, birthcertificatepic:e.target.value})} />
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Student Photo</label>
                                                    <input type="file" class="form-control"   name="studentpic" value={SubmitData.studentpic} onChange={(e)=>setSubmitData({...SubmitData, studentpic:e.target.value})} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                        <h4 className='card-title'>Other Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Reason for change</label>
                                                    <input type="text" class="form-control" placeholder="Enter reason for change ..."  name="reasonforchange" value={SubmitData.reasonforchange} onChange={(e)=>setSubmitData({...SubmitData, reasonforchange:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Previous school</label>
                                                    <input type="text" class="form-control" placeholder="Enter previous school..."  name="previousschool" value={SubmitData.previousschool} onChange={(e)=>setSubmitData({...SubmitData, previousschool:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Previous class</label>
                                                    <input type="text" class="form-control" placeholder="Enter previous class..."  name="previousclass" value={SubmitData.previousclass} onChange={(e)=>setSubmitData({...SubmitData, previousclass:e.target.value})} required/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Admission Session</label>
                                                    <select class="form-control" id="autoSizingSelect"  name="adecmicsession" onChange={(e)=>setSubmitData({...SubmitData, adecmicsession:e.target.value})} required>
                                                        <option selected>Choose...</option>
                                                        <option value="2023-2024">2023-2024</option>
                                                        <option value="2023-2024">2023-2024</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                        <h4 className='card-title'>Medical Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Blood Group</label>
                                                    <input type="text" class="form-control" placeholder="Enter blood group..." name="bloodgroup" value={SubmitData.bloodgroup} onChange={(e)=>setSubmitData({...SubmitData, bloodgroup:e.target.value})} required/>
                                                </div>

                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">History of major illness(if any)</label>
                                                    <input type="text" class="form-control" placeholder="Enter History of major illness..." name="illnesshistory" value={SubmitData.illnesshistory} onChange={(e)=>setSubmitData({...SubmitData, illnesshistory:e.target.value})}/>
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Allergies to medicine and food(if any)</label>
                                                    <input type="text" class="form-control" placeholder="Enter allergies to medicine and food..." name="alergyfood" value={SubmitData.alergyfood} onChange={(e)=>setSubmitData({...SubmitData, alergyfood:e.target.value})}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {isSelect5 && (<>
                                    <div class="col-lg-6">
                                        <div class="card">
                                            <div class="card-body text-black fw-bold fs-6">
                                            <h4 className='card-title'>Payment for Application Form</h4><hr />
                                                <div class="row form-material">
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Amount</label>
                                                        <input type="text" class="form-control" placeholder="2000/-" />
                                                    </div>

                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Method</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Transaction ID / Receipt ID / Cheque No</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Remitter Name</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Minimum Application Fee</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Start Month</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-xxl-6">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="card">
                                                    <div class="card-body text-black fw-bold fs-6">
                                                    <h4 className='card-title'>Add Notes</h4><hr />
                                                        <div class="row form-material">
                                                            <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                                <label class="form-label">File</label>
                                                                <input type="file" class="form-control" placeholder="Enter mobile" />
                                                            </div>
                                                            <div class="col-xl-3 col-xxl-12 col-md-6">
                                                                <label class="form-label">Note</label>
                                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>                                        </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                <h4 className='card-title'>Add Notes</h4>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <form class="form-valide-with-icon needs-validation">
                                                        <div class="row form-material">
                                                            <div class="col-xl-3 col-xxl-12 col-md-6">
                                                                <label class="form-label">File</label>
                                                                <input type="file" class="form-control" placeholder="Enter mobile" />
                                                            </div>
                                                            <div class="col-xl-3 col-xxl-12 col-md-6">
                                                                <label class="form-label">Note</label>
                                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>                                        </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="modal-footer">
                                                    <Link type="button" class="btn btn-primary btn-sm me-3">Submit</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                            </div>
                            <div className="text-end">
                                <button type="submit" class="bg-black text-white fw-bold mb-3" style={{ width: "100px" }}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default New_Application;