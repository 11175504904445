import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../teacher-helper";
import Loader from "../../../Loader";
const MarkAttendance = () => {
    const teacherData = localStorage.getItem("teacherData");
    const [loading, setLoading] = useState(true);
    const [classList, setClassList] = useState([]);
    const [section, setSection] = useState([]);
    const [attendance, setAttendance] = useState([]);
    const [MarkAttendance, setMarkAttendance] = useState([]);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
    const fetchTodayAttendance = async () => {
        try {
            const response = await axiosInstance.get("Teacher/GetAllAttendance");
            setMarkAttendance(response.data.Payload || []);
        } catch (error) {
            console.error('Error recording attendance:', error);
        }
    };
    useEffect(() => {
        fetchTodayAttendance();
    }, []);
    const [todayAttendance, setTodayAttendance] = useState({
        "studentid": "",
        "attendance": "",
        "attendanceby": teacherData,
    });
    const handleAttendanceClick = async (studentId, attendanceValue) => {
        todayAttendance.attendance = attendanceValue;
        todayAttendance.attendanceby = teacherData;
        todayAttendance.studentid = studentId;
        try {
            // setLoading(true);
            const response = await axiosInstance.post(`/Teacher/MakeStudentAttended`, todayAttendance);
            if (!response.data.Status === 'success') {
                throw new Error('Today attendance mark failed');
            }
            toast.success('Today attendance marked successfully!', {
                position: toast.POSITION.TOP_RIGHT,
            });
            fetchTodayAttendance();
        } catch (error) {
            console.error('Error recording attendance:', error);
        } 
        // finally {
        //     setLoading(false);
        // }
    };
    const [data, setData] = useState({
        sclass: "",
        section: "",
    });
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(
                `/Teacher/Getattendstudentdetail`,
                data
            );
            setAttendance(response.data.Payload);
        } catch (error) {
            window.location.href = "/teacher";
        }finally{
            setLoading(false);
        }
    };
    const fetchClass = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/Teacher/GetTeacherClass`);
            setClassList(response.data.Payload);
        } catch (error) {
            window.location.href = "/teacher";
        }finally{
            setLoading(false);
        }
    };
    const fetchSection = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/Teacher/GetTeacherSection");
            setSection(response.data.Payload);
        } catch (error) {
            window.location.href = "/teacher";
        }finally{
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchSection();
        fetchClass();
    }, []);
    // handle Search change
    // const searchChange = (e, field) => {
    //     const { value } = e.target
    //     setData((prev) => ({ ...prev, [field]: value }));
    // };
    useEffect(() => {
        const allFieldsEmpty = Object.values(data).every((val) => val.trim() === "")
        if (data.length === 0 || allFieldsEmpty) {
            fetchData();
        } else if (Object.values(data).some((val) => val.length > 0)) {
            fetchData();
        }
    }, [data])
    // -- pagination Code -- //
    const dataPerPage = 10;

    const [currentPage, setCurrentPage] = useState(0);

    const numberOfPage = Math.ceil(attendance?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>

            <div className="container-fluid">
                <div className="row pt-4">
                    <h4 class="card-title">Mark Attendance </h4>
                    <div class="col-lg-12 pt-4">
                        <div className="card custom-card mb-3">
                            <div className='card-header justify-content-between'>
                                <div class="card-title">Today Attendance</div>
                                {/* <div class="flex flex-wrap gap-2">
                                        <select
                                            class="form-control"
                                            id="autoSizingSelect"
                                            name="sclass"
                                            onChange={(e) => searchChange(e, "sclass")}
                                            required
                                        >
                                            <option>Choose...</option>
                                            {classList ? (
                                                classList.map((row) => (
                                                    <option value={row.sclass}>
                                                        {row.sclass}
                                                    </option>
                                                ))
                                            ) : (
                                                <p>No data available</p>
                                            )}
                                        </select>
                                    </div>
                                    <div class="flex flex-wrap gap-2">
                                        <select
                                            class="form-control"
                                            id="autoSizingSelect"
                                            name="section"
                                            onChange={(e) => searchChange(e, "section")}
                                            required
                                        >
                                            <option>Choose...</option>
                                            {section ? (
                                                section.map((row) => (
                                                    <option value={row.section}>
                                                        {row.section}
                                                    </option>
                                                ))
                                            ) : (
                                                <p>No data available</p>
                                            )}
                                        </select>
                                    </div> */}
                                <div class="flex flex-wrap gap-2">
                                    <span class="btn btn-danger light shadow btn-xs"><i class="bi bi-calendar4"></i> {formattedDate}</span>
                                </div>
                            </div>
                            <div class="card-body py-3 px-3">
                                <div class="table-responsive">
                                    {loading ? (
                                        <Loader />
                                    ) : (
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Roll No.</th>
                                                    <th>Class</th>
                                                    <th>Section</th>
                                                    <th>Atendance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {attendance ? (
                                                    attendance.slice(
                                                        currentPage * dataPerPage,
                                                        (currentPage + 1) * dataPerPage
                                                    ).map((student, index) => (
                                                        <tr className="text-black fs-5" key={student.studentid}>
                                                            <td>{index + 1}</td>
                                                            <td>{student.name}</td>
                                                            <td>{student.rollno}</td>
                                                            <td>{student.sclass}</td>
                                                            <td>{student.section}</td>
                                                            <td>
                                                                {MarkAttendance.find(item => item.studentid === student.studentid) ? (
                                                                    MarkAttendance.find(item => item.studentid === student.studentid).attendance === 'Present' ? (
                                                                        <span className="btn btn-primary light shadow btn-xs">Present</span>
                                                                    ) : (
                                                                        <span className="btn btn-danger light shadow btn-xs">Absent</span>
                                                                    )
                                                                ) : (
                                                                    <div>
                                                                        <button class="btn btn-primary light shadow btn-xs sharp me-1" onClick={() => handleAttendanceClick(student.studentid, 'Present')}>P</button>&nbsp;&nbsp;
                                                                        <button class="btn btn-danger light shadow btn-xs sharp me-1" onClick={() => handleAttendanceClick(student.studentid, 'Absent')}>A</button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <p>No data available</p>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item">
                                            <a
                                                class="page-link"
                                                style={{
                                                    pointerEvents:
                                                        currentPage === 0 ? "none" : "auto",
                                                    opacity: currentPage === 0 ? 0.5 : 1,
                                                }}
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            >
                                                Previous
                                            </a>
                                        </li>
                                        {pageIndex
                                            .slice(
                                                Math.max(0, currentPage - 2),
                                                Math.min(numberOfPage, currentPage + 2)
                                            )
                                            .map((page) => (
                                                <li
                                                    class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                        }`}
                                                    key={page}
                                                >
                                                    <a
                                                        class="page-link"
                                                        style={{
                                                            pointerEvents:
                                                                page - 1 === currentPage
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity: page - 1 === currentPage ? 0.5 : 1,
                                                        }}
                                                        onClick={() => handlePageChange(page - 1)}
                                                        href="#"
                                                    >
                                                        {page}
                                                    </a>
                                                </li>
                                            ))}
                                        <li class="page-item">
                                            <a
                                                class="page-link"
                                                style={{
                                                    pointerEvents:
                                                        currentPage === numberOfPage - 1
                                                            ? "none"
                                                            : "auto",
                                                    opacity:
                                                        currentPage === numberOfPage - 1 ? 0.5 : 1,
                                                }}
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            >
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarkAttendance;