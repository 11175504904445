import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

const months = Array.from({ length: 12 }, (_, index) => {
  const monthIndex = index + 1;
  return {
    index: monthIndex,
    name: new Date(0, index).toLocaleString("en-US", { month: "long" }),
    isActive: false,
    isPaid: false,
  };
});
function Payment() {
  const Navigate = useNavigate();
  const { studentid } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [academic, setAcademic] = useState(null);
  const [transport, setTransport] = useState([]);
  const [paidfees, setPaidFees] = useState([]);
  const [payFees, setPayFees] = useState([]);
  const [monthSelect, setmonthSelect] = useState(months);
  const [sum, setSum] = useState();
  const [selectedMonthCount, setSelectMonthCount] = useState(0);
  const [studentDetail, setStudentDetail] = useState([]);
  const [payAmount, setPayAmount] = useState(0);
  const [dueAmt, setDueAmt] = useState();
  const [prevDueAmt, setPrevDueAmt] = useState(0);
  const [lateFees, setLateFees] = useState({});
  const [discountAmt, setDiscountAmount] = useState(0);
  const [studentData, setStudentData] = useState([]);
  console.log('studentData',studentData);
  const [sibling, setSibling] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  // generate current date functions
  const currentYear = new Date().getFullYear();

  // Fetching All Data
  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        studentDetailResponse,
        studentDataResponse,
        dueAmtResponse,
        studentClassResponse,
        siblingResponse,
      ] = await Promise.all([
        axiosInstance.get(`/Student/GetStudentICard?studentid=${studentid}`),
        axiosInstance.get(`/Admin/GetLastMonthPayment?studentid=${studentid}`),
        axiosInstance.get(`/ManageFees/GetDueBalance?studentid=${studentid}`),
        axiosInstance.get(
          `/student/GetStudentInformation?studentid=${studentid}`
        ),
        axiosInstance.get(`/Admin/GetSiblingRelated?studentid=${studentid}`),
      ]);
      setAcademic(
        studentClassResponse.data.AcademicDetail
          ? studentClassResponse?.data.AcademicDetail[0]?.sclass
          : {}
      );

      setLateFees(
        studentClassResponse.data.LateFeeDetail
          ? studentClassResponse.data.LateFeeDetail[0]
          : {}
      );

      setStudentDetail(studentDetailResponse?.data?.Payload[0]);
      setStudentData(studentDataResponse.data);
      setSibling(siblingResponse.data.Payload);

      let transData = {};
      if (studentClassResponse.data.TransportDetail[0]) {
        transData = {
          amount: studentClassResponse.data.TransportDetail[0].routefee,
          class: "all",
          fee_name: studentClassResponse.data.TransportDetail[0].routeno,
          fee_type: "Monthly",
          fee_type_id: 23332,
        };
        setTransport(transData);
      }
      const dueAmountData = dueAmtResponse
      ? dueAmtResponse.data.Payload.map((d) => {
          return {
            previous_dues: d.previous_due,
            due_amt: d.due_amount,
            paid_amt: d.paid_amount,
          };
        })
      : {};
    setPrevDueAmt(dueAmountData)


      
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  //Fetching student class fees
  const fetchStudentClassFees = async () => {
    // setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/Master/GetRegularFeeType?clss=${academic}`
      );

      let feeData = response.data.Payload;

      if (Object.keys(transport).length > 0) {
        feeData.push(transport);
      }

      if (lateFees) {
        // Current Date Function and Due Date Calculations
        const toDate = new Date(lateFees?.to_date).getDate();
        const perDay = lateFees.perdayfee;
        const perMonth = lateFees.maxfee;
        const lastPaidMonth = payFees
          ?.filter((p) => p.status)
          .map((p) => p.type)
          ?.slice(-1)[0];
        let lastPaidMonthNo = 0;
        if (lastPaidMonth !== "OneTime") {
          lastPaidMonthNo = new Date(lastPaidMonth + "-1-01").getMonth() + 1;
        } else {
          lastPaidMonthNo = 0;
        }

        // calculation of late fees fine
        const calculateLateFees = (
          dueDate,
          year,
          maxFee,
          dayFee,
          firstMonthToPay
        ) => {
          const currentMonth = new Date().getMonth();
          const currentDate = new Date().getDate();

          const lateFeesObject = {};

          for (let i = firstMonthToPay; i <= currentMonth; i++) {
            let monthName = new Date(year, i, 1).toLocaleString("default", {
              month: "long",
            });
            if (i === currentMonth && currentDate < dueDate) {
              lateFeesObject[monthName] = 0;
            } else if (i === currentMonth) {
              lateFeesObject[monthName] =
                (new Date().getDate() - dueDate) * dayFee;
            } else {
              const dueMonthsFee = maxFee;
              lateFeesObject[monthName] = dueMonthsFee;
            }
          }

          return lateFeesObject;
        };

        const monthWiseLateFee = calculateLateFees(
          toDate,
          currentYear,
          perMonth,
          perDay,
          lastPaidMonthNo
        );
        const checkMonth = monthSelect
          .filter((m) => m.isActive)
          .map((m) => m.name);
        let monthLateFee = [];
        checkMonth.forEach((month) => {
          if (monthWiseLateFee[month]) {
            monthLateFee.push(monthWiseLateFee[month]);
          }
        });

        let sum = monthLateFee.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
        let lateFeesObject = {
          amount: sum,
          class: "all",
          fee_type: "Monthly",
          fee_type_id: 23565,
          to_date: lateFees.to_date,
          from_date: lateFees.from_date,
          fee_name: "Late Fine ",
          month: monthWiseLateFee,
        };
        if (
          Object.values(monthWiseLateFee).length > 0 &&
          lateFeesObject.amount > 0
        ) {
          feeData.push(lateFeesObject);
        }
      }
      // late fees addition to the monthly
      setPaidFees(
        feeData.map((fee) => {
          return {
            ...fee,
            isSelected: true,
            paidamount: fee.amount,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching data:", error);
      // window.location.href="/";
    }
  };

  //  fetching paidFees
  const fetchPaidFees = async () => {
    try {
      const response = await axiosInstance.get(
        `/ManageFees/GetStudentMonthlyPaymentDetail?studentid=${studentid}`
      );
      const selectedMonths = response.data.Payload.map((paid) => {
        return {
          type: paid.Payment_month,
          status: paid.status,
        };
      });
      setPayFees(selectedMonths);
      //calculations of late fees detail
      setmonthSelect(
        monthSelect.map((month) => {
          if (
            selectedMonths.some(
              (payFee) => payFee.type === month.name && payFee.status === 1
            )
          ) {
            return {
              ...month,
              isActive: true,
              isPaid: true,
            };
          } else {
            return month;
          }
        })
      );
    } catch (error) {
      // console.error("Error fetching data", error)
      // window.location.href="/";
    }
  };

  // Month handle checkbox
  const handleCheckBoxMonth = (id) => {
    if (
      id === 1 ||
      monthSelect[id - 2]?.isActive === true ||
      monthSelect[id - 1]?.isActive === true
    ) {
      let updatedMonthSelect = monthSelect.map((monthCheck) => {
        if (monthCheck.index === id) {
          return {
            ...monthCheck,
            isActive: !monthCheck.isActive,
          };
        } else {
          return monthCheck;
        }
      })
      setmonthSelect(updatedMonthSelect);
      setSelectMonthCount(updatedMonthSelect.filter((p) => p.isActive && !p.isPaid).length)
    } else {
      toast.error("Please Select the Previous Months...", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //Handle Checkbox fees
  const handleCheckBoxFees = (id) => {
    setPaidFees(
      paidfees.map((fees) => {
        const isSelected = !fees.isSelected;
        if (fees.fee_type_id === id) {
          return {
            ...fees,
            isSelected,
            // disamount: isSelected === false ? "" : fees.disamount ,
            paidamount: isSelected === false ? "" : fees.amount,
          };
        }

        return fees;
      })
    );
  };

  // Summation Of All fees
  const handleSum = () => {
    let numbers;
    if (lateFees?.to_date) {
      numbers = paidfees
        .filter((value) => !value.to_date)
        .map((fee) => fee.paidamount || 0);
    } else {
      numbers = paidfees.map((fee) => fee.paidamount || 0);
    }
    const addition = numbers.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setSum(addition * selectedMonthCount);
  };

  //functions for due Amount
  const handleDueAmount = () => {
    if (sum || (prevDueAmt[0]?.due_amt && payAmount !== null)) {
      const dues =
        sum +
        (lateFees && paidfees.find((f) => f.to_date && f.isSelected === true)
          ? paidfees?.find((dueDate) => dueDate?.to_date).amount
          : 0) +
        (prevDueAmt[0]?.due_amt ?? 0) -
        payAmount -
        discountAmt;
      setDueAmt(dues);
    } else {
      setDueAmt(null);
    }
  };

  // console.log('dd', sum +
  //     (lateFees && paidfees.find((f) => f.to_date && f.isSelected === true)
  //       ? paidfees?.find((dueDate) => dueDate?.to_date).amount
  //       : 0) +
  //     (prevDueAmt[0]?.due_amt ?? 0) -
  //     discountAmt);
  // Summation of All Fees
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!paidfees.every((f) => f.isSelected)) {
      toast.error("Please Select the Fee Name...", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setButtonLoading(true);
      const payload = {
        studentid: studentid,
        totalamount:
          sum +
          (lateFees && paidfees.find((f) => f.to_date && f.isSelected === true)
            ? paidfees?.find((dueDate) => dueDate?.to_date).amount
            : 0) +
          (prevDueAmt[0]?.due_amt ?? 0),
        totaldueamount: dueAmt,
        totaldiscount: discountAmt,
        totalpaidamount: payAmount,
        previous_dues: prevDueAmt ? prevDueAmt[0]?.due_amt : prevDueAmt,
        month: monthSelect
          .filter((m) => m.isActive && !m.isPaid)
          .map((mont) => mont.name),
        payment: paidfees
          .filter((p) => p.isSelected)
          .map((fee) => {
            if (fee.isSelected) {
              return {
                feename: fee.fee_name,
                feeamount: fee.amount,
                disamount: 0,
                paidamount: paidfees.find(
                  (f) => f.to_date && f.isSelected === true
                )
                  ? fee.paidamount
                  : fee.paidamount * selectedMonthCount,
              };
            }
          }),
      };

      try {
        const response = await axiosInstance.post(
          "/ManageFees/CreateMonthlyPaymentDetail",
          payload
        );

        const transactionId = response.data.transactionId;

        if (!response.data.Status === "success") {
          throw new Error("Add Payment failed");
        }

        toast.success("Payment added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setButtonLoading(false);
        Navigate(
          `/admin/students/payment-transaction/${studentid}/${transactionId}`
        );
      } catch (error) {
        setButtonLoading(true);

        toast.error("Add Payment failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleSiblingClick = (studentid) => {
    window.location = `/admin/students/monthly-payment/${studentid}`;
  };

  useEffect(() => {
    fetchData();
  }, [studentid]);

  useEffect(() => {
    fetchStudentClassFees();
  }, [academic, monthSelect]);

  useEffect(() => {
    fetchPaidFees();
  }, [studentid, lateFees]);

  useEffect(() => {
    handleSum();
  }, [selectedMonthCount]);

  useEffect(() => {
    handleDueAmount();
  }, [
    payAmount,
    sum,
    discountAmt,
    paidfees
  ]);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };
  return (
    <>
      <div>
        {loading ? (
          <div
            className="container text-center"
            style={{ paddingTop: "200px" }}
          >
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-info" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-dark" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div class="card-header">
                <h4 class="card-title">Fee Summary </h4>
              </div>
              <div class="col-lg-9 ">
                <div class="card custom-card mb-3">
                  <div class="card-header">
                    <h4 class="card-title mb-0">Fee Details</h4>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <div className="pt-3 p-2">
                        <label class="form-label">Select Month : &nbsp;</label>
                        {monthSelect.map((month) => (
                          <div
                            key={month.index}
                            class="form-check form-check-inline fs-6"
                          >
                            {payFees.some(
                              (payFee) =>
                                payFee.type === month.name &&
                                payFee.status === 1
                            ) ? (
                              <input
                                class="form-check-input"
                                type="checkbox"
                                defaultChecked={true}
                                disabled
                              />
                            ) : (
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onChange={() =>
                                  handleCheckBoxMonth(month.index)
                                }
                                id={months.index}
                                checked={month.isActive}
                              />
                            )}

                            {month.name}
                          </div>
                        ))}
                      </div>
                      <br />

                      <table class="table header-border table-responsive-sm">
                        <thead className="text-white btn-primary">
                          <tr>
                            <th>#</th>
                            <th>Fee Name</th>
                            <th>Amount</th>
                            {/* <th>Discount %</th> */}
                            <th style={{ width: "200px" }}>Paid Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paidfees.length > 0 ? (
                            paidfees?.map((fees_type, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={fees_type.isSelected === true}
                                      onChange={() =>
                                        handleCheckBoxFees(
                                          fees_type.fee_type_id
                                        )
                                      }
                                      id={fees_type.fee_type_id}
                                    />
                                  </td>
                                  <td>
                                    {fees_type.fee_name}
                                    {fees_type.to_date ? (
                                      <>
                                        <i
                                          class="bi bi-info-circle text-secondary"
                                          onClick={togglePopover}
                                        ></i>
                                        {popoverOpen && (
                                          <div
                                            className="popover"
                                            style={{
                                              position: "absolute",
                                              width: "80px",
                                            }}
                                          >
                                            <div className="popover-body p-2">
                                              {Object.keys(fees_type.month).map(
                                                (month) => (
                                                  <p className="mb-1">{`${month} : ${fees_type.month[month]}`}</p>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      class="form-label mb-0 custom-label"
                                      id="{fees_type.fee_type_id}"
                                    >
                                      {" "}
                                      {fees_type.amount}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      class="form-label mb-0 custom-label"
                                      id={fees_type.fee_type_id}
                                    >
                                      {" "}
                                      {fees_type.to_date
                                        ? selectedMonthCount > 0
                                          ? fees_type.paidamount
                                          : ""
                                        : fees_type.paidamount *
                                            selectedMonthCount || ""}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <p className="text-center text-red">
                              No data available
                            </p>
                          )}
                          <tr>
                            <td colspan="3" className="text-end py-2 border-0">
                              <span className=" fs-6 font-w500">
                                Previous Dues
                              </span>
                            </td>
                            <td className="py-2 border-0">
                              {" "}
                              <input
                                type="text"
                                value={prevDueAmt ? prevDueAmt[0]?.due_amt : 0}
                                className="form-control form-control-sm fs-5"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" className="text-end py-2 border-0">
                              <span className="fs-6 font-w500">
                                Discount Amount
                              </span>
                            </td>
                            <td className="py-2 border-0">
                              {" "}
                              <input
                                type="number"
                                className="form-control form-control-sm fs-5"
                                onChange={(e) =>
                                  setDiscountAmount(e.target.value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" className="text-end py-2 border-0">
                              <span className=" fs-6 font-w500">
                                Total Amount
                              </span>
                            </td>
                            <td className="py-2 border-0">
                              {" "}
                              <input
                                type="text"
                                className="form-control form-control-sm fs-5"
                                value={
                                  sum +
                                  (lateFees &&
                                  paidfees.find(
                                    (f) => f.to_date && f.isSelected === true
                                  )
                                    ? paidfees?.find(
                                        (dueDate) => dueDate?.to_date
                                      ).amount
                                    : 0) +
                                  (prevDueAmt[0]?.due_amt ?? 0) -
                                  discountAmt
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" className="text-end py-2 border-0">
                              <span className=" fs-6 font-w500">
                                Pay Amount
                              </span>
                            </td>
                            <td className="py-2 border-0">
                              {" "}
                              <input
                                type="number"
                                className="form-control form-control-sm fs-5"
                                onChange={(e) => setPayAmount(e.target.value)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" className="text-end py-2 border-0">
                              <span className=" fs-6 font-w500">
                                Due Amount
                              </span>
                            </td>
                            <td className="py-2 border-0">
                              {" "}
                              <input
                                type="text"
                                className="form-control form-control-sm fs-5"
                                value={dueAmt ?? 0}
                                disabled
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-end mb-3 ">
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 ">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card custom-card mb-3">
                      <div class="card-header">
                        <h4 class="card-title mb-0">Sibling list</h4>
                      </div>
                      <div class="card-body pt-3">
                        <ul class="list-style-1">
                          {sibling ? (
                            sibling.map((row) => (
                              // <li>
                              //   <label class="form-label mb-0 custom-label text-primary" onClick={() => handleClick(row.studentid)}>{row.name}</label>
                              // </li>
                              <div class="listline-wrapper mb-2">
                                <span
                                  class="item text-primary"
                                  onClick={() =>
                                    handleSiblingClick(row.studentid)
                                  }
                                >
                                  {row.name}
                                </span>
                                <span class="item">{row.rollno}</span>
                                <span class="item">{row.sclass}</span>
                              </div>
                            ))
                          ) : (
                            <p className="text-red">No data available</p>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="card custom-card mb-3">
                      <div class="card-header">
                        <h4 class="card-title mb-0">Overview</h4>
                      </div>
                      <div class="card-body pt-3">
                        <ul class="list-style-1">
                          <li>
                            <label class="form-label mb-0 custom-label">
                              Name :
                            </label>
                            <p class="mb-0">{studentData.name}</p>
                          </li>
                          <li>
                            <label class="form-label mb-0 custom-label">
                              Roll No :
                            </label>
                            <p class="mb-0">{studentData.rollno ?? ""}</p>
                          </li>
                          <li>
                            <label class="form-label mb-0 custom-label">
                              Class :
                            </label>
                            <p class="mb-0">{studentData.sclass ?? ""}</p>
                          </li>
                          <li>
                            <label class="form-label mb-0 custom-label">
                              Section :
                            </label>
                            <p class="mb-0">{studentData.section ?? ""}</p>
                          </li>
                          <li>
                            <label class="form-label mb-0 custom-label">
                              Month :
                            </label>
                            <p class="mb-0">
                              {studentData.Month ?? ""}
                            </p>
                          </li>
                          <li>
                            <label class="form-label mb-0 custom-label">
                              Total Amount:
                            </label>
                            <p class="mb-0">{studentData.totalamount ?? ""}</p>
                          </li>
                          <li>
                            <label class="form-label mb-0 custom-label">
                              Total Discount:
                            </label>
                            <p class="mb-0">{studentData.totaldiscount ?? ""}</p>
                          </li>
                          <li>
                            <label class="form-label mb-0 custom-label">
                              {" "}
                              Paid Amount :
                            </label>
                            <p class="mb-0">{studentData.totalpaid ?? ""}</p>
                          </li>
                          <li>
                            <label class="form-label mb-0 custom-label">
                              {" "}
                              Dues Amount :
                            </label>
                            <p class="mb-0">{studentData.totaldue ?? ""}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Payment;
