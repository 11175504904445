import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import Loader from "../../Loader";
const MonthAttendance = () => {
    const [loading, setLoading] = useState(true);
    const [classList, setClassList] = useState([]);
    const [section, setSection] = useState([]);
    const [getdata, setGetData] = useState([]);
    const [data, setData] = useState({
        rollno: "",
        sclass: "",
        section: "",
        month: "",
    });
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(`/Admin/GetSearchAttendance`, data);
            setGetData(response.data.Payload);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchData();
    }, [data]);

    const fetchClass = async () => {
        try {
            const response = await axiosInstance.get("/Master/GetClass");
            setClassList(response.data.Payload);
        } catch (error) {
            window.location.href = "/";
        }
    }
    const fetchSection = async () => {
        try {
            const response = await axiosInstance.get('/Master/GetSection');
            setSection(response.data.Payload);
        } catch (error) {
            window.location.href = "/";
        }
    }
    useEffect(() => {
        fetchClass();
        fetchSection();
    }, []);
    // handle search change
    const searchChange = (e, field) => {
        const { value } = e.target;
        setData((prev) => ({ ...prev, [field]: value }));
    };

    // Function to convert month number to month name
    const getMonthName = (monthNumber) => {
        const monthNames = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        return monthNames[monthNumber - 1] || '';
    };

    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(getdata?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div>
                <div className="container-fluid">
                    <div className="row ">
                        <div class="card-header">
                            <h4 class="card-title">Attendance Report</h4>
                        </div>
                        <div class="col-lg-12">
                            <div class="card custom-card mb-3">
                                <div class="card-header justify-content-between"><div class="card-title">Monthly Attendance</div></div>
                                <div class="card-body py-3 px-3">
                                    <div class="row form-material">
                                        <div class="col-xl-3 col-xxl-3 col-md-6 col-6 mb-3">
                                            <label class="form-label">Month</label>
                                            <select class="form-control" id="autoSizingSelect" onChange={(e) => searchChange(e, "month")}>
                                                <option selected>Select month...</option>
                                                <option value={1}>Jan</option>
                                                <option value={2}>Feb</option>
                                                <option value={3}>March</option>
                                                <option value={4}>April</option>
                                                <option value={5}>May</option>
                                                <option value={6}>June</option>
                                                <option value={7}>July</option>
                                                <option value={8}>Aug</option>
                                                <option value={9}>Sept</option>
                                                <option value={10}>Oct</option>
                                                <option value={11}>Nov</option>
                                                <option value={12}>Dec</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-3 col-xxl-3 col-md-6 col-6 mb-3">
                                            <label class="form-label">Class</label>
                                            <select class="form-control" id="autoSizingSelect" onChange={(e) => searchChange(e, "sclass")}>
                                                <option selected>Select class...</option>
                                                {classList ? (
                                                    classList.map((row) => (
                                                        <option value={row.sclass}>
                                                            {row.sclass}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <p>No data available</p>
                                                )}
                                            </select>
                                        </div>
                                        <div class="col-xl-3 col-xxl-3 col-md-6 col-6 mb-3">
                                            <label class="form-label">Section</label>
                                            <select class="form-control" id="autoSizingSelect" onChange={(e) => searchChange(e, "section")}>
                                                <option selected>Select section...</option>
                                                {section ? (
                                                    section.map((row) => (
                                                        <option value={row.section}>
                                                            {row.section}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <p>No data available</p>
                                                )}
                                            </select>
                                        </div>
                                        <div class="col-xl-3 col-xxl-3 col-md-6 col-6 mb-3">
                                            <label class="form-label">Roll No.</label>
                                            <input type='search' className='form-control' onChange={(e) => searchChange(e, "rollno")} placeholder='Search Roll No..' />
                                        </div>
                                    </div>
                                    {loading ? (
                                      <Loader/>
                                    ) : (
                                    <>
                                    <div class="table-responsive">
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th>Month</th>
                                                    <th>Name</th>
                                                    <th>Class</th>
                                                    <th>Section</th>
                                                    <th>Roll No.</th>
                                                    <th>Present</th>
                                                    <th>Absent</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getdata && getdata.length > 0 ? (
                                                    getdata
                                                        .slice(
                                                            currentPage * dataPerPage,
                                                            (currentPage + 1) * dataPerPage
                                                        ).map((row) => (
                                                            <tr className="text-black fs-5" key={row.id}>
                                                                <td>{getMonthName(row.month)}</td>
                                                                <td>{row.name}</td>
                                                                <td>{row.sclass}</td>
                                                                <td>{row.section}</td>
                                                                <td>{row.rollno}</td>
                                                                <td>
                                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                        <div class="progress-bar bg-success fw-bold" style={{ width: `${Math.round((row.present / (row.present + row.absent)) * 100)}%` }}>{Math.round((row.present / (row.present + row.absent)) * 100)}%</div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                        <div class="progress-bar bg-danger fw-bold" style={{ width: `${Math.round((row.absent / (row.present + row.absent)) * 100)}%` }}>{Math.round((row.absent / (row.present + row.absent)) * 100)}%</div>
                                                                    </div>
                                                                </td>
                                                                <td><Link to={`/admin/attendance/calendar/${row.studentid}/${row.month}`} class="btn btn-primary btn-xs">View Day Wise</Link></td>
                                                            </tr>
                                                        ))
                                                ) : (
                                                    <p>No data available</p>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    </>
                                   )}
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item">
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            currentPage === 0 ? "none" : "auto",
                                                        opacity: currentPage === 0 ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                >
                                                    Previous
                                                </a>
                                            </li>
                                            {pageIndex
                                                .slice(
                                                    Math.max(0, currentPage - 2),
                                                    Math.min(numberOfPage, currentPage + 2)
                                                )
                                                .map((page) => (
                                                    <li
                                                        class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                            }`}
                                                        key={page}
                                                    >
                                                        <a
                                                            class="page-link"
                                                            style={{
                                                                pointerEvents:
                                                                    page - 1 === currentPage
                                                                        ? "none"
                                                                        : "auto",
                                                                opacity: page - 1 === currentPage ? 0.5 : 1,
                                                            }}
                                                            onClick={() => handlePageChange(page - 1)}
                                                            href="#"
                                                        >
                                                            {page}
                                                        </a>
                                                    </li>
                                                ))}
                                            <li class="page-item">
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            currentPage === numberOfPage - 1
                                                                ? "none"
                                                                : "auto",
                                                        opacity:
                                                            currentPage === numberOfPage - 1 ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default MonthAttendance;