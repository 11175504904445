import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
import { toast } from "react-toastify";
import Loader from "../../Loader";
const ManageRequest = () => {
  const [loading, setLoading] = useState(true);
  const [bookRequestList, setBookRequestList] = useState([]);
  const fetchRequestData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/Admin/GetApplybookDetail`);
      setBookRequestList(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequestData();
  }, []);

  // date converter functions
  const formatDate = (inputDate) => {
    return inputDate?.split("T")[0].split("-").reverse().join("-");
  };

  // Status Selection Functionality
  const handleStatusSelect = async (e, id) => {
    const { value } = e.target;

    try {
      const response = await axiosInstance.post(
        "/Admin/UpdateApplybookStatus",
        {
          ids: id,
          status: value,
        }
      );
      if (response.data.Status !== "success") {
        throw new Error("Status Update failed");
      }
      toast.success(
        `Status ${value === "0"
          ? "Process"
          : value === "1"
            ? "Approved"
            : value === "2"
              ? "Received"
              : "Rejected"
        } Successfully!`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      const updatedBookRequestList = bookRequestList.map((item) => {
        if (item.id === id) {
          return { ...item, status: value };
        }
        return item;
      });

      setBookRequestList(updatedBookRequestList);
    } catch (error) {
      toast.error("Status Update failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // -- pagination Code -- //
  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPage = Math.ceil(bookRequestList?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row pt-4">
          <h4 class="card-title">Manage Books Request</h4>
          <div class="col-lg-12 pt-4">
            <div class="card custom-card mb-3">
              <div className="card-header justify-content-between">
                <div class="card-title">Books Request List</div>
              </div>
              <div class="card-body py-3 px-3">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Roll No.</th>
                            <th>Class</th>
                            <th>Section</th>
                            <th>Book Name</th>
                            <th>Apply Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bookRequestList ? (
                            bookRequestList
                              .slice(
                                currentPage * dataPerPage,
                                (currentPage + 1) * dataPerPage
                              )
                              .map((row) => (
                                <tr className="text-black fs-5">
                                  <td>{row?.id}</td>
                                  <td>{row?.name}</td>
                                  <td>{row?.rollno}</td>
                                  <td>{row?.sclass}</td>
                                  <td>{row?.section}</td>
                                  <td>{row?.bookname}</td>
                                  <td>
                                    <span class="badge badge-xs badge-light">
                                      {formatDate(row?.createdate)}
                                    </span>
                                  </td>
                                  <td>
                                    <select class="form-control form-control"
                                      aria-label="Default select example"
                                      value={row?.status}
                                      onChange={(e) =>
                                        handleStatusSelect(e, row?.id)
                                      }
                                     
                                    >
                                      <option value={0}>process</option>
                                      <option value={1}>approved</option>
                                      <option value={2}>received</option>
                                      <option value={3}>rejected</option>
                                    </select>
                                  </td>
                                  <td>
                                    <Link
                                      class="btn btn-primary"
                                      to={`/admin/library/manage-request/book-info/${row?.studentid}`}
                                    >
                                      {" "}
                                      Book Info
                                    </Link>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <p>No Data Available</p>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>)}
              </div>
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                  <li class="page-item">
                    <a
                      class="page-link"
                      style={{
                        pointerEvents: currentPage === 0 ? "none" : "auto",
                        opacity: currentPage === 0 ? 0.5 : 1,
                      }}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {pageIndex
                    .slice(
                      Math.max(0, currentPage - 2),
                      Math.min(numberOfPage, currentPage + 2)
                    )
                    .map((page) => (
                      <li
                        class={`page-item ${page === currentPage + 1 ? "active" : ""
                          }`}
                        key={page}
                      >
                        <a
                          class="page-link"
                          style={{
                            pointerEvents:
                              page - 1 === currentPage ? "none" : "auto",
                            opacity: page - 1 === currentPage ? 0.5 : 1,
                          }}
                          onClick={() => handlePageChange(page - 1)}
                          href="#"
                        >
                          {page}
                        </a>
                      </li>
                    ))}
                  <li class="page-item">
                    <a
                      class="page-link"
                      style={{
                        pointerEvents:
                          currentPage === numberOfPage - 1 ? "none" : "auto",
                        opacity: currentPage === numberOfPage - 1 ? 0.5 : 1,
                      }}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageRequest;
