import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
import Swal from "sweetalert2";
import Loader from "../../Loader";
import { toast } from "react-toastify";

function ManageRegister() {
  const [loading, setLoading] = useState(true);
  const [getdata, setGetData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState({
    ids: "",
    studentid: "",
    name: "",
    fname: "",
    mobile: "",
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `/Student/GetSearchRegistrationDatail`,
        data
      );
      setGetData(response.data.Payload);
      setOriginalData(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (studentID, ID) => {
    const deleteData = {
      ids: ID,
      studentid: studentID,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        const deleteStudentData = async () => {
          try {
            const response = await axiosInstance.post(
              `/student/DeleteStudentRegistration`,
              deleteData
            );
            if (response.data.Status === "success") {
              setGetData((prevData) =>
                prevData.filter((item) => item.studentid !== studentID)
              );
              toast.success(`Registration Details Deleted Successfully!`, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            throw new Error(`Registration Details Deleted Failed`, {});
          } catch (error) {
            // toast.error("Authentication failed !");
          }
        };
        deleteStudentData();
      }
    });
  };

  useEffect(() => {
    fetchData();
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, []);

  const searchChange = (e) => {
  const { value } = e.target;
  const filteredData = originalData.filter((item) => {
    return Object.values(item).some(
      (val) => val && val.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  });
  setGetData(filteredData);
};

  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPage = Math.ceil(getdata.length / dataPerPage);
  const pageIndex = Array.from({ length: numberOfPage }, (_, index) => index + 1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="card-header">
              <h4 className="card-title">Registration</h4>
              <div className="flex flex-wrap gap-2">
                <Link className="btn btn-primary" to="/admin/registration/create">
                  Create Registration
                  <span className="btn-icon-end">
                    <i className="fa fa-solid fa-plus"></i>
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card custom-card mb-3">
                <div className="card-header justify-content-between">
                  <div className="card-title">Registration List</div>
                  <div className="flex flex-wrap gap-2">
                    <input
                      className="form-control form-control-sm"
                      type="search"
                      name="name"
                      onChange={searchChange}
                      placeholder="Search Here..."
                      aria-label="Search"
                    />
                  </div>
                </div>
                <div className="card-body py-3 px-3">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table className="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Student Name</th>
                              <th>Father's Name</th>
                              <th>Email</th>
                              <th>Mobile No.</th>
                              <th>DOB</th>
                              <th>Gender</th>
                              <th>Address</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getdata
                              .slice(currentPage * dataPerPage, (currentPage + 1) * dataPerPage)
                              .map((row, index) => (
                                <tr className="text-black fs-5" key={row.id}>
                                   <td>{currentPage * dataPerPage + index + 1}</td>
                                  <td>{row.student_name}</td>
                                  <td>{row.father_name}</td>
                                  <td>{row.email}</td>
                                  <td>{row.mobile}</td>
                                  <td>{row.dob}</td>
                                  <td>{row.gender}</td>
                                  <td>{row.address}</td>
                                  <td>
                                    <Link
                                      to={`/admin/students/detail/${row.studentid}`}
                                      className="btn btn-warning  btn-sm content-icon"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link> &nbsp;
                                    <Link
                                      className="btn btn-primary  btn-sm content-icon"
                                      to={`/admin/registration/create/${row.studentid}/${row.id}`}
                                    >
                                      <i className="bi bi-pencil-square"></i>
                                    </Link> &nbsp;
                                    <Link
                                      className="btn btn-danger  btn-sm content-icon"
                                      onClick={() => handleDelete(row.studentid, row.id)}
                                    >
                                      <i className="bi bi-trash"></i> 
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                          <li className="page-item">
                            <a
                              className="page-link"
                              style={{
                                pointerEvents: currentPage === 0 ? "none" : "auto",
                                opacity: currentPage === 0 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              Previous
                            </a>
                          </li>
                          {pageIndex.map((page) => (
                            <li
                              className={`page-item ${page === currentPage + 1 ? "active" : ""}`}
                              key={page}
                            >
                              <a
                                className="page-link"
                                style={{
                                  pointerEvents: page - 1 === currentPage ? "none" : "auto",
                                  opacity: page - 1 === currentPage ? 0.5 : 1,
                                }}
                                onClick={() => handlePageChange(page - 1)}
                              >
                                {page}
                              </a>
                            </li>
                          ))}
                          <li className="page-item">
                            <a
                              className="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === numberOfPage - 1 ? "none" : "auto",
                                opacity: currentPage === numberOfPage - 1 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageRegister;
