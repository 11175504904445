import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
function Manage_fees() {
    const [selectedOption, setSelectedOption] = useState('option1');
    const [inputValue2, setInputValue2] = useState('');

    const handleRadioChange2 = (event) => {
        setSelectedOption(event.target.value);

        // Reset input value when a different radio button is selected
        setInputValue('');
    };

    const [isChecked, setIsChecked] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const latefee = () => {
        setIsChecked(!isChecked);

        // Reset input value when checkbox is unchecked
        if (!isChecked) {
            setInputValue('');
        }
    };

    const [isChecked1, setIsChecked1] = useState(false);
    const [inputValue1, setInputValue1] = useState('');

    const discount = () => {
        setIsChecked1(!isChecked1);

        // Reset input value when checkbox is unchecked
        if (!isChecked1) {
            setInputValue1('');
        }
    };

    const [selectedMonths, setSelectedMonths] = useState([]);
    const currentMonth = new Date().getMonth();

    const months = Array.from({ length: 12 }, (_, index) => {
        const monthIndex = index + 1; // Adjusting to one-based index
        const isActive = monthIndex < currentMonth + 1;
        return {
            index: monthIndex,
            name: new Date(0, index).toLocaleString('en-US', { month: 'short' }),
            isActive,
        };
    });

    const handleCheckboxChange = (monthIndex) => {
        const isSelected = selectedMonths.includes(monthIndex);
        if (isSelected) {
            setSelectedMonths(selectedMonths.filter((month) => month !== monthIndex));
        } else {
            setSelectedMonths([...selectedMonths, monthIndex]);
        }
    };
    return (<>
        <div>
            <div className="container-fluid">
                <div className="row pt-4">
                  <h3 class="text-center">Manage Fee</h3>
                    {/* <div class="card-header">
                        <h4 class="card-title">Manage Fees</h4>
                    </div> */}
                    <div class="col-lg-12 pt-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <form>
                                        <div class="row form-material">
                                            <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                            <h4 class="card-title">Student List</h4>
                                            </div>
                                            <div class="col-xl-3 col-xxl-2 col-md-6 mb-3">
                                                <input type="search" class="form-control" placeholder="Search student name..." />
                                            </div>
                                            <div class="col-xl-3 col-xxl-2 col-md-6 mb-3">
                                                <select class="form-control" id="autoSizingSelect">
                                                    <option selected>Select class</option>
                                                    <option value="I">I</option>
                                                    <option value="II">II</option>
                                                    <option value="III">III</option>
                                                </select>
                                            </div>
                                            <div class="col-xl-3 col-xxl-2 col-md-6 mb-3">
                                                <input type="search" class="form-control" placeholder="Search roll no..." />
                                            </div>
                                        </div>
                                    </form>
                                    <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Father's Name</th>
                                                <th>Class</th>
                                                <th>Roll No.</th>
                                                <th>Balance</th>
                                                <th>Fee</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>Rohan</td>
                                                <td>Sohan</td>
                                                <td>LKG</td>
                                                <td>101</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>
                                                    <Link to="/admin/fees/manage-fee/003" class="bg-black text-white btn-sm fw-bold fs-4"><i class="la la-rupee"></i> Take Fees</Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav class="pt-3" aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <a class="page-link">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Take Fees</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form class="form-valide-with-icon needs-validation">
                            <div class="row form-material">
                                
                                <div class="col-xl-3 col-xxl-6 col-md-6 pt-2">
                                    <label class="form-label">Roll No.</label>
                                    <input type="text" class="form-control" value="10001" />
                                </div>
                                <div class="col-xl-3 col-xxl-12 col-md-6">
                                    <label class="form-label">Name</label>
                                    <input type="text" class="form-control" value="Rohan" />
                                </div>
                                <div class="col-xl-3 col-xxl-6 col-md-6 pt-2">
                                    <label class="form-label">Class</label>
                                    <input type="text" class="form-control" value="II" />
                                </div>
                                <div class="col-xl-3 col-xxl-12 col-md-6 pt-2">
                                    <label class="form-label">Select Month</label><br />
                                    {months.map((month) => (
                                        <div key={month.index} class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" checked={selectedMonths.includes(month.index)} onChange={() => handleCheckboxChange(month.index)} value="option1" />
                                            {month.name}
                                        </div>
                                    ))}
                                </div>
                                <div class="col-xl-3 col-xxl-6 col-md-6 pt-2">
                                    <label class="form-label">Total</label>
                                    <input type="text" class="form-control" value="1000" />
                                </div>
                                <div class="col-xl-3 col-xxl-6 col-md-6 pt-2">
                                    <input class="form-check-input" type="checkbox" checked={!isChecked} onChange={latefee} />{' '}
                                    <label class="form-label">Late Fine</label>

                                    <input class="form-control" type="text" placeholder="Ente late fee amount" disabled={isChecked} />
                                </div>
                                <div class="col-xl-3 col-xxl-6 col-md-6 pt-2">
                                    <input class="form-check-input" type="checkbox" checked={!isChecked1} onChange={discount} />{' '}
                                    <label class="form-label">Discount</label>
                                    <input class="form-control" type="text" placeholder="Ente discount amount" disabled={isChecked1} />
                                </div>
                                <div class="col-xl-3 col-xxl-6 col-md-6 pt-2">
                                    <label class="form-label">Paid Amount</label>
                                    <input type="text" class="form-control" value="1000" />
                                </div>
                               
                                   <div class="col-xl-3 col-xxl-6 col-md-6 pt-4">
                                  
                                      
                                        <input type="radio" class="form-check-input" value="option1" checked={selectedOption === 'option1'} onChange={handleRadioChange2}/>{' '}
                                        <label class="form-label">Cash</label>
                                        <input type="radio" class="form-check-input ms-5" value="option2" checked={selectedOption === 'option2'} onChange={handleRadioChange2}/>{' '}
                                        <label class="form-label">UPI</label>
                                        </div>
                                        
                                    {selectedOption === 'option2' && (
                                        <div class="col-xl-3 col-xxl-6 col-md-6 pt-4">
                                            <input class="form-control" type="text" placeholder="Enter UPI number" value={inputValue2} onChange={(e) => setInputValue2(e.target.value)}/>
                                        </div>
                                    )}
                                   
                                {/* <div class="col-xl-3 col-xxl-3 col-md-6 pt-2">
                                    <input class="form-check-input" type="checkbox" />{' '}
                                    <label class="form-label">UPI</label>
                                    <input type="hidden" class="form-control" value="1000" />
                                </div> */}
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <Link type="button" class="btn btn-primary btn-sm me-3">Submit</Link>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Manage_fees;