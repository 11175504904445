import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
const ViewAttendance = () => {
    const { month, studentid } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState();
    const fetchTotal = async () => {
        try {
            const response = await axiosInstance.get(`/admin/GetCountAbsentPresent?studentid=${studentid}&&month=${month}`);
            setTotal(response.data);
        } catch (error) {
            window.location.href = "/";
        }
    }
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`/Admin/GetAttendanceDaywise?studentid=${studentid}&&month=${month}`);
            setData(response.data.Payload);
        } catch (error) {
            window.location.href = "/";
        }
    }
    useEffect(() => {
        fetchTotal()
        fetchData();
        const loaderTimeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(loaderTimeout);
    }, []);
    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(data?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div>
            {loading ? (
                <div
                    className="container text-center"
                    style={{ paddingBottom: "150px", paddingTop: "200px" }}
                >
                    <div class="spinner-grow text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-danger" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-dark" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="container-fluid">
                    <div className="row">
                        <div class="card-header">
                            <h4 class="card-title">Attendance Report</h4>
                        </div>
                        <div class="col-lg-12">
                            <div class="card custom-card mb-3">
                                <div class="card-header justify-content-between">
                                    <div class="card-title">Daily Attendance</div>
                                    <div class="flex flex-wrap gap-2">
                                        Total Present  <span class="badge badge-xs badge-primary">{total.Present}</span>{" "}
                                        Total Absent <span class="badge badge-xs badge-danger">{total.Absent}</span>
                                    </div>
                                </div>
                                <div class="card-body py-3 px-3">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    {/* <th>#</th> */}
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Marked By</th>
                                                    <th>Attendance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data ?
                                                    data
                                                        .slice(
                                                            currentPage * dataPerPage,
                                                            (currentPage + 1) * dataPerPage
                                                        ).map((row) => (
                                                            <tr className="text-black fs-5" key={row.id}>
                                                                {/* <td>{index + 1}</td> */}
                                                                <td><span class="badge badge-xs badge-info">{row.date}</span></td>
                                                                <td>{row.name}</td>
                                                                <td>{row.teachername}</td>
                                                                <td><span class={`badge badge-xs badge-${row.attendance === 'Present' ? 'primary' : 'danger'}`}>{row.attendance}</span></td>
                                                            </tr>
                                                        )) :
                                                    <p>No Data Available</p>}
                                            </tbody>
                                        </table>
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item">
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            currentPage === 0 ? "none" : "auto",
                                                        opacity: currentPage === 0 ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                >
                                                    Previous
                                                </a>
                                            </li>
                                            {pageIndex
                                                .slice(
                                                    Math.max(0, currentPage - 2),
                                                    Math.min(numberOfPage, currentPage + 2)
                                                )
                                                .map((page) => (
                                                    <li
                                                        class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                            }`}
                                                        key={page}
                                                    >
                                                        <a
                                                            class="page-link"
                                                            style={{
                                                                pointerEvents:
                                                                    page - 1 === currentPage
                                                                        ? "none"
                                                                        : "auto",
                                                                opacity: page - 1 === currentPage ? 0.5 : 1,
                                                            }}
                                                            onClick={() => handlePageChange(page - 1)}
                                                            href="#"
                                                        >
                                                            {page}
                                                        </a>
                                                    </li>
                                                ))}
                                            <li class="page-item">
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            currentPage === numberOfPage - 1
                                                                ? "none"
                                                                : "auto",
                                                        opacity:
                                                            currentPage === numberOfPage - 1 ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default ViewAttendance;