import React from "react";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../parent-helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Loader";
function Complaint() {
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [complaintList, setComplaintList] = useState([]);
  const [submitComplaint, setSubmitComplaint] = useState({
    name: "",
    fathername: "",
    subject: "",
    complaint: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadSubmit(true);
    try {
      const response = await axiosInstance.post(
        "/Parent/AddComplaint",
        submitComplaint
      );
      if (!response.data.Status === "success") {
        throw new Error("Complaint Submission failed");
      }

      toast.success("Complaint Submitted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSubmitComplaint((prevState) => ({
        ...prevState,
        subject: "",
        complaint: "",
      }));
      setLoadSubmit(false);
      // fetchStudentComplaintList();
    } catch (error) {
      setLoadSubmit(false);
      toast.error("Complaint Submission failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchStudentComplaintList = async () => {
    try {
      const response = await axiosInstance.get("/Parent/GetComplaint");
      setComplaintList(response.data.Payload);
      fetchStudentComplaintList();
    } catch (error) {
      window.location.href = "/parent";
    } 
  };
  const fetchStudent = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/Parent/Getstudentdetail");
      setSubmitComplaint({
        ...submitComplaint,
        name: response.data.studentname,
        fathername: response.data.fathername,
      });
    } catch (error) {
      window.location.href = "/parent";
    } finally {
      setLoading(false);
    }
  };

  const [data, setData] = useState([]);
  const [message, setMessage] = useState({
    ticketid: "",
    message: "",
  })

  const messageData = async (e, ticketid) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(
        "/parent/chatbyparent",
        {
          ...message,
          ticketid: ticketid
        }
      );

      if (response.data.Status === 1) {
        throw new Error("failed");
      }
      // toast.success("successfully!", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      setMessage({ ticketid: "", message: "" });
      fetchData(ticketid);
    } catch (error) {
      toast.error("failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchData = async (ticketid) => {
    try {
      const response = await axiosInstance.get(`/Parent/Getchatdetailbyparent?ticketid=${ticketid}`);
      setData(response.data.Payload);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchStudent();
    fetchStudentComplaintList();
  }, []);

  // -- pagination Code -- //
  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPage = Math.ceil(complaintList?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Complaint</h4>
          </div>

          <div class="col-md-4">
            <form onSubmit={handleSubmit}>
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between">
                  <div class="card-title">Filed Complaint</div>
                </div>
                <div class="card-body text-black fw-bold fs-6">
                  <div class="row form-material">
                    <div class=" col-md-12 mb-3">
                      <label class="form-label">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        value={submitComplaint.name}
                        disabled
                      />
                    </div>
                    <div class=" col-md-12 mb-3">
                      <label class="form-label">Subject</label>
                      <input
                        type="text"
                        class="form-control"
                        name="subject"
                        placeholder="Enter subject.."
                        value={submitComplaint.subject}
                        onChange={(e) =>
                          setSubmitComplaint({
                            ...submitComplaint,
                            subject: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class=" col-md-12 mb-3">
                      <label class="form-label">Complaint</label>
                      <textarea
                        class="form-control"
                        rows="8"
                        name="remark"
                        placeholder="Enter complaint.."
                        value={submitComplaint.complaint}
                        onChange={(e) =>
                          setSubmitComplaint({
                            ...submitComplaint,
                            complaint: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div className="text-end">
                      <button
                        type="submit"
                        class="btn btn-primary mb-3 btn-block"
                      >
                        {loadSubmit ? (
                          <span
                            class="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-8">
            <div className="card custom-card mb-3">
              <div className="card-header justify-content-between">
                <div class="card-title">Filed Complaint List</div>
              </div>
              <div class="card-body py-3 px-3">
                <div class="table-responsive">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <table class="table table-hover table-bordered">
                        <thead className="">
                          <tr>
                            <th>Name</th>
                            <th>Subject</th>
                            <th>Complaint</th>
                            <th>Apply Date</th>
                            <th>Reply</th>
                          </tr>
                        </thead>
                        {console.log(complaintList)}
                        <tbody>
                          {complaintList ? (
                            complaintList.map((row) => {
                              return (
                                <tr key={row.id}>
                                  <td>{row.name}</td>
                                  <td>{row.subject}</td>
                                  <td>{row.complain}</td>
                                  <td>
                                    {row.createdate
                                      .split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("-")}
                                  </td>
                                  <td>
                                    <span
                                      data-bs-toggle="offcanvas"
                                      data-bs-target={`#offcanvasRight-${row.ticketid}`}
                                      aria-controls={`offcanvasRight-${row.ticketid}`}
                                      onClick={() => fetchData(row.ticketid)}
                                    >
                                      <i className="bi bi-reply fs-2"></i>
                                    </span>
                                    <div
                                      className="offcanvas chatboxs offcanvas-end"
                                      tabIndex="-1"
                                      id={`offcanvasRight-${row.ticketid}`}
                                      aria-labelledby={`offcanvasRightLabel-${row.ticketid}`}
                                    >
                                      <div className="chatbox active">
                                        <div className="custom-tab-1">
                                          <div className="tab-content">
                                            <div
                                              className="tab-pane fade active show"
                                              id="chat"
                                              role="tabpanel"
                                            >
                                              <div className="card chat dlab-chat-history-box">
                                                <div className="card-header chat-list-header text-center">
                                                  <a
                                                    href="/"
                                                    className="dlab-chat-history-back btn-close"
                                                    data-bs-dismiss="offcanvas"
                                                    aria-label="Close"
                                                  ></a>
                                                  <div>
                                                    <h6 className="mb-1">Chat with Admin</h6>
                                                    {/* <p className="mb-0 text-success">Online</p> */}
                                                  </div>
                                                  <div className="dropdown">
                                                  </div>
                                                </div>
                                                <div className="card-body msg_card_body dlab-scroll" id="DLAB_W_Contacts_Body3">
                                                  {data.map((msg, index) => (
                                                    <div className={`d-flex justify-content-${msg.replyby === null ? 'end' : 'start'} mb-4`} key={index}>
                                                      <div className={`msg_cotainer text-start ${msg.replyby === null ? 'msg_cotainer_send' : 'msg_cotainer_rcv'}`}>
                                                        {msg.message}
                                                        <span className={`msg_time ${msg.replyby === null ? 'text-black' : 'text-white'}`}>{msg.time},  {msg.date}</span>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                                <div className="card-footer type_msg">
                                                  <form onSubmit={(e) => messageData(e, row.ticketid)}>
                                                    <div className="input-group">
                                                      <textarea
                                                        className="form-control"
                                                        placeholder="Type your message..."
                                                        name="message"
                                                        value={message.message}
                                                        onChange={(e) => setMessage({ ...message, message: e.target.value })}
                                                        required></textarea>
                                                      <div className="input-group-append">
                                                        <button type="submit" className="btn btn-primary">
                                                          <i className="fa fa-location-arrow"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <p>No Data Available</p>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-end">
                    <li class="page-item">
                      <a
                        class="page-link"
                        style={{
                          pointerEvents:
                            currentPage === 0 ? "none" : "auto",
                          opacity: currentPage === 0 ? 0.5 : 1,
                        }}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous
                      </a>
                    </li>
                    {pageIndex
                      .slice(
                        Math.max(0, currentPage - 2),
                        Math.min(numberOfPage, currentPage + 2)
                      )
                      .map((page) => (
                        <li
                          class={`page-item ${page === currentPage + 1 ? "active" : ""
                            }`}
                          key={page}
                        >
                          <a
                            class="page-link"
                            style={{
                              pointerEvents:
                                page - 1 === currentPage ? "none" : "auto",
                              opacity: page - 1 === currentPage ? 0.5 : 1,
                            }}
                            onClick={() => handlePageChange(page - 1)}
                            href="#"
                          >
                            {page}
                          </a>
                        </li>
                      ))}
                    <li class="page-item">
                      <a
                        class="page-link"
                        style={{
                          pointerEvents:
                            currentPage === numberOfPage - 1
                              ? "none"
                              : "auto",
                          opacity:
                            currentPage === numberOfPage - 1 ? 0.5 : 1,
                        }}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Complaint;
