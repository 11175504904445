import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
function New_Application() {

    const [isSelect, setIsSelect] = useState(false);
    const checkForm = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value !== 'housewife') {
            setIsSelect(true);
        } else {
            setIsSelect(false);
        }
    }
    const [isSelect1, setIsSelect1] = useState(false);
    const checkForm1 = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value !== 'Other') {
            setIsSelect1(true);
        } else {
            setIsSelect1(false);
        }
    }

    const [isSelect2, setIsSelect2] = useState(false);
    const checkForm2 = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value !== 'other') {
            setIsSelect2(true);
        } else {
            setIsSelect2(false);
        }
    }
    // for stream
    const [isSelect3, setIsSelect3] = useState(false);
    const checkForm3 = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value == 'XI') {
            setIsSelect3(true);
        } else {
            setIsSelect3(false);
        }
    }

    //for trnsport
    const [isSelect4, setIsSelect4] = useState(false);
    const checkForm4 = (e) => {
        console.log('data is', e.target.value)
        if (e.target.value !== 'Yes') {
            setIsSelect4(true);
        } else {
            setIsSelect4(false);
        }
    }
    //for payment 
    const location = useLocation();
    const [isSelect5, setIsSelect5] = useState();
    useEffect(() => {
        if (location.pathname == '/admin/application/edit/0001') {
            setIsSelect5(true);
        } else {
            setIsSelect5(false);
        }
    });
    const path = document.location.pathname;
    return (<>
        <div className="mt-4">
            <div className="container-fluid">
                <div className="row">
                    <h3 class="text-center">
                    {path === '/admin/application/edit/0001' ? "Edit Application" : "Application Form"}
                    </h3>
                    <div class="col-lg-12">
                        <form class="form-valide-with-icon needs-validation">
                            <div class="row pt-4">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                         <h4 className='card-title'>Student Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Full Name</label>
                                                    <input type="text" class="form-control" placeholder="Enter full name.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">DOB</label>
                                                    <input type="date" class="form-control" />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Gender</label>
                                                    <select class="form-control" id="autoSizingSelect">
                                                        <option selected>Choose...</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Academic Session</label>
                                                    <select class="form-control" id="autoSizingSelect">
                                                        <option selected>Choose...</option>
                                                        <option value="Father">2023-2024</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Class</label>
                                                    <select class="form-control" id="autoSizingSelect" onChange={checkForm3} >
                                                        <option selected>Choose...</option>
                                                        <option value="nursery">nursery</option>
                                                        <option value="LKG">LKG</option>
                                                        <option value="UKG">UKG</option>
                                                        <option value="I">I</option>
                                                        <option value="II">II</option>
                                                        <option value="III">III</option>
                                                        <option value="IV">IV</option>
                                                        <option value="V">V</option>
                                                        <option value="VI">VI</option>
                                                        <option value="VII">VII</option>
                                                        <option value="VIII">VIII</option>
                                                        <option value="IX">IX</option>
                                                        <option value="X">X</option>
                                                        <option value="XI">XI</option>
                                                        <option value="XII">XII</option>
                                                    </select>
                                                </div>
                                                {isSelect3 && (
                                                    <>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Stream</label>
                                                            <input type="text" class="form-control" />
                                                        </div>
                                                    </>)}
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Section</label>
                                                    <select class="form-control" id="autoSizingSelect">
                                                        <option selected>Choose...</option>
                                                        <option value="A">A</option>
                                                        <option value="B">B</option>
                                                        <option value="C">C</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">House</label>
                                                    <input type="text" class="form-control" />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Branch</label>
                                                    <input type="text" class="form-control" />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Hostel</label>
                                                    <select class="form-control" id="autoSizingSelect" onChange={checkForm4}>
                                                        <option selected>Choose...</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                                {isSelect4 && (<>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Transport Slab</label>
                                                        <input type="text" class="form-control" placeholder="Enter transport slab..." />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Area</label>
                                                        <input type="text" class="form-control" placeholder="Enter area..." />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">City</label>
                                                        <input type="text" class="form-control" placeholder="Enter City..." />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Pincode</label>
                                                        <input type="text" class="form-control" placeholder="Enter pincode..." />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">State</label>
                                                        <select class="form-control" id="autoSizingSelect">
                                                            <option selected>Choose...</option>
                                                            <option value="Father">Up</option>
                                                            <option value="Mother">Delhi</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6">
                                                        <label class="form-label">Address</label>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6">
                                                        <label class="form-label">Remarks</label>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                    </div>
                                                </>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                          <h4 className='card-title'>Address Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-3 mb-3">
                                                    <label class="form-label">Nationality</label>
                                                    <input type="text" class="form-control" placeholder="Enter your nationality.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Area</label>
                                                    <input type="text" class="form-control" placeholder="Enter Area.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">City</label>
                                                    <input type="text" class="form-control" placeholder="Enter city.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Pincode</label>
                                                    <input type="email" class="form-control" placeholder="Enter pincode.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-3 mb-3">
                                                    <label class="form-label">State</label>
                                                    <select class="form-control" id="autoSizingSelect">
                                                        <option selected>Choose...</option>
                                                        <option value="Father">Up</option>
                                                        <option value="Mother">Delhi</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Country</label>
                                                    <input type="email" class="form-control" placeholder="Enter country name.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Address</label>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="6"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                        <h4 className='card-title'>Student Contact Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mobile</label>
                                                    <input type="text" class="form-control" placeholder="Enter whatsapp number.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Alternate Mobile</label>
                                                    <input type="email" class="form-control" placeholder="Enter alternate number.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6">
                                                    <label class="form-label">Email</label>
                                                    <input type="email" class="form-control" placeholder="Enter email.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Primary Contact</label>
                                                    <select class="form-control" id="autoSizingSelect">
                                                        <option selected>Choose...</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Mother">Mother</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                           <h4 className='card-title'>Mother's Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Name</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's name..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Mobile</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's mobile..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Email</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's email..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's nature of work</label>
                                                    <select class="form-control" id="autoSizingSelect" onChange={checkForm} >
                                                        <option selected>Choose...</option>
                                                        <option value="Job">Job</option>
                                                        <option value="Bussiness">Bussiness</option>
                                                        <option value="Professional">Professional</option>
                                                        <option value="housewife">Housewife</option>
                                                    </select>
                                                </div>
                                                {isSelect && (
                                                    <>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Mother's Employer</label>
                                                            <select class="form-control" id="autoSizingSelect">
                                                                <option selected>Choose...</option>
                                                                <option value="Government">Government</option>
                                                                <option value="PSU">PSU</option>
                                                                <option value="Semi-Government">Semi-Government</option>
                                                                <option value="Private">Private</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Mother's Sector (subtype - business)</label>
                                                            <input type="text" class="form-control" placeholder="Enter sector..." />
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Mother's Firm / Department</label>
                                                            <input type="text" class="form-control" placeholder="Enter firm / department..." />
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Mother's Designation / Title</label>
                                                            <input type="text" class="form-control" placeholder="Enter designation..." />

                                                        </div>
                                                    </>)}
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Aadhar No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's aadhar no..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Pan No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter mother's pan no..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Mother's Photo</label>
                                                    <input type="file" class="form-control" placeholder="Enter mother's pan no..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                          <h4 className='card-title'>Father's Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Name</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's name..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Mobile</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's mobile..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Email</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's email..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's nature of work</label>
                                                    <select class="form-control" id="autoSizingSelect" onChange={checkForm1} >
                                                        <option selected>Choose...</option>
                                                        <option value="Job">Job</option>
                                                        <option value="Bussiness">Bussiness</option>
                                                        <option value="Professional">Professional</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                {isSelect1 && (
                                                    <>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Father's Employer</label>
                                                            <select class="form-control" id="autoSizingSelect">
                                                                <option selected>Choose...</option>
                                                                <option value="Government">Government</option>
                                                                <option value="PSU">PSU</option>
                                                                <option value="Semi-Government">Semi-Government</option>
                                                                <option value="Private">Private</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Father's Sector (subtype - business)</label>
                                                            <input type="text" class="form-control" placeholder="Enter father sector..." />
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Father's Firm / Department</label>
                                                            <input type="text" class="form-control" placeholder="Enter firm / department..." />
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Father's Designation / Title</label>
                                                            <input type="text" class="form-control" placeholder="Enter designation..." />

                                                        </div>
                                                    </>)}
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Aadhar No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's aadhar no..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Father's Pan No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter father's pan no..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Father's Photo</label>
                                                    <input type="file" class="form-control" placeholder="Enter father's pan no..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                          <h4 className='card-title'>Guardian Details (if applicable)</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian relationship</label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian relationship..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Name </label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian name ..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian's Email</label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian's email ..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Mobile</label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian mobile..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian nature of work</label>
                                                    <select class="form-control" id="autoSizingSelect" onChange={checkForm2} >
                                                        <option selected>Choose...</option>
                                                        <option value="Job">Job</option>
                                                        <option value="Bussiness">Bussiness</option>
                                                        <option value="Professional">Professional</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                                {isSelect2 && (
                                                    <>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Guardian Employer</label>
                                                            <select class="form-control" id="autoSizingSelect">
                                                                <option selected>Choose...</option>
                                                                <option value="Government">Government</option>
                                                                <option value="PSU">PSU</option>
                                                                <option value="Semi-Government">Semi-Government</option>
                                                                <option value="Private">Private</option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Guardian Sector (subtype - business)</label>
                                                            <input type="text" class="form-control" placeholder="Enter father sector..." />
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Guardian Firm / Department</label>
                                                            <input type="text" class="form-control" placeholder="Enter firm / department..." />
                                                        </div>
                                                        <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                            <label class="form-label">Guardian Designation / Title</label>
                                                            <input type="text" class="form-control" placeholder="Enter designation..." />

                                                        </div>
                                                    </>)}
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Aadhar No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian aadhar no..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Pan No.</label>
                                                    <input type="text" class="form-control" placeholder="Enter guardian pan no..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Guardian Photo</label>
                                                    <input type="file" class="form-control" placeholder="Enter guardian pan no..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                        <h4 className='card-title'>Student Documents</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Aadhar No</label>
                                                    <input type="text" class="form-control" placeholder="Enter full aadhar no.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Aadhar Photo</label>
                                                    <input type="file" class="form-control" />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Transfer Certificate No</label>
                                                    <input type="text" class="form-control" placeholder="Enter transfer certificate no.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Transfer Certificate</label>
                                                    <input type="file" class="form-control" />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Birth Certificate No </label>
                                                    <input type="text" class="form-control" placeholder="Enter birth certificate no.." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Birth Certificate</label>
                                                    <input type="file" class="form-control" />
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Student Photo</label>
                                                    <input type="file" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                        <h4 className='card-title'>Other Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Reason for change</label>
                                                    <input type="text" class="form-control" placeholder="Enter reason for change ..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Previous school</label>
                                                    <input type="text" class="form-control" placeholder="Enter previous school..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Previous class</label>
                                                    <input type="text" class="form-control" placeholder="Enter previous class..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Admission Session</label>
                                                    <select class="form-control" id="autoSizingSelect">
                                                        <option selected>Choose...</option>
                                                        <option value="housewife">2022-2023</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body text-black fw-bold fs-6">
                                        <h4 className='card-title'>Medical Details</h4><hr />
                                            <div class="row form-material">
                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">Blood Group</label>
                                                    <input type="text" class="form-control" placeholder="Enter blood group..." />
                                                </div>

                                                <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                    <label class="form-label">History of major illness(if any)</label>
                                                    <input type="text" class="form-control" placeholder="Enter History of major illness..." />
                                                </div>
                                                <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                    <label class="form-label">Allergies to medicine and food(if any)</label>
                                                    <input type="text" class="form-control" placeholder="Enter allergies to medicine and food..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {isSelect5 && (<>
                                    <div class="col-lg-6">
                                        <div class="card">
                                            <div class="card-body text-black fw-bold fs-6">
                                            <h4 className='card-title'>Payment for Application Form</h4><hr />
                                                <div class="row form-material">
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Amount</label>
                                                        <input type="text" class="form-control" placeholder="2000/-" />
                                                    </div>

                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Method</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Transaction ID / Receipt ID / Cheque No</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Remitter Name</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Minimum Application Fee</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                        <label class="form-label">Start Month</label>
                                                        <input type="text" class="form-control" placeholder="" />
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-xxl-6">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="card">
                                                    <div class="card-body text-black fw-bold fs-6">
                                                    <h4 className='card-title'>Add Notes</h4><hr />
                                                        <div class="row form-material">
                                                            <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                                                <label class="form-label">File</label>
                                                                <input type="file" class="form-control" placeholder="Enter mobile" />
                                                            </div>
                                                            <div class="col-xl-3 col-xxl-12 col-md-6">
                                                                <label class="form-label">Note</label>
                                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>                                        </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                <h4 className='card-title'>Add Notes</h4>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <form class="form-valide-with-icon needs-validation">
                                                        <div class="row form-material">
                                                            <div class="col-xl-3 col-xxl-12 col-md-6">
                                                                <label class="form-label">File</label>
                                                                <input type="file" class="form-control" placeholder="Enter mobile" />
                                                            </div>
                                                            <div class="col-xl-3 col-xxl-12 col-md-6">
                                                                <label class="form-label">Note</label>
                                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>                                        </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="modal-footer">
                                                    <Link type="button" class="btn btn-primary btn-sm me-3">Submit</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                            </div>
                            <div className="text-end">
                                <button type="button" class="bg-black text-white fw-bold mb-3" style={{ width: "100px" }}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default New_Application;