import React from 'react';
function ParentDashboard() {
  return (<>
    <div className=""><br/>
            <div className="container-fluid">
                <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Parent's Dashboard</h4>
                </div>
                     {/* <div className="col-sm-3">
                        <div className="widget-stat card">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3 text-black" style={{backgroundColor:"#e2dfe0"}}>
                                        <i className="la la-user" />
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1">Student</h6>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                   <div className="col-sm-3">
                        <div className="widget-stat card">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3 text-black" style={{backgroundColor:"#d3e8e6"}}>
                                        <i className="la la-user-plus" />
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1">Assigned Teachers</h6>
                                        <h4>6</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="widget-stat card">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3 text-black" style={{backgroundColor:"#ceeccd"}}>
                                        <i className="la la-file" />
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1"> Assignment</h6>
                                        <h4>90</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="widget-stat card">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3 text-black" style={{backgroundColor:"#f3d7dc"}}>
                                        <i className="bi bi-pencil-square"/>
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1">Attendance</h6>
                                        <h4>75%</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div className="col-sm-3">
                        <div className="widget-stat card">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3 text-black" style={{backgroundColor:"#f3f0d2"}}>
                                    <i class="la la-rupee"></i>
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1">Due Fees</h6>
                                        <h4>5000</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   {/* <div className="col-sm-3">
                        <div className="widget-stat card">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3 text-black" style={{backgroundColor:"#9fbd9e"}}>
                                    <i class="la la-rupee"></i>
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1">tuition fee</h6>
                                        <h4>1,60,000</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="widget-stat card">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3 text-black" style={{backgroundColor:"#efd7c1"}}>
                                    <i className="la la-bus" />
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1">Transport fee</h6>
                                        <h4>1,20,000</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="widget-stat card">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3 text-black" style={{backgroundColor:"#80cb7e"}}>
                                    <i class="fas fa-basketball-ball"></i>
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1">Sports fee</h6>
                                        <h4>16</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-sm-3">
                        <div className="widget-stat card bg-dark">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3">
                                        <i className="la la-coins" />
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1 text-white">Allowance</h6>
                                        <h4 className="text-white">76000</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="widget-stat card bg-secondary">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3">
                                        <i className="la la-rupee" />
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1 text-white">Total Expense</h6>
                                        <h4 className="text-white">3800</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="widget-stat card bg-warning">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3">
                                        <i className="la la-user" />
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1 text-white">Total Clients</h6>
                                        <h4 className="text-white">134</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="widget-stat card bg-primary">
                            <div className="card-body  p-4">
                                <div className="media">
                                    <span className="me-3">
                                        <i className="la la-user" />
                                    </span>
                                    <div className="text-white">
                                        <h6 className="mb-1 text-white">Lost lead</h6>
                                        <h4 className="text-white">34</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    {/* <Footer /> */}
    {/* </div> */}
  </>);
}

export default ParentDashboard;