import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

function ManageAcademic() {
  const Navigate = useNavigate();
  const { studentid, id } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState([]);
  const [classList, setClassList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentDetail, setStudentDetail] = useState([]);
  const [session, setSession] = useState([]);
  const [SubmitData, setSubmitData] = useState({
    ids: id,
    studentid: studentid,
    sclass: "",
    section: "",
    rollno: "",
    academicsession: "",
  });

  useEffect(() => {
    // Fetch existing data if in edit mode
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          "/student/GetStudentInformation",
          {
            params: {
              studentid,
              id,
            },
          }
        );
        setSubmitData({
          ids: response.data.AcademicDetail[0].id,
          studentid: response.data.AcademicDetail[0].studentid,
          sclass: response.data.AcademicDetail[0].sclass,
          section: response.data.AcademicDetail[0].section,
          rollno: response.data.AcademicDetail[0].rollno,
          academicsession: response.data.AcademicDetail[0].academicsession,
        });
      } catch (error) {
        // toast.error('Authentication failed!');
        window.location.href = "/";
      }
    };

    const fetchStudentDetail = async () => {
      try {
        const response = await axiosInstance.get(`/Student/Getstudentdetail?studentid=${studentid}`)
        setStudentDetail(response.data?.Payload[0])
      } catch (error) {
        console.error(error)
      }
    }
    fetchStudentDetail()
    if (id) {
      fetchData();
    }
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [id, studentid]);

  const handleInputChange = (e) => {
    setSubmitData({
      ...SubmitData,
      [e.target.name]: e.target.value,
    });
  };
  // data submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    let url = "/student/CreateAcademicDetails";
    if (id) {
      url = "/student/updateStudentacademicDetail";
    }
    try {
      const response = await axiosInstance.post(url, SubmitData);
      console.log(response.data);
      if (response.data.Status === "success") {
        toast.success(
          `${id ? "Academic Details Updated" : "Academic Details Added"
          } Successfully!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setButtonLoading(false);
        if (!id) {
          setSubmitData({
            ids: null,
            studentid: null,
            sclass: "",
            section: "",
            rollno: "",
            academicsession: "",
          });
        }
        Navigate(`/admin/students/detail/${studentid}`);
      } else {
        throw new Error(`Academic Detail ${id ? "Update" : "Add"} Failed`);
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error(`Academic Detail ${id ? "Update" : "Add"} failed`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchClass = async () => {
    try {
      const response = await axiosInstance.get(`/Master/GetClass`);
      setClassList(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      window.location.href = "/";
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`/Master/GetSection`);
      setData(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      window.location.href = "/";
    }
  };
  const fetchSession = async () => {
    try {
      const response = await axiosInstance.get(`/admin/Getsession`);
      setSession(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      window.location.href = "/";
    }
  };
  useEffect(() => {
    fetchSession();
    fetchData();
    fetchClass();
  }, []);
  const path = document.location.pathname;
  return (
    <div className="mt-4">
      <div className="container-fluid">
        <div className="row">
          <h4 class="card-title">
            {path === `/admin/students/manage-academic/${studentid}`
              ? "Manage Student"
              : "Edit Academic Detail"}
          </h4>
          {loading ? (
            <div
              className="container text-center"
              style={{ paddingTop: "200px", paddingBottom: "150px" }}
            >
              <div class="spinner-grow text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="col-lg-12">
              <form onSubmit={handleSubmit}>
                <div className="row pt-4">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card">
                        <div class="table-responsive">
                          <div className="pt-3 p-2">
                            <li class="list-group-item">
                              <span class="custom-label fs-5">&nbsp;&nbsp;<b>Student Name</b> : &nbsp;{studentDetail.studentname ?? ''} </span>
                              <span class="custom-label fs-5">&nbsp;&nbsp;<b>Father Name</b> : &nbsp;{studentDetail.fathername ?? ''} </span>
                              <span class="custom-label fs-5">&nbsp;&nbsp;<b>Mobile</b> : &nbsp;{studentDetail.mobile ?? ''} </span>
                              {studentDetail.rollno ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Rollno</b> : &nbsp; {studentDetail.rollno}</span> : ''}
                              {studentDetail.sclass ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Class</b> : &nbsp; {studentDetail.sclass}</span> : ''}
                              {studentDetail.section ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Section</b> : &nbsp; {studentDetail.section}</span> : ''}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Gender</b> : &nbsp; {studentDetail.gender ?? ''}</span> */}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>DOB</b> : &nbsp; {studentDetail.dob ?? ''}</span> */}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Address</b> : &nbsp; {studentDetail.address ?? ''}</span> */}
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card mt-3">
                        <div className='card-header justify-content-between'>
                          <div class="card-title"> Academic Details</div>
                        </div>
                      </div>
                      <div class="card-body text-black fw-bold fs-6">
                        <div class="row form-material">
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Class</label>
                            <select
                              className="form-control"
                              id="autoSizingSelect"
                              name="sclass"
                              value={SubmitData.sclass}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="">Choose...</option>
                              {classList ? (
                                classList.map((row) => (
                                  <option value={row.sclass}>
                                    {row.sclass}
                                  </option>
                                ))
                              ) : (
                                <p>No data available</p>
                              )}
                            </select>
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Section</label>
                            <select
                              className="form-control"
                              id="autoSizingSelect"
                              name="section"
                              value={SubmitData.section}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="">Choose...</option>
                              {data ? (
                                data.map((row) => (
                                  <option key={row.section} value={row.section}>
                                    {row.section}
                                  </option>
                                ))
                              ) : (
                                <p>No data available</p>
                              )}
                            </select>
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Roll No.</label>
                            <input
                              type="text"
                              class="form-control"
                              name="rollno"
                              placeholder="Enter roll no..."
                              defaultValue={SubmitData.rollno}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Academic Session</label>
                            <select
                              class="form-control"
                              id="autoSizingSelect"
                              name="academicsession"
                              value={SubmitData.academicsession}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="">Choose...</option>
                              {session ? (
                                session.map((row) => (
                                  <option key={row.session} value={row.academicsession}>
                                    {row.session}
                                  </option>
                                ))
                              ) : (
                                <p>No data available</p>
                              )}
                            </select>
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              style={{ width: "100px" }}
                            >
                              {buttonLoading ? (
                                <span
                                  class="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : id ? (
                                "Edit"
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageAcademic;
