import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

function ManageMother() {
  const Navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { studentid, id } = useParams();
  const [studentDetail, setStudentDetail] = useState([]);
  const [SubmitData, setSubmitData] = useState({
    ids: id,
    studentid: studentid,
    name: "",
    mobile: "",
    email: "",
    occupation: "",
    aadharcard: "",
    pancard: "",
    picture: null,
    empolyer: "",
    sector: "",
    department: "",
    designation: "",
  });
  useEffect(() => {
    // Fetch existing data if in edit mode
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          "/student/GetStudentInformation",
          {
            params: {
              studentid,
              id,
            },
          }
        );
        setSubmitData({
          ids: response.data.MotherDetail[0].id,
          studentid: response.data.MotherDetail[0].studentid,
          name: response.data.MotherDetail[0].name,
          mobile: response.data.MotherDetail[0].mobileno,
          email: response.data.MotherDetail[0].emailid,
          occupation: response.data.MotherDetail[0].occupation,
          aadharcard: response.data.MotherDetail[0].aadharcard,
          pancard: response.data.MotherDetail[0].pancard,
          picture: response.data.MotherDetail[0].picture,
          empolyer: response.data.MotherDetail[0].employer,
          sector: response.data.MotherDetail[0].sector,
          department: response.data.MotherDetail[0].department,
          designation: response.data.MotherDetail[0].designation,
        });
      } catch (error) {
        // toast.error('Authentication failed!');
        window.location.href = "/";
      }
    };
    const fetchStudentDetail = async () => {
      try {
        const response = await axiosInstance.get(
          `/Student/Getstudentdetail?studentid=${studentid}`
        );
        setStudentDetail(response.data?.Payload[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentDetail();

    if (id) {
      fetchData();
    }
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [id, studentid]);

  const handleInputChange = (e) => {
    setSubmitData({
      ...SubmitData,
      [e.target.name]: e.target.value,
    });
  };

  // image upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64-encoded image string to the state
        const filebase64 = reader.result.split("base64,");
        setSubmitData({ ...SubmitData, picture: filebase64[1] });
      };
      reader.readAsDataURL(file);
    }
  };
  // data submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    let url = "/student/CreateMotherDetails";
    if (id) {
      url = "/student/updateStudentmotherDetail";
    }
    try {
      const response = await axiosInstance.post(url, SubmitData);
      if (response.data.Status === "success") {
        toast.success(
          `${
            id ? "Mother Details Updated" : "Mother Details Added"
          } Successfully!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setButtonLoading(false);
        if (!id) {
          setSubmitData({
            ids: null,
            studentid: null,
            name: "",
            mobile: "",
            email: "",
            occupation: "",
            aadharcard: "",
            pancard: "",
            picture: null,
            employer: "",
            sector: "",
            department: "",
            designation: "",
          });
        }
        Navigate(`/admin/students/detail/${studentid}`);
      } else {
        throw new Error(`Mother Details ${id ? "Update" : "Add"} Failed`);
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error(`Mother Details ${id ? "Update" : "Add"} Failed`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [isSelect1, setIsSelect1] = useState(false);
  const checkForm1 = (e) => {
    if (e.target.value !== "Housewife") {
      setIsSelect1(true);
    } else {
      setIsSelect1(false);
    }
    setSubmitData({ ...SubmitData, occupation: e.target.value });
  };
  const path = document.location.pathname;
  return (
    <div className="mt-4">
      <div className="container-fluid">
        <div className="row">
          <h4 class="card-title">
            {path === `/admin/students/manage-mother/${studentid}`
              ? "Manage Student"
              : "Edit Mother Detail"}
          </h4>
          <div className="col-lg-12">
            {loading ? (
              <div
                className="container text-center"
                style={{ paddingTop: "200px" }}
              >
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                <div className="row pt-4">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card">
                        <div class="table-responsive">
                          <div className="pt-3 p-2">
                            <li class="list-group-item">
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Student Name</b> : &nbsp;
                                {studentDetail.studentname ?? ""}{" "}
                              </span>
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Father Name</b> : &nbsp;
                                {studentDetail.fathername ?? ""}{" "}
                              </span>
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Mobile</b> : &nbsp;
                                {studentDetail.mobile ?? ""}{" "}
                              </span>
                              {studentDetail.rollno ? (
                                <span class="custom-label fs-5">
                                  &nbsp;&nbsp;<b>Rollno</b> : &nbsp;{" "}
                                  {studentDetail.rollno}
                                </span>
                              ) : (
                                ""
                              )}
                              {studentDetail.sclass ? (
                                <span class="custom-label fs-5">
                                  &nbsp;&nbsp;<b>Class</b> : &nbsp;{" "}
                                  {studentDetail.sclass}
                                </span>
                              ) : (
                                ""
                              )}
                              {studentDetail.section ? (
                                <span class="custom-label fs-5">
                                  &nbsp;&nbsp;<b>Section</b> : &nbsp;{" "}
                                  {studentDetail.section}
                                </span>
                              ) : (
                                ""
                              )}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Gender</b> : &nbsp; {studentDetail.gender ?? ''}</span> */}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>DOB</b> : &nbsp; {studentDetail.dob ?? ''}</span> */}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Address</b> : &nbsp; {studentDetail.address ?? ''}</span> */}
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card mt-3">
                        <div className="card-header justify-content-between">
                          <div class="card-title"> Mother's Details</div>
                        </div>
                      </div>

                      <div class="card-body text-black fw-bold fs-6">
                        <div class="row form-material">
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Mother's Name</label>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              placeholder="Enter Name..."
                              pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                              value={SubmitData.name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Mother's Mobile</label>
                            <input
                              type="text"
                              class="form-control"
                              pattern="[6789][0-9]{9}"
                              name="mobile"
                              placeholder="Enter Mobile No..."
                              maxLength={10}
                              value={SubmitData.mobile}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Mother's Email</label>
                            <input
                              type="email"
                              class="form-control"
                              name="email"
                              placeholder="Enter Emailid..."
                              value={SubmitData.email}
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                          {id ? (
                            <>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Mother's occupation
                                </label>
                                <select
                                  class="form-control"
                                  id="autoSizingSelect"
                                  name="occupation"
                                  value={SubmitData.occupation}
                                  onChange={handleInputChange}
                                >
                                  <option>Choose...</option>
                                  <option value="Job">Job</option>
                                  <option value="Bussiness">Bussiness</option>
                                  <option value="Professional">
                                    Professional
                                  </option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Mother's Employer
                                </label>
                                <select
                                  class="form-control"
                                  id="autoSizingSelect"
                                  name="employer"
                                  defaultValue={SubmitData.empolyer}
                                  onChange={handleInputChange}
                                >
                                  <option>{SubmitData.empolyer}</option>
                                  <option value="Government">Government</option>
                                  <option value="PSU">PSU</option>
                                  <option value="Semi-Government">
                                    Semi-Government
                                  </option>
                                  <option value="Private">Private</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Mother's Sector (subtype - business)
                                </label>
                                <input
                                  type="text"
                                    pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                  class="form-control"
                                  placeholder="Enter sector (subtype - business)..."
                                  name="sector"
                                  value={SubmitData.sector}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Mother's Firm / Department
                                </label>
                                <input
                                  type="text"
                                    pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                  class="form-control"
                                  placeholder="Enter firm / department..."
                                  name="department"
                                  value={SubmitData.department}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Mother's Designation / Title
                                </label>
                                <input
                                  pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                  type="text"
                                  class="form-control"
                                  name="designation"
                                  value={SubmitData.designation}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Mother's occupation
                                </label>
                                <select
                                  class="form-control"
                                  id="autoSizingSelect"
                                  onChange={checkForm1}
                                  name="occupation"
                                >
                                  <option>Choose...</option>
                                  <option value="Job">Job</option>
                                  <option value="Bussiness">Bussiness</option>
                                  <option value="Professional">
                                    Professional
                                  </option>
                                  <option value="Housewife">Housewife</option>
                                </select>
                              </div>
                              {isSelect1 && (
                                <>
                                  <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                    <label class="form-label">
                                      Mother's Employer
                                    </label>
                                    <select
                                      class="form-control"
                                      id="autoSizingSelect"
                                      name="employer"
                                      onChange={handleInputChange}
                                    >
                                      <option>Choose...</option>
                                      <option value="Government">
                                        Government
                                      </option>
                                      <option value="PSU">PSU</option>
                                      <option value="Semi-Government">
                                        Semi-Government
                                      </option>
                                      <option value="Private">Private</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  </div>
                                  <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                    <label class="form-label">
                                      Mother's Sector (subtype - business)
                                    </label>
                                    <input
                                     pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter sector (subtype - business)..."
                                      name="sector"
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                    <label class="form-label">
                                      Mother's Firm / Department
                                    </label>
                                    <input
                                     pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter firm / department..."
                                      name="department"
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                    <label class="form-label">
                                      Mother's Designation / Title
                                    </label>
                                    <input
                                     pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                      type="text"
                                      class="form-control"
                                      name="designation"
                                      placeholder="Enter Designation..."
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">
                              Mother's Aadhar No.
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter aadhar card no... "
                              name="aadharcard"
                              pattern="\d{4}\s\d{4}\s\d{4}"
                              title=" xxxx xxxx xxxx"
                              value={SubmitData.aadharcard}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Mother's Pan No.</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter pan no..."
                              name="pancard"
                              pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                              title=" AAAAA1111A"
                              value={SubmitData.pancard}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Mother's Photo</label>
                            <input
                              type="file"
                              class="form-control"
                              name="picture"
                              onChange={handleImageChange}
                            />
                            {id ? (
                              <img
                                className="rounded "
                                src={`https://schoolapi.mnbsoft.co.in/UploadImage/${SubmitData.picture}`}
                                style={{ height: "50px", width: "100%" }}
                                alt="mother Photo"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              style={{ width: "100px" }}
                            >
                              {buttonLoading ? (
                                <span
                                  class="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : id ? (
                                "Edit"
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageMother;
