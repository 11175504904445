import React, { useEffect, useState } from "react";
import PreLoader from "./Components/Loader";
import { Link } from "react-router-dom";

const Home = () => {
    const [isOpenNav, setIsOpenNav] = useState(true);
    const toggleNav = () => { setIsOpenNav(!isOpenNav) };
  console.log(toggleNav)
    useEffect(() => {
        setTimeout(() => {
            document.querySelector(".requsetdemo").click();
        }, 5000);
    }, []);
  return <>
   <div class="main-page-wrapper p0 font-recoleta bg-white">
   <PreLoader/>
        <div class="theme-main-menu sticky-menu theme-menu-six bg-none fixed">
            <div class="d-flex align-items-center">
                <div class="logo order-lg-1"><a href="/"><img src="main/img/logo/logo.png" alt=""/></a>
                </div>

                <div class="right-widget order-lg-3">
                    <ul class="d-flex align-items-center">
                        <li>
                          <Link to="/login"><a class="signIn-action d-flex align-items-center">
                                <img src="main/img/icon/120.svg" alt=""/>
                                <span>login</span>
                            </a></Link>  
                        </li>
                        <li>
                            <button type="button" href="#contactModal" data-toggle="modal" data-target="#contactModal" class="signup-btn requsetdemo" data-bs-toggle="modal" data-bs-target="#exampleModal">Request A Demo</button>
                        </li>
                    </ul>
                </div>

                <nav id="mega-menu-holder" class="navbar navbar-expand-lg ml-lg-auto order-lg-2">
                    <div class="nav-container">
                        <button  className={`navbar-toggler bg-dark ${isOpenNav ? "" : "open"}`} onClick={toggleNav}  >
                            <span></span>
                        </button>
                        <div className={`navbar-collapse collapse  ${isOpenNav ? "" : "show"}`} onClick={toggleNav}   id="navbarSupportedContent">
                            <div class="d-lg-flex justify-content-between align-items-center">
                                <ul class="navbar-nav main-side-nav " id="one-page-nav">
                                    <li class="nav-item position-static">
                                        <a class="nav-link" >Home</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#modules" class="nav-link">Modules</a>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#feature" class="nav-link">Features</a>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#pricing" class="nav-link">Pricing</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <div class="hero-banner-nine hero-banner-one bg-white lg-container">
    <img src="main/images/shape/190.png" alt="" class="shapes bg-shape"/>
    <div class="container">
        <div class="illustration-container">
            <img src="/main/images/assets/screen_17.png" alt=""/> 
           
            <div class="icon-box-five"><img src="main/images/media/check.png" alt=""/></div>
					<div class="icon-box-six"><img src="main/images/media/information.png" alt=""/></div>
					<div class="icon-box-seven"><img src="main/images/media/trophy.png" alt=""/></div>
					<div class="icon-box-eight"><img src="main/images/media/check2.png" alt=""/></div>

            <div class="bubble-one"></div>
					<div class="bubble-two"></div>
					<div class="bubble-three"></div>
					<div class="bubble-four"></div>
					<div class="bubble-five"></div>
					<div class="bubble-six"></div>
        </div>

        <div class="row">
            <div class="col-lg-5">
                <h1 class="hero-heading">All-in-one <span>School <img src="main/images/shape/189.svg"
                            alt=""/></span> Management Solution.</h1>
              
                    <p class="hero-sub-heading">Most Innovative <strong>School Management ERP</strong> &amp; Best Digital Transformation in Education sector </p>
                <form action="#">
                    <input type="email" placeholder="info@mnbschool.com"/>
                    <button class="d-flex justify-content-center align-items-center"><img
                            src="main/images/icon/119.svg" alt=""/></button>
                </form>
                <p class="term-text">Already using School Management <a
                        href="login">Sign in.</a></p>
            </div>
        </div>
    </div>
    {/* <div id="carouselExampleControls" class="carousel slide partner-slider-two mt-130 md-mt-100" data-bs-ride="carousel">
    <div class="container">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="..." class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="..." class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="..." class="d-block w-100" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
  </div>
</div> */}
    <div class="carousel slide partner-slider-two mt-130 md-mt-100" id="carouselExampleControls" data-bs-ride="carousel">
        <div class="container">
            <p>Simplified scheduling for more than <span>200,0+</span> meetings</p>
            <div class="partnerSliderTwo carousel-inner" style={{display:"flex",justifyContent:"space-between"}} >
                <div class="item">
                    <div class="img-meta d-flex align-items-center carousel-item"><img  src="main/images/logo/logo-21.png"
                            alt=""/></div>
                </div>
                <div class="item">
                    <div class="img-meta d-flex align-items-center carousel-item"><img src="main/images/logo/logo-22.png"
                            alt=""/></div>
                </div>
                <div class="item">
                    <div class="img-meta d-flex align-items-center carousel-item"><img src="main/images/logo/logo-23.png"
                            alt=""/></div>
                </div>
                <div class="item">
                    <div class="img-meta d-flex align-items-center carousel-item"><img src="main/images/logo/logo-24.png"
                            alt=""/></div>
                </div>
                <div class="item">
                    <div class="img-meta d-flex align-items-center carousel-item"><img src="main/images/logo/logo-25.png"
                            alt=""/></div>
                </div>
                <div class="item">
                    <div class="img-meta d-flex align-items-center carousel-item"><img src="main/images/logo/logo-22.png"
                            alt=""/></div>
                </div>
                <div class="item">
                    <div class="img-meta d-flex align-items-center carousel-item"><img src="main/images/logo/logo-24.png"
                            alt=""/></div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
            </div>
        </div>
    </div>
</div>
<div class="fancy-feature-twentyTwo mt-120 md-mt-120 bg-white">
    <div class="container">
        <div class="row">
            <div class="col-xl-10 col-md-8 m-auto aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                <div class="title-style-eight text-center mb-40 md-mb-10">
                    <h2>Attain, Engage & Retain <span>Customers <img src="main/images/shape/191.svg"
                                alt=""/></span>Were Never So Easy </h2>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                <div class="block-style-twentyTwo">
                    <div class="icon d-flex align-items-center justify-content-center"
                        style={{background:"#FF4A8B"}}><img src="main/images/icon/121.svg" alt=""/></div>
                    <h4>Simple and easy to use</h4>
                    <p>Clean and clutter-free interface to engage teachers, students and parents. No expertise required to accomplish a task quickly.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                <div class="block-style-twentyTwo">
                    <div class="icon d-flex align-items-center justify-content-center"
                        style={{background:"#6D49FF"}}><img src="main/images/icon/122.svg" alt=""/></div>
                    <h4>Powerful features</h4>
                    <p>We provide everything you will ever need to run an education institution successfully. No customization required.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                <div class="block-style-twentyTwo">
                    <div class="icon d-flex align-items-center justify-content-center"
                        style={{background:"#FFB951"}}><img src="main/images/icon/123.svg" alt=""/></div>
                    <h4>Customizable dashboards</h4>
                    <p>MNB Schools dashboards can be customized to suit any format for the efficient functioning of the institution.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fancy-feature-twentyThree mt-170 md-mt-100" id="modules">
    <div class="container">
        <div class="title-style-nine text-center pb-180 md-pb-100 aos-init aos-animate" data-aos="fade-up"
            data-aos-duration="1200">

            <h2>All Modules In <span>One Place <img src="main/images/shape/192.svg" alt=""/></span></h2>
            <p>Our online booking software allows you to schedule your client’s appointments easily &
                efficiently.</p>
                
        </div>
        
        
        <div class="block-style-twentyThree">
            <div class="row align-items-center">
                <div class="col-lg-6 order-lg-last ml-auto aos-init aos-animate" data-aos="fade-left" data-aos-duration="1200">
                    <div class="screen-container ml-auto">
                        <div class="oval-shape" style={{background:"#69FF9C"}}></div>
                        <div class="oval-shape" style={{background:"#FFF170"}}></div>
                        <img src="main/images/assets/screen_18.png" alt="" class="shapes shape-one"/>
                    </div>
                </div>
                <div class="col-lg-5 order-lg-first aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
                    <div class="text-wrapper">
                        <h6>One click away</h6>
                        <h3 class="title">Admission \ Fees</h3>
                        <p>Our automated fee management system reduces the administration stress of fee
                            collection and allows them to focus on other important work. </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="block-style-twentyThree">
            <div class="row">
                <div class="col-lg-6">
                    <div class="screen-container mr-auto aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
                        <div class="oval-shape" style={{background:"#FFDE69"}}></div>
                        <div class="oval-shape" style={{background:"#FF77D9"}}></div>
                        <img src="main/images/assets/screen_19.png" alt="" class="shapes shape-two"/>
                    </div>
                </div>
                <div class="col-lg-5 ml-auto aos-init aos-animate" data-aos="fade-left" data-aos-duration="1200">
                    <div class="text-wrapper">
                        <h6>WORKFLOW MANAGEMENT</h6>
                        <h3 class="title">Automate reminders & follow-ups.</h3>
                        <p>Calendly puts your entire meeting workflow on autopilot, sending everything from
                            reminder emails to thank you notes, so you can focus on the work only you can
                            do.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="block-style-twentyThree">
            <div class="row">
                <div class="col-lg-6 order-lg-last ml-auto aos-init aos-animate" data-aos="fade-left"
                    data-aos-duration="1200">
                    <div class="screen-container ml-auto">
                        <div class="oval-shape" style={{background:"#00F0FF"}}></div>
                        <div class="oval-shape" style={{background:"#FC6BFF"}}></div>
                        <img src="main/images/assets/screen_20.png" alt=""
                            class="shapes shape-three"/>
                    </div>
                </div>
                <div class="col-lg-5 order-lg-first aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
                    <div class="text-wrapper">
                        <h6>ON-DEMAND SCHEDULING</h6>
                        <h3 class="title">Timetable Management</h3>
                        <p>We help schools automate the process of developing timetables and marking the
                            attendance of teachers and students.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fancy-feature-twentyFour pt-90 md-pt-60" id="feature">
    <div class="container">
        <div class="title-style-nine text-center">
            <h6>Our Features</h6>
            <h2>Key Features of <span> MNB School <img src="main/images/shape/194.svg" alt=""/> </span>
                 Management Software </h2>
            <p>Our school management software is designed to streamline and enhance the entire school
                management process, making it easier and more efficient for schools to operate.</p>
        </div>
    </div>
    <div class="bg-wrapper mt-100 md-mt-80">
        <a href="#feedback" class="click-scroll-button scroll-target d-flex justify-content-center"><img
                src="main/images/shape/200.svg" alt=""/></a>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                    <div class="block-style-twentyFour">
                        <div class="d-flex align-items-start">
                            <div class="icon d-flex align-items-center justify-content-center"
                                style={{background: "#3FE193"}}><img src="main/images/icon/124.svg"
                                    alt=""/></div>
                            <div class="text">
                                <h4>Multi-User Functionality </h4>
                                <p>All staff members can log in to the MNB School Management Software according to their roles, which reduces confusion and helps them manage and complete tasks efficiently.
 </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                    data-aos-delay="100">
                    <div class="block-style-twentyFour">
                        <div class="d-flex align-items-start">
                            <div class="icon d-flex align-items-center justify-content-center"
                                style={{background: "#FF4F86"}}><img src="main/images/icon/125.svg"
                                    alt=""/></div>
                            <div class="text">
                                <h4>User-Friendly Interface
 </h4>
                                <p> The interface of the MNB School Management System is user-friendly. It consists of high-tech functions that help teachers and other staff members in the school to manage their operations easily without taking help from anyone. 
</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                    <div class="block-style-twentyFour">
                        <div class="d-flex align-items-start">
                            <div class="icon d-flex align-items-center justify-content-center"
                                style={{background: "#FFCF39"}}><img src="main/images/icon/126.svg"
                                    alt=""/></div>
                            <div class="text">
                                <h4>Customizable Modules  </h4>
                                <p>The MNB School Management System can be customized to meet the specific needs of your school. This software enables the head of school administration to have access to all roles and analyze all the operations happening in the school. 
 </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                    data-aos-delay="100">
                    <div class="block-style-twentyFour">
                        <div class="d-flex align-items-start">
                            <div class="icon d-flex align-items-center justify-content-center"
                                style={{background: "#6D64FF"}}><img src="main/images/icon/127.svg"
                                    alt=""/></div>
                            <div class="text">
                                <h4>High-Level Security </h4>
                                <p>A High-Tech Security system has been used in MNB School Management Software. Therefore, you can easily safeguard your confidential data using this software and access it whenever required. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                    <div class="block-style-twentyFour">
                        <div class="d-flex align-items-start">
                            <div class="icon d-flex align-items-center justify-content-center"
                                style={{background: "#E752FF"}}><img src="main/images/icon/128.svg"
                                    alt=""/></div>
                            <div class="text">
                                <h4>Generate Reports  </h4>
                                <p>MNB School Management System provides teachers with the ability to generate various reports, including grade reports, evaluation reports, student lists, receipts, and pending fees, which enhance the learning experience of students.
</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-flex mb-35 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                    data-aos-delay="100">
                    <div class="block-style-twentyFour">
                        <div class="d-flex align-items-start">
                            <div class="icon d-flex align-items-center justify-content-center"
                                style={{background:" #29EEFB"}}><img src="main/images/icon/129.svg"
                                    alt=""/></div>
                            <div class="text">
                                <h4>SMS & Email Integration </h4>
                                <p>MNB School Management System automates the process of sending messages and emails to parents about the activities of their wards in school. It also helps to keep parents updated with all the important activities and events in the school. 
</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <img src="main/images/shape/195.svg" alt="" class="shapes shape-one"/>
        <img src="main/images/shape/196.svg" alt="" class="shapes shape-two"/>
        <img src="main/images/shape/197.svg" alt="" class="shapes shape-three"/>
        <img src="main/images/shape/198.svg" alt="" class="shapes shape-four"/>
        <img src="main/images/shape/199.svg" alt="" class="shapes shape-five"/>
    </div>
</div>

<div class="pricing-section-seven mt-200 md-mt-130" id="pricing">
    <div class="container">
        <div class="row">
            <div class="col-xl-9 col-lg-10 col-md-10 m-auto">
                <div class="title-style-eleven text-center mb-100 md-mb-70">
                    <h2>No hidden charge, Choose your plan.</h2>
                </div>
            </div>
        </div>
        <div class="pricing-table-area-seven">
            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                    <div class="pr-table-wrapper md-mb-40">
                        <img src="main/img/plan/091426260224936894.svg" alt="" class="icon"/>
                        <div class="pack-name">Basic</div>
                        <div class="pr-feature">
                            <ul><li>Student Registration</li><li>Fee Module</li><li>Result Printing</li><li>Student ID Card</li></ul>
                        </div>
                        <div class="price">₹1999 /<span>Yearly</span></div>
                        <div class="trial-text">10 Student</div>
                        <a href="plan/eyJpdiI6IjZ3OXczLzZvZFcvamIwbWhLbTlCSHc9PSIsInZhbHVlIjoiY0pHTjNTeE0rRjZrZGdxeU5aQjgwUT09IiwibWFjIjoiNWU2YWE2YTQ5YjQ1NzE2MzAxZTZjZmY1ZTc5ZmNkYTQ3MGNjNzgxZjFjMTI2YTdmNGQyMjU4ZWIwZDA3NTIyM" class="trial-button">Choose</a>
                    </div>
                </div>
                                <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                    <div class="pr-table-wrapper md-mb-40">
                        <img src="main/img/plan/112657570324791150.svg" alt="" class="icon"/>
                        <div class="pack-name">Silver Model</div>
                        <div class="pr-feature">
                            <ul><li>Student Registration</li><li>Fee Module</li><li>Result Printing</li><li>Student ID Card</li><li>Assignment upload</li><li>Parent Login (With App)</li><li>Teacher Login (With App)</li><li>Student Attendance - Parents also check</li></ul>
                        </div>
                        <div class="price">₹4999 /<span>Yearly</span></div>
                        <div class="trial-text">15 Student</div>
                        <a href="plan/eyJpdiI6InpaNHRMZWVzZC9oQWdYdUVNUHpqSUE9PSIsInZhbHVlIjoiYUI0RU81ZFErT3pydTFETW1IZzdpUT09IiwibWFjIjoiNWQ0OWI5YzM0ZjVkNDgzNDZhN2YzODI2MTMyMDllMjY4ZTIxNzE0YTdkNDA2ZmU2Yzk0ZjM0MDI1ZjM3ODEyO" class="trial-button">Choose</a>
                    </div>
                </div>
                                <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                    <div class="pr-table-wrapper md-mb-40">
                        <img src="main/img/plan/112921210324250169.svg" alt="" class="icon"/>
                        <div class="pack-name">Gold Model</div>
                        <div class="pr-feature">
                            <ul><li>Student Registration</li><li>Fee Module</li><li>Result Printing</li><li>Student ID Card</li><li>Parent Login (With App)</li><li>Teacher Login (With App)</li><li>Assignment upload</li><li>Student Attendance - Parents also check</li><li>Expanse Management</li><li>Manage Library</li></ul>
                        </div>
                        <div class="price">₹9999 /<span>Yearly</span></div>
                        <div class="trial-text">25 Student</div>
                        <a href="plan/eyJpdiI6Ikxja3A1cDl6ZEhBMDE3aVpKMnNJWnc9PSIsInZhbHVlIjoiOVYrOERISU52OURna3NWenRxVCtqUT09IiwibWFjIjoiZDUyZDQ4MGYzNjQ0MDFhMjJjNzk2ZGY0ZWRhZGMxNDBmZjQyNTNjMmVkMzQ3YzI2ODVjMGQ4M2Q4Mjc5OGMyY" class="trial-button">Choose</a>
                    </div>
                </div>
                            </div>
        </div>
    </div>
</div>
<div class="fancy-short-banner-ten mt-170 md-mt-80">
    <div class="container">
        <div class="row">
            <div class="col-xl-9 col-lg-11 m-auto aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                <div class="text-center">
                    <h2>Try MNB School Management Free</h2>
                    <p>After your 14-day trial of our Professional plan, enjoy the Free version of Calendly
                        – forever.</p>
                </div>
            </div>
        </div>
        <div className="aos-init aos-animate" data-aos="fade-up " data-aos-duration="1200" data-aos-delay="150">
            <div class="dropdown download-btn">
                <button class="dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Start Free Trial
                </button>

            </div>
            <div class="info-text">No Credit Card Required. Cancel Anytime</div>
        </div>
    </div>
    <img src="main/images/shape/210.svg" alt="" class="shapes shape-one"/>
    <img src="main/images/shape/211.svg" alt="" class="shapes shape-two"/>
</div>

<footer class="theme-footer-eight mt-100">
    <div class="top-footer">
        <div class="container">
            <div class="row justify-content-end">
                <div class="col-xl-4 col-lg-3 col-12 footer-about-widget">
                    <div class="logo"><a href="index-2.html"><img src="main/img/logo/logo.png" alt=""/></a></div>
                    <ul class="font-rubik mt-10">
                        <li><a href="#">info@mnbschools.com</a></li>
                        <li><a href="#" class="phone">+91 93112 21776</a></li>
                    </ul>
                </div> 
                <div class="col-lg-3 col-md-4 footer-list">
                    <h5 class="footer-title">All Modules</h5>
                    <ul>
                        <li><a href="#">Lead Management</a></li>
                        <li><a href="#">Timetable Management</a></li>
                        <li><a href="#">Online Assessment</a></li>
                        <li><a href="#">Admission \ Fees</a></li>
                        <li><a href="#">HRMS / Payroll</a></li>
                    </ul>
                </div> 
                <div class="col-lg-3 col-md-4 footer-list">
                    <h5 class="footer-title">Our Features</h5>
                    <ul>
                        <li>
                            <a href="#">
                                User Friendly UI
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Streamline the Activites
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Web Application
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Cloud Based Software
                            </a>
                        </li>
                        <li>
                            <a href="#">

                                Extremely Secure
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Regular Updates and Support
                            </a>
                        </li>
                    </ul>
                </div> 
                <div class="col-xl-2 col-lg-3 col-md-4 footer-list">
                    <h5 class="footer-title">Products</h5>
                    <ul>
                        <li><a href="#">Take the tour</a></li>
                        <li><a href="#">Live chat</a></li>
                        <li><a href="#">Self-service</a></li>
                        <li><a href="#">Social</a></li>
                        <li><a href="#">Mobile</a></li>
                        <li><a href="#">deski Reviews</a></li>
                    </ul>
                </div> 
            </div> 
        </div> 
    </div>

    <div class="container">
        <div class="bottom-footer mt-50 md-mt-30">
            <div class="row">
                <div class="col-lg-6 order-lg-last mb-20">
                    <ul class="d-flex justify-content-center justify-content-lg-end social-icon">
                        <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
                <div class="col-lg-6 order-lg-first mb-20">
                    <p class="copyright text-center text-lg-left">Copyright @2024 MNB School Managment.</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="modal fade modal-contact-popup-one" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  role="dialog"
   >
    <div class="modal-dialog modal-dialog-centered" role="document">
        <main class="main-body modal-content clearfix">
          
            <button  type="button" class="btn-close csbtn" data-bs-dismiss="modal" aria-label="Close">
            <i class="bi bi-x-lg"></i>
            </button>
           
            <div class="right-side">
                <p class="form-pre">Safe & Confidential</p>
                <h2 class="form-title">Request For a Free Demo</h2>

                <form class="submitrequest">
                    <div class="formerror"></div>
                    <div class="row controls">
                        <div class="col-12">
                            <div class="input-group-meta form-group mb-15">
                                <label>Name</label>
                                <input type="text" placeholder="Your Name" name="Fname"id="name"
                                    required="required" data-error="Name is required."/>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group-meta form-group mb-15">
                                <label>Email*</label>
                                <input type="email" placeholder="Email Address" name="email" id="email"
                                    required="required" data-error="Valid email is required."/>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group-meta form-group mb-15">
                                <label>Phone*</label>
                                <input type="number" placeholder="Phone No." name="phone" id="phone"
                                    required="required" data-error="Valid phone no. is required."/>
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        
                        <div class="col-12"><button class="theme-btn-seven w-100">Send Message</button></div>
                    </div>
                </form>
            </div>
        </main>
    </div>
</div>
   </div>
  </>;
};

export default Home;
