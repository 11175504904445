import React from 'react';
import { Link } from 'react-router-dom';
const ViewAttendance = () => {
    return (
        <div>
            {/* <div class="month">
                <ul>
                    <li>
                        December<br />
                        <span style={{ fontSize: 18 }}>2023</span>
                    </li>
                </ul>
            </div>
            <ul class="weekdays" style={{ fontSize: 18 }}>
                <li>Mon</li>
                <li>Tue</li>
                <li>Wed</li>
                <li>Thu</li>
                <li>Fri</li>
                <li>Sat</li>
                <li>Sun</li>
            </ul>
            <ul class="days" style={{ fontSize: 18 }}>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li><span class="active">10</span></li>
                <li>11</li>
                <li>12</li>
                <li><span class="active">13</span></li>
                <li>14</li>
                <li>15</li>
                <li>16</li>
                <li><span class="active">17</span></li>
                <li>18</li>
                <li>19</li>
                <li>20</li>
                <li>21</li>
                <li><span class="active">22</span></li>
                <li><span class="active">23</span></li>
                <li><span class="active">24</span></li>
                <li><span class="active">25</span></li>
                <li>26</li>
                <li>27</li>
                <li>28</li>
                <li>29</li>
                <li>30</li>
                <li>31</li>
            </ul> */}
            <div className="container-fluid">
                <div className="row pt-4">
                    <h3 class="text-center">December Attendance</h3>
                    <div class="col-lg-12 pt-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Attendance</th>
                                                <th>Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>1</td>
                                                <td><i class="bi bi-calendar-check"></i> 01-12-2023</td>
                                                <td>Ronak</td>
                                                <td><span class="badge badge-xs light badge-success">Present</span></td>
                                                <td>Reason here</td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>2</td>
                                                <td><i class="bi bi-calendar-check"></i> 02-12-2023</td>
                                                <td>Ronak</td>
                                                <td><span class="badge badge-xs light badge-danger">Absent</span></td>
                                                <td>Reason here</td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>3</td>
                                                <td><i class="bi bi-calendar-check"></i> 03-12-2023</td>
                                                <td>Ronak</td>
                                                <td><span class="badge badge-xs light badge-success">Present</span></td>
                                                <td>Reason here</td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>4</td>
                                                <td><i class="bi bi-calendar-check"></i> 04-12-2023</td>
                                                <td>Ronak</td>
                                                <td><span class="badge badge-xs light badge-success">Present</span></td>
                                                <td>Reason here</td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>5</td>
                                                <td><i class="bi bi-calendar-check"></i> 05-12-2023</td>
                                                <td>Ronak</td>
                                                <td><span class="badge badge-xs light badge-success">Present</span></td>
                                                <td>Reason here</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <a class="page-link">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div></div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewAttendance;