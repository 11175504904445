import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
function ManageNotice() {
    const [SubmitData, setSubmitData] = useState({
        message: "",
    });
    const [notice, setNotice] = useState({
        ids: "",
        message: "",
    });
    const [noticLoading, setnoticLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axiosInstance.post("/master/AddNotification", SubmitData);
            if (!response.data.Status === "success") {
                throw new Error("Add notice failed");
            }
            toast.success("Notice added successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setSubmitData({ sclass: "" })
            fetchData();
            setLoading(false);
            // history('/admin/master/manage-class');
        } catch (error) {
            setLoading(false);
            toast.error("Add notice failed", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    // Table Data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/master/Getnotificationdetail`);
            setData(response.data.Payload);
        } catch (error) {
            // toast.error("Authentication failed !");
            window.location.href = "/";
        } finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    // Edit table data..
    const [editingId, setEditingId] = useState(null);
    const handleEditClick = (id) => {
        setEditingId(id);
    };
    const handleSaveClick = async (e) => {
        e.preventDefault();
        setEditingId(null);
        try {
            const response = await axiosInstance.post(
                "/master/UpdateNotification",
                notice
            );
            if (!response.data.Status === "success") {
                throw new Error("Notice updated failed");
            }
            toast.success("Notice updated successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            toast.error("Notice update failed", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    const handleCancelClick = () => {
        setEditingId(null);
    };
    const handleInputChange = (e, id, key) => {
        const newData = data.map((item) =>
            item.id === id ? { ...item, [key]: e.target.value } : item
        );
        setNotice((prev) => ({ ...prev, ids: id, message: e.target.value }));
        setData(newData);
    };
    // -- pagination Code -- //

    const dataPerPage = 10;

    const [currentPage, setCurrentPage] = useState(0);

    const numberOfPage = Math.ceil(data?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div class="card-header">
                        <h4 class="card-title">Manage Notice</h4>
                    </div>
                    <div class="col-lg-4">
                        <form onSubmit={handleSubmit}>
                            <div class="card custom-card mb-3">
                                <div class="card-header justify-content-between"><div class="card-title">Add Notice</div></div>
                                <div class="card-body text-black fw-bold fs-6">
                                    <div class=" form-material mb-3">
                                        <label class="form-label">
                                            Notice<span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            type="text" rows="3"
                                            class="form-control"
                                            placeholder="Enter notice..."
                                            name="message"
                                            value={SubmitData.message}
                                            onChange={(e) =>
                                                setSubmitData({
                                                    ...SubmitData,
                                                    message: e.target.value,
                                                })
                                            }
                                            required
                                        />
                                    </div>
                                    <div className="text-end">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mb-3 btn-block" >
                                            {noticLoading ? (
                                                <span
                                                    class="spinner-border spinner-border-sm me-2 shadow bg-body-tertiary rounded"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-8">
                        <div class="card custom-card mb-3">
                            <div class="card-header justify-content-between"><div class="card-title">Notice List</div></div>
                            <div class="card-body py-3 px-3">
                                {loading ? (
                                    <div class="table-responsive">
                                        <table class="table text-nowrap table-hover border table-bordered">
                                            <thead class="">
                                                <tr>
                                                    <th><p class="placeholder-wave">
                                                        <span class="placeholder col-12"></span>
                                                    </p></th>
                                                    <th><p class="placeholder-wave">
                                                        <span class="placeholder col-12"></span>
                                                    </p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-black fs-5">
                                                    <td><p class="placeholder-glow">
                                                        <span class="placeholder col-12"></span>
                                                    </p></td>
                                                    <td class="text-nowrap">
                                                        <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="text-black fs-5">
                                                    <td><p class="placeholder-glow">
                                                        <span class="placeholder col-12"></span>
                                                    </p></td>
                                                    <td class="text-nowrap">
                                                        <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="text-black fs-5">
                                                    <td><p class="placeholder-glow">
                                                        <span class="placeholder col-12"></span>
                                                    </p></td>
                                                    <td class="text-nowrap">
                                                        <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="text-black fs-5">
                                                    <td><p class="placeholder-glow">
                                                        <span class="placeholder col-12"></span>
                                                    </p></td>
                                                    <td class="text-nowrap">
                                                        <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <>
                                        <div class="table-responsive">
                                            <table class="table table-hover table-bordered">
                                                <thead>
                                                    <tr>
                                                        {/* <th>Date</th> */}
                                                        <th>Notice</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data &&
                                                        data.map((row) => (
                                                            <tr className="text-black fs-5" key={row.id}>
                                                                {/* <td>{row.createdate}</td> */}
                                                                <td>
                                                                    {editingId === row.id ? (
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={row.message}
                                                                            onChange={(e) =>
                                                                                handleInputChange(e, row.id, "message")
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        row.message ?? ""
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {editingId === row.id ? (
                                                                        <>
                                                                            <span class="btn btn-danger shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                                                            <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                                                        </>
                                                                    ) : (
                                                                        <span class="btn btn-primary shadow btn-xs sharp " onClick={() => handleEditClick(row.id)}><i class="fas fa-pencil-alt"></i></span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    {!data && <p>No data available</p>}
                                                </tbody>
                                            </table>
                                        </div>
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination justify-content-end">
                                                <li class="page-item">
                                                    <a
                                                        class="page-link"
                                                        style={{
                                                            pointerEvents:
                                                                currentPage === 0 ? "none" : "auto",
                                                            opacity: currentPage === 0 ? 0.5 : 1,
                                                        }}
                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                {pageIndex
                                                    .slice(
                                                        Math.max(0, currentPage - 2),
                                                        Math.min(numberOfPage, currentPage + 2)
                                                    )
                                                    .map((page) => (
                                                        <li
                                                            class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                                }`}
                                                            key={page}
                                                        >
                                                            <a
                                                                class="page-link"
                                                                style={{
                                                                    pointerEvents:
                                                                        page - 1 === currentPage
                                                                            ? "none"
                                                                            : "auto",
                                                                    opacity: page - 1 === currentPage ? 0.5 : 1,
                                                                }}
                                                                onClick={() => handlePageChange(page - 1)}
                                                                href="#"
                                                            >
                                                                {page}
                                                            </a>
                                                        </li>
                                                    ))}
                                                <li class="page-item">
                                                    <a
                                                        class="page-link"
                                                        style={{
                                                            pointerEvents:
                                                                currentPage === numberOfPage - 1
                                                                    ? "none"
                                                                    : "auto",
                                                            opacity:
                                                                currentPage === numberOfPage - 1 ? 0.5 : 1,
                                                        }}
                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                    >
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageNotice;
