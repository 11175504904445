import React, { useEffect } from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

function ManageFather() {
  const Navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { studentid } = useParams();
  const { id } = useParams();
  const [studentDetail, setStudentDetail] = useState([]);
  const [SubmitData, setSubmitData] = useState({
    ids: id,
    studentid: studentid,
    name: "",
    mobile: "",
    email: "",
    occupation: "",
    aadharcard: "",
    pancard: "",
    picture: "",
    empolyer: "",
    sector: "",
    department: "",
    designation: "",
  });
  // image upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const filebase64 = reader.result.split("base64,");
        setSubmitData({ ...SubmitData, picture: filebase64[1] });
      };
      reader.readAsDataURL(file);
    }
  };

  //data get

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `/student/GetStudentInformation?studentid=${studentid}`
      );
      const fatherDetail = response.data.FatherDetail[0];
      if (id) {
        setSubmitData({
          ids: fatherDetail.id,
          studentid: fatherDetail.studentid,
          name: fatherDetail.name,
          mobile: fatherDetail.mobileno,
          email: fatherDetail.emailid,
          occupation: fatherDetail.occupation,
          aadharcard: fatherDetail.aadharcard,
          pancard: fatherDetail.pancard,
          picture: fatherDetail.picture,
          empolyer: fatherDetail.employer,
          sector: fatherDetail.sector,
          department: fatherDetail.department,
          designation: fatherDetail.designation,
        });
      }
    } catch (error) {
      // toast.error("Authentication failed !");
      // window.location.href = "/";
    }
  };
  useEffect(() => {
    const fetchStudentDetail = async () => {
      try {
        const response = await axiosInstance.get(
          `/Student/Getstudentdetail?studentid=${studentid}`
        );
        setStudentDetail(response.data?.Payload[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentDetail();
    fetchData();
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [studentid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    try {
      const response = await axiosInstance.post(
        "/student/CreateFatherDetails",
        SubmitData
      );
      if (!response.data.Status === "success") {
        throw new Error("Father Details Added  Failed");
      }
      toast.success("Father Details Added Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setButtonLoading(false);
      Navigate(`/admin/students/detail/${studentid}`);
    } catch (error) {
      setButtonLoading(true);
      toast.error("Father Details Added Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    try {
      const response = await axiosInstance.post(
        "/student/updateStudentfatherDetail",
        SubmitData
      );
      if (!response.data.Status === "success") {
        throw new Error("Father Details Update Failed");
      }
      toast.success("Father Details Updated Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
      toast.error("Father Details Update Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    Navigate(`/admin/students/detail/${studentid}`);
  };

  const [isSelect1, setIsSelect1] = useState(false);
  const checkForm1 = (e) => {
    if (e.target.value !== "Other") {
      setIsSelect1(true);
    } else {
      setIsSelect1(false);
    }
    setSubmitData({ ...SubmitData, occupation: e.target.value });
  };
  const path = document.location.pathname;
  return (
    <div className="mt-4">
      <div className="container-fluid">
        <div className="row">
          <h4 class="card-title">
            {path === `/admin/students/manage-father/${studentid}`
              ? "Manage Student"
              : "Edit Father Detail"}
          </h4>
          <div className="col-lg-12">
            {loading ? (
              <div
                className="container text-center"
                style={{ paddingTop: "200px" }}
              >
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <form
                encType="multipart/form-data"
                onSubmit={id ? handleEdit : handleSubmit}
              >
                <div className="row pt-4">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card">
                        <div class="table-responsive">
                          <div className="pt-3 p-2">
                            <li class="list-group-item">
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Student Name</b> : &nbsp;
                                {studentDetail.studentname ?? ""}{" "}
                              </span>
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Father Name</b> : &nbsp;
                                {studentDetail.fathername ?? ""}{" "}
                              </span>
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Mobile</b> : &nbsp;
                                {studentDetail.mobile ?? ""}{" "}
                              </span>
                              {studentDetail.rollno ? (
                                <span class="custom-label fs-5">
                                  &nbsp;&nbsp;<b>Rollno</b> : &nbsp;{" "}
                                  {studentDetail.rollno}
                                </span>
                              ) : (
                                ""
                              )}
                              {studentDetail.sclass ? (
                                <span class="custom-label fs-5">
                                  &nbsp;&nbsp;<b>Class</b> : &nbsp;{" "}
                                  {studentDetail.sclass}
                                </span>
                              ) : (
                                ""
                              )}
                              {studentDetail.section ? (
                                <span class="custom-label fs-5">
                                  &nbsp;&nbsp;<b>Section</b> : &nbsp;{" "}
                                  {studentDetail.section}
                                </span>
                              ) : (
                                ""
                              )}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Gender</b> : &nbsp; {studentDetail.gender ?? ''}</span> */}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>DOB</b> : &nbsp; {studentDetail.dob ?? ''}</span> */}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Address</b> : &nbsp; {studentDetail.address ?? ''}</span> */}
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card mt-3">
                        <div className="card-header justify-content-between">
                          <div class="card-title">Father's Details</div>
                        </div>
                      </div>

                      <div class="card-body text-black fw-bold fs-6">
                        <div class="row form-material">
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Father's Name</label>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              placeholder="Enter name..."
                              pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                              defaultValue={studentDetail.fathername}
                              onChange={(e) =>
                                setSubmitData({
                                  ...SubmitData,
                                  name: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Father's Mobile</label>
                            <input
                              type="text"
                              class="form-control"
                              pattern="[6789][0-9]{9}"
                              name="mobile"
                              placeholder="Enter Mobile no..."
                              maxLength={10}
                              defaultValue={studentDetail.mobile}
                              onChange={(e) =>
                                setSubmitData({
                                  ...SubmitData,
                                  mobile: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Father's Email</label>
                            <input
                              type="email"
                              class="form-control"
                              name="email"
                              placeholder="Enter Email id"
                              defaultValue={studentDetail.email}
                              onChange={(e) =>
                                setSubmitData({
                                  ...SubmitData,
                                  email: e.target.value,
                                })
                              }
                            />
                          </div>
                          {id ? (
                            <>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Father's occupation
                                </label>
                                <select
                                  class="form-control"
                                  id="autoSizingSelect"
                                  onChange={checkForm1}
                                  name="occupation"
                                >
                                  <option>{SubmitData.occupation}</option>
                                  <option value="Job">Job</option>
                                  <option value="Bussiness">Bussiness</option>
                                  <option value="Professional">
                                    Professional
                                  </option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Father's Employer
                                </label>
                                <select
                                  class="form-control"
                                  id="autoSizingSelect"
                                  name="employer"
                                  defaultValue={SubmitData.empolyer}
                                  onChange={(e) =>
                                    setSubmitData({
                                      ...SubmitData,
                                      employer: e.target.value,
                                    })
                                  }
                                >
                                  <option>{SubmitData.empolyer}</option>
                                  <option value="Government">Government</option>
                                  <option value="PSU">PSU</option>
                                  <option value="Semi-Government">Semi-Government</option>
                                  <option value="Private">Private</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Father's Sector (subtype - business)
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="sector"
                                   pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                  placeholder="Enter sector..."
                                  defaultValue={SubmitData.sector}
                                  onChange={(e) =>
                                    setSubmitData({
                                      ...SubmitData,
                                      sector: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Father's Firm / Department
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="department"
                                   pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                  placeholder="Enter firm/department..."
                                  defaultValue={SubmitData.department}
                                  onChange={(e) =>
                                    setSubmitData({
                                      ...SubmitData,
                                      department: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Father's Designation / Title
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="designation"
                                   pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                  placeholder="Enter Designation / Title..."
                                  defaultValue={SubmitData.designation}
                                  onChange={(e) =>
                                    setSubmitData({
                                      ...SubmitData,
                                      designation: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                <label class="form-label">
                                  Father's occupation
                                </label>
                                <select
                                  class="form-control"
                                  id="autoSizingSelect"
                                  onChange={checkForm1}
                                  name="occupation"
                                >
                                  <option>Choose...</option>
                                  <option value="Job">Job</option>
                                  <option value="Bussiness">Bussiness</option>
                                  <option value="Professional">
                                    Professional
                                  </option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                              {isSelect1 && (
                                <>
                                  <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                    <label class="form-label">
                                      Father's Employer
                                    </label>
                                    <select
                                      class="form-control"
                                      id="autoSizingSelect"
                                      name="employer"
                                      onChange={(e) =>
                                        setSubmitData({
                                          ...SubmitData,
                                          employer: e.target.value,
                                        })
                                      }
                                    >
                                      <option>Choose...</option>
                                      <option value="Government">
                                        Government
                                      </option>
                                      <option value="PSU">PSU</option>
                                      <option value="Semi-Government">
                                        Semi-Government
                                      </option>
                                      <option value="Private">Private</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  </div>
                                  <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                    <label class="form-label">
                                      Father's Sector (subtype - business)
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="sector"
                                       pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                      placeholder="Enter sector..."
                                      defaultValue={SubmitData.sector}
                                      onChange={(e) =>
                                        setSubmitData({
                                          ...SubmitData,
                                          sector: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                    <label class="form-label">
                                      Father's Firm / Department
                                    </label>
                                    <input
                                      type="text"
                                       pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                      class="form-control"
                                      name="department"
                                      placeholder="Enter Firm / Department..."
                                      defaultValue={SubmitData.department}
                                      onChange={(e) =>
                                        setSubmitData({
                                          ...SubmitData,
                                          department: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                    <label class="form-label">
                                      Father's Designation / Title
                                    </label>
                                    <input
                                     pattern="[A-Za-z0-9]+[A-Za-z'\-\s]*"
                                      type="text"
                                      class="form-control"
                                      name="designation"
                                      placeholder="Enter designation / Title..."
                                      defaultValue={SubmitData.designation}
                                      onChange={(e) =>
                                        setSubmitData({
                                          ...SubmitData,
                                          designation: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">
                              Father's Aadhar No.
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="aadharcard"
                              pattern="\d{4}\s\d{4}\s\d{4}"
                              placeholder="Enter Aadhar no..."
                              title=" xxxx xxxx xxxx"
                              defaultValue={SubmitData.aadharcard}
                              onChange={(e) =>
                                setSubmitData({
                                  ...SubmitData,
                                  aadharcard: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Father's Pan No.</label>
                            <input
                              type="text"
                              class="form-control"
                              name="pancard"
                              placeholder="Enter Pan no..."
                              pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                              title=" AAAAA1111A"
                              defaultValue={SubmitData.pancard}
                              onChange={(e) =>
                                setSubmitData({
                                  ...SubmitData,
                                  pancard: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label className="form-label">Father's Photo</label>
                            <input
                              type="file"
                              className="form-control"
                              name="picture"
                              onChange={handleImageChange}
                            />
                            {id ? (
                              <img
                                className="rounded "
                                src={`https://schoolapi.mnbsoft.co.in/UploadImage/${SubmitData.picture}`}
                                style={{ height: "50px", width: "100%" }}
                                alt="mother Photo"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              style={{
                                width: "100px",
                              }}
                            >
                              {buttonLoading ? (
                                <span
                                  class="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : id ? (
                                "Edit"
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManageFather;
