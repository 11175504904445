import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Loader";
import { axiosInstance } from "../../admin-helper";

function Student_list() {
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [getdata, setGetData] = useState([]);
  const [data, setData] = useState({
    name: "",
    fname: "",
    mobile: "",
    sclass: "",
    rollno: "",
  });

  // fetching StudentDetails
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `/Student/GetSearchStudentDatail`,
        data
      );
      setGetData(response.data.Payload);
      setOriginalData(response.data.Payload); // Store original unfiltered data
    } catch (error) {
      window.location.href = "/";
    } finally {
      setLoading(false);
    }
  };

  const searchChange = (e) => {
    const { value } = e.target;
    const filteredData = originalData.filter((item) => {
      return Object.values(item).some(
        (val) => val && val.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    });
    setGetData(filteredData);
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  const dataPerPage = 10;

  const [currentPage, setCurrentPage] = useState(0);

  const numberOfPage = Math.ceil(getdata?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const changeBlock = async (studentID, blockstatus) => {
    try {
      const response = await axiosInstance.post("/Student/BlockStudent", {
        studentid: studentID,
        type: blockstatus
      });
      if (response.data.Status === "success") {
        toast.success("Student block status updated successfully!");
      } else {
        toast.error("Failed to update student block status.");
      }
    } catch (error) {
      toast.error("Failed to update student block status.");
    } finally {
      fetchData();
    }
  };

  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="card-header">
              <h4 className="card-title">Manage Student</h4>
              <div className="flex flex-wrap gap-2">
                <Link className="btn btn-primary" to="/admin/students/block-list">Blocked Student<span className="btn-icon-end"><i className="fa fa-solid fa-list"></i></span></Link>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card custom-card mb-3">
                <div className="card-header justify-content-between">
                  <div className="card-title">Student List</div>
                  <div className="flex flex-wrap gap-2">
                    <input
                      className="form-control form-control-sm"
                      onChange={searchChange}
                      type="search"
                      placeholder="Search Here..."
                      aria-label=".form-control-sm example"
                    />
                  </div>
                </div>
                <div className="card-body py-2 px-2">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="table-responsive">
                      <table className="table text-nowrap table-hover border table-bordered">
                        <thead className="">
                          <tr>
                            <th>#</th>
                            <th>Roll No.</th>
                            <th>Student Name</th>
                            <th>Father Name</th>
                            <th>Email ID</th>
                            <th>Mobile No.</th>
                            <th>Class</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getdata && getdata.length > 0 ? (
                            getdata
                              .slice(
                                currentPage * dataPerPage,
                                (currentPage + 1) * dataPerPage
                              )
                              .map((row,index) => (
                                <tr className="text-black fs-5" key={row.id}>
                                  <td>{currentPage * dataPerPage + index+1}</td>
                                  <td>{row.rollno}</td>
                                  <td>{row.name}</td>
                                  <td>{row.fname}</td>
                                  <td>{row.emailid}</td>
                                  <td>{row.mobileno}</td>
                                  <td>{row.sclass}</td>
                                  <td className="text-nowrap">

                                    &nbsp;
                                    <Link
                                      to={`/admin/students/detail/${row.studentid}`}
                                      className="btn btn-warning  btn-sm content-icon"
                                      title="View Student Details"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={`/admin/students/monthly-payment/${row.studentid}`}
                                      className="btn btn-primary btn-sm content-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Pay monthly fee"
                                    >
                                      <i class="bi bi-credit-card"></i>
                                      {/* <i className="fa-solid fa-receipt"></i> */}
                                    </Link>
                                    &nbsp;
                                    {row.blockstatus === 0 ? (
                                      <Link className="btn btn-danger btn-sm content-icon" title="Block Student" onClick={() => changeBlock(row.studentid, row.blockstatus === 0 ? 1 : 0)}>
                                        <i class="fa fa-ban"></i>
                                      </Link>
                                    ) : (
                                      <Link className="btn btn-primary btn-sm content-icon" onClick={() => changeBlock(row.studentid, row.blockstatus === 1 ? 0 : 1)}>
                                        <i className="bi bi-unlock"></i>
                                      </Link>
                                    )}

                                    {/* <Link class="btn btn-danger btn-sm content-icon">{row.blockstatus ? <i class="bi bi-lock"></i> :<i class="bi bi-unlock"></i>}</Link> */}
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <p>No data available</p>
                          )}
                        </tbody>
                      </table>
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                          <li className="page-item">
                            <a
                              className="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === 0 ? "none" : "auto",
                                opacity: currentPage === 0 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              Previous
                            </a>
                          </li>
                          {pageIndex
                            .slice(
                              Math.max(0, currentPage - 2),
                              Math.min(numberOfPage, currentPage + 2)
                            )
                            .map((page) => (
                              <li
                                className={`page-item ${page === currentPage + 1 ? "active" : ""
                                  }`}
                                key={page}
                              >
                                <a
                                  className="page-link"
                                  style={{
                                    pointerEvents:
                                      page - 1 === currentPage
                                        ? "none"
                                        : "auto",
                                    opacity: page - 1 === currentPage ? 0.5 : 1,
                                  }}
                                  onClick={() => handlePageChange(page - 1)}
                                >
                                  {page}
                                </a>
                              </li>
                            ))}
                          <li className="page-item">
                            <a
                              className="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === numberOfPage - 1
                                    ? "none"
                                    : "auto",
                                opacity:
                                  currentPage === numberOfPage - 1 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Student_list;
