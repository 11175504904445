import React from 'react';
import { Link } from 'react-router-dom';
function ManageClass() {
    return (<>
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div class="card-header">
                        {/* <h4 class="card-title"></h4>
                        <Link to="/admin/branch/create" class="bg-black text-white btn-sm fw-bold fs-4"><i class="fas fa-plus me-2"></i> Create Branch</Link> */}
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                              
                                    <div class="row form-material">
                                        <div class="col-xl-3 col-xxl-9 col-md-6 mb-3">
                                            <h3 class="card-title">Assign Class List</h3>
                                        </div>
                                    
                                    </div>
                                
                                <div class="table-responsive">
                                    <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Assign Class</th>
                                                <th>Assign Subject</th>
                                            
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>1</td>
                                                <td>LKG</td>
                                                <td>English</td>
                                               
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default ManageClass;