import React from 'react';
import { Link } from 'react-router-dom';
function Application_list() {
    return (<>
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div class="card-header">
                        <h4 class="card-title"></h4>
                        <Link to="/admin/application/create" class="bg-black text-white btn-sm fw-bold fs-4"><i class="fas fa-plus me-2"></i> Create Application</Link>
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <form>
                                    <div class="row form-material">
                                        <div class="col-xl-3 col-xxl-9 col-md-6 mb-3">
                                            <h3 class="card-title">Application List</h3>
                                        </div>
                                        <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                            <form class="d-flex" role="search">
                                                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                                {/* <button class="btn btn-outline-primary" type="submit">Search</button> */}
                                            </form>
                                        </div>
                                    </div>
                                </form>
                                <div class="table-responsive">
                                    <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Student Name</th>
                                                <th>Father's Name</th>
                                                <th>DOB</th>
                                                <th>Gender</th>
                                                <th>Class</th>
                                                <th>Mobile</th>
                                                <th>Address</th>
                                                <th>Created At</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>1</td>
                                                <td>Kittu </td>
                                                <td>Prakssh Jha</td>
                                                <td>02-10-2018</td>
                                                <td>Male</td>
                                                <td>U.K.G</td>
                                                <td>8675657777</td>
                                                <td>Noida Uttar Pradesh</td>
                                                <td>03-11-2023</td>
                                                <td>
                                                    <Link to="/admin/application/view/0002"><i class="bi bi-emoji-frown fs-3"></i></Link>{' '}
                                                    <Link to="/admin/application/edit/0001"><i class="bi bi-pencil-square fs-3"></i></Link>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>2</td>
                                                <td>Kittu </td>
                                                <td>Prakssh Jha</td>
                                                <td>02-10-2018</td>
                                                <td>Male</td>
                                                <td>U.K.G</td>
                                                <td>8675657777</td>
                                                <td>Noida Uttar Pradesh</td>
                                                <td>03-11-2023</td>
                                                <td>
                                                    <Link to="/admin/application/view/0002"><i class="bi bi-emoji-frown fs-3"></i></Link>{' '}
                                                    <Link to="/admin/application/edit/0001"><i class="bi bi-pencil-square fs-3"></i></Link>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>3</td>
                                                <td>Kittu </td>
                                                <td>Prakssh Jha</td>
                                                <td>02-10-2018</td>
                                                <td>Male</td>
                                                <td>U.K.G</td>
                                                <td>8675657777</td>
                                                <td>Noida Uttar Pradesh</td>
                                                <td>03-11-2023</td>
                                                <td>
                                                    <Link to="/admin/application/view/0002"><i class="bi bi-emoji-frown fs-3"></i></Link>{' '}
                                                    <Link to="/admin/application/edit/0001"><i class="bi bi-pencil-square fs-3"></i></Link>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>4</td>
                                                <td>Kittu </td>
                                                <td>Prakssh Jha</td>
                                                <td>02-10-2018</td>
                                                <td>Male</td>
                                                <td>U.K.G</td>
                                                <td>8675657777</td>
                                                <td>Noida Uttar Pradesh</td>
                                                <td>03-11-2023</td>
                                                <td>
                                                    <Link to="/admin/application/view/0002"><i class="bi bi-emoji-frown fs-3"></i></Link>{' '}
                                                    <Link to="/admin/application/edit/0001"><i class="bi bi-pencil-square fs-3"></i></Link>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>5</td>
                                                <td>Kittu </td>
                                                <td>Prakssh Jha</td>
                                                <td>02-10-2018</td>
                                                <td>Male</td>
                                                <td>U.K.G</td>
                                                <td>8675657777</td>
                                                <td>Noida Uttar Pradesh</td>
                                                <td>03-11-2023</td>
                                                <td>
                                                    <Link to="/admin/application/view/0002"><i class="bi bi-emoji-frown fs-3"></i></Link>{' '}
                                                    <Link to="/admin/application/edit/0001"><i class="bi bi-pencil-square fs-3"></i></Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <a class="page-link">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Application_list;