import React from 'react';

function Loader() {
    // Placeholder for loader rows
    const loaderRows = Array.from({ length: 5 }, (_, index) => (
        <tr key={index}>
            <td>
                <p className="placeholder-glow">
                    <span className="placeholder col-12"></span>
                </p>
            </td>
            <td>
                <p className="placeholder-glow">
                    <span className="placeholder col-12"></span>
                </p>
            </td>
            <td>
                <p className="placeholder-glow">
                    <span className="placeholder col-12"></span>
                </p>
            </td>
            <td>
                <p className="placeholder-glow">
                    <span className="placeholder col-12"></span>
                </p>
            </td>
            <td>
                <p className="placeholder-glow">
                    <span className="placeholder col-12"></span>
                </p>
            </td>
        </tr>
    ));

    // Placeholder for loader table body
    const loaderBody = (
        <tbody>
            {loaderRows}
        </tbody>
    );

    // Placeholder for loader table
    const loaderTable = (
        <div className="table-responsive">
            <table className="table text-nowrap table-hover border table-bordered">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                {loaderBody}
            </table>
        </div>
    );

    return loaderTable;
}

export default Loader;
