import React from 'react';
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../teacher-helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../Loader';
function StudentLeave() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const fetchList = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/Teacher/GetstudentleaveByteacher");
            setData(response.data.Payload);
        } catch (error) {
            window.location.href = "/";
        } finally {
            setLoading(false);
        }
    }

    const handleInputChange = async (e, ids) => {
        const selectedStatus = e.target.value;
        try {
            const response = await axiosInstance.post("/Teacher/ApproveLeavebyTeacher", { ids, type: selectedStatus });
            if (response.data.Status !== "success") {
                throw new Error("Failed to update leave status");
            }
            const updatedData = data.map(application => {
                if (application.id === ids) {
                    return { ...application, status: selectedStatus };
                } else {
                    return application;
                }
            });
            setData(updatedData);
            toast.success("Leave status updated successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            toast.error("Failed to update leave status", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    useEffect(() => {
        fetchList();
    }, []);
    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(data?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (<>
        <div className="container-fluid">
            <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Manage Leave</h4>
                </div>

                <div class="col-lg-12">
                    <div className="card custom-card mb-3">
                        <div className='card-header justify-content-between'>
                            <div class="card-title">Leave Application List</div>
                        </div>
                        <div class="card-body py-3 px-3">
                            <div class="table-responsive">
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th>Roll No.</th>
                                                    <th>Name</th>
                                                    <th>Class</th>
                                                    <th>Subject</th>
                                                    <th>Remark</th>
                                                    <th>Status</th>
                                                    <th>Apply Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data ?
                                                    data
                                                        .slice(
                                                            currentPage * dataPerPage,
                                                            (currentPage + 1) * dataPerPage
                                                        ).map((row) => (
                                                            <tr className="text-black fs-5" key={row.id}>
                                                                <td>{row.rollno}</td>
                                                                <td>{row.name}</td>
                                                                <td>{row.sclass}</td>
                                                                <td>{row.subject}</td>
                                                                <td>{row.remark}</td>
                                                                <td>
                                                                    <select
                                                                        className='form-control form-control'
                                                                        value={row.status}
                                                                        onChange={(e) => handleInputChange(e, row.id)}>
                                                                        <option value={0}>Process</option>
                                                                        <option value={1}>Approve</option>
                                                                        <option value={2}>Reject</option>
                                                                    </select>
                                                                </td>
                                                                <td>{row.createdate}</td>
                                                            </tr>
                                                        )) :
                                                    <p>No Data Available</p>}
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-end">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            style={{
                                                pointerEvents:
                                                    currentPage === 0 ? "none" : "auto",
                                                opacity: currentPage === 0 ? 0.5 : 1,
                                            }}
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            Previous
                                        </a>
                                    </li>
                                    {pageIndex
                                        .slice(
                                            Math.max(0, currentPage - 2),
                                            Math.min(numberOfPage, currentPage + 2)
                                        )
                                        .map((page) => (
                                            <li
                                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                    }`}
                                                key={page}
                                            >
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            page - 1 === currentPage
                                                                ? "none"
                                                                : "auto",
                                                        opacity: page - 1 === currentPage ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(page - 1)}
                                                    href="#"
                                                >
                                                    {page}
                                                </a>
                                            </li>
                                        ))}
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            style={{
                                                pointerEvents:
                                                    currentPage === numberOfPage - 1
                                                        ? "none"
                                                        : "auto",
                                                opacity:
                                                    currentPage === numberOfPage - 1 ? 0.5 : 1,
                                            }}
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            Next
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default StudentLeave;