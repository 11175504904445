import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
import { toast } from "react-toastify";
function Library() {
  const [bookdata, setBookData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [slabData, setSlabData] = useState([])
  const [classData, setClassData] = useState([])
  const [editingId, setEditingId] = useState(null)
  const [libraryData, setLibraryData] = useState({
    id:"",
   slab:"",
   sclass:"",
   Bookno:"",
   Bookname:""
 });
  const history = useNavigate();

  const handleInputChange = (e, id, key) => {
    const newData = bookdata.map((item) =>
        item.id === id ? { ...item, [key]: e.target.value } : item
    );
    const EditData = bookdata.find((b)=>{
     return b.id === id
    })
    setLibraryData((prev) => ({ ...prev, id: id,slab: EditData.slab, sclass: EditData.sclass, Bookno: EditData.bookno ,Bookname: EditData.bookname }));
    setLibraryData((prev)=>({ ...prev, id: id,  [key]: e.target.value, Bookno : key === "bookno" ? e.target.value : EditData.bookno, Bookname : key === "bookname" ? e.target.value : EditData.bookname}))
    setBookData(newData);
};

  const fetchBookData = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get("/Admin/GetStudentLibraryBook");
      setBookData(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
      history("/");
    }finally{
      setTableLoading(false);
    }
    };

    //slab data
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/Master/GetLibrarySlab");
      setSlabData(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      history("/");
    }
  };
  useEffect(() => {
    fetchData();
    fetchClassData();
  }, []);

  //class data
  const fetchClassData = async () => {
    try {
      const response = await axiosInstance.get("/Master/GetClass");
      setClassData(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
      window.location.href = "/";
    }
  };
    useEffect(() => {
        fetchBookData();
    }, []);

    const handleEditClick = (id) => {
        setEditingId(id);
    };
    const handleSaveClick = async(e) => {
      e.preventDefault();
    setEditingId(null);
    try {
      const response = await axiosInstance.post(
        "/Master/UpdateLibraryBooks",
        libraryData
      );
      if (!response.data.Status === "successs") {
        throw new Error("Book deails updated failed");
      }
      toast.success("Book deails updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error("Book deails updated failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    };
    const handleCancelClick = () => {
        setEditingId(null);
    };


    // -- pagination Code -- //

    const dataPerPage = 10;

    const [currentPage, setCurrentPage] = useState(0);

    const numberOfPage = Math.ceil(bookdata?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Library</h4>
            <div class="flex flex-wrap gap-2">
              <Link
                className="btn btn-primary"
                to={"/admin/master/manage-book/manage-book-system"}
              >
                Add New Book{" "}
                <span class="btn-icon-end">
                  <i class="fa fa-solid fa-plus"></i>
                </span>
              </Link>
            </div>
          </div>
          <div class="col-md-12">
            <div className="card custom-card mb-3">
              <div className="card-header justify-content-between">
                <div class="card-title">Book List</div>
                <div class="flex flex-wrap gap-2">
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    placeholder="Search Here"
                    aria-label=".form-control-sm example"
                  />
                </div>
              </div>

              <div class="card-body py-3 px-3">
              {tableLoading ? (
                  <div class="table-responsive">
                    <table class="table text-nowrap table-hover border table-bordered">
                      <thead class="">
                        <tr>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                <div class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead className="">
                      <tr>
                        <th>#</th>
                        <th>Slab No.</th>
                        <th>Class Name</th>
                        <th>Book No</th>
                        <th>Book Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        {bookdata?.slice(
                                currentPage * dataPerPage,
                                (currentPage + 1) * dataPerPage
                              ).map((row) => (
                            <tr className="text-black fs-5" key={row.id}>
                                <td>{row.id}</td>
                                <td>{editingId === row.id ? (<select type="text" className='form-control form-control-sm' value={row.slab} name="slab" onChange={(e) => handleInputChange(e, row.id, 'slab')} >{slabData.map((s)=> <option>{s.slab}</option>)}</select>) : (row.slab)}</td>
                                <td>{editingId === row.id ? (<select type="text" className='form-control form-control-sm' value={row.sclass} name="sclass" onChange={(e) => handleInputChange(e, row.id, 'sclass')} >{classData.map((c)=> <option>{c.sclass}</option>)}</select>) : (row.sclass)}</td>
                                <td>{editingId === row.id ? (<input type="text" className='form-control form-control-sm' value={row.bookno} name="bookno" onChange={(e) => handleInputChange(e, row.id, 'bookno')} />) : (row.bookno)}</td>
                                <td>{editingId === row.id ? (<input type="text" className='form-control form-control-sm' value={row.bookname} name="bookname" onChange={(e) => handleInputChange(e, row.id, 'bookname')} />) : (row.bookname)}</td>
                                <td>{editingId === row.id ? (<>

                                    <div class="d-flex">
                                        <span class="btn btn-primary shadow btn-xs sharp me-1"  onClick={handleSaveClick}> <i class="bi bi-check2-all"></i></span> {' '}
                                        <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span></div>
                                    </>
                                ) : (
                                    <span class="btn btn-primary shadow btn-xs me-1" onClick={() => handleEditClick(row.id)}><i class="bi bi-pencil-square"></i> Edit</span>
                                )}

                                
                                </td>
                            </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <nav aria-label="Page navigation example">
                      <ul class="pagination justify-content-end">
                        <li class="page-item">
                          <a
                            class="page-link"
                            style={{
                              pointerEvents:
                                currentPage === 0 ? "none" : "auto",
                              opacity: currentPage === 0 ? 0.5 : 1,
                            }}
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            Previous
                          </a>
                        </li>
                        {pageIndex
                          .slice(
                            Math.max(0, currentPage - 2),
                            Math.min(numberOfPage, currentPage + 2)
                          )
                          .map((page) => (
                            <li
                              class={`page-item ${
                                page === currentPage + 1 ? "active" : ""
                              }`}
                              key={page}
                            >
                              <a
                                class="page-link"
                                style={{
                                  pointerEvents:
                                    page - 1 === currentPage ? "none" : "auto",
                                  opacity: page - 1 === currentPage ? 0.5 : 1,
                                }}
                                onClick={() => handlePageChange(page - 1)}
                              >
                                {page}
                              </a>
                            </li>
                          ))}
                        <li class="page-item">
                          <a
                            class="page-link"
                            style={{
                              pointerEvents:
                                currentPage === numberOfPage - 1
                                  ? "none"
                                  : "auto",
                              opacity:
                                currentPage === numberOfPage - 1 ? 0.5 : 1,
                            }}
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    </>)}
              </div>
               
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Library;
