import React, { useEffect } from 'react';

function PreLoader() {
    useEffect(() => {
        const hideLoader = () => {
            document.getElementById('preloader').style.display = 'none';
        };

        // Hide the loader after 2000 milliseconds (2 seconds)
        const timeout = setTimeout(hideLoader,1800);

       
        return () => clearTimeout(timeout);
    }, []);

    return (
        <section>
            <div id="preloader">
                <div id="ctn-preloader" className="ctn-preloader">
                    <div className="animation-preloader">
                        <div className="spinner"></div>
                        <div className="txt-loading">
                            <span data-text-preloader="M" className="letters-loading">M</span>
                            <span data-text-preloader="N" className="letters-loading">N</span>
                            <span data-text-preloader="B" className="letters-loading">B</span>
                            <span data-text-preloader="S" className="letters-loading">S</span>
                            <span data-text-preloader="C" className="letters-loading">C</span>
                            <span data-text-preloader="H" className="letters-loading">H</span>
                            <span data-text-preloader="O" className="letters-loading">O</span>
                            <span data-text-preloader="O" className="letters-loading">O</span>
                            <span data-text-preloader="L" className="letters-loading">L</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PreLoader;
