import React from 'react';
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
function View_application() {

    const location = useLocation();
    const [isSelect5, setIsSelect5] = useState();
    useEffect(() => {
        if (location.pathname == '/admin/application/view/0002') {
            setIsSelect5(true);
        } else {
            setIsSelect5(false);
        }
    });
    const path = document.location.pathname;

    return (<>
        <div>
            <div className="container-fluid">
                <div className="row pt-4">
                <h3 class="text-center">
                    {path === '/admin/application/view/0002' ? "Application Details" : "Student Details"}
                    </h3>
                </div>
                <div class="row pt-4">
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header border-0 pb-2">
                                        <h4 class="fs-20 mb-0">Student Details 11</h4>
                                    </div><hr />
                                    <div class="card-body pt-0">
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Full Name:</h5>
                                            <span>Kittu</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">DOB:</h5>
                                            <span>02-10-2018</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Gender:</h5>
                                            <span>Male</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Academic Session:</h5>
                                            <span>2022-2023</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Class:</h5>
                                            <span>II</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Stream:</h5>
                                            <span></span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Section:</h5>
                                            <span>A</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">House:</h5>
                                            <span>Anupama House</span>
                                        </div>
                                        {/* <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Transport Slab:</h5>
                                            <span></span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Area:</h5>
                                            <span>Anupama Area</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">City:</h5>
                                            <span>Noida</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Pincode:</h5>
                                            <span>454545</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">State:</h5>
                                            <span>U.P</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Address:</h5>
                                            <span>Noida</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Remark:</h5>
                                            <span>Test</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body pb-4">
                                        <h4 class="fs-20 mb-3">Student Contact Details</h4>
                                        <div class=""><hr />
                                            <p class="font-w500 mb-3"><span class="custom-label">Mobile :</span><span class="font-w400"> 1234567890</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Alternate Number :</span><span class="font-w400"> 0987654321</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Primary Contact :</span><span class="font-w400"> Father</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Email :</span><span class="font-w400"> admin@gmail.com</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Country : </span><span class="font-w400"> India</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">State : </span><span class="font-w400"> Uttar Pradesh</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">City : </span><span class="font-w400"> Noida</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Pincode : </span><span class="font-w400"> 000011</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Address : </span><span class="font-w400"> Sector-135, Lovely Colony Noida</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body pb-4">
                                        <h4 class="fs-20 mb-3">Student Documents</h4>
                                        <div class=""><hr />
                                            <p class="font-w500 mb-3"><span class="custom-label">Aadhar No :</span><span class="font-w400"> 7457456456457</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Aadhar Photo :</span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Transfer Certificate No :</span><span class="font-w400"> 423423423</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Transfer Certificate :</span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Birth Certificate No : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Birth Certificate  : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Student Photo : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Blood Group  : </span><span class="font-w400">A+</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">History of major illness : </span><span class="font-w400"></span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Allergies to medicine and food : </span><span class="font-w400"></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body pb-4">
                                        <h4 class="fs-20 mb-3">Father's Details</h4>
                                        <div class=""><hr />
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Name :</span><span class="font-w400"> Ramesh Yadav</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Mobile :</span><span class="font-w400"> 0987654321</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Email :</span><span class="font-w400"> admin@gmail.com</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's nature of work :</span><span class="font-w400"> Job</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Employer : </span><span class="font-w400"> Government</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Sector  : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Firm / Department : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Designation  : </span><span class="font-w400"></span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Aadhar No. : </span><span class="font-w400"> 098765432123</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Pan No. : </span><span class="font-w400">YG534534545</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Father's Aadhar No. : </span><span class="font-w400"> 098765432123</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body pb-4">
                                        <h4 class="fs-20 mb-3">Mother's Details</h4>
                                        <div class=""><hr />
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Name :</span><span class="font-w400"> Radha Yadav</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Mobile :</span><span class="font-w400"> 0987654321</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Email :</span><span class="font-w400"> admin@gmail.com</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's nature of work :</span><span class="font-w400"> Job</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Employer : </span><span class="font-w400"> Government</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Sector  : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Firm / Department : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Designation  : </span><span class="font-w400"></span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Aadhar No. : </span><span class="font-w400"> 098765432123</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Pan No. : </span><span class="font-w400">YG534534545</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mother's Aadhar No. : </span><span class="font-w400"> 098765432123</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body pb-4">
                                        <h4 class="fs-20 mb-3">Guardian Details</h4>
                                        <div class=""><hr />
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian relationship :</span><span class="font-w400">Son</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Name :</span><span class="font-w400"> Ramesh Yadav</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian's Email :</span><span class="font-w400"> admin@gmail.com</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Mobile :</span><span class="font-w400"> 5654645645</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian nature of work : </span><span class="font-w400"> Job</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Employer  : </span><span class="font-w400"> Government</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Sector : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Firm / Department  : </span><span class="font-w400"></span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Designation  : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Aadhar No. : </span><span class="font-w400">098765432123</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Pan No. : </span><span class="font-w400">YG534534545 </span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header border-0 pb-2">
                                        <h4 class="fs-20 mb-0">Transport Details</h4>
                                    </div><hr />
                                    <div class="card-body pt-0">
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Transport Slab:</h5>
                                            <span></span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Area:</h5>
                                            <span>Anupama Area</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">City:</h5>
                                            <span>Noida</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Pincode:</h5>
                                            <span>454545</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">State:</h5>
                                            <span>U.P</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Address:</h5>
                                            <span>Noida</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Remark:</h5>
                                            <span>Test</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header border-0 pb-2">
                                        <h4 class="fs-20 mb-0">Payment Details</h4>
                                    </div><hr />
                                    <div class="card-body pt-0">
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Amount : </h5>
                                            <span>2000</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Method : </h5>
                                            <span>Online</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Transaction ID : </h5>
                                            <span>6456bfg4354</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Remitter Name : </h5>
                                            <span></span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Minimum Application Fee : </h5>
                                            <span>1000</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Start Month : </h5>
                                            <span>Jan</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Remark:</h5>
                                            <span>Test</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default View_application;