import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import html2pdf from "html2pdf.js";
function Fee_History() {
  const { studentid } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [modalData, setModalData] = useState({});
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `/ManageFees/GetMonthlyWisePaymentDetail?studentid=${studentid}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      window.location.href = "/";
    }
  };


  const fetchModalData = async (month) => {
    try {
      const response = await axiosInstance.get(
        `/ManageFees/GetMonthlyPaymentReciept?studentid=${studentid}&month=${month}`
      );
      setModalData(response.data);
    } catch (error) {
      toast.error("Authentication failed !");
    }
  };
  const generatePDF = (modalid, month) => {
    fetchModalData(month)
      .then(() => {
        setTimeout(() => {
          const content = document.getElementById(modalid);
          const pdfOptions = { filename: "receipt.pdf" };
          html2pdf(content, pdfOptions);
        }, 0);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    fetchData();
  },[]);

  return (
    <>
      <div className="mt-4">
        <div className="container-fluid">
          <div className="card-header">
            <h4 class="card-title">Fee History</h4>
            <div
              class="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabindex="-1"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
                      Admission Fees receipt
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    id="html-onetime-pdf"
                    style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Fee Name</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody class="table-group-divider">
                        {modalData.FeeDetail ? (
                          modalData.FeeDetail.map((row) => {
                            return (
                              <tr>
                                <td>{row.fee_name}:</td>
                                <td>{row.paid_amount}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <p>Not Paid</p>
                        )}
                        <tr>
                          <td>Total Amount:</td>
                          <td>{modalData.totalfeeamount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="container text-center">
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() =>
                        generatePDF("html-to-pdf-content", "OneTime")
                      }
                    >
                      PDF Download
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <button
              class="btn btn-primary text-white btn-sm fs-4 shadow bg-body-tertiary rounded"
              onClick={() => fetchModalData("OneTime")}
              data-bs-target="#exampleModalToggle"
              data-bs-toggle="modal"
            >
              Admission Fees Receipt
            </button>
          </div>
          <div className="row">
            <div class="col-lg-12 pt-4">
              {loading ? (
                <div
                  className="container text-center"
                  style={{ paddingTop: "150px", paddingBottom: "130px" }}
                >
                  <div class="spinner-grow text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-info" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-dark" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div class="row">
                  {months.map((month) => (
                    <div key={month} className="col-lg-3">
                      <div className="card shadow bg-body-tertiary rounded">
                        <h5 className="card-header">
                          {month}{" "}
                          <span
                            type="button"
                            onClick={() =>
                              generatePDF("html-to-pdf-content", month)
                            }
                            defaultValue={month}
                          >
                            {data[month] ? <i class="bi bi-cloud-download fs-5"></i> : ''}
                          </span>
                        </h5>
                        {/* <div class="card-body py-2 px-2">
                          <div class="table-responsive">
                            <table class="table text-nowrap table-hover border table-bordered">
                              <thead className="">
                                <tr>
                                  <th>Fee Name</th>
                                  <th>Fee</th>
                                  <th>Discount</th>
                                  <th>Paid</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data[month] ? (
                                  data[month].map((row) => (
                                    <tr className="text-black fs-5" key={row.fee_name}>
                                      <td>{row.fee_name}</td>
                                      <td>{row.fee_amount}</td>
                                      <td>{row.discount_amount}%</td>
                                      <td>{row.paid_amount}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <p class="text-red">No data available</p>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                        <ul className="list-group list-group-flush">
                          {data[month] ? (
                            data[month].map((row) => (
                              <li
                                key={row.fee_name}
                                className="list-group-item"
                              >
                                <h5 className="mb-1 fs-14 custom-label">
                                  {row.fee_name} :
                                </h5>
                                <span>{row.fee_amount}</span>
                              </li>
                            ))
                          ) : (
                            <p className="text-center text-red">
                              Not paid
                            </p>
                          )}
                        </ul>
                      </div>
                    </div>
                  ))}
                  {/* receipt section */}
                  <div>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="card-header">
                          {/* <h3 class="me-auto p-2 text-center">Fee Receipt</h3> */}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-3 col-xxl-12 ">
                          {/* <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button class="btn btn-primary me-md-2" type="button" onClick={generatePDF}>Download Receipt</button>
                        </div> */}
                          <div
                            class="modal fade"
                            id="exampleModalToggle"
                            aria-hidden="true"
                            aria-labelledby="exampleModalToggleLabel"
                            tabindex="-1"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="row" id="html-to-pdf-content">
                                {/* <div class="col-xl-3"></div> */}
                                <div
                                  class="col-xl-12"
                                  style={{
                                    paddingLeft: "5rem",
                                    paddingRight: "5rem",
                                  }}
                                >
                                  <div class="row pt-5 ">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <h6>&nbsp;From,</h6>
                                        <img src="/images/MNB_logo.png" className="invoice-logo" alt="MNB Logo" />
                                        <h6 class="fw-bold mt-2 mb-0">MNB Public School</h6>
                                        <p class="ms-1">D-160, D Block, Sector 10,<br></br> Noida, Uttar Pradesh - 201301 <br />
                                        </p>
                                      </div>
                                      <div class="col-md-6 text-end">
                                        <address>
                                          <h6>Student Detail</h6>
                                          <h6 class="fw-bold invoice-customer">
                                            {" "}  {" "}{modalData.StudentName}
                                          </h6>
                                          <p class="font-w500 mb-1"> Roll No. :{" "}{modalData.RollNo}</p>
                                          <p class="font-w500 mb-1"> Class :{" "}{modalData.Class}</p>
                                          <p class="mt-4 mb-1">
                                            <span>Receipt No. :{" "}</span>
                                            <i class="ti ti-calendar"></i>
                                            {" "}
                                            {modalData.ReceiptNo}
                                          </p>
                                          <p>
                                            <span> Month :{" "}</span>
                                            <i class="ti ti-calendar"></i>
                                            {" "}
                                            {modalData.Month}
                                          </p>
                                        </address>
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                      <div class="table-responsive mt-5">
                                        <table class="table table-hover">
                                          <thead>
                                            <tr>
                                              <th scope="col">#</th>
                                              <th scope="col">Particulars</th>
                                              <th scope="col" class="text-end">Amount</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {modalData.FeeDetail ? (
                                              modalData.FeeDetail.map((row, index) => (
                                                <tr class="text-black fs-5">
                                                  <th scope="row">{index + 1}</th>
                                                  <td>{row.fee_name}</td>
                                                  <td class="text-end">{row.fee_amount}</td>
                                                </tr>
                                              ))
                                            ) : (
                                              <p>No data available</p>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                      <div class="pull-right text-end">
                                        <h5><b>Total Amount :</b> &nbsp;{modalData.totalfeeamount}</h5>
                                      </div>
                                      <div class="clearfix"></div>
                                      <hr />
                                      <div class="text-end">
                                        <p class="pt-4 text-end">Signature</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div class="col-xl-3"></div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fee_History;
