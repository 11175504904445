import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
const StudentAttendanceCalendar = () => {
    // const { month, studentid } = useParams();
    const { month: initialMonth, studentid } = useParams();
    const [selectedMonth, setSelectedMonth] = useState(Number(initialMonth));
    const [currentMonth, setCurrentMonth] = useState(() => {
        const date = new Date();
        date.setMonth(selectedMonth - 1);
        return date;
    });

    const dateWithMonth = new Date(new Date().getFullYear(), selectedMonth - 1);
    const [attendance, setAttendance] = useState({});
    const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        return new Date(year, month + 1, 0).getDate();
    };

    const getMonthDaysArray = (date) => {
        const daysInMonth = getDaysInMonth(date);
        const daysArray = [];
        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push(i);
        }
        return daysArray;
    };

    const toggleAttendance = (day) => {
        const newAttendance = { ...attendance };
        newAttendance[day] = !newAttendance[day];
        setAttendance(newAttendance);
    };

    const renderCalendar = () => {
        const daysArray = getMonthDaysArray(currentMonth);
        const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
        const blanks = [];
        for (let i = 0; i < firstDayOfMonth; i++) {
            blanks.push(<td key={`blank-${i}`}></td>);
        }

        const calendarCells = blanks.concat(
            daysArray.map((day, index) => {
                const attendanceDataForDay = data ? data.find(item => new Date(item.date).getDate() === day) : null;
                const isAttended = attendanceDataForDay ? attendanceDataForDay.attendance === 'Present' : false;
                const isAbsent = attendanceDataForDay ? attendanceDataForDay.attendance === 'Absent' : false;
                const isSunday = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day).getDay() === 0;

                let cellStyle = {};
                if (isAttended) {
                    cellStyle.backgroundColor = '#4ec53b';
                } else if (isAbsent) {
                    cellStyle.backgroundColor = '#f33737';
                } else if (isSunday) {
                    cellStyle.backgroundColor = 'orange';
                } else if (!isAttended) {
                    cellStyle.backgroundColor = '#f5f5f5';
                }

                return (
                    <td key={`day-${index}`} onClick={() => toggleAttendance(day)} style={cellStyle}>
                        {day}
                        <br />
                        <span style={{ fontSize: '12px', fontWeight: 'bold', color: isAttended ? 'white' : (isSunday ? 'white' : 'white') }}>
                            {isAttended ? 'Present' : (isAbsent ? 'Absent' : (isSunday ? 'Holiday' : ''))}
                        </span>
                    </td>
                );
            })
        );


        const weeks = [];
        let week = [];
        calendarCells.forEach((cell, index) => {
            if (index % 7 === 0 && index !== 0) {
                weeks.push(<tr key={`week-${weeks.length}`}>{week}</tr>);
                week = [];
            }
            week.push(cell);
        });
        weeks.push(<tr key={`week-${weeks.length}`}>{week}</tr>);
        return weeks;
    };

    const [data, setData] = useState([]);
    const fetchAttendanceData = async (month) => {
        try {
            const response = await axiosInstance.get(`/Admin/GetAttendanceDaywise?studentid=${studentid}&&month=${month}`);
            setData(response.data.Payload);
        } catch (error) {
            // window.location.href = "/";
        }
    }
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState();
    const fetchTotal = async (month) => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/admin/GetCountAbsentPresent?studentid=${studentid}&&month=${month}`);
            setTotal(response.data);
        } catch (error) {
            window.location.href = "/";
        } finally {
            setLoading(false);
        }
    }
   
    const handleMonthChange = (e) => {
        const selectedMonth = parseInt(e.target.value);
        setSelectedMonth(selectedMonth);
        setCurrentMonth(new Date(new Date().getFullYear(), selectedMonth - 1));
        fetchAttendanceData(selectedMonth);
        fetchTotal(selectedMonth);
    };

    useEffect(() => {
        fetchAttendanceData(selectedMonth);
        fetchTotal(selectedMonth);
    }, [selectedMonth]);


    return (
        <div>
            <div className="col-lg-12 pt-5 px-5">
                {loading ? (
                    <div
                        className="container text-center"
                        style={{ paddingBottom: "150px", paddingTop: "200px" }}
                    >
                        <div class="spinner-grow text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-info" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="card custom-card mb-3">
                        <div class="card-header justify-content-between">
                            <div className="card-title">  {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })} attendance {data && data.length > 0 ? data[0].name : ''}</div>
                            <div className="col-xl-3 col-xxl-3 col-md-6 col-6 mb-3">
                                {/* <label className="form-label">Month</label> */}
                                <select className="form-control" id="autoSizingSelect" value={selectedMonth} onChange={handleMonthChange}>
                                    <option value={1}>Jan</option>
                                    <option value={2}>Feb</option>
                                    <option value={3}>Mar</option>
                                    <option value={4}>Apr</option>
                                    <option value={5}>May</option>
                                    <option value={6}>Jun</option>
                                    <option value={7}>Jul</option>
                                    <option value={8}>Aug</option>
                                    <option value={9}>Sep</option>
                                    <option value={10}>Oct</option>
                                    <option value={11}>Nov</option>
                                    <option value={12}>Dec</option>
                                </select>
                            </div>
                            {total && (
                                <div class="flex flex-wrap gap-2">
                                    Present  <span class="badge badge-xs badge-primary">{total.Present ?? ''}</span>{" "}
                                    Absent <span class="badge badge-xs badge-danger">{total.Absent ?? ''}</span>
                                </div>
                            )}
                        </div>
                        <div className="table-responsive">
                            <table className="table table table-bordered">
                                <thead className="">
                                    <tr>
                                        <th>Sun</th>
                                        <th>Mon</th>
                                        <th>Tue</th>
                                        <th>Wed</th>
                                        <th>Thu</th>
                                        <th>Fri</th>
                                        <th>Sat</th>
                                    </tr>
                                </thead>
                                <tbody>{renderCalendar()}</tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StudentAttendanceCalendar;
