import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Loader from "../../Loader"
function Teacher_list() {
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [getdata, setGetData] = useState([]);
  const [data, setData] = useState(
    {
      name:"",
      mobile:""
    }
  );
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`/Admin/GetAllTeachersDetail`,data);
      // setData(response.data.Payload);
      setGetData(response.data.Payload);
      setOriginalData(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [data]);


  // -- pagination Code -- //

  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPage = Math.ceil(data?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const changeBlock = async (teacherID, blockstatus) => {
    try {
      const response = await axiosInstance.post("/Teacher/BlockTeacher", {
        teacherid: teacherID,
        type: blockstatus
      });
      if (response.data.Status === "success") {
        toast.success("Teacher's status update successfully!");
      } else {
        toast.error("Failed to update teacher's status.");
      }
    } catch (error) {
      toast.error("Failed to update teacher's status.");
    } finally {
      fetchData();
    }
  };

  const searchChange = (e) => {
    const { value } = e.target;
    const filteredData = originalData.filter((item) => {
      return Object.values(item).some(
        (val) => val && val.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    });
    setGetData(filteredData);
  };

  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div class="card-header">
              <h4 class="card-title">Manage Teacher</h4>
              <div class="flex flex-wrap gap-2">
                <Link className="btn btn-primary" to="/admin/teachers/create"> Add Teacher<span class="btn-icon-end"><i class="fa fa-solid fa-plus"></i></span></Link>
                &nbsp;<Link className="btn btn-primary" to="/admin/teachers/block-list">Blocked Teacher<span class="btn-icon-end"><i class="fa fa-solid fa-list"></i></span></Link>
              </div>
            </div>
            <div class="col-lg-12">
              <div className="card custom-card mb-3">
                <div className='card-header justify-content-between'>
                  <div class="card-title">Teacher List</div>
                  <div className="flex flex-wrap gap-2">
                    <input
                      className="form-control form-control-sm"
                      onChange={searchChange}
                      type="search"
                      placeholder="Search Here..."
                      aria-label=".form-control-sm example"
                    />
                  </div>
                </div>
                <div class="card-body py-3 px-3">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Teacher ID</th>
                              <th>Name</th>
                              <th>Email ID</th>
                              <th>Mobile No</th>
                              <th>Incharge Class</th>
                              <th>Subject Handling</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getdata ? (
                              getdata.slice(
                                currentPage * dataPerPage,
                                (currentPage + 1) * dataPerPage
                              ).map((row,index) => {
                                return (
                                  <tr className="text-black fs-5" key={row.id}>
                                    <td>{currentPage * dataPerPage + index + 1}</td>
                                    <td>{row.teacherid}</td>
                                    <td>{row.name}</td>
                                    <td>{row.emailid}</td>
                                    <td>{row.mobileno}</td>
                                    <td>{row.incharge_class}</td>
                                    <td>{row.subject_handling}</td>
                                    <td>
                                      <Link class="btn btn-warning btn-sm content-icon"
                                      title="view teacher's details"
                                        to={`/admin/teachers/view/${row.teacherid}`}
                                      >
                                          <i className="fa fa-eye"></i>
                                      </Link>{" "}
                                      <Link class="btn btn-primary btn-sm content-icon"
                                      title="edit teacher's details"
                                        to={`/admin/teachers/edit/${row.teacherid}/${row.id}`}
                                      >
                                        <i class="bi bi-pencil-square"></i>
                                      </Link>
                                      &nbsp;
                                      {row.blockstatus === 0 ? (
                                        <Link class="btn btn-danger btn-sm content-icon" title="change teacher's status" onClick={() => changeBlock(row.teacherid, row.blockstatus === 0 ? 1 : 0)}>
                                        
                                          <i class="bi bi-lock"></i>
                                        </Link>
                                      ) : (
                                        <Link class="btn btn-primary btn-sm content-icon" onClick={() => changeBlock(row.teacherid, row.blockstatus === 1 ? 0 : 1)}>
                                          <i class="bi bi-unlock"></i>
                                        </Link>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <p>No data available</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end">
                          <li class="page-item">
                            <a
                              class="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === 0 ? "none" : "auto",
                                opacity: currentPage === 0 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              Previous
                            </a>
                          </li>
                          {pageIndex
                            .slice(
                              Math.max(0, currentPage - 2),
                              Math.min(numberOfPage, currentPage + 2)
                            )
                            .map((page) => (
                              <li
                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                  }`}
                                key={page}
                              >
                                <a
                                  class="page-link"
                                  style={{
                                    pointerEvents:
                                      page - 1 === currentPage
                                        ? "none"
                                        : "auto",
                                    opacity: page - 1 === currentPage ? 0.5 : 1,
                                  }}
                                  onClick={() => handlePageChange(page - 1)}
                                 
                                >
                                  {page}
                                </a>
                              </li>
                            ))}
                          <li class="page-item">
                            <a
                              class="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === numberOfPage - 1
                                    ? "none"
                                    : "auto",
                                opacity:
                                  currentPage === numberOfPage - 1 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Teacher_list;
