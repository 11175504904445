import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
function View_application() {
  const { studentid } = useParams();
  const [attendance, setAttendance] = useState();
  const [transaction, setTransaction] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState([]);
  const [father, setFather] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [mother, setMother] = useState([]);
  const [Guardian, setGuardian] = useState([]);
  const [Medical, setMedical] = useState([]);
  const [otherDetails, setOtherDetails] = useState([]);
  const [academic, setAcademic] = useState([]);
  const [transport, setTransport] = useState([]);
  const [registration, setRegistration] = useState([]);
  // const [plus, setPlus] = useState({
  //   collapseOne: false,
  //   collapseTwo: false,
  //   collapseThree: false,
  //   collapseFour: false,
  //   collapseFive: false,
  //   collapseSix: false,
  //   collapseSeven: false,
  //   collapseEight: false,
  //   collapseNine: false,
  //   collapseTen: false,
  // });
  const fetchAttendance = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/Admin/GetCountAttandance?studentid=${studentid}`);
      setAttendance(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  }
  const [studentId, setStudentId] = useState([]);
  const fetchStudent = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/Student/Getstudentdetail?studentid=${studentid}`
      );
      setStudentId(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAttendance();
    fetchStudent();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/student/GetStudentInformation?studentid=${studentid}`
      );
      setData(response.data);
      setRegistration(
        response.data.RegistrationDetail ? response.data.RegistrationDetail[0] : {}
      );
      setStudent(
        response.data.StudentDetail ? response.data.StudentDetail[0] : {}
      );
      setTransport(
        response.data.TransportDetail ? response.data.TransportDetail[0] : {}
      );
      setFather(
        response.data.FatherDetail ? response.data.FatherDetail[0] : {}
      );
      setAcademic(
        response.data.AcademicDetail ? response.data.AcademicDetail[0] : {}
      );
      setMother(
        response.data.MotherDetail ? response.data.MotherDetail[0] : {}
      );
      setGuardian(
        response.data.GuardienDetail ? response.data.GuardienDetail[0] : {}
      );
      setMedical(
        response.data.MedicalDetail ? response.data.MedicalDetail[0] : {}
      );
      setOtherDetails(
        response.data.OtherDetail ? response.data.OtherDetail[0] : {}
      );
      setDocuments(response.data.Document ? response.data.Document[0] : {});
    } catch (error) {
      // console.error("Error fetching data:", error);
      window.location.href = "/";
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchTransactionID = async () => {
    try {
      const response = await axiosInstance.get(
        `/Student/GetOneTimeTransactionNo?studentid=${studentid}`
      );
      setTransaction(response.data.transactionstatus);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchTransactionID();
  }, []);
  const path = document.location.pathname;
  // const handlePlusClick = (collapseId) => {
  //   setPlus((prevPlus) => ({
  //     ...prevPlus,
  //     [collapseId]: !prevPlus[collapseId],
  //   }));
  // };

  const convertStudent = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/student/StudentRegister", {
        studentid: studentid,
      });
      if (!response.data.Status === "success") {
        throw new Error("Convert student failed");
      }
      toast.success("Convert student successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
    } catch (error) {
      toast.error("Convert student failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <>
      <div>
        {loading ? (
          <div
            className="container text-center"
            style={{ paddingTop: "100px" }}
          >
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-info" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-dark" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div class="card-header">
                <h4 class="card-title">Student's Details</h4>
                <div class="flex flex-wrap gap-2">
                  {transaction !== "success" ? (
                    <Link to={`/admin/students/payment/${studentid}`} className="btn btn-primary">
                      <span><i className="la la-rupee fs-4"></i>
                      </span>{" "}One Time Fee
                      <span className="btn-icon-end"><i className="fa fa-solid fa-plus"></i></span>
                    </Link>
                  ) : (
                    !student.studentid ? (
                      <Link to="/admin/student/list" onClick={convertStudent} className="btn btn-primary">
                        Convert student
                        <span className="btn-icon-end">
                          <i className="fa fa-solid fa-plus"></i>
                        </span>
                      </Link>
                    ) : (
                      ''
                    )
                  )}
                </div>
              </div>
              {!student.studentid && registration.id ? (
                <div class="col-xl-9">
                  <div class="card">
                    <div class="card-body pt-4">
                      <h4 class="card-title">Registration Detail</h4>
                      <div class="row">
                        <div class="col-xl-6 col-md-6">
                          <ul class="list-style-1">
                            <li><label class="custom-label-2 mb-0">Name : </label>{registration.student_name ? registration.student_name.split("-").reverse().join("-") : '---'}</li>
                            <li><label class="custom-label-2 mb-0">Father's Name : </label>{registration.father_name ? registration.father_name : '---'}</li>
                            <li><label class="custom-label-2 mb-0">Mobile No. : </label>{registration.mobile ? registration.mobile : '---'}</li>
                            <li><label class="custom-label-2 mb-0">Email ID : </label>{registration.email ? registration.email : '---'}</li>
                            <li><label class="custom-label-2 mb-0">DOB : </label>{registration.dob ? registration.dob : '---'}</li>
                          </ul>
                        </div>
                        <div class="col-xl-6 col-md-6">
                          <ul class="list-style-1">
                            <li><label class="custom-label-2 mb-0">Gender : </label>{registration.gender ? registration.gender : '---'}</li>
                            <li><label class="custom-label-2 mb-0">Address : </label>{registration.address ? registration.address : '---'}</li>
                            <li><label class="custom-label-2 mb-0">City : </label>{registration.city ? registration.city : '---'}</li>
                            <li><label class="custom-label-2 mb-0">State  : </label>{registration.state ? registration.state : '---'}</li>
                            <li><label class="custom-label-2 mb-0">Pincode : </label>{registration.pincode ? registration.pincode : '---'}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
              
                <div class="col-xl-9">
                <div class="card">
                  <div class="card-header border-0 flex-wrap align-items-start">
                    <div class="col-md-8">
                      <div class="user d-sm-flex d-block pe-md-5 pe-0">
                        <img src={documents.student_pic ? `https://schoolapi.mnbsoft.co.in/UploadImage/${documents.student_pic}` : '/images/user.png'} alt="" />
                        <div class="ms-sm-3 ms-0 me-md-5 md-0">
                          <h5 class="mb-1 font-w500 fs-4 text-black">{student.name}</h5>
                          <div class="listline-wrapper mb-2">
                            <span class="item">Class: {academic.sclass}</span>
                            <span class="item">Section : {academic.section}</span>
                            <span class="item">Roll No : {academic.rollno}</span>
                          </div>
                          <p className="font-w500 text-muted">Academic Session : {academic.academicsession}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 text-end">
                      {/* <Link to="/admin/IDCard/IdCard-list"><span class="btn btn-sm btn-primary ">Student ID Card</span></Link> */}
                      <div class="mt-3">
                        <h6 class="text-start">Attendance
                        </h6>
                        <div class="progress h-25" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                          {attendance ? (
                            <>
                              {attendance.Present !== undefined && attendance.Absent !== undefined && (attendance.Present + attendance.Absent) > 0 ? (
                                <div class="progress-bar bg-success fw-bold" style={{ width: `${Math.round((attendance.Present / (attendance.Present + attendance.Absent)) * 100)}%` }}>
                                  {Math.round((attendance.Present / (attendance.Present + attendance.Absent)) * 100)}%
                                </div>
                              ) : (
                                <div class="progress-bar bg-warning fw-bold" style={{ width: "0%" }}>
                                  N/A
                                </div>
                              )}
                            </>
                          ) : (
                            <div class="progress-bar bg-warning fw-bold" style={{ width: "0%" }}>
                              N/A
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0">
                    <h4 class="card-title">Description</h4>
                    <div class="row">
                      <div class="col-xl-6 col-md-6">
                        <ul class="list-style-1">
                          <li><label class="custom-label-2 mb-0">Date of Birth :</label>{student.dob ? student.dob.split("-").reverse().join("-") : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Gender :</label>{student.gender ? student.gender : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Registration :</label>{student.createdate ? student.createdate.split("T")[0].split("-").reverse().join("-") : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Father's Name:</label>{father.name ? father.name : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Father's Mobile :</label>{father.mobileno ? father.mobileno : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Father's Email :</label>{father.emailid ? father.emailid : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Father's Occupation :</label>{father.occupation ? father.occupation : '---'}</li>
                          <li><label class="custom-label-2 mb-0 ">Mother's Name:</label>{mother.name ? mother.name : '---'}</li>
                          <li><label class="custom-label-2 mb-0 ">Mother's Mobile :</label>{mother.mobileno ? mother.mobileno : '---'}</li>
                          <li><label class="custom-label-2 mb-0 ">Mother's Email :</label>{mother.emailid ? mother.emailid : '---'}</li>
                          <li><label class="custom-label-2 mb-0 ">Mother's Occupation :</label>{mother.occupation ? mother.occupation : '---'}</li>
                        </ul>
                      </div>
                      <div class="col-xl-6 col-md-6">
                        <ul class="list-style-1">
                          <li><label class="custom-label-2 mb-0">Permanent Address :</label>{student.address ? student.address : '---'}</li>
                          <li><label class="custom-label-2 mb-0">City :</label>{student.city ? student.city : '---'}</li>
                          <li><label class="custom-label-2 mb-0">State  :</label>{student.state ? student.state : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Pincode :</label>{student.pincode ? student.pincode : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Country :</label>{student.country ? student.country : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Blood Group :</label>{Medical.bloodgroup ? Medical.bloodgroup : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Illness  :</label>{Medical.illness ? Medical.illness : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Allergic Food :</label>{Medical.allergyfood ? Medical.allergyfood : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Student Id :</label>{student.studentid ? student.studentid : '---'}</li>
                          <li><label class="custom-label-2 mb-0">Password :</label>{father.parent_password ? father.parent_password : '---'}</li>

                          {transport.id ? (
                            <> <li><label class="custom-label-2 mb-0">Transport Route No :</label> {" "}
                              {transport.routeno}</li>
                            </>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer d-flex flex-wrap justify-content-between align-items-center">
                    <div>
                      {father.id ? (
                        <>
                          <span data-bs-toggle="modal" data-bs-target="#father" class="btn btn-sm btn-primary me-2 ">Father's Details</span>
                          <div class="modal fade" id="father" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Father's Details</h5>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal">
                                  </button>
                                </div>
                                <div class="modal-body pt-3">
                                  <div class="user d-sm-flex d-block pe-md-5 pe-0">
                                    <img className="" src={`https://schoolapi.mnbsoft.co.in/UploadImage/${father.picture} `} alt="" />
                                    <div class="ms-sm-3 ms-0 me-md-12 md-0">
                                      <h5 class="mb-1">{father.name}</h5>
                                      <div class="listline-wrapper mb-2">
                                        <span class="item"><i class="text-primary far fa-envelope"></i>{father.emailid ? father.emailid : '---'}</span>
                                        <span class="item"><i class="text-primary far fa-id-badge"></i>{father.mobileno ? father.mobileno : '---'}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <ul class="list-style-1 li-custom ">
                                    {/* <li><label class="custom-label-2 mb-0">Father's Occupation :</label>{father.occupation}</li> */}
                                    <li><label class="custom-label-2 mb-0">Father's Aadhar No :</label>{father.aadharcard}</li>
                                    <li><label class="custom-label-2 mb-0">Father's Pan No : </label>{father.pancard}</li>
                                    <li><label class="custom-label-2 mb-0">Father's Occupation :</label>{father.occupation}</li>
                                    <li><label class="custom-label-2 mb-0">Father's Sector :</label> {father.sector}</li>
                                    <li><label class="custom-label-2 mb-0">Father's Employer :</label>{father.employer}</li>
                                    <li><label class="custom-label-2 mb-0">Father's Department :</label>{father.department}</li>
                                    <li><label class="custom-label-2 mb-0">Father's Designation :</label>{father.designation}</li>
                                  </ul>
                                </div>
                                <div class="modal-footer">
                                  <span class="btn btn-sm btn-danger light" data-bs-dismiss="modal">Close</span>
                                  <Link
                                    to={`/admin/students/manage-father/${father.id ? `${studentid}/${student.id}` : studentid
                                      }`}
                                  ><span class="btn btn-sm btn-primary" data-bs-dismiss="modal">Edit</span> </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {mother.id ? (
                        <>
                          <span data-bs-toggle="modal" data-bs-target="#Mother" class="btn btn-sm btn-info me-2  ">Mother's Details</span>
                          <div class="modal fade" id="Mother" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Mother's Details</h5>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal">
                                  </button>
                                </div>
                                <div class="modal-body pt-3">
                                  <div class="user d-sm-flex d-block pe-md-5 pe-0">
                                    {mother.picture ? (
                                      <Link
                                        to={`https://schoolapi.mnbsoft.co.in/UploadImage/${mother.picture}`}
                                        target="_blank"
                                      >
                                        <img
                                          className=""
                                          src={`https://schoolapi.mnbsoft.co.in/UploadImage/${mother.picture} `}

                                          alt=""
                                        />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                    <div class="ms-sm-3 ms-0 me-md-12 md-0">
                                      <h5 class="mb-1">{mother.name}</h5>
                                      <div class="listline-wrapper mb-2">
                                        <span class="item"><i class="text-primary far fa-envelope"></i>{mother.emailid}</span>
                                        <span class="item"><i class="text-primary far fa-id-badge"></i>{mother.mobileno}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <ul class="list-style-1 li-custom ">
                                    <li><label class="custom-label-2 mb-0">Mother's Aadhar No :</label>{mother.aadharcard}</li>
                                    <li><label class="custom-label-2 mb-0">Mother's PanCard No : </label>{mother.pancard}</li>
                                    <li><label class="custom-label-2 mb-0">Mother's Sector :</label> {mother.sector}</li>
                                    <li><label class="custom-label-2 mb-0">Mother's Occupation :</label>{mother.occupation}</li>
                                    <li><label class="custom-label-2 mb-0">Mother's Employer :</label>{mother.employer}</li>
                                    <li><label class="custom-label-2 mb-0">Mother's Department :</label>{mother.department}</li>
                                    <li><label class="custom-label-2 mb-0">Mother's Designation :</label>{mother.designation}</li>
                                  </ul>
                                </div>
                                <div class="modal-footer">
                                  <span class="btn btn-sm btn-danger light" data-bs-dismiss="modal">Close</span>
                                  <Link
                                    to={`/admin/students/manage-mother/${mother.id ? `${studentid}/${student.id}` : studentid
                                      }`}
                                  ><span class="btn btn-sm btn-primary" data-bs-dismiss="modal">Edit</span> </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {Guardian.id ? (
                        <>
                          <span data-bs-toggle="modal" data-bs-target="#Guardian" class="btn btn-sm btn-warning me-2 ">Guardian's Details</span>
                          <div class="modal fade" id="Guardian" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Guardian's Details</h5>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal">
                                  </button>
                                </div>
                                <div class="modal-body pt-3">
                                  <div class="user d-sm-flex d-block pe-md-5 pe-0">
                                    {mother.picture ? (
                                      <Link
                                        to={`https://schoolapi.mnbsoft.co.in/UploadImage/${Guardian.picture}`}
                                        target="_blank"
                                      >
                                        <img
                                          className=""
                                          src={`https://schoolapi.mnbsoft.co.in/UploadImage/${Guardian.picture} `}

                                          alt=""
                                        />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                    <div class="ms-sm-3 ms-0 me-md-12 md-0">
                                      <h5 class="mb-1">{father.name}</h5>
                                      <div class="listline-wrapper mb-2">
                                        <span class="item"><i class="text-primary far fa-envelope"></i>{Guardian.emailid}</span>
                                        <span class="item"><i class="text-primary far fa-id-badge"></i>{Guardian.mobileno}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <ul class="list-style-1 li-custom ">
                                    <li><label class="custom-label-2 mb-0">Guardian's Aadhar No :</label>{Guardian.aadharcard}</li>
                                    <li><label class="custom-label-2 mb-0">Guardian's PanCard No : </label>{Guardian.pancard}</li>
                                    <li><label class="custom-label-2 mb-0">Guardian's Sector :</label> {Guardian.sector}</li>
                                    <li><label class="custom-label-2 mb-0">Guardian's Occupation :</label>{Guardian.occupation}</li>
                                    <li><label class="custom-label-2 mb-0">Guardian's Employer :</label>{Guardian.employer}</li>
                                    <li><label class="custom-label-2 mb-0">Guardian's Department :</label>{Guardian.department}</li>
                                    <li><label class="custom-label-2 mb-0">Guardian's Designation :</label>{Guardian.designation}</li>
                                  </ul>
                                </div>
                                <div class="modal-footer">
                                  <span class="btn btn-sm btn-danger light" data-bs-dismiss="modal">Close</span>
                                  <Link
                                    to={`/admin/students/manage-father/${Guardian.id ? `${studentid}/${student.id}` : studentid
                                      }`}
                                  ><span class="btn btn-sm btn-primary" data-bs-dismiss="modal">Edit</span> </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {otherDetails.id ? (
                        <>
                          <span data-bs-toggle="modal" data-bs-target="#Other" class="btn btn-sm btn-danger ">Other Details</span>
                          <div class="modal fade" id="Other" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Other Details</h5>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal">
                                  </button>
                                </div>
                                <div class="modal-body pt-3">
                                  <ul class="list-style-1 li-custom ">
                                    <li><label class="custom-label-2 mb-0">Reason for change :{" "}</label>{otherDetails.chng_reason}</li>
                                    <li><label class="custom-label-2 mb-0">Previous school:{" "}</label>{otherDetails.prev_school}</li>
                                    <li><label class="custom-label-2 mb-0">Previous class :{" "}</label>{otherDetails.prev_class}</li>
                                    <li><label class="custom-label-2 mb-0">Academic session :{" "}</label>{otherDetails.adacemic_session}</li>
                                  </ul>
                                </div>
                                <div class="modal-footer">
                                  <span class="btn btn-sm btn-danger light" data-bs-dismiss="modal">Close</span>
                                  <Link
                                    to={`/admin/students/other-detail/${otherDetails.id ? `${studentid}/${student.id}` : studentid
                                      }`}
                                  ><span class="btn btn-sm btn-primary" data-bs-dismiss="modal">Edit</span> </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
                
              )}
             
              <div class="col-xl-3">
                <div class="card">
                  <div class="card-header border-0">
                    <h4 class="card-title">Activity</h4>
                  </div>
                  <div class="card-body pt-0 pb-2">
                    <div>
                      {student.studentid ? (
                        <Link
                          class="btn text-start d-block mb-3 bg-facebook light" to={`/admin/students/manage-student/${student.id ? `${studentid}/${student.id}` : studentid
                            }`}
                        ><i className="text-facebook bi scale5 bi-pencil-square"></i>&nbsp; Student Details
                        </Link>
                      ) : (
                        ''
                      )}
                     
                      <Link
                        class="btn text-start d-block mb-3 bg-linkedin light"
                        to={`/admin/students/manage-academic/${academic.id ? `${studentid}/${student.id}` : studentid
                          }`}
                      >
                        <i
                          className={`text-linkedin bi scale5 bi-${academic.id ? "pencil-square" : "plus-square"
                            }`}
                        ></i>
                        &nbsp; Academic Detail
                      </Link>
                      {student.studentid ? (
                        <>
                         <Link
                        class="btn text-start d-block mb-3 bg-facebook light" to={`/admin/students/student-document/${documents.id ? `${studentid}/${student.id}` : studentid
                          }`}
                      ><i className={` text-facebook bi scale5 bi-${documents.id ? "pencil-square" : "plus-square"}`}></i>&nbsp; Student Documents
                      </Link>
                      <Link
                        class="btn text-start d-block mb-3 bg-dribble light"
                        to={`/admin/students/manage-father/${father.id ? `${studentid}/${student.id}` : studentid
                          }`}
                      >
                        <i
                          className={`text-dribble bi scale5 bi-${father.id ? "pencil-square" : "plus-square"
                            }`}
                        ></i>
                        &nbsp; Father's Detail
                      </Link>
                      <Link
                        class="btn text-start d-block mb-3 bg-youtube light"
                        to={`/admin/students/manage-mother/${mother.id ? `${studentid}/${student.id}` : studentid
                          }`}
                      >
                        <i
                          className={`text-youtube bi scale5 bi-${mother.id ? "pencil-square" : "plus-square"
                            }`}
                        ></i>
                        &nbsp; Mother's Detail
                      </Link>
                      <Link
                        class="btn text-start d-block mb-3 bg-facebook light"
                        to={`/admin/students/manage-gardian/${Guardian.id ? `${studentid}/${student.id}` : studentid
                          }`}
                      >
                        <i
                          className={`text-facebook bi scale5  bi-${Guardian.id ? "pencil-square" : "plus-square"
                            }`}
                        ></i>
                        &nbsp; Guardian's Detail
                      </Link>
                      <Link
                        class="btn text-start d-block mb-3 bg-linkedin light"
                        to={`/admin/students/medical-detail/${Medical.id ? `${studentid}/${student.id}` : studentid
                          }`}
                      >
                        <i
                          className={`text-linkedin bi scale5  bi-${Medical.id ? "pencil-square" : "plus-square"
                            }`}
                        ></i>
                        &nbsp; Medical Detail
                      </Link>
                      <Link
                        class="btn text-start d-block mb-3 bg-dribble light"
                        to={`/admin/students/manage-transport/${transport.id ? `${studentid}/${student.id}` : studentid
                          }`}
                      >
                        <i
                          className={`text-dribble bi scale5  bi-${transport.id ? "pencil-square" : "plus-square"
                            }`}
                        ></i>
                        &nbsp; Transport Detail
                      </Link>
                      <Link
                        class="btn text-start d-block mb-3 bg-youtube light"
                        to={`/admin/students/other-detail/${otherDetails.id ? `${studentid}/${student.id}` : studentid
                          }`}
                      >
                        <i
                          className={`text-youtube bi scale5  bi-${otherDetails.id ? "pencil-square" : "plus-square"
                            }`}
                        ></i>
                        &nbsp; Other Detail
                      </Link>
                      <Link
                        class="btn text-start d-block mb-3 bg-facebook light"
                        to={`/admin/report/fee-summary/${studentid}`}
                      >
                        <i class="text-facebook scale5 bi bi-file-earmark-bar-graph"></i>
                        Fee Summary
                      </Link>
                      </> ) : (
                        ''
                      )}
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default View_application;
