import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
function Slab() {
  // Edit table data..
  const [submit, setSubmit] = useState(false);
  const [slabdata, setSlabData] = useState({
    slab: "",
  });
  const [slabupdatedata, setSlabUpdateData] = useState({
    id: "",
    slab: "",
  });
  const history = useNavigate();
  const handleSlabSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    try {
      const response = await axiosInstance.post(
        "/Master/AddLibrarySlab",
        slabdata
      );
      if (!response.data.Status === "success") {
        throw new Error("Slab Added Failed");
      }
      toast.success("Slab Added Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
      setSubmit(false);
      setSlabData({
        slab: "",
      });
    } catch (error) {
      setSubmit(false);
      toast.error("Slab Added failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // Table Data
  const [slabnodata, setSlabnoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/Master/GetLibrarySlab`);
      setSlabnoData(response.data.Payload);
    } catch (error) {
      history("/");
    }finally{
      setLoading(false);
    }
  };
  // console.log(data.Payload);
  useEffect(() => {
    fetchData();
  }, []);
  // Edit table data..
  const [editingId, setEditingId] = useState(null);
  const handleEditClick = (id) => {
    setEditingId(id);
  };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    const sessionPattern = /^[A-Za-z0-9\s'-]+$/;
    if (!sessionPattern.test(slabupdatedata.slab)) {
      toast.error("Special characters are not allowed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setEditingId(null);
    try {
      const response = await axiosInstance.post(
        "/Master/UpdateLibrarySlab",
        slabupdatedata
      );
      if (!response.data.Status === "success") {
        throw new Error("Slab Update Failed");
      }
      toast.success("Slab Updated Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history('/admin/master/manage-class');
    } catch (error) {
      toast.error("Slab Update Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleCancelClick = () => {
    setEditingId(null);
  };
  const handleInputChange = (e, id, key) => {
    const newData = slabnodata.map((item) =>
      item.id === id ? { ...item, [key]: e.target.value } : item
    );
    setSlabUpdateData((prev) => ({ ...prev, id: id, slab: e.target.value }));
    setSlabnoData(newData);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Library Slab</h4>
          </div>
          <div class="col-lg-4">
            <form
              class="form-valide-with-icon needs-validation"
              onSubmit={handleSlabSubmit}
            >
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Add Library Slab</div></div>
                <div class="card-body">
                  <div class=" form-material">

                    <label class="form-label">
                      Slab<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="slab"
                      value={slabdata.slab}
                      onChange={(e) =>
                        setSlabData({ ...slabdata, slab: e.target.value })
                      }
                      placeholder="Enter slab name.."
                      required
                    />

                    <div className="text-end mt-3">
                      <button
                        type="submit"
                        class="btn btn-primary mb-3 btn-block "

                      >
                        {submit ? (
                          <span
                            class="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-8">
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Library Slab List</div>
                </div>
                <div class="card-body py-3 px-3">
                {loading ? (
                  <div class="table-responsive">
                    <table class="table text-nowrap table-hover border table-bordered">
                      <thead class="">
                        <tr>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead >
                        <tr>
                          <th>#</th>
                          <th>Slab</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {slabnodata ?
                          slabnodata.map((row) => (
                            <tr className="text-black fs-5" key={row.id}>
                              <td>{row.id}</td>
                              <td>
                                {editingId === row.id ? (
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={row.slab}
                                    onChange={(e) =>
                                      handleInputChange(e, row.id, "slab")
                                    }
                                  />
                                ) : (
                                  row.slab
                                )}
                              </td>
                              <td>
                                {editingId === row.id ? (
                                  <>
                                    <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                    <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                  </>
                                ) : (
                                  <span class="btn btn-primary shadow btn-xs sharp " onClick={() => handleEditClick(row.id)}><i class="fas fa-pencil-alt"></i></span>
                                )}
                              </td>
                            </tr>
                          )) :
                          (
                            <p>No data available</p>
                          )}
                      </tbody>
                    </table>
                  </div>
                  </>)}
                </div>
              </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Slab;
