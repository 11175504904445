import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
function New_Enquiry(props) {
  const layout = props.layout ?? "";
  const [sectionLoading, setSectionLoading] = useState(false);
  const [classData, setClassData] = useState([]);
  const [SubmitData, setSubmitData] = useState({
    name: "",
    fname: "",
    mname: "",
    dob: "",
    gender: "",
    asession: "",
    sclass: "",
    hostel: "",
    area: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    address: "",
    whatappsno: "",
    alternateno: "",
    primarycontact: "",
    email: "",
    aboutus: "",
    modeofenquiry: "",
    enquiryby: "",
    previousschool: "",
    reason: "",
    referby: "",
    remark: "",
  });
  const history = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSectionLoading(true);
    try {
      const response = await axiosInstance.post(
        "/Admin/CreateEnquiry",
        SubmitData
      );
      if (response.data.Status === 1) {
        throw new Error("Enquiry failed");
      }
      toast.success("Enquiry was successfully submitted!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSectionLoading(false);
      history("/admin/enquiries");
    } catch (error) {
      setSectionLoading(false);
      toast.error("Enquiry failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //class data
  const fetchClassData = async () => {
    try {
      const response = await axiosInstance.get("/Master/GetClass");
      setClassData(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
      window.location.href = "/";
    }
  };

  useEffect(() => {
    fetchClassData();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">
              {" "}
              {layout && layout === "admin"
                ? "Enquiry Form"
                : "Student Admission Enquiry (2023-24)"}
            </h4>
          </div>
          <div class="col-lg-12">
            <form onSubmit={handleSubmit}>
              <div className="card custom-card mb-3">
                <div className="card-header justify-content-between">
                  <div class="card-title">Student Details</div>
                </div>
                <div class="card-body py-3 px-3 ">
                  <div class="row form-material">
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">Full Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter full name.."
                        name="name"
                        value={SubmitData.name}
                        pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                        onChange={(e) =>
                          setSubmitData({ ...SubmitData, name: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">Father's Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter father's name.."
                        name="fname"
                        pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                        value={SubmitData.fname}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            fname: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-6 col-md-6">
                      <label class="form-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter email.."
                        name="email"
                        value={SubmitData.email}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            email: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">Mobile</label>
                      <input
                        type="text"
                        class="form-control"
                        pattern="[6789][0-9]{9}"
                        placeholder="Enter mobile number.."
                        name="whatappsno"
                        maxLength={10}
                        value={SubmitData.whatappsno}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            whatappsno: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    {/* <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Mother's Name</label>
                                        <input type="text" class="form-control" placeholder="Enter mother's name.." name="mname" value={SubmitData.mname} onChange={(e) => setSubmitData({ ...SubmitData, mname: e.target.value })} required />
                                    </div> */}
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">DOB</label>
                      <input
                        type="date"
                        class="form-control"
                        name="dob"
                        value={SubmitData.dob}
                        onChange={(e) =>
                          setSubmitData({ ...SubmitData, dob: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">Gender</label>
                      <select
                        class="form-control"
                        id="autoSizingSelect"
                        name="gender"
                        value={SubmitData.gender}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            gender: e.target.value,
                          })
                        }
                      >
                        <option selected>Choose...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    {/* <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Academic Session</label>
                                        <select class="form-control" id="autoSizingSelect" name="asession" value={SubmitData.asession} onChange={(e) => setSubmitData({ ...SubmitData, asession: e.target.value })} required>
                                            <option selected>Choose...</option>
                                            <option value="Father">2023-2024</option>
                                        </select>
                                    </div> */}
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">Class</label>
                      <select
                        class="form-control"
                        id="autoSizingSelect"
                        name="sclass"
                        value={SubmitData.sclass}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            sclass: e.target.value,
                          })
                        }
                        required
                      >
                        <option selected>Choose...</option>
                        {classData.map((c) => (
                          <option>{c.sclass}</option>
                        ))}
                      </select>
                    </div>
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">Address</label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="2"
                        name="address"
                        value={SubmitData.address}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            address: e.target.value,
                          })
                        }
                        required
                      ></textarea>
                    </div>
                    {/* <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Hostel</label>
                                        <select class="form-control" id="autoSizingSelect" name="hostel" value={SubmitData.hostel} onChange={(e) => setSubmitData({ ...SubmitData, hostel: e.target.value })} required>
                                            <option selected>Choose...</option>
                                            <option value="Father">Yes</option>
                                            <option value="Father">No</option>
                                        </select>
                                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="card custom-card mt-3">
                            <div className='card-header justify-content-between'>
                                <div class="card-title">Address Details</div>

                            </div>


                            <div class="card-body py-3 px-3 ">

                                <div class="row form-material">
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Area / Socity / Colony</label>
                                        <input type="text" class="form-control" placeholder="Enter Area / Socity / Colony.." name="area" value={SubmitData.area} onChange={(e) => setSubmitData({ ...SubmitData, area: e.target.value })} required />
                                    </div>

                                    <div class="col-xl-3 col-xxl-3 col-md-3 mb-3">
                                        <label class="form-label">Country</label>
                                        <input type="text" class="form-control" placeholder="Enter country.." name="country" value={SubmitData.country} onChange={(e) => setSubmitData({ ...SubmitData, country: e.target.value })} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-3 col-md-3 mb-3">
                                        <label class="form-label">State</label>
                                        <select class="form-control" id="autoSizingSelect" name="state" value={SubmitData.state} onChange={(e) => setSubmitData({ ...SubmitData, state: e.target.value })} required>
                                            <option selected>Choose...</option>
                                            <option value="Father">Up</option>
                                            <option value="Mother">Delhi</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">City</label>
                                        <input type="text" class="form-control" placeholder="Enter city.." name="city" value={SubmitData.city} onChange={(e) => setSubmitData({ ...SubmitData, city: e.target.value })} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Pincode</label>
                                        <input type="text" class="form-control" placeholder="Enter pincode.." name="pincode" value={SubmitData.pincode} onChange={(e) => setSubmitData({ ...SubmitData, pincode: e.target.value })} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                        <label class="form-label">Address</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="address" value={SubmitData.address} onChange={(e) => setSubmitData({ ...SubmitData, address: e.target.value })} required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card custom-card mb-3">
                            <div className='card-header justify-content-between'>
                                <div class="card-title">Contact Details</div>
                            </div>
                            <div class="card-body py-3 px-3 ">
                                <div class="row form-material">
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Whatsapp</label>
                                        <input type="text" class="form-control" placeholder="Enter whatsapp number.." name="whatappsno" value={SubmitData.whatappsno} onChange={(e) => setSubmitData({ ...SubmitData, whatappsno: e.target.value })} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Alternate Mobile</label>
                                        <input type="text" class="form-control" placeholder="Enter alternate number.." name="alternateno" value={SubmitData.alternateno} onChange={(e) => setSubmitData({ ...SubmitData, alternateno: e.target.value })} />
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Primary Contact</label>
                                        <select class="form-control" id="autoSizingSelect" name="primarycontact" value={SubmitData.primarycontact} onChange={(e) => setSubmitData({ ...SubmitData, primarycontact: e.target.value })} >
                                            <option selected>Choose...</option>
                                            <option value="Father">Father</option>
                                            <option value="Mother">Mother</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6">
                                        <label class="form-label">Email</label>
                                        <input type="email" class="form-control" placeholder="Enter email.." name="email" value={SubmitData.email} onChange={(e) => setSubmitData({ ...SubmitData, email: e.target.value })} required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card custom-card mt-3">
                            <div className='card-header justify-content-between'>
                                <div class="card-title">Other Details</div>
                            </div>
                            <div class="card-body py-3 px-3 ">
                                <div class="row form-material">
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">How DId you About Us</label>
                                        <select class="form-control" id="autoSizingSelect" name="aboutus" value={SubmitData.aboutus} onChange={(e) => setSubmitData({ ...SubmitData, aboutus: e.target.value })} required>
                                            <option selected>Choose...</option>
                                            <option value="Online">Online</option>
                                            <option value="Offline">Offline</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Mode of Enquiry</label>
                                        <select class="form-control" id="autoSizingSelect" name="modeofenquiry" value={SubmitData.modeofenquiry} onChange={(e) => setSubmitData({ ...SubmitData, modeofenquiry: e.target.value })} required>
                                            <option selected>Choose...</option>
                                            <option value="Online">Online</option>
                                            <option value="Offline">Offline</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Enquiry By</label>
                                        <select class="form-control" id="autoSizingSelect" name="enquiryby" value={SubmitData.enquiryby} onChange={(e) => setSubmitData({ ...SubmitData, enquiryby: e.target.value })} required>
                                            <option selected>Choose...</option>
                                            <option value="Father">Father</option>
                                            <option value="Mother">Mother</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                        <label class="form-label">Previous School</label>
                                        <input type="text" class="form-control" placeholder="Enter previous school name" name="previousschool" value={SubmitData.previousschool} onChange={(e) => setSubmitData({ ...SubmitData, previousschool: e.target.value })} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6">
                                        <label class="form-label">Reason for change</label>
                                        <input type="text" class="form-control" placeholder="Enter reason for change.." name="reason" value={SubmitData.reason} onChange={(e) => setSubmitData({ ...SubmitData, reason: e.target.value })} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-6 col-md-6">
                                        <label class="form-label">Reffered By</label>
                                        <input type="text" class="form-control" placeholder="Enter reffered by.." name="referby" value={SubmitData.referby} onChange={(e) => setSubmitData({ ...SubmitData, referby: e.target.value })} required />
                                    </div>
                                    <div class="col-xl-3 col-xxl-12 col-md-6">
                                        <label class="form-label">Remarks</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="remark" value={SubmitData.remark} onChange={(e) => setSubmitData({ ...SubmitData, remark: e.target.value })} required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div> */}
              <div className="text-end mb-5">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  style={{ width: "100px" }}
                >
                  {sectionLoading ? (
                    <span
                      class="spinner-border spinner-border-sm me-2 shadow bg-body-tertiary rounded"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default New_Enquiry;
