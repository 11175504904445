import React from 'react';
import { Link } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
function FeeDetail() {
    const generatePDF = () => {

        const content = document.getElementById('html-to-pdf-content');
        const pdfOptions = { filename: 'receipt.pdf' };
        html2pdf(content, pdfOptions);
    };

    return (<>
        <div>
            <div className="container-fluid">
                <div className="row pt-4">
                    <h3 class="text-center">Fee Detail</h3>
                    <div class="col-xl-3 col-xxl-12 pt-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body pb-4">
                                        {/* <h4 class="fs-20 mb-3">Father's Details</h4> */}
                                        <div class="row">
                                            <div class="col-xl-3 col-xxl-2">
                                                <img src="/images/6606-male-user.png" style={{ width: '150px', background: "#c7e2b8" }} />
                                            </div>
                                            <div class="col-xl-3 col-xxl-3 fs-15">
                                                <p class="font-w500 mb-3"><span class="custom-label">Student's Name :</span><span class="custom-label"> Rohan Yadav</span></p>
                                                <p class="font-w500 mb-3"><span class="custom-label">Father's Name :</span><span class="custom-label"> Ramesh Yadav</span></p>
                                                <p class="font-w500 mb-3"><span class="custom-label">Mobile No. :</span><span class="custom-label"> 8655756756</span></p>
                                                {/* <p class="font-w500 mb-3"><span class="custom-label">Address :</span><span class="custom-label"> Sector-58, Noida</span></p> */}
                                            </div>
                                            <div class="col-xl-3 col-xxl-3 fs-15">
                                                <p class="font-w500 mb-3"><span class="custom-label">Roll No. :</span><span class="custom-label"> 101</span></p>
                                                <p class="font-w500 mb-3"><span class="custom-label">Class :</span><span class="custom-label"> LKG</span></p>
                                                <p class="font-w500 mb-3"><span class="custom-label">Section :</span><span class="custom-label"> A</span></p>
                                            </div>
                                            <div class="col-xl-3 col-xxl-3 fs-15">
                                                <p class="font-w500 mb-3"><span class="custom-label">Admission No. :</span><span class="custom-label"> 34435</span></p>
                                                <p class="font-w500 mb-3"><span class="custom-label">Academic Session :</span><span class="custom-label"> 2022-2023</span></p>
                                                {/* <p class="font-w500 mb-3"><span class="custom-label">Section :</span><span class="custom-label"> A</span></p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row form-material">
                                    <div class="col-xl-3 col-xxl-9 col-md-6 mb-3">
                                        <h3 class="card-title">Fee Summary</h3>
                                    </div>
                                    <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                        <form class="d-flex" role="search">
                                            <input class="form-control me-2" type="search" placeholder="Search date..." aria-label="Search" />
                                            {/* <button class="btn btn-primary" type="submit">Search</button> */}
                                        </form>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table header-border table-responsive-sm">
                                    <thead className="bg-black text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Dues</th>
                                                <th>Tuition fee</th>
                                                <th>Late fee</th>
                                                <th>Total fee</th>
                                                <th>Discount</th>
                                                <th>Net fee</th>
                                                <th>Payable amount</th>
                                                <th>method</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>1</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>2000</td>
                                                <td><span class="badge badge-xs light badge-secondary">Cash</span></td>
                                                <td><span class="badge badge-xs light badge-success">Success</span></td>
                                                <td>02-01-2023</td>
                                                <td>
                                                    <Link to="" onClick={generatePDF} class="bg-green text-white btn-sm fw-bold fs-4"> Receipt</Link>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>2</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>2000</td>
                                                <td><span class="badge badge-xs light badge-secondary">Cash</span></td>
                                                <td><span class="badge badge-xs light badge-success">Success</span></td>
                                                <td>12-02-2023</td>
                                                <td>
                                                    <Link to="" onClick={generatePDF} class="bg-green text-white btn-sm fw-bold fs-4"> Receipt</Link>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>3</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>2000</td>
                                                <td><span class="badge light badge-dark">UPI</span></td>
                                                <td><span class="badge badge-xs light badge-danger">Pending</span></td>
                                                <td>02-03-2023</td>
                                                <td>
                                                    <Link to="" onClick={generatePDF} class="bg-green text-white btn-sm fw-bold fs-4"> Receipt</Link>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>4</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>2000</td>
                                                <td><span class="badge badge-xs light badge-secondary">Cash</span></td>
                                                <td><span class="badge badge-xs light badge-success">Success</span></td>
                                                <td>02-04-2023</td>
                                                <td>
                                                    <Link to="" onClick={generatePDF} class="bg-green text-white btn-sm fw-bold fs-4"> Receipt</Link>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>5</td>
                                                <td>1000</td>
                                                <td>1000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>0.00</td>
                                                <td>2000</td>
                                                <td>2000</td>
                                                <td><span class="badge light badge-dark">UPI</span></td>
                                                <td><span class="badge badge-xs light badge-danger">Pending</span></td>
                                                <td>02-05-2023</td>
                                                <td>
                                                    <Link to="" onClick={generatePDF} class="bg-green text-white btn-sm fw-bold fs-4"> Receipt</Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item disabled">
                                                <a class="page-link">Previous</a>
                                            </li>
                                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="row" id="html-to-pdf-content">
                                                {/* <div class="col-xl-3"></div> */}
                                                <div class="col-xl-12">
                                                    <div class="card">
                                                        <div class="card-body pb-4">
                                                            <div class="row pt-4">
                                                                <div class="col-xl-4">
                                                                    <img src="/images/MNB_logo.png" />
                                                                </div>
                                                                <div class="col-xl-4" style={{ background: '#b81111', color: 'white' }}>
                                                                    <h3 class="fs-25 mb-3 pt-3 text-center text-white">MNB Public School</h3>
                                                                </div>
                                                                <div class="col-xl-3 pt-4 text-center">
                                                                    {/* <a href="" class="bg-black text-white btn-sm fw-bold fs-4">Download</a> */}
                                                                </div>
                                                            </div>
                                                            <div class="pt-4"><hr />
                                                                <div class="row">
                                                                    <div class="col-xl-7 pt-4">
                                                                        <p class="font-w500 mb-3"><span class="custom-label">Student's Name : </span><span class="custom-label"> Tanuj</span></p>
                                                                        <p class="font-w500 mb-3"><span class="custom-label">Roll No. :</span><span class="custom-label"> 1001</span></p>
                                                                        <p class="font-w500 mb-3"><span class="custom-label">Class :</span><span class="custom-label"> UKG</span></p>
                                                                        {/* <p class="font-w500 mb-3"><span class="custom-label">Section :</span><span class="font-w400"> A</span></p> */}
                                                                    </div>
                                                                    <div class="col-xl-5 pt-4">
                                                                        <p class="font-w500 mb-3"><span class="custom-label">Receipt No. : </span><span class="custom-label">1003423</span></p>
                                                                        <p class="font-w500 mb-3"><span class="custom-label">Date : </span><span class="custom-label"> 11-Jan-2023</span></p>
                                                                    </div>
                                                                </div>
                                                                <table class="table">
                                                                    <thead class="text-black">
                                                                        <tr style={{ background: '#b81111', color: 'white' }}>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Particulars</th>
                                                                            <th scope="col">Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr class="text-black fs-5">
                                                                            <th scope="row">1</th>
                                                                            <td>Tuition Fee</td>
                                                                            <td>1000</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <th scope="row">2</th>
                                                                            <td>Exam Fee</td>
                                                                            <td>100</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <th scope="row">3</th>
                                                                            <td>Transport Fee</td>
                                                                            <td>500</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <th scope="row">4</th>
                                                                            <td>Sports Fee</td>
                                                                            <td>1000</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <th scope="row">5</th>
                                                                            <td>Hostel Fee</td>
                                                                            <td>0.00</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <td></td>
                                                                            <td class="bg-light">Total</td>
                                                                            <td class="bg-light fw-bold">2600</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <td></td>
                                                                            <td class="bg-light">Balance</td>
                                                                            <td class="bg-light fw-bold">400</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <td></td>
                                                                            <td class="bg-light">Net Amount</td>
                                                                            <td class="bg-light fw-bold">3000</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <td></td>
                                                                            <td class="bg-light">Paid Amount</td>
                                                                            <td class="bg-light fw-bold">2000</td>
                                                                        </tr>
                                                                        <tr class="text-black fs-5">
                                                                            <td></td>
                                                                            <td class="bg-light">Dues Amount</td>
                                                                            <td class="bg-light fw-bold">1000</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <p class="pt-5 text-end">Signature of coordinator</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="col-xl-3"></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default FeeDetail;