import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
import Loader from "../../Loader";
function Teacher_list() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/Admin/GetExpensedetail`);
      setData(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    }finally{
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [classData, setClassData] = useState([]);
  const fetchClass = async () => {
    try {
      const response = await axiosInstance.get(`/Master/GetClass`);
      setClassData(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    }
  };
  useEffect(() => {
    fetchClass();
  }, []);

  // -- pagination Code -- //

  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfPage = Math.ceil(data?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div class="card-header">
              <h4 class="card-title">Manage Expenses</h4>
              <div class="flex flex-wrap gap-2">
                <Link className="btn btn-primary" to="/admin/expense/add-expense"> Add Expense<span class="btn-icon-end"><i class="fa fa-solid fa-plus"></i></span></Link>
              </div>
            </div>
            <div class="col-lg-12">
              <div className="card custom-card mb-3">
                <div className='card-header justify-content-between'>
                  <div class="card-title">Expenses List</div>
                  {/* <div class="flex flex-wrap gap-2">
                    <input class="form-control form-control-sm" type="text" placeholder="Search Here" aria-label=".form-control-sm example" />
                  </div> */}
                </div>
                <div class="card-body py-3 px-3">
                  {loading ? (
                    <Loader/>
                  ) : (
                    <>
                      <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th>Expense ID</th>
                              <th>Vendor Name</th>
                              <th>Email ID</th>
                              <th>Mobile No</th>
                              <th>GST Amount</th>
                              <th>Total Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data ? (
                              data.slice(
                                currentPage * dataPerPage,
                                (currentPage + 1) * dataPerPage
                              ).map((row) => {
                                return (
                                  <tr className="text-black fs-5" key={row.id}>
                                    <td>{row.expenid}</td>
                                    <td>{row.name}</td>
                                    <td>{row.email}</td>
                                    <td>{row.mobile}</td>
                                    <td>{row.total_gst}</td>
                                    <td>{row.total_amount}</td>
                                    <td>
                                      <Link class="btn btn-xs btn-danger"
                                        to={`/admin/expense/expense-receipt/${row.expenid}`}
                                      >
                                        <i class="bi bi-eye"></i> View
                                      </Link>{" "}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <p>No data available</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end">
                          <li class="page-item">
                            <a
                              class="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === 0 ? "none" : "auto",
                                opacity: currentPage === 0 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              Previous
                            </a>
                          </li>
                          {pageIndex
                            .slice(
                              Math.max(0, currentPage - 2),
                              Math.min(numberOfPage, currentPage + 2)
                            )
                            .map((page) => (
                              <li
                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                  }`}
                                key={page}
                              >
                                <a
                                  class="page-link"
                                  style={{
                                    pointerEvents:
                                      page - 1 === currentPage
                                        ? "none"
                                        : "auto",
                                    opacity: page - 1 === currentPage ? 0.5 : 1,
                                  }}
                                  onClick={() => handlePageChange(page - 1)}
                                  href="#"
                                >
                                  {page}
                                </a>
                              </li>
                            ))}
                          <li class="page-item">
                            <a
                              class="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === numberOfPage - 1
                                    ? "none"
                                    : "auto",
                                opacity:
                                  currentPage === numberOfPage - 1 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Teacher_list;
