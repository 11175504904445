import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../parent-helper';
import Loader from '../../Loader';
function Assignment() {

    const [assignmentData, setAssignmentData] = useState([])
    const [loading, setLoading] = useState(true)
    //Fetch Assignment Details
    const fetchAssignmentData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/Parent/GetParentAssignment')
            setAssignmentData(response.data.Payload)
        } catch (error) {
            console.error(error)
        }finally{
            setLoading(false);
        }
    }
    // date converter functions
    const formatDate = (inputDate) => {
        return inputDate.split("T")[0].split("-").reverse().join("-");
    };

    useEffect(() => {
        fetchAssignmentData()
    }, [])

    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(assignmentData?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (<>
        <div className="container-fluid">
            <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Assignment</h4>
                </div>
                <div class="col-lg-12">
                    <div class="card custom-card mb-3">
                        <div class="card-header justify-content-between"><div class="card-title">Assignment List</div>
                            <div class="flex flex-wrap gap-2">
                                <input class="form-control form-control-sm" type="text" placeholder="Search Here" aria-label=".form-control-sm example" />
                            </div>
                        </div>

                        <div class="card-body py-3 px-3">
                            <div class="table-responsive">
                                {
                                    loading ? (
                                        <Loader />
                                    ) : (
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Class</th>
                                                    <th>Subject</th>
                                                    {/* <th>Uploaded by</th> */}
                                                    <th>Assignment</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    assignmentData ?
                                                        assignmentData.slice(
                                                            currentPage * dataPerPage,
                                                            (currentPage + 1) * dataPerPage
                                                        ).map((row, index) => (
                                                            <tr className="text-black fs-5" key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{row.sclass} </td>
                                                                <td className='font-w600 text-primary'>{row.subject}</td>
                                                                {/* <td>Manoj Kumar</td> */}
                                                                <td><span class="btn btn-danger shadow btn-xs" ><a className='text-white' href={`https://schoolapi.mnbsoft.co.in/AssignmentDoc/${row.assignment_doc}`} download target="_blank"><i class="bi bi-cloud-arrow-down"></i> Download</a></span> </td>
                                                                <td >{formatDate(row.createdate)}</td>
                                                            </tr>
                                                        )) : <p>No Data Available</p>
                                                }
                                            </tbody>
                                        </table>
                                    )
                                }
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-end">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            style={{
                                                pointerEvents:
                                                    currentPage === 0 ? "none" : "auto",
                                                opacity: currentPage === 0 ? 0.5 : 1,
                                            }}
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            Previous
                                        </a>
                                    </li>
                                    {pageIndex
                                        .slice(
                                            Math.max(0, currentPage - 2),
                                            Math.min(numberOfPage, currentPage + 2)
                                        )
                                        .map((page) => (
                                            <li
                                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                    }`}
                                                key={page}
                                            >
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            page - 1 === currentPage
                                                                ? "none"
                                                                : "auto",
                                                        opacity: page - 1 === currentPage ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(page - 1)}
                                                    href="#"
                                                >
                                                    {page}
                                                </a>
                                            </li>
                                        ))}
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            style={{
                                                pointerEvents:
                                                    currentPage === numberOfPage - 1
                                                        ? "none"
                                                        : "auto",
                                                opacity:
                                                    currentPage === numberOfPage - 1 ? 0.5 : 1,
                                            }}
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            Next
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Assignment;