import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import Loader from "../../Loader"
function FeeType() {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [SubmitData, setSubmitData] = useState({
    feename: "",
    feetype: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    try {
      const response = await axiosInstance.post(
        "/Master/AddFeeType",
        SubmitData
      );
      if (!response.data.Status === "success") {
        throw new Error("Fee Type Added failed");
      }

      toast.success("Fee Type Added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setButtonLoading(false);
      fetchData();
      hidePopup();
    } catch (error) {
      setButtonLoading(true);
      toast.error("Fee Type Added failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  //Model state
  const [isModalOpen, setModalOpen] = useState(false);
  const handlePupup = () => {
    setModalOpen(true);
  };
  const hidePopup = () => {
    setModalOpen(false);
  };

  //Get Data for Class
  const history = useNavigate();
  const [feeTypeData, setFeeTypeData] = useState({
    id: "",
    feename: "",
  });

  const [feeType, setFeeType] = useState([]);
  const [loading, setLoading] = useState(true);

  // Edit table data..
  const [editingId, setEditingId] = useState(null);
  const handleEditClick = (id) => {
    setEditingId(id);
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    setEditingId(null);

    try {
      const response = await axiosInstance.post(
        "/Master/UpdateFeeName",
        feeTypeData
      );
      if (!response.data.Status === "success") {
        throw new Error("Fee Type Update failed");
      }

      toast.success("Fee Type Update successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
      // history('/admin/master/fee-type');
    } catch (error) {
      toast.error("Fee Type Update failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleCancelClick = () => {
    setEditingId(null);
  };

  const handleInputChange = (e, fee_type_id, key) => {
    const newData = feeType.map((item) =>
      item.id === fee_type_id ? { ...item, [key]: e.target.value } : item
    );
    setFeeTypeData((prev) => ({
      ...prev,
      id: fee_type_id,
      feename: e.target.value,
    }));
    setFeeType(newData);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/Master/GetAddFeeType");
      setFeeType(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    }finally{
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Fees Type</h4>
            <div class="flex flex-wrap gap-2">
              <Link className="btn btn-primary" to="/admin/master/create-fee-type"
                onClick={handlePupup} data-bs-toggle="modal"
                data-bs-target="#exampleModal">Create Fees Type<span class="btn-icon-end"><i class="fa fa-solid fa-plus"></i></span></Link>
            </div>
          </div>
          <div class="col-lg-12">
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Fees Type List</div>
                </div>
                <div class="card-body py-3 px-3">
                {loading ? (
                  <Loader/>
                ) : (
                  <>
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead >
                        <tr>
                          <th>#</th>
                          <th>Fee Name</th>
                          <th>Fee Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feeType ? (
                          feeType.map((row, index) => (
                            <tr className="text-black fs-5" key={row.id}>
                              <td>{index + 1}</td>
                              <td>
                                {editingId === row.fee_type_id ? (
                                  <input
                                    type="text"
                                    name="feename"
                                    className="form-control"
                                    defaultValue={row.fee_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        row.fee_type_id,
                                        "fee_name"
                                      )
                                    }
                                  />
                                ) : (
                                  row.fee_name
                                )}
                              </td>
                              <td>{row.fee_type}</td>
                              <td>
                                {editingId === row.fee_type_id ? (
                                  <>
                                    <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                    <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                  </>
                                ) : (

                                  <span class="btn btn-primary shadow btn-xs sharp " onClick={() =>
                                    handleEditClick(row.fee_type_id)
                                  }><i class="fas fa-pencil-alt"></i></span>

                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <p>No data available</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={`modal fade ${isModalOpen ? "show" : ""}`}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden={!isModalOpen}
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div className='card-header justify-content-between'>
                          <div class="card-title"> Add Fee Type</div>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        {/* </div> */}
                        {/* <h1
                              class="modal-title fs-5 text-white"
                              id="exampleModalLabel"
                            >
                              Add Fee Type
                            </h1> */}
                        {/* <button
                            type="button"
                            class="btn-close text-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div> */}
                        <div class="modal-body">
                          <form onSubmit={handleSubmit}>
                            <div class="row form-material">
                              <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                <label class="form-label">
                                  Fee Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter fee name.."
                                  name="feename"
                                  pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                                  value={SubmitData.feename}
                                  onChange={(e) =>
                                    setSubmitData({
                                      ...SubmitData,
                                      feename: e.target.value,
                                    })
                                  }
                                  required
                                />
                              </div>
                              <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                <label class="form-label">Fee Type</label>
                                <select
                                  class="form-control"
                                  id="autoSizingSelect"
                                  name="feetype"
                                  value={SubmitData.feetype}
                                  onChange={(e) =>
                                    setSubmitData({
                                      ...SubmitData,
                                      feetype: e.target.value,
                                    })
                                  }
                                  required
                                >
                                  <option value="">Choose</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="One Time">One Time</option>
                                </select>
                              </div>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  class="btn btn-primary text-white fw-bold mb-3 shadow bg-body-tertiary rounded"
                                  style={{ width: "100px" }}
                                >
                                  {buttonLoading ? (
                                    <span
                                      class="spinner-border spinner-border-sm me-2"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>)}
                </div>
                 
              </div>
          
          </div>
        </div>
      </div>
    </>
  );
}
export default FeeType;
