import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

function Late_fee() {
  const [SubmitData, setSubmitData] = useState({
    fromdate: "",
    todate: "",
    perdayfee: "",
    maxfee: "",
  });

  const [routeData, setRouteData] = useState({
    ids: "",
    fromdate: "",
    todate: "",
    perdayfee: "",
    maxfee: "",
  });

  const [loadSubmit, setLoadSubmit] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadSubmit(true);
    try {
      const response = await axiosInstance.post(
        "/Master/AddLateFee",
        SubmitData
      );
      if (!response.data.Status === "success") {
        throw new Error("Transport Fee Added Failed");
      }
      toast.success("Transport Fee Added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
      setSubmitData({
        fromdate: "",
        todate: "",
        perdayfee: "",
        maxfee: "",
      });
      setLoadSubmit(false);
    } catch (error) {
      setLoadSubmit(false);
      toast.error("Add late fee failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //Table Data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/Master/GetLateFeeDatail`);
      setData(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      window.location.href = "/";
    }finally{
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Edit table data..
  const [editingId, setEditingId] = useState(null);
  const handleEditClick = (id) => {
    setEditingId(id);
  };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    setEditingId(null);
    try {
      const response = await axiosInstance.post(
        "/Master/UpdateLateFee",
        routeData
      );
      if (!response.data.Status === "success") {
        throw new Error("Late Fee Updated failed");
      }
      toast.success("Late Fee Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history("/admin/master/manage-transport");
    } catch (error) {
      toast.error("Late Fee Updated failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };

  const handleInputChange = (e, id, key) => {
    const { name, value } = e.target;
    const newData = data.map((item) =>
      item.id === id ? { ...item, [key]: e.target.value } : item
    );

    const EditData = data.find((val) => {
      return val.id === id;
    });

    setRouteData((prev) => ({
      ...prev,
      ids: id,
      fromdate: EditData.from_date,
      todate: EditData.to_date,
      perdayfee: EditData.perdayfee,
      maxfee: EditData.maxfee,
    }));
    setRouteData((prev) => ({
      ...prev,
      ids: id,
      [name]: value,
    }));
    setData(newData);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Late Fee</h4>
          </div>
          {/* <div class="col-lg-4">
            <form onSubmit={handleSubmit}>
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Add Late Fee</div></div>
                <div class="card-body py-3 px-3">
                  <div class="row form-material">
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">
                        From Fee Date<span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Enter route no.."
                        name="fromdate"
                        value={SubmitData.fromdate}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            fromdate: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                      <label class="form-label">
                        To Fee Date<span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Enter route no.."
                        name="todate"
                        value={SubmitData.todate}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            todate: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                        Per Day Late Fee<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter route fee.."
                        name="perdayfee"
                        value={SubmitData.perdayfee}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            perdayfee: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                        Max Late Fee<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter route fee.."
                        name="maxfee"
                        value={SubmitData.maxfee}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            maxfee: e.target.value,
                          })
                        }
                        required
                      />
                    </div>

                    <div className="text-end">
                      {data?.length > 0 ? (
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary btn-block mb-3"
                          disabled
                        >
                          {loadSubmit ? (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary btn-block mb-3"
                          // disabled
                        >
                          {loadSubmit ? (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div> */}
          <div class="col-lg-12">
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Late Fee List</div></div>
                <div class="card-body py-3 px-3">
                {loading ? (
                  <div class="table-responsive">
                    <table class="table text-nowrap table-hover border table-bordered">
                      <thead class="">
                        <tr>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>From Fee Date</th>
                          <th>To Fee Date</th>
                          <th>Per Day Fee</th>
                          <th>Max Late Fee</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data ? (
                          data.map((row, index) => (
                            <tr className="text-black fs-5" key={row.id}>
                              <td>{index + 1}</td>
                              <td>
                                {editingId === row.id ? (
                                  <input
                                    type="text"
                                    name="fromdate"
                                    className="form-control"
                                    defaultValue={row.from_date}
                                    onChange={(e) =>
                                      handleInputChange(e, row.id, "from_date")
                                    }
                                  />
                                ) : (
                                  row.from_date
                                )}
                              </td>
                              <td>
                                {editingId === row.id ? (
                                  <input
                                    type="text"
                                    name="todate"
                                    className="form-control"
                                    defaultValue={row.to_date}
                                    onChange={(e) =>
                                      handleInputChange(e, row.id, "to_date")
                                    }
                                  />
                                ) : (
                                  row.to_date
                                )}
                              </td>
                              <td>
                                {editingId === row.id ? (
                                  <input
                                    type="text"
                                    name="perdayfee"
                                    className="form-control"
                                    defaultValue={row.perdayfee}
                                    onChange={(e) =>
                                      handleInputChange(e, row.id, "perdayfee")
                                    }
                                  />
                                ) : (
                                  row.perdayfee
                                )}
                              </td>
                              <td>
                                {editingId === row.id ? (
                                  <input
                                    type="text"
                                    name="maxfee"
                                    className="form-control"
                                    defaultValue={row.maxfee}
                                    onChange={(e) =>
                                      handleInputChange(e, row.id, "maxfee")
                                    }
                                  />
                                ) : (
                                  row.maxfee
                                )}
                              </td>
                              <td>
                                {editingId === row.id ? (
                                  <>
                                    <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                    <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                  </>
                                ) : (

                                  <span class="btn btn-primary shadow btn-xs sharp " onClick={() => handleEditClick(row.id)}><i class="fas fa-pencil-alt"></i></span>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <p>No data available</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                  </>)}
                </div>
              </div>
         
          </div>
        </div>
      </div>
    </>
  );
}

export default Late_fee;
