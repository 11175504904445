import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
function Branch_list() {
    const [data, setData] = useState([]);
    //console.log('data is', data)
    useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://staging.mnbcrm.com/api/test/get_emi_customer?admin_id=100001'); // Replace with your API endpoint
        setData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    // Call the fetch data function
    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts


      return (<>
    {/* <div>
      <h1>Data Table</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.mobile}</td>
              <td>{item.address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> */}
        <div>
           <div className="container-fluid">
                <div className="row">
                    <div class="card-header">
                        <h4 class="card-title">Branch</h4>

                         
                        <div class="flex flex-wrap gap-2"> 
                    <Link className="btn btn-primary" to="/admin/branch/create">Add Branch<span class="btn-icon-end"><i class="fa fa-solid fa-plus"></i></span></Link>

                                
                    </div>
                       
                    </div>
                    <div class="col-lg-12">
                    <div className="card custom-card mb-3">
                                    <div className='card-header justify-content-between'>
                                                <div class="card-title">Branch List</div>
                                                <div class="flex flex-wrap gap-2"> 
                                                
                                                    <input class="form-control form-control-sm" type="text" placeholder="Search Here" aria-label=".form-control-sm example"/> 
                                                   
                                                     </div>
                                                </div>
                        <div class="card-body py-3 px-3">
                               
                                <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                                        <thead >
                                            <tr>
                                                <th>Branch Code</th>
                                                <th>Branch Name</th>
                                                <th>Country</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Address</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>100001</td>
                                                <td>NX1</td>
                                                <td>India</td>
                                                <td>Uttar Pradesh</td>
                                                <td>Greater Noida</td>
                                                <td>Gaur city</td>
                                                <td>
                                                <div class="d-flex">
                                                <Link  class="btn btn-danger shadow btn-xs sharp me-1" to="/admin/branch/detail/000002"><i class="bi bi-eye "></i></Link>{' '}
                                                <Link class="btn btn-primary shadow btn-xs sharp " to="/admin/branch/edit/000001"><i class="fas fa-pencil-alt"></i></Link>
                                                   </div>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>100002</td>
                                                <td>NX2</td>
                                                <td>India</td>
                                                <td>Uttar Pradesh</td>
                                                <td>Greater Noida</td>
                                                <td>Gaur city</td>
                                                <td>
                                                <div class="d-flex">
                                                <Link  class="btn btn-danger shadow btn-xs sharp me-1" to="/admin/branch/detail/000002"><i class="bi bi-eye "></i></Link>{' '}
                                                <Link class="btn btn-primary shadow btn-xs sharp " to="/admin/branch/edit/000001"><i class="fas fa-pencil-alt"></i></Link>
                                                   </div>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>100003</td>
                                                <td>NX3</td>
                                                <td>India</td>
                                                <td>Uttar Pradesh</td>
                                                <td>Greater Noida</td>
                                                <td>Gaur city</td>
                                                <td>
                                                <div class="d-flex">
                                                <Link  class="btn btn-danger shadow btn-xs sharp me-1" to="/admin/branch/detail/000002"><i class="bi bi-eye "></i></Link>{' '}
                                                <Link class="btn btn-primary shadow btn-xs sharp " to="/admin/branch/edit/000001"><i class="fas fa-pencil-alt"></i></Link>
                                                   </div>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>100004</td>
                                                <td>NX4</td>
                                                <td>India</td>
                                                <td>Uttar Pradesh</td>
                                                <td>Greater Noida</td>
                                                <td>Gaur city</td>
                                                <td>
                                                <div class="d-flex">
                                                <Link  class="btn btn-danger shadow btn-xs sharp me-1" to="/admin/branch/detail/000002"><i class="bi bi-eye "></i></Link>{' '}
                                                <Link class="btn btn-primary shadow btn-xs sharp " to="/admin/branch/edit/000001"><i class="fas fa-pencil-alt"></i></Link>
                                                   </div>
                                                </td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>100005</td>
                                                <td>NX5</td>
                                                <td>India</td>
                                                <td>Uttar Pradesh</td>
                                                <td>Greater Noida</td>
                                                <td>Gaur city</td>
                                                <td>
                                                <div class="d-flex">
                                                <Link  class="btn btn-danger shadow btn-xs sharp me-1" to="/admin/branch/detail/000002"><i class="bi bi-eye "></i></Link>{' '}
                                                <Link class="btn btn-primary shadow btn-xs sharp " to="/admin/branch/edit/000001"><i class="fas fa-pencil-alt"></i></Link>
                                                   </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <a class="page-link">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Branch_list;