import React from 'react';
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../parent-helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../Loader';
function LeaveApply() {
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [loading, setLoading] = useState(true);
    const [student, setStudent] = useState({});
    console.log('student',student);
    const [book, setBook] = useState([]);
    const [SubmitData, setSubmitData] = useState({
        name: student.name,
        sclass: student.sclass,
        rollno: student.rollno,
        section: student.section,
        subject: "",
        remark: ""
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadSubmit(true)
        try {
            const response = await axiosInstance.post('/Parent/ApplyLeave', SubmitData);
            console.log(response)
            if (!response.data.Status === 'success') {
                throw new Error('Leave apply failed');
            }

            toast.success('Leave applied successfully!', {
                position: toast.POSITION.TOP_RIGHT,
            });
            setLoadSubmit(false)
            setSubmitData({subject:"",remark:""})
        } catch (error) {
            setLoadSubmit(false)
            toast.error('Leave apply failed', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    const fetchList = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/Parent/Getstudentleave");
            setBook(response.data.Payload);
            fetchList();
        } catch (error) {
            window.location.href = "/parent";
        } finally {
            setLoading(false);
        }
    }
    const fetchStudent = async () => {
        try {
            const response = await axiosInstance.get("/Parent/Getstudentdetail");
            setStudent(response.data);
            setSubmitData({ ...SubmitData, name: response.data.studentname, rollno: response.data.rollno, sclass: response.data.sclass, section: response.data.section });
        } catch (error) {
            window.location.href = "/parent";
        }
    }
    useEffect(() => {
        fetchStudent();
        fetchList();
    }, []);
    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(book?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (<>
        <div className="container-fluid">
            <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Manage Leave</h4>
                </div>
                <div class="col-md-4">
                    <form onSubmit={handleSubmit}>
                        <div class="card custom-card mb-3">
                            <div class="card-header justify-content-between"><div class="card-title">Leave Application</div></div>
                            <div class="card-body text-black fw-bold fs-6">
                                <div class="row form-material">
                                    <div class=" col-md-12 mb-3">
                                        <label class="form-label">Name</label>
                                        <input type="text" class="form-control" name="name" value={student.studentname} onChange={(e) => setSubmitData({ ...SubmitData, name: e.target.value })} disabled />
                                    </div>
                                    <div class=" col-md-12 mb-3">
                                        <label class="form-label">Subject</label>
                                        <input type="text" class="form-control" name="subject" placeholder="Enter subject.." value={SubmitData.subject}  onChange={(e) => setSubmitData({ ...SubmitData, subject: e.target.value })} required />
                                    </div>
                                    <div class=" col-md-12 mb-3">
                                        <label class="form-label">Remark</label>
                                        <textarea class="form-control" rows="5" name="remark" placeholder="Enter remark.." value={SubmitData.remark} onChange={(e) => setSubmitData({ ...SubmitData, remark: e.target.value })} required />
                                    </div>
                                    <div className="text-end">
                                        <button type="submit" class="btn btn-primary mb-3 btn-block" >
                                            {
                                                loadSubmit ? (<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>) : "Submit"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-8">
                    <div className="card custom-card mb-3">
                        <div className='card-header justify-content-between'>
                            <div class="card-title">Leave Application List</div>
                        </div>
                        <div class="card-body py-3 px-3">
                            <div class="table-responsive">
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Subject</th>
                                                    <th>Remark</th>
                                                    <th>Status</th>
                                                    <th>Apply Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {book ?
                                                    book
                                                        .slice(
                                                            currentPage * dataPerPage,
                                                            (currentPage + 1) * dataPerPage
                                                        ).map((row) => (
                                                            <tr className="text-black fs-5" key={row.id}>
                                                                <td>{row.name}</td>
                                                                <td>{row.subject}</td>
                                                                <td>{row.remark}</td>
                                                                <td> {row.status === 0 ? (
                                                                    <span class="badge badge-xs badge-warning">Process</span>
                                                                ) : row.status === 1 ? (
                                                                    <span class="badge badge-xs badge-primary">Approved</span>
                                                                ) : (
                                                                    <span class="badge badge-xs badge-danger">Reject</span>
                                                                )}
                                                                </td>
                                                                <td>{row.createdate}</td>

                                                            </tr>
                                                        )) :
                                                    <p>No Data Available</p>}
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-end">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            style={{
                                                pointerEvents:
                                                    currentPage === 0 ? "none" : "auto",
                                                opacity: currentPage === 0 ? 0.5 : 1,
                                            }}
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            Previous
                                        </a>
                                    </li>
                                    {pageIndex
                                        .slice(
                                            Math.max(0, currentPage - 2),
                                            Math.min(numberOfPage, currentPage + 2)
                                        )
                                        .map((page) => (
                                            <li
                                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                    }`}
                                                key={page}
                                            >
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            page - 1 === currentPage
                                                                ? "none"
                                                                : "auto",
                                                        opacity: page - 1 === currentPage ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(page - 1)}
                                                    href="#"
                                                >
                                                    {page}
                                                </a>
                                            </li>
                                        ))}
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            style={{
                                                pointerEvents:
                                                    currentPage === numberOfPage - 1
                                                        ? "none"
                                                        : "auto",
                                                opacity:
                                                    currentPage === numberOfPage - 1 ? 0.5 : 1,
                                            }}
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            Next
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default LeaveApply;