import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

function Manage_Vendor() {
  const [SubmitData, setSubmitData] = useState({
    Name: "",
    email: "",
    mobile: "",
    address: "",
  });

  const [expense, setExpense] = useState({
    ids: "",
    Name: "",
    email: "",
    mobile: "",
    address: "",
  });

  const [loadSubmit, setLoadSubmit] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadSubmit(true);
    try {
      const response = await axiosInstance.post(
        "/Master/AddVender",
        SubmitData
      );
      if (!response.data.Status === "success") {
        throw new Error("Vendor Added Failed");
      }

      toast.success("Vendor Added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchData();
      setSubmitData({
        Name: "",
        email: "",
        mobile: "",
        address: "",
      });
      setLoadSubmit(false);
    } catch (error) {
      setLoadSubmit(false);
      toast.error("Add Vendor failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //Table Data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(`/Master/GetVender`);
      setData(response.data.Payload);
    } catch (error) {
      window.location.href = "/";
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Edit table data..
  const [editingId, setEditingId] = useState(null);
  const handleEditClick = (id) => {
    setEditingId(id);
  };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    const sessionPattern = /^[A-Za-z0-9\s'-]+$/;
    if (!sessionPattern.test(expense.Name)) {
      toast.error("Special characters are not allowed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setEditingId(null);
    try {
      const response = await axiosInstance.post(
        "/Master/UpdateVender",
        expense
      );
      if (!response.data.Status === "success") {
        throw new Error("Vendor Updated failed");
      }
      toast.success("Vendor Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    //   history("/admin/master/manage-transport");
    } catch (error) {
      toast.error("Vendor Updated failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };
  const handleInputChange = (e, id, key) => {
    const { name, value } = e.target;
    const newData = data.map((item) =>
      item.id === id ? { ...item, [key]: e.target.value } : item
    );
    const EditData = data.find((val) => {
      return val.id === id;
    });
    setExpense((prev) => ({
      ...prev,
      ids: id,
      Name: EditData.name,
      email: EditData.email,
      mobile: EditData.mobile,
      address: EditData.address,
    }));
    setExpense((prev) => ({
      ...prev,
      ids: id,
      [name]: value,
    }));
    setData(newData);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Vendor</h4>
          </div>
          <div class="col-lg-4">
            <form onSubmit={handleSubmit}>
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Add Vendor</div></div>
                <div class="card-body py-3 px-3">
                  <div class="row form-material">
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Vendor Name.."
                        name="Name"
                        pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                        value={SubmitData.Name}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            Name: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                        Email ID<span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter Vendor Email.."
                        name="email"
                        value={SubmitData.email}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            email: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                       Mobile No.<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        pattern="[6789][0-9]{9}"
                        placeholder="Enter Mobile No.."
                        name="mobile"
                        maxLength={10}
                        value={SubmitData.mobile}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            mobile: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">Address</label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        name="address"
                        value={SubmitData.address}
                        onChange={(e) =>
                          setSubmitData({
                            ...SubmitData,
                            address: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                    <div className="text-end">
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary btn-block mb-3"
                      >
                        {loadSubmit ? (
                          <span
                            class="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-8">

            <div class="card custom-card mb-3">
              <div class="card-header justify-content-between"><div class="card-title">Vendor List</div></div>
              <div class="card-body py-3 px-3">
                {loading ? (
                  <div class="table-responsive">
                    <table class="table text-nowrap table-hover border table-bordered">
                      <thead class="">
                        <tr>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead >
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email ID</th>
                            <th>Mobile No.</th>
                            <th>Address</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data ? (
                            data.map((row) => (
                              <tr className="text-black fs-5" key={row.id}>
                                <td>{row.id}</td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      defaultValue={row.name}
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "name")
                                      }
                                    />
                                  ) : (
                                    row.name
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      name="email"
                                      className="form-control"
                                      defaultValue={row.email}
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "email")
                                      }
                                    />
                                  ) : (
                                    row.email
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      name="mobile"
                                      className="form-control"
                                      pattern="[6789][0-9]{9}"
                                      maxLength={10}
                                      defaultValue={row.mobile}
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "mobile")
                                      }
                                    />
                                  ) : (
                                    row.mobile
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      name="address"
                                      className="form-control"
                                      defaultValue={row.address}
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "address")
                                      }
                                    />
                                  ) : (
                                    row.address
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <>
                                      <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                      <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                    </>
                                  ) : (
                                    <span class="btn btn-primary shadow btn-xs sharp " onClick={() => handleEditClick(row.id)}><i class="fas fa-pencil-alt"></i></span>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <p>No data available</p>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Manage_Vendor;
