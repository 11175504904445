import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
function CreateRegistration() {
    const Navigate = useNavigate();
    const { studentid, id } = useParams();
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [siblingDiscount, setSiblingDiscount] = useState([])
    const [formData, setFormData] = useState({
        id: null,
        studentid: null,
        name: "",
        fname: "",
        dob: "",
        gender: "",
        state: "",
        city: "",
        pincode: "",
        address: "",
        mobile: "",
        email: "",
        siblingno: "",
        siblingamount: ""
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonLoading(true);
        let URL = "/Student/StudentRegistration"
        if (id) {
            URL = "/student/UpdateStudentRegistration"
        }
        try {
            const response = await axiosInstance.post(URL, formData);
            if (!response.data.Status === "success") {
                throw new Error(`${id ? "Registeration Updated" : "Registration Details Added"} Failed`);
            }
            toast.success(`${id ? "Registration Details Updated" : "Registration Details Added"} Successfully!`, {
                position: toast.POSITION.TOP_RIGHT,
            });

            setButtonLoading(false);

            if (!id) {
                setFormData({
                    id: null,
                    studentid: null,
                    name: "",
                    fname: "",
                    dob: "",
                    gender: "",
                    state: "",
                    city: "",
                    pincode: "",
                    address: "",
                    mobile: "",
                    email: "",
                    siblingno: "",
                    siblingamount: ""
                });
            }
            Navigate("/admin/registration/manage-registration");
        } catch (error) {
            setButtonLoading(false);
            toast.error(`Registration ${id ? "Updated" : "Added"} failed`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        };
    };

    const fetchRegistrationData = async () => {
        try {
            const response = await axiosInstance.post(`/Student/GetSearchRegistrationDatail`, {
                ids: id,
                studentid: studentid,
                name: "",
                fname: "",
                mobile: ""
            });
            console.log(formData);
            setFormData({
                ids: response.data.Payload[0].id,
                studentid: response.data.Payload[0].studentid,
                name: response.data.Payload[0].student_name,
                fname: response.data.Payload[0].father_name,
                dob: response.data.Payload[0].dob,
                gender: response.data.Payload[0].gender,
                state: response.data.Payload[0].state,
                city: response.data.Payload[0].city,
                pincode: response.data.Payload[0].pincode,
                mobile: response.data.Payload[0].mobile,
                email: response.data.Payload[0].email,
                address: response.data.Payload[0].address,
                siblingno: response.data.Payload[0].sibling,
                siblingamount: response.data.Payload[0].siblingamount
            });
            if ((formData?.siblingamount !== "") || (formData?.siblingamount !== 0)) {
                setIsSelect1(true)
            }
        } catch (error) {
            toast.error("Authentication failed !");
            // window.location.href = "/";
        }
    }

    console.log(formData);

    // fetch Siblings Discount Data
    const fetchSiblingsData = async () => {
        try {
            const response = await axiosInstance.get(
                `/Master/GetSiblingDiscount`);
            setSiblingDiscount(response.data?.Payload);
        } catch (error) {
            // toast.error("Authentication failed !");
            // window.location.href = "/";
        }
    };

    const [isSelect1, setIsSelect1] = useState(false);
    const handleSelectChange = (e) => {
        const { value } = e.target

        if (value !== "None" && value !== "") {
            setIsSelect1(true);
            const fee = siblingDiscount?.find((s) => s.siblingno === parseInt(value)).discountamount
            setFormData({
                ...formData,
                siblingno: value,
                siblingamount: fee,
            });
        } else {
            setIsSelect1(false);
            setFormData({ ...formData, siblingno: String(value) === "None" ? "" : 0, siblingamount: String(value) === "None" ? "" : 0 });
        }
    };

    useEffect(() => {
        if (id) {
            fetchRegistrationData()
        }
        const loaderTimeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(loaderTimeout);
    }, [id, studentid])
    useEffect(() => {
        fetchSiblingsData()
    }, [])

    const path = document.location.pathname
    return (
        <>
            <div className="mt-4">
                <div className="container-fluid">
                    <div className="row">
                        <h4 class="card-title">{path === "/admin/registration/create"
                            ? "Registration Form"
                            : "Edit Registration"}</h4>
                        <div class="col-lg-12">
                            <form onSubmit={handleSubmit}>
                                <div class="row pt-4">
                                    <div class="col-lg-12">
                                        <div class="card shadow bg-body-tertiary rounded">
                                            <div className="card custom-card mt-3">
                                                <div className='card-header justify-content-between'>
                                                    <div class="card-title"> Registration Details</div>
                                                </div>
                                            </div>
                                            <div class="card-body text-black fw-bold fs-6">
                                                <div class="row form-material">
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">Student Name</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Enter student name..."
                                                            name="name"
                                                            pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                                                            defaultValue={formData.name}
                                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">Father Name</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Enter father name..."
                                                            name="fname"
                                                            pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                                                            defaultValue={formData.fname}
                                                            onChange={(e) => setFormData({ ...formData, fname: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">Email</label>
                                                        <input
                                                            type="email"
                                                            class="form-control"
                                                            placeholder="Enter email..."
                                                            name="email"
                                                            defaultValue={formData.email}
                                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    {id ? (
                                                        <>
                                                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                                <label class="form-label">
                                                                    Sibling
                                                                </label>
                                                                <select
                                                                    class="form-control"
                                                                    id="autoSizingSelect"
                                                                    onChange={handleSelectChange}
                                                                    value={formData.siblingno === "" || formData.siblingno === 0 ? "None" : formData.siblingno}
                                                                >
                                                                    {
                                                                        siblingDiscount ? (siblingDiscount.map((s) => <option value={s.siblingno}>{s.siblingno}</option>)) : (<p>Not Available</p>)
                                                                    }
                                                                    <option value="None">None</option>
                                                                </select>
                                                            </div>

                                                            {
                                                                (isSelect1 && formData.siblingamount !== 0) && (
                                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                                        <label class="form-label">
                                                                            Sibling Discount
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name="siblingdiscount"
                                                                            value={formData.siblingamount}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                                <label class="form-label">
                                                                    Sibling
                                                                </label>
                                                                <select
                                                                    class="form-control"
                                                                    id="autoSizingSelect"
                                                                    onChange={handleSelectChange}
                                                                    name="siblingno"
                                                                >
                                                                    <option value="">Choose...</option>
                                                                    {
                                                                        siblingDiscount ? (siblingDiscount.map((s) => <option value={s.siblingno}>{s.siblingno}</option>)) : (<p>Not Available</p>)
                                                                    }
                                                                    <option value="None">None</option>
                                                                </select>
                                                            </div>
                                                            {
                                                                isSelect1 && (
                                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                                        <label class="form-label">
                                                                            Sibling Discount
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            name="siblingdiscount"
                                                                            value={formData.siblingamount}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    )}

                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">Mobile</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Enter mobile..."
                                                            name="mobile"
                                                            pattern="[6789][0-9]{9}"
                                                            maxLength={10}
                                                            defaultValue={formData.mobile}
                                                            onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">DOB</label>
                                                        <input
                                                            type="date"
                                                            class="form-control"
                                                            name="dob"
                                                            placeholder="Enter DOB..."
                                                            defaultValue={formData.dob}
                                                            onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">Gender</label>
                                                        <select
                                                            className="form-control"
                                                            id="autoSizingSelect"
                                                            name="gender"
                                                            value={formData.gender}
                                                            onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                                                            required
                                                        >
                                                            <option value="">Choose</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">City</label>
                                                        <input
                                                            type="text"
                                                             pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                                                            class="form-control"
                                                            placeholder="Enter city.."
                                                            name="city"
                                                            defaultValue={formData.city}
                                                            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">State</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                             pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                                                            placeholder="Enter state.."
                                                            name="state"
                                                            defaultValue={formData.state}
                                                            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">Pincode</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Enter pincode.."
                                                            name="pincode"
                                                            title="xxxxxx"
                                                            pattern="[1-9][0-9]{5}"
                                                            defaultValue={formData.pincode}
                                                            onChange={(e) => setFormData({ ...formData, pincode: e.target.value })}
                                                            required
                                                        />
                                                    </div>
                                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                                        <label class="form-label">Address</label>
                                                        <textarea
                                                            class="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows="3"
                                                            name="address"
                                                            defaultValue={formData.address}
                                                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                                            required
                                                        ></textarea>
                                                    </div>
                                                    <div className="text-end">
                                                        <button
                                                            type="submit"
                                                            class="btn btn-primary text-white fw-bold mb-3 shadow bg-body-tertiary rounded"
                                                            style={{ width: "100px" }}
                                                        >
                                                            {buttonLoading ? (
                                                                <span
                                                                    class="spinner-border spinner-border-sm me-2"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                            ) : id ? (
                                                                "Edit"
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {/* )} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateRegistration;
