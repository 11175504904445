import Header from "../Header";
import React from 'react';
import { Link } from 'react-router-dom';
function View() {
    return (<>
        {/* <Header /> */}
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div class="card-header">
                        <h3 class="me-auto p-2 text-center">Enquiry Details</h3>
                        <Link to="/admin/application/convert" class="btn btn-primary me-3 btn-sm" type="button"> Convert</Link>
                        <div class="dropdown">
                            <Link class="btn btn-primary me-3 btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Assign</Link>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Teacher1</a></li>
                                <li><a class="dropdown-item" href="#">Teacher2</a></li>
                                <li><a class="dropdown-item" href="#">Teacher3</a></li>
                            </ul>
                        </div>
                        <div class="dropdown">
                            <Link class="btn btn-primary me-3 btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Status</Link>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Invalid</a></li>
                                <li><a class="dropdown-item" href="#">Undecided</a></li>
                                <li><a class="dropdown-item" href="#">Interested</a></li>
                                <li><a class="dropdown-item" href="#">Visit Scheduled</a></li>
                                <li><a class="dropdown-item" href="#">Visited</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header border-0 pb-2">
                                        <h4 class="fs-20 mb-0">Child Details</h4>
                                    </div><hr />
                                    <div class="card-body pt-0">
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Full Name:</h5>
                                            <span>Kittu</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Father's Name:</h5>
                                            <span>Tanuj Ojha</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Mother's Name:</h5>
                                            <span>Tannu Ojha</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Date of Birth:</h5>
                                            <span>02-10-2018</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Gender:</h5>
                                            <span>Boy</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Academic Session:</h5>
                                            <span>2022-2023</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Class:</h5>
                                            <span>L.K.G</span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <h5 class="mb-1 fs-14 custom-label">Hostel:</h5>
                                            <span>Anupama Hostel</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body pb-4">
                                        <h4 class="fs-20 mb-3">Address Details</h4>
                                        <div class=""><hr />
                                            <p class="font-w500 mb-3"><span class="custom-label">Whatsapp Number :</span><span class="font-w400"> 1234567890</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Alternate Number :</span><span class="font-w400"> 0987654321</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Primary Contact :</span><span class="font-w400"> Father</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Email :</span><span class="font-w400"> admin@gmail.com</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Country : </span><span class="font-w400"> India</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">State : </span><span class="font-w400"> Uttar Pradesh</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">City : </span><span class="font-w400"> Noida</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Pincode : </span><span class="font-w400"> 000011</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Address : </span><span class="font-w400"> Sector-135, Lovely Colony Noida</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header border-0 pb-0">
                                        <h4 class="fs-20 mb-0">Notes</h4>
                                        <a href="javascript:void(0);" class="btn btn-primary btn-sm me-0" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fas fa-tasks me-2"></i>Add Notes</a>
                                    </div><hr />
                                    <div class="card-body  pt-0">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr className="text-black">
                                                        <th scope="col">#</th>
                                                        <th scope="col">File</th>
                                                        <th scope="col">Remark</th>
                                                        <th scope="col">Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>file</td>
                                                        <td>Testing</td>
                                                        <td>06-11-2023</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">2</th>
                                                        <td>file</td>
                                                        <td>Testing</td>
                                                        <td>06-11-2023</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">3</th>
                                                        <td>file</td>
                                                        <td>Testing</td>
                                                        <td>06-11-2023</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">4</th>
                                                        <td>file</td>
                                                        <td>Testing</td>
                                                        <td>06-11-2023</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">5</th>
                                                        <td>file</td>
                                                        <td>Testing</td>
                                                        <td>06-11-2023</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination justify-content-end">
                                                <li class="page-item disabled">
                                                    <a class="page-link">Previous</a>
                                                </li>
                                                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                <li class="page-item">
                                                    <a class="page-link" href="#">Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header border-0 pb-0">
                                        <h4 class="fs-20 mb-0">Others Details</h4>
                                    </div><hr />
                                    <div class="card-body  pt-2">
                                        <div class="ms-4">
                                            <p class="font-w500 mb-3"><span class="custom-label">How DId you About Us : </span><span class="font-w400"> Online</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Mode of Enquiry : </span><span class="font-w400"> Online</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Enquiry By : </span><span class="font-w400"> Father</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Previous School : </span><span class="font-w400"> BD Public School</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Reason for change : </span><span class="font-w400"> No Reason</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Reffered By : </span><span class="font-w400"> Teacher</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Remarks : </span><span class="font-w400"> Testing</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Add Notes</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form class="form-valide-with-icon needs-validation">
                            <div class="row form-material">
                                <div class="col-xl-3 col-xxl-12 col-md-6">
                                    <label class="form-label">File</label>
                                    <input type="file" class="form-control" placeholder="Enter mobile" />
                                </div>
                                <div class="col-xl-3 col-xxl-12 col-md-6">
                                    <label class="form-label">Notes</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>                                        </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <Link type="button" class="btn btn-primary btn-sm me-3">Submit</Link>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default View;