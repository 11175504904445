import React, { useEffect } from "react";
import { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

function ManageDocument() {
  const Navigate = useNavigate();
  const [studentDetail, setStudentDetail] = useState([]);
  console.log("studentDetail", studentDetail);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { studentid, id } = useParams();
  const [loading, setLoading] = useState(true);
  // console.log(id);
  const [SubmitData, setSubmitData] = useState({
    ids: id,
    studentid: studentid,
    picture: "",
    aadharcard: "",
    aadhar_pic: "",
    transfer_cert: "",
    transfer_cert_pic: "",
    birth_cert: "",
    birth_cert_pic: "",
  });

  //Get Data from api
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/student/GetStudentInformation?studentid=${studentid}`
        );
        // console.log(response);
        const documentDetail = response.data.Document[0];
        setSubmitData({
          ids: documentDetail.id,
          studentid: documentDetail.studentid,
          picture: documentDetail.student_pic,
          aadharcard: documentDetail.aadharcard,
          aadhar_pic: documentDetail.aadhar_pic,
          transfer_cert: documentDetail.transfer_cert,
          transfer_cert_pic: documentDetail.transfer_cert_pic,
          birth_cert: documentDetail.birth_cert,
          birth_cert_pic: documentDetail.birth_cert_pic,
        });
        // console.log(SubmitData);
      } catch (error) {
        // toast.error("Authentication failed !");
        window.location.href = "/";
      }
    };
    if (id) {
      fetchData();
    }
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [id, studentid]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64-encoded image string to the state
        const filebase64 = reader.result.split("base64,");
        setSubmitData({ ...SubmitData, picture: filebase64[1] });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64-encoded image string to the state
        const filebase64 = reader.result.split("base64,");
        setSubmitData({ ...SubmitData, aadhar_pic: filebase64[1] });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64-encoded image string to the state
        const filebase64 = reader.result.split("base64,");
        setSubmitData({ ...SubmitData, transfer_cert_pic: filebase64[1] });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageChange3 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64-encoded image string to the state
        const filebase64 = reader.result.split("base64,");
        setSubmitData({ ...SubmitData, birth_cert_pic: filebase64[1] });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    try {
      const response = await axiosInstance.post(
        id
          ? "student/updateStudentdocumentDetail"
          : "/student/CreateStudentDecument",
        SubmitData
      );
      console.log(response.data);
      if (response.data.Status === "success") {
        toast.success(
          `Student ${
            id ? "Document Updated" : "document added"
          }  successfully!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setButtonLoading(false);
        if (!id) {
          setSubmitData({
            ids: null,
            studentid: null,
            picture: null,
            aadharcard: "",
            aadhar_pic: null,
            transfer_cert: "",
            transfer_cert_pic: null,
            birth_cert: "",
            birth_cert_pic: null,
          });
        }
        Navigate(`/admin/students/detail/${studentid}`);
      }
    } catch (error) {
      // console.log('SubmitData', SubmitData);
      // throw new Error(`${id ? "Update" : "Add"} student document Successfully`);
      setButtonLoading(false);
      toast.error(`${id ? "Update" : "Add"}  student document failed`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchStudentDetail = async () => {
    try {
      const response = await axiosInstance.get(
        `/Student/Getstudentdetail?studentid=${studentid}`
      );
      setStudentDetail(response.data?.Payload[0]);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchStudentDetail();
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [studentid]);
  const path = document.location.pathname;
  return (
    <div className="mt-4">
      <div className="container-fluid">
        <div className="row">
          <h4 class="card-title">
            {path === `/admin/students/student-document/${studentid}`
              ? "Manage Student"
              : "Edit Student Document"}
          </h4>
          <div className="col-lg-12">
            {loading ? (
              <div
                className="container text-center"
                style={{ paddingTop: "200px" }}
              >
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <form encType="multipart/form-data" onSubmit={handleSubmit}>
                <div className="row pt-4">
                  <div class="col-lg-12">
                    <div className="card custom-card mt-3">
                      <div class="card">
                        <div className="pt-3 p-2">
                          <li class="list-group-item">
                            <span class="custom-label fs-5">
                              &nbsp;&nbsp;<b>Student Name</b> : &nbsp;
                              {studentDetail.studentname ?? ""}{" "}
                            </span>
                            <span class="custom-label fs-5">
                              &nbsp;&nbsp;<b>Father Name</b> : &nbsp;
                              {studentDetail.fathername ?? ""}{" "}
                            </span>
                            <span class="custom-label fs-5">
                              &nbsp;&nbsp;<b>Mobile</b> : &nbsp;
                              {studentDetail.mobile ?? ""}{" "}
                            </span>
                            {studentDetail.rollno ? (
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Rollno</b> : &nbsp;{" "}
                                {studentDetail.rollno}
                              </span>
                            ) : (
                              ""
                            )}
                            {studentDetail.sclass ? (
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Class</b> : &nbsp;{" "}
                                {studentDetail.sclass}
                              </span>
                            ) : (
                              ""
                            )}
                            {studentDetail.section ? (
                              <span class="custom-label fs-5">
                                &nbsp;&nbsp;<b>Section</b> : &nbsp;{" "}
                                {studentDetail.section}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Gender</b> : &nbsp; {studentDetail.gender ?? ''}</span> */}
                            {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>DOB</b> : &nbsp; {studentDetail.dob ?? ''}</span> */}
                            {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Address</b> : &nbsp; {studentDetail.address ?? ''}</span> */}
                          </li>
                        </div>
                      </div>
                      <div className="card-header justify-content-between">
                        <div class="card-title">Student Documents</div>
                      </div>
                      <div class="card-body">
                        <div class="row form-material">
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Student's Photo</label>
                            <input
                              type="file"
                              className="form-control"
                              name="picture"
                              onChange={handleImageChange}
                            />
                            {id ? (
                              <img
                                className="rounded "
                                src={`https://schoolapi.mnbsoft.co.in/UploadImage/${SubmitData.picture}`}
                                style={{ height: "50px", width: "100%" }}
                                alt="student Photo"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Aadhar No.</label>
                            <input
                              type="text"
                              class="form-control"
                              name="aadharcard"
                              pattern="\d{4}\s\d{4}\s\d{4}"
                              title="XXXX XXXX XXXX"
                              value={SubmitData.aadharcard}
                              onChange={(e) =>
                                setSubmitData({
                                  ...SubmitData,
                                  aadharcard: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">Aadhar Photo</label>
                            <input
                              type="file"
                              class="form-control"
                              name="aadhar_pic"
                              onChange={handleImageChange1}
                            />
                            {id ? (
                              <img
                                className="rounded "
                                src={`https://schoolapi.mnbsoft.co.in/UploadImage/${SubmitData.aadhar_pic}`}
                                style={{ height: "50px", width: "100%" }}
                                alt="aadhar Photo"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">
                              Transfer Certificate No
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="transfer_cert"
                              value={SubmitData.transfer_cert}
                              onChange={(e) =>
                                setSubmitData({
                                  ...SubmitData,
                                  transfer_cert: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">
                              Transfer Certificate Photo
                            </label>
                            <input
                              type="file"
                              class="form-control"
                              name="transfer_cert_pic"
                              onChange={handleImageChange2}
                            />
                            {id ? (
                              <img
                                className="rounded "
                                src={`https://schoolapi.mnbsoft.co.in/UploadImage/${SubmitData.transfer_cert_pic}`}
                                style={{ height: "50px", width: "100%" }}
                                alt="transfer certificate Photo"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">
                              Birth Certificate No
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="birth_cert"
                              value={SubmitData.birth_cert}
                              onChange={(e) =>
                                setSubmitData({
                                  ...SubmitData,
                                  birth_cert: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                          <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                            <label class="form-label">
                              Birth Certificate Photo
                            </label>
                            <input
                              type="file"
                              class="form-control"
                              name="birth_cert_pic"
                              onChange={handleImageChange3}
                            />
                            {id ? (
                              <img
                                className="rounded "
                                src={`https://schoolapi.mnbsoft.co.in/UploadImage/${SubmitData.birth_cert_pic}`}
                                style={{ height: "50px", width: "100%" }}
                                alt="birth certificate Photo"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              style={{ width: "100px" }}
                            >
                              {buttonLoading ? (
                                <span
                                  class="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : id ? (
                                "Edit"
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageDocument;
