import React from "react";
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import html2pdf from "html2pdf.js";
import Loader from "../../Loader";

function Manage_fees() {
    const { studentid } = useParams();
    const [data, setData] = useState([]);
    const [receipt, setReceipt] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalData, setModalData] = useState({});
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/Admin/Gettotaltransactionlog?studentid=${studentid}`);
            response.data.Payload.forEach((val) => {
                val.paymentlog = JSON.parse(val.paymentlog);
            })
            setData(response.data.Payload);
        } catch (error) {
            console.error("Error fetching data", error)
        }finally{
            setLoading(false);
        }
    }

    const fetchReceipt = async (id) => {
        try {
            const response = await axiosInstance.get(`/managefees/GetMonthlyLogReciept?ids=${id}`);
            setReceipt(response.data);
        } catch (error) {
            toast.error("Authentication failed !");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchModalData = async (Payment_month) => {
        try {
            const response = await axiosInstance.get(`/ManageFees/GetMonthlyPaymentReciept?studentid=${studentid}&month=${Payment_month}`
            );
            setModalData(response.data);
        } catch (error) {
            toast.error("Authentication failed !");
        }
    };
    const generatePDF = (modalid, id) => {
        fetchReceipt(id)
            .then(() => {
                setTimeout(() => {
                    const content = document.getElementById(modalid);
                    const pdfOptions = { filename: "receipt.pdf" };
                    html2pdf(content, pdfOptions);
                }, 0);
            })
            .catch((error) => console.log(error));
    };

    const ReggeneratePDF = (modalid, month) => {
        fetchModalData(month)
            .then(() => {
                setTimeout(() => {
                    const content = document.getElementById(modalid);
                    const pdfOptions = { filename: "receipt.pdf" };
                    html2pdf(content, pdfOptions);
                }, 0);
            })
            .catch((error) => console.log(error));
    };
    return (<>
        <div>
            <div className="container-fluid">

                <div className="row">
                    <div class="card-header">
                        <h4 class="card-title">Fee Summary</h4>
                        <div class="flex flex-wrap gap-2">
                            <Link className="btn btn-primary" onClick={() => fetchModalData("OneTime")}
                                data-bs-target="#exampleModalToggle"
                                data-bs-toggle="modal">Registration Fee</Link>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="exampleModalToggle"
                        aria-hidden="true"
                        aria-labelledby="exampleModalToggleLabel"
                        tabindex="-1"
                    >
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
                                        Admission Fees receipt
                                    </h1>
                                    <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div
                                    id="html-onetime-pdf"
                                    style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Fee Name</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        {loading ? (
                                            <Loader />
                                        ) : (
                                            <tbody class="table-group-divider">
                                                {modalData.FeeDetail ? (
                                                    modalData.FeeDetail.map((row) => {
                                                        return (
                                                            <tr>
                                                                <td>{row.fee_name}:</td>
                                                                <td>{row.paid_amount}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <p>Not Paid</p>
                                                )}
                                                <tr>
                                                    <td>Total Amount:</td>
                                                    <td>{modalData.totalpaidamount}</td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                                <div className="container text-center">
                                    <button
                                        className="btn btn-primary mb-3"
                                        onClick={() =>
                                            ReggeneratePDF("html-to-pdf-content1", "OneTime")
                                        }
                                    >
                                        PDF Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div className="card custom-card mb-3">
                            <div className='card-header justify-content-between'>
                                <div class="card-title">Monthly fee report of {data.length > 0 && <span>{data[0].name}</span>}</div>
                            </div>
                            <div class="card-body py-3 px-3">
                                <div class="table-responsive">
                                    {loading ? (
                                        <Loader />
                                    ) : (
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th>Month</th>
                                                    <th>Previous Dues</th>
                                                    <th>Total Amount</th>
                                                    <th>Discount</th>
                                                    <th>Paid Amount</th>
                                                    <th>Rest Amount</th>
                                                    <th>Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data && data.length > 0 ? (
                                                    data.map((row) => (
                                                        <tr className="text-black fs-5" key={row.id}>
                                                            {/* <td>{row.paymentlog.month}</td> */}
                                                            {/* <td>{row.Payment_month}</td> */}
                                                            <td>{row.paymentlog.month ? row.paymentlog.month.join(", ") : ""}</td>
                                                            <td>{row.previous_due}</td>

                                                            {/* <td>{row.paymentlog.month ? row.paymentlog.month.join(", ") : ""}</td> */}
                                                            <td>{row.totalamount}</td>
                                                            <td>{row.paymentlog.totaldiscount}</td>
                                                            <td className='text-primary'>{row.total_paid}</td>
                                                            <td>{row.due_amount}</td>
                                                            <td>{row.createdate}</td>
                                                            <td className='text-danger'><span onClick={() => generatePDF("html-to-pdf-content", row.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                                                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                                    <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                                                </svg></span></td>
                                                        </tr>
                                                    ))) : (<p>No data available</p>)}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="card-header">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-xxl-12 ">
                            <div
                                class="modal fade"
                                id="exampleModalToggle"
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabindex="-1"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="row" id="html-to-pdf-content">
                                        <div
                                            class="col-xl-12"
                                            style={{
                                                paddingLeft: "5rem",
                                                paddingRight: "5rem",
                                            }}
                                        >
                                            <div class="row pt-5 ">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <h6>&nbsp;From,</h6>
                                                        <img src="/images/MNB_logo.png" className="invoice-logo" alt="MNB Logo" />
                                                        <h6 class="fw-bold mt-2 mb-0">MNB Public School</h6>
                                                        <p class="ms-1">D-160, D Block, Sector 10,<br></br> Noida, Uttar Pradesh - 201301 <br />
                                                        </p>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <address>
                                                            <h6>Student Detail</h6>
                                                            <h6 class="fw-bold invoice-customer">
                                                                {" "}  {" "}{receipt.StudentName}
                                                            </h6>
                                                            <p class="font-w500 mb-1"> Roll No. :{" "}{receipt.RollNo}</p>
                                                            <p class="font-w500 mb-1"> Class :{" "}{receipt.Class}</p>
                                                            <p class="mt-4 mb-1">
                                                                <span>Receipt No. :{" "}</span>
                                                                <i class="ti ti-calendar"></i>
                                                                {" "}
                                                                {receipt.ReceiptNo}
                                                            </p>
                                                            <p>
                                                                <span> Month :{" "}</span>
                                                                <i class="ti ti-calendar"></i>
                                                                {" "}
                                                                {receipt.Month}
                                                            </p>
                                                        </address>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="table-responsive mt-5">
                                                        <table class="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Particulars</th>
                                                                    <th scope="col" class="text-end">Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {receipt.FeeDetail ? (
                                                                    receipt.FeeDetail.map((row, index) => (
                                                                        <tr class="text-black fs-5">
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{row.fee_name}</td>
                                                                            <td class="text-end">{row.fee_amount}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <p>No data available</p>
                                                                )}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="pull-right text-end">
                                                        <h5><b>Previous Dues :</b> &nbsp;{receipt.previousdue}</h5>
                                                        <h5><b>Total Amount :</b> &nbsp;{receipt.totalamount}</h5>
                                                        <h5><b>Total Discount :</b> &nbsp;{receipt.totaldiscount}</h5>
                                                        <h5><b>Paid Amount :</b> &nbsp;{receipt.totalpaidamount}</h5>
                                                        <h5><b>Rest Amount:</b> &nbsp;{receipt.totaldueamount}</h5>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <hr />
                                                    <div class="text-end">
                                                        <p class="pt-4 text-end">Signature</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="card-header">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-xxl-12 ">
                            <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="row" id="html-to-pdf-content1">
                                        <div
                                            class="col-xl-12"
                                            style={{
                                                paddingLeft: "5rem",
                                                paddingRight: "5rem",
                                            }}
                                        >
                                            <div class="row pt-5 ">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <h6>&nbsp;From,</h6>
                                                        <img src="/images/MNB_logo.png" className="invoice-logo" alt="MNB Logo" />
                                                        <h6 class="fw-bold mt-2 mb-0">MNB Public School</h6>
                                                        <p class="ms-1">D-160, D Block, Sector 10,<br></br> Noida, Uttar Pradesh - 201301 <br />
                                                        </p>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                        <address>
                                                            <h6>Student Detail</h6>
                                                            <h6 class="fw-bold invoice-customer">
                                                                {" "}  {" "}{modalData.StudentName}
                                                            </h6>
                                                            <p class="font-w500 mb-1"> Roll No. :{" "}{modalData.RollNo}</p>
                                                            <p class="font-w500 mb-1"> Class :{" "}{modalData.Class}</p>
                                                            <p class="mt-4 mb-1">
                                                                <span>Receipt No. :{" "}</span>
                                                                <i class="ti ti-calendar"></i>
                                                                {" "}
                                                                {modalData.ReceiptNo}
                                                            </p>
                                                            <p>
                                                                <span> Month :{" "}</span>
                                                                <i class="ti ti-calendar"></i>
                                                                {" "}
                                                                {modalData.Month}
                                                            </p>
                                                        </address>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="table-responsive mt-5">
                                                        <table class="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Particulars</th>
                                                                    <th scope="col" class="text-end">Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {modalData.FeeDetail ? (
                                                                    modalData.FeeDetail.map((row, index) => (
                                                                        <tr class="text-black fs-5">
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{row.fee_name}</td>
                                                                            <td class="text-end">{row.fee_amount}</td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <p>No data available</p>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="pull-right text-end">
                                                        <h5><b>Total Amount :</b> &nbsp;{modalData.totalpaidamount}</h5>

                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <hr />
                                                    <div class="text-end">
                                                        <p class="pt-4 text-end">Signature</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default Manage_fees;