import { useEffect, useState } from "react";
import { axiosInstance } from "../../admin-helper";
import { toast } from "react-toastify";



const ManageSiblings = () => {
    const [siblingDiscount, setSiblingDiscount] = useState({
        siblingno: "",
        siblingdiscount: "",
    })
    const [siblingDiscountUpdate, setSiblingDiscountUpdate] = useState({
        ids: "",
        siblingno: "",
        siblingdiscount: "",
    })
    const [siblingDiscountList, setSiblingDiscountList] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [loading, setLoading] = useState(true)
    const [editingId, setEditingId] = useState(null);

    
    const handleInputChange = (e, id, key) => {
        const { name, value } = e.target;
        // Update siblingDiscountList State
        const newData = siblingDiscountList.map((item) =>
            item.id === id ? { ...item, [key]: value , discountamount: key === "siblingdiscount" ? value : item.discountamount} : item
        );

        setSiblingDiscountList(newData);
        // Update siblingDiscountUpdate State
        const EditData = siblingDiscountList.find((val) => {
            return val.id === id;
          });
        setSiblingDiscountUpdate((prev) => ({
            ...prev,
            ids: id,
            siblingno: key === 'siblingno' ? value : EditData.siblingno,
            siblingdiscount: key === 'siblingdiscount' ? value : EditData.discountamount,
            [name]: value,
        }));
    };
    
    

    const handleSubmit = async(e) =>{
        e.preventDefault();
        setLoadSubmit(true)
        try {
            const response = await axiosInstance.post("/Master/AddSiblingDiscount", siblingDiscount)
            if (!response.data.Status === "success") {
                throw new Error("Sibling Discount Added Failed");
              }
        
              toast.success("Sibling Discount Added successfully!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchSiblingList()
              setSiblingDiscount({
                siblingno: "",
                siblingdiscount: "",
            })
              setLoadSubmit(false);
        } catch (error) {
            setLoadSubmit(false)
            toast.error("Sibling Discount Added failed", {
                position: toast.POSITION.TOP_RIGHT,
              });
        }
    }

    const fetchSiblingList = async () => {
      setLoading(true);
        try {
            const response = await axiosInstance.get("/Master/GetSiblingDiscount");
            setSiblingDiscountList(response.data.Payload)
        } catch (error) {
            // setLoading(false)
        }finally{
          setLoading(false);
        }
    }

    // Edit table Siblings data..
  const handleEditClick = (id) => {
    setEditingId(id);
  };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    const sessionPattern = /^[0-9\s'-]+$/;
    if (!sessionPattern.test(siblingDiscountUpdate.siblingno) || !sessionPattern.test(siblingDiscountUpdate.siblingdiscount)) {
      toast.error("This type of are not allowed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setEditingId(null);
    try {
      const response = await axiosInstance.post(
        "/Master/UpdateSiblingDiscount",
        siblingDiscountUpdate
      );
      if (!response.data.Status === "success") {
        throw new Error("Sibling updated failed");
      }
      toast.success("Sibling updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      siblingDiscountList.find(item => item.id === siblingDiscountUpdate.ids);
    } catch (error) {
      toast.error("Sibling update failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleCancelClick = () => {
    setEditingId(null);
  };

    useEffect(()=>{
        fetchSiblingList()
    },[])
  return (
     <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Sibling</h4>
          </div>
          <div class="col-lg-4">
            <form  onSubmit={handleSubmit}>
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Add Sibling Fee</div></div>
                <div class="card-body py-3 px-3">
                  <div class="row form-material">
                  <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                        No of Child<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        pattern="[0-9]{2}"
                        class="form-control"
                        placeholder="Enter No of child.."
                        name="siblingno"
                      value={siblingDiscount.siblingno}
                      onChange={(e) =>
                        setSiblingDiscount({
                          ...siblingDiscount,
                          siblingno: e.target.value,
                        })
                      }
                        required
                      />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">
                        Discount Amount<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                         pattern="[0-9]{9}"
                        class="form-control"
                        placeholder="Enter Fee.."
                        name="siblingdiscount"
                      value={siblingDiscount.siblingdiscount}
                      onChange={(e) =>
                        setSiblingDiscount({
                          ...siblingDiscount,
                          siblingdiscount: e.target.value,
                        })
                      }
                        required
                      />
                    </div>
                    <div className="text-end">
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary btn-block mb-3"
                      >
                        {loadSubmit ? (
                          <span
                            class="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-8">
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Sibling List</div></div>
                <div class="card-body py-3 px-3">
                {loading ? (
                  <div class="table-responsive">
                    <table class="table text-nowrap table-hover border table-bordered">
                      <thead class="">
                        <tr>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead >
                        <tr>
                          <th>#</th>
                          <th>No of Child.</th>
                          <th>Discount Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {siblingDiscountList ? (
                          siblingDiscountList.map((row) => (
                            <tr key={row.id} className="text-black fs-5" >
                              <td>{row.id}</td>
                              <td>
                                {editingId === row.id ? (
                                  <input
                                    type="text"
                                    name="siblingno"
                                    className="form-control"
                                    defaultValue={row.siblingno}
                                    onChange={(e) =>
                                      handleInputChange(e, row.id, "siblingno")
                                    }
                                  />
                                ) : (
                                  row.siblingno
                                )}
                              </td>
                              <td>
                                {editingId === row.id ? (
                                  <input
                                    type="text"
                                    name="siblingdiscount"
                                    className="form-control"
                                    defaultValue={row.discountamount}
                                    onChange={(e) =>
                                      handleInputChange(e, row.id, "siblingdiscount")
                                    }
                                  />
                                ) : (
                                  row.discountamount
                                )}
                              </td>
                              <td>
                                {editingId === row.id ? (
                                  <>
                                    <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                    <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                  </>
                                ) : (

                                  <span class="btn btn-primary shadow btn-xs sharp " onClick={() => handleEditClick(row.id)}><i class="fas fa-pencil-alt"></i></span>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <p>No data available</p>
                        )} 
                      </tbody>
                    </table>
                  </div>
                  </>)}
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageSiblings;