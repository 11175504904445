import React from 'react';
import { Link, useLocation } from 'react-router-dom';
function Header(props) {
  const location = useLocation();
  let path = location.pathname;
  const layout = props.layout ?? '';
  const logout = evt => {
    evt.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  }

  const plogout = evt => {
    evt.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/parent';
  }
  const tlogout = evt => {
    evt.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/teacher';
  }
  return (
    <>
      <div class="offcanvas offcanvas-start dlabnav-width   " tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header border-bottom">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel"><img src="/images/logo-mnb-schools.png" width="150" /></h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body  py-0 px-0">
          <div class="dlabnav">
            <div class="dlabnav-scroll mm-active">
              <div class="dropdown header-profile2 ">
                {layout && layout === 'public' ?
                  <Link class="btn btn-outline-secondary text-white" aria-current="page" to="/enquiry"> Admission Enquiry</Link>
                  : layout && layout === 'parent' ?
                    <>
                      <Link class="nav-link " role="button" data-bs-toggle="dropdown">
                        <div class="header-info2 d-flex align-items-center">
                          <img src="/images/profile.png" width="20" alt="" />
                          <div class="d-flex align-items-center sidebar-info">
                            <div>
                              <span class="font-w400 d-block">Parent</span>
                              <small class="text-end font-w400">Welcome Back</small>
                            </div>
                            <i class="fas fa-chevron-down"></i>
                          </div>
                        </div>
                      </Link>
                      <div class="dropdown-menu dropdown-menu-end">
                        <Link class="dropdown-item ai-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                          <span class="ms-2" onClick={plogout}>Logout </span>
                        </Link>
                      </div>
                    </>
                    :
                    layout && layout === 'teacher' ?
                      <>
                        <Link class="nav-link " role="button" data-bs-toggle="dropdown">
                          <div class="header-info2 d-flex align-items-center">
                            <img src="/images/profile.png" width="20" alt="" />
                            <div class="d-flex align-items-center sidebar-info">
                              <div>
                                <span class="font-w400 d-block">Teacher</span>
                                <small class="text-end font-w400">Welcome Back</small>
                              </div>
                              <i class="fas fa-chevron-down"></i>
                            </div>
                          </div>
                        </Link>
                        <div class="dropdown-menu dropdown-menu-end" data-bs-dismiss="offcanvas">
                          <Link to="/teacher/my-profile" class="dropdown-item ai-icon">
                            <i class="bi bi-person-bounding-box text-primary fs-4"></i>
                            <span class="ms-2" >My Profile </span>
                          </Link>
                          <Link class="dropdown-item ai-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                            <span class="ms-2" onClick={tlogout}>Logout </span>
                          </Link>
                        </div>
                      </>
                      :
                      // <div class="dropdown">
                      //   <Link class="btn btn-primary me-3 btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-person-circle text-white" style={{ fontSize: "20px" }}></i> <span class="fs-5">Admin</span></Link>
                      //   <ul class="dropdown-menu dropdown-menu-dark bg-black" onClick={logout}>
                      //     <li><button class="dropdown-item text-white"><i class="bi bi-box-arrow-right"></i> Logout</button></li>
                      //   </ul>
                      // </div>
                      <Link class="nav-link " role="button" data-bs-toggle="dropdown">
                      <div class="header-info2 d-flex align-items-center">
                        <img src="/images/profile.png" width="20" alt="" />
                        <div class="d-flex align-items-center sidebar-info">
                          <div>
                            <span class="font-w400 d-block">Admin</span>
                            <small class="text-end font-w400">Welcome Back</small>
                          </div>
                          <i class="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </Link>
                }
                <div class="dropdown-menu dropdown-menu-end">
                  {/* <a href="app-profile.html" class="dropdown-item ai-icon ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    <span class="ms-2">Profile </span>
                  </a>
                  <a href="email-inbox.html" class="dropdown-item ai-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                    <span class="ms-2">Inbox </span>
                  </a> */}
                  <a href="page-register.html" class="dropdown-item ai-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                    <span class="ms-2" onClick={logout}>Logout </span>
                  </a>
                </div>
              </div>
              <ul class="metismenu mm-show" id="menu">
                {layout && layout === 'admin' ?
                  <>
                    <li class="mm-active" data-bs-dismiss="offcanvas"><Link to="/admin/dashboard">	<i class="flaticon-025-dashboard"></i><span class="nav-text">Dashboard</span></Link></li>
                    <li data-bs-dismiss="offcanvas"><Link class="" aria-current="page" to="/admin/enquiries"><i class="bi bi-info-circle"></i><span class="nav-text">Enquiry</span> </Link>
                    </li>
                    <li>
                      <Link class="has-arrow " to="#Master" role="button" data-bs-toggle="collapse" aria-expanded="False" aria-controls="Master">
                        <i class="flaticon-093-waving"></i>
                        <span class="nav-text">Masters</span></Link>
                      <ul class="collapse" data-bs-parent="#navbarVerticalCollapse" id="Master">
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/master/manage-class" > Class Master</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/master/manage-section" > Section Master</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/master/manage-academic"> Academic Session</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/master/fee-type" > Fees Type</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/master/manage-fee"> Fees Master</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/master/manage-transport" > Transport Master</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/master/manage-slab" > Slab Master</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/master/manage-library" > Library Master</Link></li>
                        <li><Link class="" to="/admin/master/manage-late-fee"> Late Fee Master</Link></li>
                        <li><Link class="" to="/admin/master/manage-siblings"> Sibling Master</Link></li>
                        <li><Link class="" to="/admin/master/manage-vendor"> Vendor Master</Link></li>
                      </ul>
                    </li>
                    <li data-bs-dismiss="offcanvas" >
                      <Link class="" to="/admin/registration/manage-registration"><i class="bi bi-pencil-square"></i><span class="nav-text">Registration</span></Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/admin/students/list" ><i class="bi bi-people-fill"></i><span class="nav-text">Students</span> </Link>
                    </li>
                    {/* <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to="/admin/attendance/today-attendance"><i class="bi bi-pencil-square"></i> Attendance</Link>
                  </li> */}
                    {/* <li class="nav-item dropdown">
                    <Link class="nav-link dropdown-toggle text-white" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-pencil-square"></i> Attendance</Link>
                    <ul class="dropdown-menu dropdown-menu-dark bg-black">
                      <li><Link class="dropdown-item text-white" to="/admin/attendance/today-attendance"> Mark Attendance </Link></li>
                      <li><hr class="dropdown-divider bg-light" /></li>
                      <li><Link class="dropdown-item text-white" to="/admin/attendance/monthly-attendance"> Monthy Attendance </Link></li>
                      
                    </ul>
                  </li> */}
                    {/* <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to="/admin/manage-fee"><i class="la la-rupee"></i> Manage Fees</Link>
                  </li> */}
                    {/* <li data-bs-dismiss="offcanvas" >
                    <Link class="" to="/admin/report/fee-report"><i class="bi bi-graph-up"></i> <span class="nav-text">Report</span></Link>
                    </li> */}

                    <li>
                      <Link class="has-arrow " to="#Report" role="button" data-bs-toggle="collapse" aria-expanded="False" aria-controls="Report">
                        <i class="flaticon-093-waving"></i>
                        <span class="nav-text">Report</span></Link>
                      <ul class="collapse" data-bs-parent="#navbarVerticalCollapse" id="Report">
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/report/fee-report"> Fee Summary </Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/attendance/monthly-attendance"> Attendance</Link></li>
                      </ul>
                    </li>
                    <li data-bs-dismiss="offcanvas" >
                      <Link class="" aria-current="page" to="/admin/teachers"><i class="bi bi-person-lines-fill"></i><span class="nav-text">Teachers</span> </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" >
                      <Link class="" aria-current="page" to="/admin/library/manage-request"><i class="bi bi-book"></i><span class="nav-text">Library</span> </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" >
                      <Link class="" aria-current="page" to="/admin/IDCard/IdCard-list"><i class="bi bi-person-badge-fill"></i><span class="nav-text">Id Card</span> </Link>
                    </li>
                    {/* <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/admin/branch" ><i class="bi bi-house-fill"></i><span class="nav-text">Branch</span> </Link>
                    </li> */}
                    {/* <li>
                      <Link class="has-arrow " to="#Report" role="button" data-bs-toggle="collapse" aria-expanded="False" aria-controls="Report"><i class="bi bi-graph-up"></i><span class="nav-text">Report 11</span></Link>
                      <ul class="collapse" data-bs-parent="#navbarVerticalCollapse" id="Report">
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/attendance/mark-attendance" >Mark Attendance </Link></li>

                        <li data-bs-dismiss="offcanvas"> <Link class="" to="/admin/attendance/today-attendance" >Today Attendance </Link></li>

                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/attendance/monthly-attendance" >Monthy Attendance </Link></li>

                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/report/fee-summary" >Fee Summary </Link></li>

                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/report/fee-structure" >Fee Structure</Link></li>
                      </ul>
                    </li> */}
                    {/* <li class="nav-item dropdown">
                      <Link class="has-arrow " to="#Library" role="button" data-bs-toggle="collapse" aria-expanded="False" aria-controls="Library" ><i class="bi bi-book"></i><span class="nav-text">Library</span> </Link>
                      <ul class="collapse" data-bs-parent="#navbarVerticalCollapse" id="Library">
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/library/manage-request"> Books Request </Link></li>

                        <li data-bs-dismiss="offcanvas"><Link class="" to="/admin/library/book-list"> Books List </Link></li>

                      </ul>
                    </li> */}
                  </>
                  : ''
                }
                {layout && layout === 'parent' ?
                  <>
                    <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/parent/dashboard"><i class="bi bi-speedometer"></i>Dashboard</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/parent/student"><i class="la la-user"></i>Student </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/parent/assignment"><i class="la la-file"></i>Assignment</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/parent/library/apply-book"><i class="bi bi-book"></i>Library</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/parent/assign-teacher"><i class="la la-user-plus"></i>Assign Teacher</Link>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/parent/report/attendance/monthly-attendance"><i class="bi bi-pencil-square"></i><span class="nav-text">Attendance</span></Link>
                    </li>
                    <li class="">
                      <Link class="has-arrow " to="#PReport" role="button" data-bs-toggle="collapse" aria-expanded="False" aria-controls="PReport"><i class="bi bi-graph-up"></i> Report</Link>
                      <ul class="collapse" data-bs-parent="#navbarVerticalCollapse" id="PReport">
                        {/* <li data-bs-dismiss="offcanvas"><Link class="" to="/parent/report/attendance/monthly-attendance"> Attendance</Link></li> */}
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/parent/report/fee-structure"> Fee Structure</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/parent/report/fee-summary"> Fee Summary</Link></li>
                      </ul>
                    </li>
                    <li data-bs-dismiss="offcanvas">
                      <Link class="" aria-current="page" to="/parent/complaint"><i class="bi bi-envelope-fill"></i><span class="nav-text">Complaint</span></Link>
                    </li>
                  </>
                  : ''
                }
                {layout && layout === 'super-admin' ?
                  <></>
                  : ''
                }
                {layout && layout === 'teacher' ?
                  <>
                    <li class="mm-active" data-bs-dismiss="offcanvas">
                      <Link class="" to="/teacher/dashboard"><i class="bi bi-speedometer"></i><span class="nav-text">Dashboard</span></Link>
                    </li>
                    {/* <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to="/teacher/manage-class"><i class="la la-user"></i> Assigned Class </Link>
                  </li> */}
                    {/* <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to="/teacher/manage-class"><i class="bi bi-book"></i> Assigned Class </Link>
                  </li> */}
                    <li class="" data-bs-dismiss="offcanvas">
                      <Link class="" to="/teacher/manage-assignment"><i class="la la-file"></i><span class="nav-text">Assignment</span></Link>
                    </li>
                    <li class="" data-bs-dismiss="offcanvas">
                      <Link class="" to="/teacher/attendance/mark-attendance"><i class="bi bi-pencil-square"></i><span class="nav-text">Mark Attendance</span></Link>
                    </li>
                    <li  data-bs-dismiss="offcanvas">
                  <Link class="" aria-current="page" to="/teacher/leave/student-leave"><i class="bi bi-bell"></i>Student Leave</Link>
                </li>
                <li  data-bs-dismiss="offcanvas">
                  <Link class="" aria-current="page" to="/teacher/leave/leave-apply"><i class="bi bi-pencil-square"></i>Leave Apply</Link>
                </li>
                    {/* <li class="" >
                      <Link class="has-arrow " to="#Attendance" role="button" data-bs-toggle="collapse" aria-expanded="False" aria-controls="Attendance"><i class="bi bi-pencil-square"></i> <span class="nav-text">Mark Attendance</span></Link>
                      <ul class="collapse" data-bs-parent="#navbarVerticalCollapse" id="Attendance">
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/teacher/attendance/mark-attendance">Mark Attendance</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/teacher/attendance/today-attendance">Today Attendance</Link></li>
                        <li data-bs-dismiss="offcanvas"><Link class="" to="/teacher/attendance/month-attendance">Month Attendance</Link></li>
                      </ul>
                    </li> */}
                  </>
                  : ''
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-header">
        <Link class="brand-logo" to={layout && layout === 'admin' ? "/admin/dashboard" : (layout && layout === 'teacher' ? "/teacher/dashboard" : (layout && layout === 'parent' ? '/parent/dashboard' : '/'))}>
          <img class="logo-abbr" src="/images/icon.png" />
          <img class="brand-title" src="/images/cutlogo.png" />
        </Link>
        <div class="nav-control">
          <div class="hamburger " data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
            <span class="line"></span><span class="line"></span><span class="line"></span>
          </div>
        </div>
      </div>
      <div class="header">
        <div class="header-content">
          {layout && layout === 'superAdmin' ? '' :
            <nav class="navbar navbar-expand">
              <div class="collapse navbar-collapse justify-content-between">
                <div class="header-left">
                  <div class="nav-item d-flex align-items-center">
                    <div class="input-group search-area">
                      <input type="text" class="form-control" placeholder="Search" />
                      <span class="input-group-text"><a href="/"><i class="flaticon-381-search-2"></i></a></span>
                    </div>
                    <div class="plus-icon">
                      <a href="/" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg"><i class="fas fa-plus"></i></a>
                    </div>
                  </div>
                </div>
                <ul class="navbar-nav header-right">
                  <li class="nav-item dropdown notification_dropdown">
                  <Link class="nav-link" to={layout && layout === 'admin' ? '/admin/parent-complaint' :'#'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22.871" viewBox="0 0 24 22.871">
                        <g data-name="Layer 2" transform="translate(-2 -2)">
                          <path id="Path_9" data-name="Path 9" d="M23.268,2H4.73A2.733,2.733,0,0,0,2,4.73V17.471A2.733,2.733,0,0,0,4.73,20.2a.911.911,0,0,1,.91.91v1.94a1.82,1.82,0,0,0,2.83,1.514l6.317-4.212a.9.9,0,0,1,.5-.153h4.436a2.742,2.742,0,0,0,2.633-2L25.9,5.462A2.735,2.735,0,0,0,23.268,2Zm.879,2.978-3.539,12.74a.915.915,0,0,1-.88.663H15.292a2.718,2.718,0,0,0-1.514.459L7.46,23.051v-1.94a2.733,2.733,0,0,0-2.73-2.73.911.911,0,0,1-.91-.91V4.73a.911.911,0,0,1,.91-.91H23.268a.914.914,0,0,1,.879,1.158Z" transform="translate(0 0)"></path>
                          <path id="Path_10" data-name="Path 10" d="M7.91,10.82h4.55a.91.91,0,1,0,0-1.82H7.91a.91.91,0,1,0,0,1.82Z" transform="translate(-0.45 -0.63)"></path>
                          <path id="Path_11" data-name="Path 11" d="M16.1,13H7.91a.91.91,0,1,0,0,1.82H16.1a.91.91,0,1,0,0-1.82Z" transform="translate(-0.45 -0.99)"></path>
                        </g>
                      </svg>
                      <span class="badge light text-white bg-primary rounded-circle">76</span>
                    </Link>
                  </li>
                  <li class="nav-item dropdown notification_dropdown">
                    <Link class="nav-link" to="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g data-name="Layer 2" transform="translate(-2 -2)">
                          <path id="Path_20" data-name="Path 20" d="M22.571,15.8V13.066a8.5,8.5,0,0,0-7.714-8.455V2.857a.857.857,0,0,0-1.714,0V4.611a8.5,8.5,0,0,0-7.714,8.455V15.8A4.293,4.293,0,0,0,2,20a2.574,2.574,0,0,0,2.571,2.571H9.8a4.286,4.286,0,0,0,8.4,0h5.23A2.574,2.574,0,0,0,26,20,4.293,4.293,0,0,0,22.571,15.8ZM7.143,13.066a6.789,6.789,0,0,1,6.78-6.78h.154a6.789,6.789,0,0,1,6.78,6.78v2.649H7.143ZM14,24.286a2.567,2.567,0,0,1-2.413-1.714h4.827A2.567,2.567,0,0,1,14,24.286Zm9.429-3.429H4.571A.858.858,0,0,1,3.714,20a2.574,2.574,0,0,1,2.571-2.571H21.714A2.574,2.574,0,0,1,24.286,20a.858.858,0,0,1-.857.857Z"></path>
                        </g>
                      </svg>
                      <span class="badge light text-white bg-primary rounded-circle">4</span>
                    </Link>
                    {/* <div class="dropdown-menu dropdown-menu-end">
                      <div id="DZ_W_Notification1" class="widget-media dlab-scroll p-3" >
                        <ul class="timeline">
                          <li>
                            <div class="timeline-panel">
                              <div class="media me-2">
                                <img alt="image" width="50" src="images/avatar/1.jpg" />
                              </div>
                              <div class="media-body">
                                <h6 class="mb-1">Dr sultads Send you Photo</h6>
                                <small class="d-block">29 July 2020 - 02:26 PM</small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="timeline-panel">
                              <div class="media me-2 media-info">
                                KG
                              </div>
                              <div class="media-body">
                                <h6 class="mb-1">Resport created successfully</h6>
                                <small class="d-block">29 July 2020 - 02:26 PM</small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="timeline-panel">
                              <div class="media me-2 media-success">
                                <i class="fa fa-home"></i>
                              </div>
                              <div class="media-body">
                                <h6 class="mb-1">Reminder : Treatment Time!</h6>
                                <small class="d-block">29 July 2020 - 02:26 PM</small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="timeline-panel">
                              <div class="media me-2">
                                <img alt="image" width="50" src="images/avatar/1.jpg" />
                              </div>
                              <div class="media-body">
                                <h6 class="mb-1">Dr sultads Send you Photo</h6>
                                <small class="d-block">29 July 2020 - 02:26 PM</small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="timeline-panel">
                              <div class="media me-2 media-danger">
                                KG
                              </div>
                              <div class="media-body">
                                <h6 class="mb-1">Resport created successfully</h6>
                                <small class="d-block">29 July 2020 - 02:26 PM</small>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="timeline-panel">
                              <div class="media me-2 media-primary">
                                <i class="fa fa-home"></i>
                              </div>
                              <div class="media-body">
                                <h6 class="mb-1">Reminder : Treatment Time!</h6>
                                <small class="d-block">29 July 2020 - 02:26 PM</small>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a class="all-notification" href="/">See all notifications <i class="ti-arrow-end"></i></a>
                    </div> */}
                  </li>
                  <li class="nav-item dropdown notification_dropdown">
                    <Link class="nav-link " to="/admin/setting/change-password" data-bs-toggle="dropdown">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23.262" height="24" viewBox="0 0 23.262 24">
                        <g id="icon" transform="translate(-1565 90)">
                          <path id="setting_1_" data-name="setting (1)" d="M30.45,13.908l-1-.822a1.406,1.406,0,0,1,0-2.171l1-.822a1.869,1.869,0,0,0,.432-2.385L28.911,4.293a1.869,1.869,0,0,0-2.282-.818l-1.211.454a1.406,1.406,0,0,1-1.88-1.086l-.213-1.276A1.869,1.869,0,0,0,21.475,0H17.533a1.869,1.869,0,0,0-1.849,1.567L15.47,2.842a1.406,1.406,0,0,1-1.88,1.086l-1.211-.454a1.869,1.869,0,0,0-2.282.818L8.126,7.707a1.869,1.869,0,0,0,.432,2.385l1,.822a1.406,1.406,0,0,1,0,2.171l-1,.822a1.869,1.869,0,0,0-.432,2.385L10.1,19.707a1.869,1.869,0,0,0,2.282.818l1.211-.454a1.406,1.406,0,0,1,1.88,1.086l.213,1.276A1.869,1.869,0,0,0,17.533,24h3.943a1.869,1.869,0,0,0,1.849-1.567l.213-1.276a1.406,1.406,0,0,1,1.88-1.086l1.211.454a1.869,1.869,0,0,0,2.282-.818l1.972-3.415a1.869,1.869,0,0,0-.432-2.385ZM27.287,18.77l-1.211-.454a3.281,3.281,0,0,0-4.388,2.533l-.213,1.276H17.533l-.213-1.276a3.281,3.281,0,0,0-4.388-2.533l-1.211.454L9.75,15.355l1-.822a3.281,3.281,0,0,0,0-5.067l-1-.822L11.721,5.23l1.211.454A3.281,3.281,0,0,0,17.32,3.151l.213-1.276h3.943l.213,1.276a3.281,3.281,0,0,0,4.388,2.533l1.211-.454,1.972,3.414h0l-1,.822a3.281,3.281,0,0,0,0,5.067l1,.822ZM19.5,7.375A4.625,4.625,0,1,0,24.129,12,4.63,4.63,0,0,0,19.5,7.375Zm0,7.375A2.75,2.75,0,1,1,22.254,12,2.753,2.753,0,0,1,19.5,14.75Z" transform="translate(1557.127 -90)"></path>
                        </g>
                      </svg>
                      <span class="badge light text-white bg-primary rounded-circle">15</span>
                    </Link>
                    <div class="dropdown-menu dropdown-menu-end">
                      <div id="DZ_W_TimeLine02" class="widget-timeline dlab-scroll style-1 p-3 height370">
                        <ul class="timeline">
                          <li>
                            <div class="timeline-badge primary"></div>
                            <a class="timeline-panel text-muted" href="/">
                              <span>10 minutes ago</span>
                              <h6 class="mb-0">Youtube, a video-sharing website, goes live <strong class="text-primary">$500</strong>.</h6>
                            </a>
                          </li>
                          <li>
                            <div class="timeline-badge info">
                            </div>
                            <a class="timeline-panel text-muted" href="/">
                              <span>20 minutes ago</span>
                              <h6 class="mb-0">New order placed <strong class="text-info">#XF-2356.</strong></h6>
                              <p class="mb-0">Quisque a consequat ante Sit amet magna at volutapt...</p>
                            </a>
                          </li>
                          <li>
                            <div class="timeline-badge danger">
                            </div>
                            <a class="timeline-panel text-muted" href="/">
                              <span>30 minutes ago</span>
                              <h6 class="mb-0">john just buy your product <strong class="text-warning">Sell $250</strong></h6>
                            </a>
                          </li>
                          <li>
                            <div class="timeline-badge success">
                            </div>
                            <a class="timeline-panel text-muted" href="/">
                              <span>15 minutes ago</span>
                              <h6 class="mb-0">StumbleUpon is acquired by eBay. </h6>
                            </a>
                          </li>
                          <li>
                            <div class="timeline-badge warning">
                            </div>
                            <a class="timeline-panel text-muted" href="/">
                              <span>20 minutes ago</span>
                              <h6 class="mb-0">Mashable, a news website and blog, goes live.</h6>
                            </a>
                          </li>
                          <li>
                            <div class="timeline-badge dark">
                            </div>
                            <a class="timeline-panel text-muted" href="/">
                              <span>20 minutes ago</span>
                              <h6 class="mb-0">Mashable, a news website and blog, goes live.</h6>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item dropdown header-profile">
                    <a class="nav-link" href="/" role="button" data-bs-toggle="dropdown">
                      <img src="/images/profile.png" width="20" alt="" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                      {/* <Link to={layout && layout === 'admin' ? "/admin/profile/admin-profile" : (layout && layout === 'teacher' ? "/teacher/dashboard" : (layout && layout === 'parent' ? '/parent/student' : '/'))} class="dropdown-item ai-icon">
                        <svg id="icon-user2" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <span class="ms-2">Profile </span>
                      </Link> */}
                      <Link to={layout && layout === 'admin' ? "/admin/setting/change-password" : '#'} class="dropdown-item ai-icon">
                        <i class="bi bi-gear fs-4 text-success"></i>
                        <span class="ms-2">Setting </span>
                      </Link>
                      <Link class="dropdown-item ai-icon" onClick={layout === 'admin' ? logout : (layout === 'parent' ? plogout : (layout === 'teacher' ? tlogout : null))}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        {/* <span class="ms-2" onClick={logout}>Logout </span> */}
                        <span className="ms-2">Logout</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          }
        </div>
      </div>
      <div class="dlabnav desktopmenu ">
        <div class="dlabnav-scroll mm-active">
          <div class="dropdown header-profile2 ">
            <a class="nav-link " href="/" role="button" data-bs-toggle="dropdown">
              <div class="header-info2 d-flex align-items-center">
                <img src="/images/profile.png" width="20" alt="" />
                <div class="d-flex align-items-center sidebar-info">
                  <div>
                    <span class="font-w400 d-block">Franklin Jr</span>
                    <small class="text-end font-w400">Superadmin</small>
                  </div>
                  <i class="fas fa-chevron-down"></i>
                </div>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a href="app-profile.html" class="dropdown-item ai-icon ">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                <span class="ms-2">Profile </span>
              </a>
              <a href="email-inbox.html" class="dropdown-item ai-icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                <span class="ms-2">Inbox </span>
              </a>
              <a href="page-register.html" class="dropdown-item ai-icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                <span class="ms-2">Logout </span>
              </a>
            </div>
          </div>
          <ul class="metismenu mm-show" id="menu">
            {layout && layout === 'admin' ?
              <>
                <li class={`mm-${path === '/admin/dashboard' ? 'active' : ""}`} ><Link to="/admin/dashboard">	<i class="flaticon-025-dashboard"></i> 	<span class="nav-text">Dashboard</span></Link></li>
                <li class={`mm-${path === '/admin/enquiries' ? 'active' : ''}`}><Link to="/admin/enquiries"><i class="bi bi-info-circle"></i><span class="nav-text">Enquiry</span> </Link>
                </li>
                <li class={`mm-${path === '/admin/master/manage-class' || path === '/admin/master/manage-section' || path === '/admin/master/fee-type' || path === '/admin/master/manage-fee' || path === '/admin/master/manage-transport' || path === '/admin/master/manage-slab' || path === '/admin/master/manage-library' || path === '/admin/master/manage-academic' || path === '/admin/master/manage-late-fee' || path === '/admin/master/manage-vendor' || path === '/admin/master/manage-notice' || path === '/admin/master/manage-siblings' ? 'active' : ''}`}>
                  <Link class="" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/">
                    <i class="flaticon-093-waving"></i>
                    <span class="nav-text">Masters</span></Link>
                  <ul class="mm-collapse">
                    <li><Link class="" to="/admin/master/manage-class"> Class Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-section"> Section Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-academic"> Academic Session</Link></li>
                    <li><Link class="" to="/admin/master/fee-type"> Fees Type</Link></li>
                    <li><Link class="" to="/admin/master/manage-fee"> Fees Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-transport"> Transport Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-notice"> Notice Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-slab"> Slab Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-library"> Library Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-late-fee"> Late Fee Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-siblings"> Sibling Master</Link></li>
                    <li><Link class="" to="/admin/master/manage-vendor"> Vendor Master</Link></li>
                  </ul>
                </li>
                <li class={`mm-${path === '/admin/registration/manage-registration' || path === '/admin/registration/create' ? 'active' : ''}`}>
                  <Link aria-current="page" to="/admin/registration/manage-registration"><i class="bi bi-pencil-square"></i>Registration</Link>
                </li>
                <li class={`mm-${path === '/admin/students/list' || path === '/admin/IDCard/IdCard-list' || path === '/admin/students/block-list' ? 'active' : ''}`}>
                  <Link aria-current="page" to="/admin/students/list"><i class="bi bi-people-fill"></i> <span class="nav-text">Students</span> </Link>
                </li>
                {/* <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to="/admin/attendance/today-attendance"><i class="bi bi-pencil-square"></i> Attendance</Link>
                  </li> */}
                {/* <li class="nav-item dropdown">
                    <Link class="nav-link dropdown-toggle text-white" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-pencil-square"></i> Attendance</Link>
                    <ul class="dropdown-menu dropdown-menu-dark bg-black">
                      <li><Link class="dropdown-item text-white" to="/admin/attendance/today-attendance"> Mark Attendance </Link></li>
                      <li><hr class="dropdown-divider bg-light" /></li>
                      <li><Link class="dropdown-item text-white" to="/admin/attendance/monthly-attendance"> Monthy Attendance </Link></li>
                      
                    </ul>
                  </li> */}
                {/* <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to="/admin/manage-fee"><i class="la la-rupee"></i> Manage Fees</Link>
                  </li> */}
                <li class={`mm-${path === '/admin/teachers' || path ==='/admin/teachers/block-list' || path === '/admin/teachers/create' ? 'active' : ''}`}>
                  <Link aria-current="page" to="/admin/teachers"><i class="bi bi-person-lines-fill"></i> <span class="nav-text">Teachers</span> </Link>
                </li>
                {/* <li class={`mm-${path === '/admin/branch' ? 'active' : ''}`} >
                  <Link aria-current="page" to="/admin/branch"><i class="bi bi-house-fill"></i> <span class="nav-text">Branch</span> </Link>
                </li> */}
                {/* <li>
                  <Link class="" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-graph-up"></i> <span class="nav-text">Report</span></Link>
                  <ul class="mm-collapse">
                    <li><Link class="" to="/admin/attendance/mark-attendance">Mark Attendance </Link></li>
                    <li><Link class="" to="/admin/attendance/today-attendance">Today Attendance </Link></li>
                    <li><Link class="" to="/admin/attendance/monthly-attendance">Monthy Attendance </Link></li>
                    <li><Link class="" to="/admin/report/fee-summary">Fee Summary </Link></li>
                    <li><Link class="" to="/admin/report/fee-structure">Fee Structure</Link></li>
                  </ul>
                </li> */}

                {/* <li class={`mm-${path === '/admin/report/fee-report' ? 'active' : ''}`}>
                  <Link to="/admin/report/fee-report"><i class="bi bi-graph-up"></i> <span class="nav-text">Report</span></Link>
                </li> */}
                <li class={`mm-${path === '/admin/report/fee-report' || path === '/admin/attendance/monthly-attendance' || path === '/admin/expense/manage-expense' || path === "/admin/parent-complaint" ? 'active' : ''}`}>
                  <Link class="" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-graph-up"></i> 	<span class="nav-text">Report</span> </Link>
                  <ul class="mm-collapse">
                    <li><Link to="/admin/expense/manage-expense"> <span class="nav-text">Manage Expense</span></Link></li>
                    <li><Link class="" to="/admin/report/fee-report"> Fee Summary </Link></li>
                    <li><Link class="" to="/admin/attendance/monthly-attendance"> Attendance</Link></li>
                    <li><Link class="" to="/admin/parent-complaint"> Complain</Link></li>
                  </ul>
                </li>
                <li class={`mm-${path === '/admin/library/manage-request' ? 'active' : ''}`}>
                  <Link to="/admin/library/manage-request"><i class="bi bi-book"></i> <span class="nav-text">Library</span></Link>
                </li>
                <li class={`mm-${path === '/admin/leave/student-leave' || path === '/admin/leave/teacher-leave' ? 'active' : ''}`}>
                  <Link class="" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-pencil-square"></i> 	<span class="nav-text">Leave</span> </Link>
                  <ul class="mm-collapse">
                    <li><Link to="/admin/leave/student-leave"> <span class="nav-text">Student Leave</span></Link></li>
                    <li><Link class="" to="/admin/leave/teacher-leave">Teacher Leave </Link></li>
                  </ul>
                </li>
                {/* <li  class={`mm-${path === '/admin/IDCard/IdCard-list' ? 'active' : ''}`}>
                      <Link class="" aria-current="page" to="/admin/IDCard/IdCard-list"><i class="bi bi-person-badge-fill"></i><span class="nav-text">Id Card</span> </Link>
                    </li> */}
                {/* <li class="nav-item dropdown">
                  <Link class="" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-book"></i> 	<span class="nav-text">Library</span> </Link>
                  <ul class="mm-collapse">
                    <li><Link class="" to="/admin/library/manage-request"> Books Request </Link></li>
                    <li><Link class="" to="/admin/library/book-list"> Books List </Link></li>
                  </ul>
                </li> */}
              </>
              : ''
            }
            {layout && layout === 'parent' ?
              <>
                <li class={`mm-${path === '/parent/dashboard' ? 'active' : ''}`}>
                  <Link class="" to="/parent/dashboard"><i class="bi bi-speedometer"></i><span class="nav-text">Dashboard</span></Link>
                </li>
                <li class={`mm-${path === '/parent/student' ? 'active' : ''}`}>
                  <Link class="" to="/parent/student"><i class="la la-user"></i><span class="nav-text">Student</span></Link>
                </li>
                <li class={`mm-${path === '/parent/assignment' ? 'active' : ''}`}>
                  <Link class="" to="/parent/assignment"><i class="la la-file"></i><span class="nav-text">Assignment</span></Link>
                </li>
                <li class={`mm-${path === '/parent/library/apply-book' ? 'active' : ''}`}>
                  <Link class="" to="/parent/library/apply-book"><i class="bi bi-book"></i><span class="nav-text">Library</span></Link>
                </li>
                <li class={`mm-${path === '/parent/assign-teacher' ? 'active' : ''}`}>
                  <Link class="" to="/parent/assign-teacher"><i class="la la-user-plus"></i><span class="nav-text">Assign Teacher</span></Link>
                </li>
                <li class={`mm-${path === '/parent/report/attendance/monthly-attendance' ? 'active' : ''}`}>
                  <Link class="" to="/parent/report/attendance/monthly-attendance"><i class="bi bi-pencil-square"></i><span class="nav-text">Attendance</span></Link>
                </li>
                <li class={`mm-${path === '/parent/report/fee-structure' || path === '/parent/report/fee-summary' ? 'active' : ''}`}>
                  <Link class="" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-graph-up"></i><span class="nav-text">Report</span> </Link>
                  <ul class="mm-collapse">
                    {/* <li><Link class="" to="/parent/report/attendance/monthly-attendance"> Attendance</Link></li> */}
                    <li><Link class="" to="/parent/report/fee-structure"> Fee Structure</Link></li>
                    <li><Link class="" to="/parent/report/fee-summary"> Fee Summary</Link></li>
                  </ul>
                </li>
                <li class={`mm-${path === '/parent/leave/leave-apply' || path === '/parent/complaint' ? 'active' : ''}`}>
                  <Link class="" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-pencil-square"></i><span class="nav-text">Leave & Complain</span> </Link>
                  <ul class="mm-collapse">
                    <li>
                      <Link class="" to="/parent/leave/leave-apply"><span class="nav-text">Leave</span></Link>
                    </li>
                    <li>
                      <Link class="" aria-current="page" to="/parent/complaint"><span class="nav-text">Complaint</span></Link>
                    </li>
                  </ul>
                </li>
                {/* <li class={`mm-${path === '/parent/leave/leave-apply' ? 'active' : ''}`}>
                  <Link class="" to="/parent/leave/leave-apply"><i class="bi bi-pencil-square"></i><span class="nav-text">Leave</span></Link>
                </li>
                <li class={`mm-${path === '/parent/complaint' ? 'active' : ''}`}>
                 <Link class="" aria-current="page" to="/parent/complaint"><i class="bi bi-pencil-square"></i><span class="nav-text">Complaint</span></Link>
                </li> */}
              </>
              : ''
            }
            {layout && layout === 'super-admin' ?
              <></>
              : ''
            }
            {layout && layout === 'teacher' ?
              <>
                <li class={`mm-${path === '/teacher/dashboard' ? 'active' : ''}`}>
                  <Link class="" aria-current="page" to="/teacher/dashboard"><i class="bi bi-speedometer"></i> Dashboard</Link>
                </li>
                {/* <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to="/teacher/manage-class"><i class="la la-user"></i> Assigned Class </Link>
                  </li> */}
                {/* <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to="/teacher/manage-class"><i class="bi bi-book"></i> Assigned Class </Link>
                  </li> */}
                <li class={`mm-${path === '/teacher/manage-assignment' ? 'active' : ''}`}>
                  <Link class="" aria-current="page" to="/teacher/manage-assignment"><i class="la la-file"></i> Assignment</Link>
                </li>
                <li class={`mm-${path === '/teacher/attendance/mark-attendance' ? 'active' : ''}`}>
                  <Link class="" aria-current="page" to="/teacher/attendance/mark-attendance"><i class="bi bi-pencil-square"></i>Mark Attendance</Link>
                </li>
                <li class={`mm-${path === '/teacher/leave/student-leave' ? 'active' : ''}`}>
                  <Link class="" aria-current="page" to="/teacher/leave/student-leave"><i class="bi bi-bell"></i>Student Leave</Link>
                </li>
                <li class={`mm-${path === '/teacher/leave/leave-apply' ? 'active' : ''}`}>
                  <Link class="" aria-current="page" to="/teacher/leave/leave-apply"><i class="bi bi-pencil-square"></i>Leave Apply</Link>
                </li>
                {/* <li class="">
                  <Link class="" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" href="/"><i class="bi bi-pencil-square"></i>Mark Attendance</Link>
                  <ul class="mm-collapse">
                    <li><Link class="" to="/teacher/attendance/mark-attendance"> Mark Attendance</Link></li>
                    <li><Link class="" to="/teacher/attendance/today-attendance"> Today Attendance</Link></li>
                    <li><Link class="" to="/teacher/attendance/month-attendance"> Month Attendance</Link></li>
                  </ul>
                </li> */}
              </>
              : ''
            }
          </ul>
        </div>
      </div>
    </>);
}

export default Header;