import React from 'react';
function View_branch() {
    return (<>
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div class="card-header">
                        <h3 class="me-auto p-2 text-center">Branch Details</h3>
                    </div>
                </div>
                <div class="row">
                    {/* <div class="col-xl-3 col-xxl-4">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-body pb-4">
                                        <h4 class="fs-20 mb-3">Guardian Details</h4>
                                        <div class=""><hr />
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian relationship :</span><span class="font-w400">Son</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Name :</span><span class="font-w400"> Ramesh Yadav</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian's Email :</span><span class="font-w400"> admin@gmail.com</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Mobile :</span><span class="font-w400"> 5654645645</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian nature of work : </span><span class="font-w400"> Job</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Employer  : </span><span class="font-w400"> Government</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Sector : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Firm / Department  : </span><span class="font-w400"></span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Designation  : </span><span class="font-w400"> </span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Aadhar No. : </span><span class="font-w400">098765432123</span></p>
                                            <p class="font-w500 mb-3"><span class="custom-label">Guardian Pan No. : </span><span class="font-w400">YG534534545 </span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </>);
}

export default View_branch;