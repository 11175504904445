import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
function FeeStructure() {

    const [data, setData] = useState([
        { id: 1, class: 'Nersery', registration: 1000, tuition: 1000, exam: 100, lab: 100, sport: 100, transport: 1000 },
        { id: 2, class: 'LKG', registration: 1000, tuition: 1500, exam: 100, lab: 100, sport: 100, transport: 1000 },
        { id: 3, class: 'UKG', registration: 1000, tuition: 2000, exam: 100, lab: 100, sport: 100, transport: 1000 },
        // Add more rows as needed
    ]);
    return (<>
        <div className="mt-4">
            <div className="container-fluid">
                <div className="row">
                    <h3 class="text-center">Fee Structure</h3>
                    <div class="col-lg-12 pt-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>Class</th>
                                                <th>Registration Fee</th>
                                                <th>Tuition Fee</th>
                                                <th>Exam Fee</th>
                                                <th>Lab Fee</th>
                                                <th>Sports Fee</th>
                                                <th>Transport Fee</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((row) => (
                                                <tr className="text-black fs-5" key={row.id}>
                                                    <td>{row.class}</td>
                                                    <td>{row.registration}</td>
                                                    <td>{row.tuition}</td>
                                                    <td>{row.exam}</td>
                                                    <td>{row.lab}</td>
                                                    <td>{row.sport}</td>
                                                    <td>{row.transport}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default FeeStructure;