import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
function Class() {
  const [SubmitData, setSubmitData] = useState({
    sclass: "",
  });
  const [ClassData, setClassData] = useState({
    id: "",
    sclass: "",
  });
  const [classLoading, setClassLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setClassLoading(true);
    try {
      const response = await axiosInstance.post("/Master/Addclass", SubmitData);
      if (!response.data.Status === "success") {
        throw new Error("Add class failed");
      }
      toast.success("Class Added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSubmitData({ sclass: "" })
      fetchData();
      setClassLoading(false);
    } catch (error) {
      setClassLoading(false);
      toast.error("Add class failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // Table Data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/Master/GetClass`);
      setData(response.data.Payload);
    } catch (error) {
      // toast.error("Authentication failed !");
      window.location.href = "/";
    }finally{
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // Edit table data..
  const [editingId, setEditingId] = useState(null);
  const handleEditClick = (id) => {
    setEditingId(id);
  };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    const sessionPattern = /^[A-Za-z0-9\s'-]+$/;
    if (!sessionPattern.test(ClassData.sclass)) {
      toast.error("Special characters are not allowed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setEditingId(null);
    try {
      const response = await axiosInstance.post(
        "/Master/UpdateClass",
        ClassData
      );
      if (!response.data.Status === "success") {
        throw new Error("Class updated failed");
      }
      toast.success("Class updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error("Class update failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleCancelClick = () => {
    setEditingId(null);
  };
  const handleInputChange = (e, id, key) => {
    const newData = data.map((item) =>
      item.id === id ? { ...item, [key]: e.target.value } : item
    );
    setClassData((prev) => ({ ...prev, id: id, sclass: e.target.value }));
    setData(newData);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Manage Class</h4>
          </div>
          <div class="col-lg-4">
            <form onSubmit={handleSubmit}>
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Add Class</div></div>
                <div class="card-body text-black fw-bold fs-6">
                  <div class=" form-material mb-3">
                    <label class="form-label">
                      Class<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter class name.."
                      name="sclass"
                      pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                      value={SubmitData.sclass}
                      onChange={(e) =>
                        setSubmitData({
                          ...SubmitData,
                          sclass: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="text-end">
                    <button
                      type="submit"
                      class="btn btn-primary mb-3 btn-block" >
                      {classLoading ? (
                        <span
                          class="spinner-border spinner-border-sm me-2 shadow bg-body-tertiary rounded"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-8">
            <div class="card custom-card mb-3">
              <div class="card-header justify-content-between"><div class="card-title">Class List</div></div>
              <div class="card-body py-3 px-3">
                {loading ? (
                  <div class="table-responsive">
                    <table class="table text-nowrap table-hover border table-bordered">
                      <thead class="">
                        <tr>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                          <th><p class="placeholder-wave">
                            <span class="placeholder col-12"></span>
                          </p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                        <tr class="text-black fs-5">
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td><p class="placeholder-glow">
                            <span class="placeholder col-12"></span>
                          </p></td>
                          <td class="text-nowrap">
                            <span class="btn btn-primary light btn-xs disabled placeholder col-12" >
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <>
                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Class</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((row) => (
                              <tr className="text-black fs-5" key={row.id}>
                                <td>{row.id}</td>
                                <td>
                                  {editingId === row.id ? (
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={row.sclass}
                                      onChange={(e) =>
                                        handleInputChange(e, row.id, "sclass")
                                      }
                                    />
                                  ) : (
                                    row.sclass ?? ""
                                  )}
                                </td>
                                <td>
                                  {editingId === row.id ? (
                                    <>
                                      <span class="btn btn-primary shadow btn-xs sharp " onClick={handleSaveClick} ><i class="bi bi-check2-all fs-6"></i></span>{" "}
                                      <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span>
                                    </>
                                  ) : (
                                    <span class="btn btn-primary shadow btn-xs sharp " onClick={() => handleEditClick(row.id)}><i class="fas fa-pencil-alt"></i></span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          {!data && <p>No data available</p>}
                        </tbody>
                      </table>
                    </div>
                  </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Class;
