import React from "react";
import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../parent-helper";
const ViewAttendance = () => {
    const { monthno } = useParams();
    // const { month } = useParams();
    const [currentMonth, setCurrentMonth] = useState(() => {
        const date = new Date();
        date.setMonth(monthno - 1);
        return date;
    });

    const dateWithMonth = new Date(new Date().getFullYear(), monthno - 1);
    const [attendance, setAttendance] = useState({});
    const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        return new Date(year, month + 1, 0).getDate();
    };

    const getMonthDaysArray = (date) => {
        const daysInMonth = getDaysInMonth(date);
        const daysArray = [];
        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push(i);
        }
        return daysArray;
    };

    const toggleAttendance = (day) => {
        const newAttendance = { ...attendance };
        newAttendance[day] = !newAttendance[day];
        setAttendance(newAttendance);
    };

    const renderCalendar = () => {
        const daysArray = getMonthDaysArray(currentMonth);
        const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
        const blanks = [];
        for (let i = 0; i < firstDayOfMonth; i++) {
            blanks.push(<td key={`blank-${i}`}></td>);
        }

        const calendarCells = blanks.concat(
            daysArray.map((day, index) => {
                const attendanceDataForDay = data ? data.find(item => new Date(item.date).getDate() === day) : null;
                const isAttended = attendanceDataForDay ? attendanceDataForDay.attendance === 'Present' : false;
                const isAbsent = attendanceDataForDay ? attendanceDataForDay.attendance === 'Absent' : false;
                const isSunday = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day).getDay() === 0;

                let cellStyle = {};
                if (isAttended) {
                    cellStyle.backgroundColor = '#4ec53b';
                } else if (isAbsent) {
                    cellStyle.backgroundColor = '#f33737';
                } else if (isSunday) {
                    cellStyle.backgroundColor = 'orange';
                } else if (!isAttended) {
                    cellStyle.backgroundColor = '#f5f5f5';
                }

                return (
                    <td key={`day-${index}`} onClick={() => toggleAttendance(day)} style={cellStyle}>
                        {day}
                        <br />
                        <span style={{ fontSize: '12px', fontWeight: 'bold', color: isAttended ? 'white' : (isSunday ? 'white' : 'white') }}>
                            {isAttended ? 'Present' : (isAbsent ? 'Absent' : (isSunday ? 'Holiday' : ''))}
                        </span>
                    </td>
                );
            })
        );


        const weeks = [];
        let week = [];
        calendarCells.forEach((cell, index) => {
            if (index % 7 === 0 && index !== 0) {
                weeks.push(<tr key={`week-${weeks.length}`}>{week}</tr>);
                week = [];
            }
            week.push(cell);
        });
        weeks.push(<tr key={`week-${weeks.length}`}>{week}</tr>);
        return weeks;
    };

    
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    console.log('data', data);
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`/Parent/GetMonthlyAttendanceReport?monthno=${monthno}`);
            setData(response.data.Payload);
        } catch (error) {
            // window.location.href = "/parent";
        }
    }
    useEffect(() => {
        fetchData();
        const loaderTimeout = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(loaderTimeout);
    }, []);

    // const [total, setTotal] = useState();
    // console.log(total);
    // const fetchTotal = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await axiosInstance.get(`/admin/GetCountAbsentPresent?studentid=${studentid}&&month=${month}`);
    //         setTotal(response.data);
    //     } catch (error) {
    //         window.location.href = "/";
    //     } finally {
    //         setLoading(false);
    //     }
    // }
    // useEffect(() => {
    //     fetchTotal();
    //     fetchData();
    // }, []);

    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(data?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        // <div>
        //     {loading ? (
        //           <div
        //           className="container text-center"
        //           style={{ paddingBottom: "150px", paddingTop: "200px" }}
        //       >
        //           <div class="spinner-grow text-primary" role="status">
        //               <span class="visually-hidden">Loading...</span>
        //           </div>
        //           <div class="spinner-grow text-secondary" role="status">
        //               <span class="visually-hidden">Loading...</span>
        //           </div>
        //           <div class="spinner-grow text-success" role="status">
        //               <span class="visually-hidden">Loading...</span>
        //           </div>
        //           <div class="spinner-grow text-danger" role="status">
        //               <span class="visually-hidden">Loading...</span>
        //           </div>
        //           <div class="spinner-grow text-warning" role="status">
        //               <span class="visually-hidden">Loading...</span>
        //           </div>
        //           <div class="spinner-grow text-info" role="status">
        //               <span class="visually-hidden">Loading...</span>
        //           </div>
        //           <div class="spinner-grow text-light" role="status">
        //               <span class="visually-hidden">Loading...</span>
        //           </div>
        //           <div class="spinner-grow text-dark" role="status">
        //               <span class="visually-hidden">Loading...</span>
        //           </div>
        //       </div>
        //     ):(
        //     <div className="container-fluid">
        //         <div className="row">
        //             <div class="card-header">
        //                 <h4 class="card-title">Attendance Report</h4>
        //             </div>
        //             <div class="col-lg-12">
        //                 <div class="card custom-card mb-3">
        //                     <div class="card-header justify-content-between"><div class="card-title">Daily Attendance</div></div>
        //                     <div class="card-body py-3 px-3">
        //                         <div class="table-responsive">
        //                             <table class="table table-hover table-bordered">
        //                                 <thead className="">
        //                                     <tr>
        //                                         {/* <th>#</th> */}
        //                                         <th>Date</th>
        //                                         <th>Name</th>
        //                                         <th>Marked By</th>
        //                                         <th>Attendance</th>
        //                                     </tr>
        //                                 </thead>
        //                                 <tbody>
        //                                     {data ?
        //                                         data
        //                                         .slice(
        //                                             currentPage * dataPerPage,
        //                                             (currentPage + 1) * dataPerPage
        //                                           ).map((row) => (
        //                                             <tr className="text-black fs-5" key={row.id}>
        //                                                 {/* <td>{index + 1}</td> */}
        //                                                 <td><span class="badge badge-xs badge-info">{row.date}</span></td>
        //                                                 <td>{row.name}</td>
        //                                                 <td>{row.teachername}</td>
        //                                                 <td><span class={`badge badge-xs badge-${row.attendance === 'Present' ? 'primary' : 'danger'}`}>{row.attendance}</span></td>
        //                                             </tr>
        //                                         )) :
        //                                         <p>No Data Available</p>}
        //                                 </tbody>
        //                             </table>
        //                         </div>
        //                         <nav aria-label="Page navigation example">
        //                             <ul class="pagination justify-content-end">
        //                                 <li class="page-item">
        //                                     <a
        //                                         class="page-link"
        //                                         style={{
        //                                             pointerEvents:
        //                                                 currentPage === 0 ? "none" : "auto",
        //                                             opacity: currentPage === 0 ? 0.5 : 1,
        //                                         }}
        //                                         onClick={() => handlePageChange(currentPage - 1)}
        //                                     >
        //                                         Previous
        //                                     </a>
        //                                 </li>
        //                                 {pageIndex
        //                                     .slice(
        //                                         Math.max(0, currentPage - 2),
        //                                         Math.min(numberOfPage, currentPage + 2)
        //                                     )
        //                                     .map((page) => (
        //                                         <li
        //                                             class={`page-item ${page === currentPage + 1 ? "active" : ""
        //                                                 }`}
        //                                             key={page}
        //                                         >
        //                                             <a
        //                                                 class="page-link"
        //                                                 style={{
        //                                                     pointerEvents:
        //                                                         page - 1 === currentPage
        //                                                             ? "none"
        //                                                             : "auto",
        //                                                     opacity: page - 1 === currentPage ? 0.5 : 1,
        //                                                 }}
        //                                                 onClick={() => handlePageChange(page - 1)}
        //                                                 href="#"
        //                                             >
        //                                                 {page}
        //                                             </a>
        //                                         </li>
        //                                     ))}
        //                                 <li class="page-item">
        //                                     <a
        //                                         class="page-link"
        //                                         style={{
        //                                             pointerEvents:
        //                                                 currentPage === numberOfPage - 1
        //                                                     ? "none"
        //                                                     : "auto",
        //                                             opacity:
        //                                                 currentPage === numberOfPage - 1 ? 0.5 : 1,
        //                                         }}
        //                                         onClick={() => handlePageChange(currentPage + 1)}
        //                                     >
        //                                         Next
        //                                     </a>
        //                                 </li>
        //                             </ul>
        //                         </nav>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     )}
        // </div>
        <div>
        <div className="col-lg-12 pt-5 px-5">
            {loading ? (
                <div
                    className="container text-center"
                    style={{ paddingBottom: "150px", paddingTop: "200px" }}
                >
                    <div class="spinner-grow text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-danger" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-dark" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="card custom-card mb-3">
                    <div class="card-header justify-content-between">
                        <div className="card-title">  {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })} attendance ({data && data.length > 0 ? data[0].name : ''})</div>
                        {/* {total && (
                            <div class="flex flex-wrap gap-2">
                                Present  <span class="badge badge-xs badge-primary">{total.Present ?? ''}</span>{" "}
                                Absent <span class="badge badge-xs badge-danger">{total.Absent ?? ''}</span>
                            </div>
                        )} */}
                    </div>
                    <div className="table-responsive">
                        <table className="table table table-bordered">
                            <thead className="">
                                <tr>
                                    <th>Sun</th>
                                    <th>Mon</th>
                                    <th>Tue</th>
                                    <th>Wed</th>
                                    <th>Thu</th>
                                    <th>Fri</th>
                                    <th>Sat</th>
                                </tr>
                            </thead>
                            <tbody>{renderCalendar()}</tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    </div>
    );
};

export default ViewAttendance;