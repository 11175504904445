import React from 'react';
import { Link } from 'react-router-dom';
const TodayAttendance = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row pt-4">
                    <h3 class="text-center">Today Attendance</h3>
                    <div class="col-lg-12 pt-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row form-material">
                                    <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                        <h3 class="card-title">Today Attendance List</h3>
                                    </div>
                                    <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                            <select class="form-control" id="autoSizingSelect">
                                                <option selected>Select class...</option>
                                                <option value="Nursery">Nursery</option>
                                                <option value="LKG">LKG</option>
                                                <option value="UKG">UKG</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                            <select class="form-control" id="autoSizingSelect">
                                                <option selected>Select section...</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                            </select>
                                        </div>
                                    <div class="col-xl-3 col-xxl-2 col-md-6 mb-3 text-end">
                                        <h3 class="card-title badge badge-xs light badge-secondary"><i class="bi bi-calendar-check"></i> 11-Dec-2023</h3>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Roll No.</th>
                                                <th>Name</th>
                                                <th>Attendance</th>
                                                <th>Remark</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>1</td>
                                                <td>100001</td>
                                                <td>Ronak</td>
                                                <td><span class="badge badge-xs light badge-success">Present</span></td>                                                <td>Reason here</td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>2</td>
                                                <td>100002</td>
                                                <td>Ankit</td>
                                                <td><span class="badge badge-xs light badge-success">Present</span></td>                                                <td>Reason here</td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>3</td>
                                                <td>100003</td>
                                                <td>Rohit</td>
                                                <td><span class="badge badge-xs light badge-success">Present</span></td>                                                <td>Reason here</td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>4</td>
                                                <td>100004</td>
                                                <td>Abhi</td>
                                                <td><span class="badge badge-xs light badge-danger">Absent</span></td>                                                <td>Reason here</td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>5</td>
                                                <td>100005</td>
                                                <td>Kiyara</td>
                                                <td><span class="badge badge-xs light badge-success">Present</span></td>
                                                <td>Reason here</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <a class="page-link">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TodayAttendance;