import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../admin-helper';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Loader from '../../Loader';
function BookInfo() {
  const { studentid } = useParams()
  const [bookReceiveData, setBookReceiveData] = useState({
    studentid: studentid,
    bookname: "",
    bookno: "",
    receiveddate: "",
    returndate: "",
    remark: ""
  })
  const [loadSubmit, setLoadSubmit] = useState(false)
  const [tableBookData, setTableBookData] = useState([]);
  const [loading, setLoading] = useState(true);

  //Book Recieve Details Data For Table View
  const fetchTableData = async () => {
    try {
      const response = await axiosInstance.get(`/Admin/GetBookReceiveInfo`);
      setTableBookData(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
      window.location.href = "/"
    }
  };
  useEffect(() => {
    fetchTableData();
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [studentid]);

  // Book Recieve Function to submit the data
  const handleBookReceive = async (e) => {
    e.preventDefault();
    setLoadSubmit(true)
    try {
      const response = await axiosInstance.post('/Admin/AddBookReceiveInfo', bookReceiveData);
      if (response.data.Status === 'success') {
        fetchTableData();
        setBookReceiveData({
          bookname: "",
          bookno: "",
          receiveddate: "",
          returndate: "",
          remark: ""
        })
        toast.success('Book Receive Details Added Successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadSubmit(false)
      }
    } catch (error) {
      setLoadSubmit(false)
      toast.error('Book Receive Details Added failed', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // -- pagination Code -- //

  const dataPerPage = 10;

  const [currentPage, setCurrentPage] = useState(0);

  const numberOfPage = Math.ceil(tableBookData?.length / dataPerPage);
  const pageIndex = Array.from(
    { length: numberOfPage },
    (_, index) => index + 1
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div class="card-header">
            <h4 class="card-title">Book Info</h4>
          </div>
          <div class="col-lg-4">
            <form onSubmit={handleBookReceive}>
             
                <div className="card custom-card mb-3">
                <div class="card-header justify-content-between">
                  <div class="card-title">Add Book Receive Info</div>
                  </div>
                  <div class="card-body py-3 px-3">
                  <div class="row form-material">
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">Book Name.<span className='text-danger'>*</span></label>
                      <input type="text" class="form-control" placeholder="Enter Book Name.." name="bookname" value={bookReceiveData.bookname} onChange={(e) => setBookReceiveData({ ...bookReceiveData, bookname: e.target.value })} required />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">Book No.<span className='text-danger'>*</span></label>
                      <input type="text" class="form-control" placeholder="Enter Book No.." name="bookno" value={bookReceiveData.bookno} onChange={(e) => setBookReceiveData({ ...bookReceiveData, bookno: e.target.value })} required />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">Recieved Date.<span className='text-danger'>*</span></label>
                      <input type="date" class="form-control" placeholder="Enter Book No.." name="receiveddate" value={bookReceiveData.receiveddate} onChange={(e) => setBookReceiveData({ ...bookReceiveData, receiveddate: e.target.value })} required />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">Return Date.<span className='text-danger'>*</span></label>
                      <input type="date" class="form-control" placeholder="Enter Book No.." name="returndate" value={bookReceiveData.returndate} onChange={(e) => setBookReceiveData({ ...bookReceiveData, returndate: e.target.value })} required />
                    </div>
                    <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                      <label class="form-label">Remark</label>
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="remark" value={bookReceiveData.remark} onChange={(e) => setBookReceiveData({ ...bookReceiveData, remark: e.target.value })}   ></textarea>
                    </div>
                    <div className="text-end">
                      <button type="submit" class="btn btn-primary text-white fw-bold mb-3" style={{ width: "100px" }}>
                        {
                          loadSubmit ? (<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>) : "Submit"
                        }
                      </button>
                    </div>
                  </div>
                </div>
                </div>
            </form>
          </div>
          <div class="col-lg-8">
            
              <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Book Receive Info List</div></div>
                <div class="card-body py-3 px-3">
                {loading ? (
             <Loader/>
            ) : (
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead >
                        <tr>
                          <th>#</th>
                          <th>Book Name.</th>
                          <th>Book No</th>
                          <th>Recieved Date</th>
                          <th>Apply Date</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableBookData ? (
                          tableBookData.slice(
                            currentPage * dataPerPage,
                            (currentPage + 1) * dataPerPage
                          ).map((row) => (
                            <tr className="text-black fs-5" key={row.id}>
                              <td>{row.id}</td>
                              <td>{row.bookname}</td>
                              <td>{row.bookno}</td>
                              <td>{row.received_date}</td>
                              <td>{row.return_date}</td>
                              <td>{row.remark}</td>
                            </tr>
                          ))
                        ) : (
                          <p>No data available</p>
                        )}
                      </tbody>
                    </table>
                  </div>
            )}
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                      <li class="page-item">
                        <a
                          class="page-link"
                          style={{
                            pointerEvents:
                              currentPage === 0 ? "none" : "auto",
                            opacity: currentPage === 0 ? 0.5 : 1,
                          }}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </a>
                      </li>
                      {pageIndex
                        .slice(
                          Math.max(0, currentPage - 2),
                          Math.min(numberOfPage, currentPage + 2)
                        )
                        .map((page) => (
                          <li
                            class={`page-item ${page === currentPage + 1 ? "active" : ""
                              }`}
                            key={page}
                          >
                            <a
                              class="page-link"
                              style={{
                                pointerEvents:
                                  page - 1 === currentPage
                                    ? "none"
                                    : "auto",
                                opacity: page - 1 === currentPage ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(page - 1)}
                              href="#"
                            >
                              {page}
                            </a>
                          </li>
                        ))}
                      <li class="page-item">
                        <a
                          class="page-link"
                          style={{
                            pointerEvents:
                              currentPage === numberOfPage - 1
                                ? "none"
                                : "auto",
                            opacity:
                              currentPage === numberOfPage - 1 ? 0.5 : 1,
                          }}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
           
          </div>
        </div>
      </div>
    </>
  )
}

export default BookInfo