import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";

const UnpaidFees = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([])
  const [select, setSelect] = useState(false)

  const fetchData = async () => {
        try {
          const response = await axiosInstance.get(`/admin/GetPendingFeelist`);
          setData(response.data.Payload);
        } catch (error) {
          window.location.href = "/";
        }
      };

  //Functions for Checkbox Selection
  const handleSelectAll = (e, id) => {
    const { checked } = e.target;
  
    if (id) {
      // condition for individual checkbox clicked
      const updatedData = data.map(row => {
        if (row.studentid === id) {
          return { ...row, selected: checked };
        }
        return row;
      });
  
      setData(updatedData);
  
      // condition for Check if all individual checkboxes are selected or not
      const allSelected = updatedData.every(row => row.selected);
      setSelect(allSelected || false);
    } else {
      // condition for Select all checkbox clicked
      setSelect(checked);
      const updatedData = data.map(row => ({ ...row, selected: checked }));
      setData(updatedData);
    }
  };

  useEffect(()=>{
    fetchData()
    const loaderTimeout = setTimeout(() => {
              setLoading(false);
            }, 3000);
    return () => clearTimeout(loaderTimeout);
  },[])
  
  
  
  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div class="card-header">
              <h4 class="card-title">Unpaid Fee List</h4>
              <div class="flex flex-wrap gap-2">
                <button
                  className="btn btn-primary "
                  data-bs-target="#exampleModalToggle"
                  data-bs-toggle="modal"
                >
                  Message
                </button>
              </div>
            </div>
            <div
              class="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabindex="-1"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
                      Type a Message
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    id="html-onetime-pdf"
                    className="mt-4 mb-4"
                    style={{ paddingLeft: "3.5rem"}}
                  >
                    <textarea className="rounded" style={{width:"400px",height:"200px"}}/>
                  </div>
                  <div className="container text-center">
                    <button className="btn btn-primary mb-3">
                    Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div className="card custom-card mb-3">
                <div className="card-header justify-content-between">
                  <div class="card-title">Student List</div>
                  <div class="flex flex-wrap gap-2">
                    <input
                      class="form-control form-control-sm"
                      //   onChange={(e) => handleSearchChange(e, "rollno")}
                      type="text"
                      placeholder="Search month"
                      aria-label=".form-control-sm example"
                    />
                  </div>
                </div>
                {loading ? (
                 <div
                    className="container text-center"
                    style={{ paddingBottom: "150px", paddingTop: "200px" }}
                  >
                    <div class="spinner-grow text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-danger" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-info" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-light" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-dark" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : ( 
                <div class="card-body ">
                  <div class="table-responsive">
                    <table class="table table-hover border table-bordered">
                      <thead className="">
                        <tr>
                          <th>
                            <input type="checkbox" checked={select === true} onChange={(e)=>handleSelectAll(e)} class="form-check-input " />
                          </th>
                          <th>Roll No.</th>
                          <th>Class</th>
                          <th>Student Name</th>
                          <th>Father Name</th>
                          <th>Email ID</th>
                          <th>Mobile No.</th>
                          {/* <th>Pending Month</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                              data.map((row) => (
                                <tr className="text-black fs-5" key={row.studentid}>
                                        <td><input type="checkbox" checked={row.selected === true} onChange={(e)=>handleSelectAll(e, row.studentid)} class="form-check-input " /></td>
                                        <td>{row.rollno}</td>
                                        <td>{row.sclass}</td>
                                        <td>{row.studentname}</td>
                                        <td>{row.fathername}</td>
                                        <td>{row.emailid}</td>
                                        <td>{row.mobileno}</td>
                                        {/* <td>{row.Payment_month}</td> */}
                                        </tr>
                        ))
                          ) : (
                        <p>No data available</p>
                          )}
                      </tbody>
                    </table>
                    {/* <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end">
                          <li class="page-item">
                            <a
                              class="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === 0 ? "none" : "auto",
                                opacity: currentPage === 0 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              Previous
                            </a>
                          </li>
                          {pageIndex
                            .slice(
                              Math.max(0, currentPage - 2),
                              Math.min(numberOfPage, currentPage + 2)
                            )
                            .map((page) => (
                              <li
                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                  }`}
                                key={page}
                              >
                                <a
                                  class="page-link"
                                  style={{
                                    pointerEvents:
                                      page - 1 === currentPage
                                        ? "none"
                                        : "auto",
                                    opacity: page - 1 === currentPage ? 0.5 : 1,
                                  }}
                                  onClick={() => handlePageChange(page - 1)}
                                  href="#"
                                >
                                  {page}
                                </a>
                              </li>
                            ))}
                          <li class="page-item">
                            <a
                              class="page-link"
                              style={{
                                pointerEvents:
                                  currentPage === numberOfPage - 1
                                    ? "none"
                                    : "auto",
                                opacity:
                                  currentPage === numberOfPage - 1 ? 0.5 : 1,
                              }}
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav> */}
                  </div>
                </div>
                )} 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnpaidFees;
