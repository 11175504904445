import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../admin-helper";
function ManageStudent() {
  const Navigate = useNavigate();
  const { studentid, id } = useParams();
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    ids: id,
    studentid: studentid,
    name: "",
    dob: "",
    gender: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    mobile: "",
    email: "",
    address: "",
  });
  console.log("formData is ", formData);
  //Get Data
  useEffect(() => {
    // Fetch existing data if in edit mode
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          "/student/GetStudentInformation",
          {
            params: {
              studentid,
              id,
            },
          }
        );
        setFormData({
          ids: response.data.StudentDetail[0].id,
          studentid: response.data.StudentDetail[0].studentid,
          name: response.data.StudentDetail[0].name,
          dob: response.data.StudentDetail[0].dob,
          gender: response.data.StudentDetail[0].gender,
          country: response.data.StudentDetail[0].country,
          state: response.data.StudentDetail[0].state,
          city: response.data.StudentDetail[0].city,
          pincode: response.data.StudentDetail[0].pincode,
          mobile: response.data.StudentDetail[0].mobileno,
          email: response.data.StudentDetail[0].emailid,
          address: response.data.StudentDetail[0].address,
        });
      } catch (error) {
        // toast.error('Authentication failed!');
        window.location.href = "/";
      }
    };

    if (id) {
      fetchData();
    }
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [id, studentid]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    let url = "/student/StudentRegister";
    if (id) {
      url = "/student/updateStudentDetail";
    }
    try {
      const response = await axiosInstance.post(url, formData);
      if (response.data.Status === "success") {
        toast.success(
          `${
            id ? "Student Details Updated" : "Student Details Added"
          } Successfully!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setButtonLoading(false);
        // Reset form data after successful submission in add mode
        if (!id) {
          setFormData({
            ids: null,
            studentid: null,
            name: "",
            dob: "",
            gender: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
            mobile: "",
            email: "",
            address: "",
          });
        }
        // Redirect or perform other actions as needed
        Navigate(`/admin/students/detail/${studentid}`);
      } else {
        throw new Error(`Student ${id ? "Updated" : "Added"} failed`);
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error(`Student ${id ? "Updated" : "Added"} failed`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const path = document.location.pathname;
  return (
    <>
      <div className="mt-4">
        <div className="container-fluid">
          <div className="row">
            <h3 class="text-center">
              {path === "/admin/students/manage-student"
                ? "Student Form"
                : "Edit Student"}
            </h3>
            <div class="col-lg-12">
              {loading ? (
                <div
                  className="container text-center"
                  style={{ paddingTop: "200px" }}
                >
                  <div class="spinner-grow text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-info" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="spinner-grow text-dark" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div class="row pt-4">
                    <div class="col-lg-12">
                      <div class="card shadow bg-body-tertiary rounded">
                        <div className="card custom-card mt-3">
                          <div className="card-header justify-content-between">
                            <div class="card-title"> Student Details</div>
                          </div>
                        </div>
                        <div class="card-body text-black fw-bold fs-6">
                          <div class="row form-material">
                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                              <label class="form-label">Full Name</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter full name..."
                                name="name"
                                pattern="[A-Za-z]+[A-Za-z'\-\s]*"
                                defaultValue={formData.name}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                              <label class="form-label">Email</label>
                              <input
                                type="email"
                                class="form-control"
                                placeholder="Enter email..."
                                name="email"
                                defaultValue={formData.email}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                              <label class="form-label">Mobile</label>
                              <input
                                type="text"
                                class="form-control"
                                pattern="[6789][0-9]{9}"
                                placeholder="Enter mobile..."
                                maxLength={10}
                                name="mobile"
                                defaultValue={formData.mobile}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                              <label class="form-label">DOB</label>
                              <input
                                type="date"
                                class="form-control"
                                name="dob"
                                placeholder="Enter DOB..."
                                defaultValue={formData.dob}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            {id ? (
                              <>
                                <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                  <label class="form-label">Gender</label>
                                  <select
                                    class="form-control"
                                    id="autoSizingSelect"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleInputChange}
                                  >
                                    <option>{formData.gender}</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>
                              </>
                            ) : (
                              <>
                                <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                                  <label class="form-label">Gender</label>
                                  <select
                                    class="form-control"
                                    id="autoSizingSelect"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleInputChange}
                                  >
                                    <option>Choose...</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>
                              </>
                            )}
                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                              <label class="form-label">City</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter city.."
                                name="city"
                                defaultValue={formData.city}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                              <label class="form-label">State</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter state.."
                                name="state"
                                defaultValue={formData.state}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                              <label class="form-label">Pincode</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter pincode.."
                                name="pincode"
                                title="xxxxxx"
                                pattern="[1-9][0-9]{5}"
                                defaultValue={formData.pincode}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                              <label class="form-label">Country</label>
                              <input
                                type="text"
                                class="form-control"
                                name="country"
                                placeholder="Enter country..."
                                value={formData.country}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                              <label class="form-label">Address</label>
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                name="address"
                                defaultValue={formData.address}
                                onChange={handleInputChange}
                                required
                              ></textarea>
                            </div>
                            <div className="text-end">
                              <button
                                type="submit"
                                class="btn btn-sm btn-primary"
                                style={{ width: "100px" }}
                              >
                                {buttonLoading ? (
                                  <span
                                    class="spinner-border spinner-border-sm me-2"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : id ? (
                                  "Edit"
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageStudent;
