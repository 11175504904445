import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../../admin-helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Loader";
function ParentComplaint() {
  const [loading, setLoading] = useState(true);
  const [complaintList, setComplaintList] = useState([]);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState({
    ticketid: "",
    message: "",
  })

  const messageData = async (e, ticketid) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(
        "/admin/chatbyadmin",
        {
          ...message,
          ticketid: ticketid
        }
      );

      if (response.data.Status === 1) {
        throw new Error("failed");
      }
      // toast.success("successfully!", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      setMessage({ ticketid: "", message: "" });
      fetchData(ticketid);
    } catch (error) {
      toast.error("failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchList = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("Admin/Getteachercomplaint");
      setComplaintList(response.data.Payload);
    } catch (error) {
      toast.error("Authentication failed !");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (ticketid) => {
    try {
      const response = await axiosInstance.get(`/Admin/Getchatdetailbyadmin?ticketid=${ticketid}`);
      setData(response.data.Payload);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="card-header">
          <h4 className="card-title">Complaint List</h4>
        </div>
        <div className="col-lg-12">
          <div className="card custom-card mb-3">
            <div className="card-header justify-content-between">
              <div className="card-title">Complaint List</div>
            </div>
            <div className="card-body py-3 px-3">
              <div className="table-responsive">
                {loading ? (
                  <Loader />
                ) : (
                  <table className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Subject</th>
                        <th>Complaint</th>
                        <th>Apply Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {complaintList.map((row) => (
                        <tr key={row.id}>
                          <td>{row.name}</td>
                          <td>{row.subject}</td>
                          <td>{row.complain}</td>
                          <td>
                            {row.createdate
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("-")}
                          </td>
                          <td>
                            <span
                              data-bs-toggle="offcanvas"
                              data-bs-target={`#offcanvasRight-${row.ticketid}`}
                              aria-controls={`offcanvasRight-${row.ticketid}`}
                              onClick={() => fetchData(row.ticketid)}
                            >
                              <i className="bi bi-reply fs-2"></i>
                            </span>
                            <div
                              className="offcanvas chatboxs offcanvas-end"
                              tabIndex="-1"
                              id={`offcanvasRight-${row.ticketid}`}
                              aria-labelledby={`offcanvasRightLabel-${row.ticketid}`}
                            >
                              <div className="chatbox active">
                                <div className="custom-tab-1">
                                  <div className="tab-content">
                                    <div
                                      className="tab-pane fade active show"
                                      id="chat"
                                      role="tabpanel"
                                    >
                                      <div className="card chat dlab-chat-history-box">
                                        <div className="card-header chat-list-header text-center">
                                          <a
                                            href="/"
                                            className="dlab-chat-history-back btn-close"
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                          ></a>
                                          <div>
                                            <h6 className="mb-1">Chat with {row.name}</h6>
                                            {/* <p className="mb-0 text-success">Online</p> */}
                                          </div>
                                          <div className="dropdown">
                                            {/* <a
                                              href="/"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="bi bi-three-dots-vertical"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                              <li className="dropdown-item">
                                                <i className="fa fa-user-circle text-primary me-2"></i>{" "}
                                                View profile
                                              </li>
                                              <li className="dropdown-item">
                                                <i className="fa fa-users text-primary me-2"></i>{" "}
                                                Add to btn-close friends
                                              </li>
                                              <li className="dropdown-item">
                                                <i className="fa fa-plus text-primary me-2"></i>{" "}
                                                Add to group
                                              </li>
                                              <li className="dropdown-item">
                                                <i className="fa fa-ban text-primary me-2"></i>{" "}
                                                Block
                                              </li>
                                            </ul> */}
                                          </div>
                                        </div>
                                        <div className="card-body msg_card_body dlab-scroll" id="DLAB_W_Contacts_Body3">
                                        {data && data.length > 0 ? (
                                          data.map((msg, index) => (
                                            <div className={`d-flex justify-content-${msg.replyby === null ? 'end' : 'start'} mb-4`} key={index}>
                                              <div className={`msg_cotainer text-start ${msg.replyby === null ? 'msg_cotainer_send' : 'msg_cotainer_rcv'}`}>
                                                {msg.message}
                                                <span className={`msg_time ${msg.replyby === null ? 'text-black' : 'text-white'}`}>{msg.time},  {msg.date}</span>
                                              </div>
                                            </div>
                                          ))) : (<p>No data available</p>)}
                                        </div>
                                        <div className="card-footer type_msg">
                                          <form onSubmit={(e) => messageData(e, row.ticketid)}>
                                            <div className="input-group">
                                              <textarea
                                                className="form-control"
                                                placeholder="Type your message..."
                                                name="message"
                                                value={message.message}
                                                onChange={(e) => setMessage({ ...message, message: e.target.value })}
                                                required></textarea>
                                              <div className="input-group-append">
                                                <button type="submit" className="btn btn-primary">
                                                  <i className="fa fa-location-arrow"></i>
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentComplaint;
