import React from 'react';
// import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Register() {
    const [registrationData, setRegistrationData] = useState({
        name: '',
        email: 'sanjay@gmail.com',
        mobile: '898767543',
        gender: 'Male',
        address: 'Delhi',
        zipcode: '323212',
        password: '123123',
      });
      const history = useNavigate();
      const handleRegister = async () => {
        try {
          const authToken = 'Qk9qdXR3M0FrbjJHaDRUMThIMWU5RW0wblMzZzNHbXFqMlBiQnFpWjl6aWxaWnJGcEtRZjBGYmc5U0RMbGNteXBGWDFjb';
          const response = await fetch('https://phplaravel-979432-4125900.cloudwaysapps.com/api/user', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Token': authToken,
            },
            body: JSON.stringify(registrationData),
          });
           
          
          if (!response.ok) {
            throw new Error('Registration failed');
          }
        
          const responseData = await response.json();
          console.log('Registration successful!', responseData);
        
          const registrationId = responseData.data.userID;
        //   console.log(responseData.data.userID);
          history(`/admin/registration-list/${registrationId}`);
       
        } catch (error) {
          console.error('Error during registration:', error);
        }
      };

  return (
    <div>
            <div className="container-fluid">
                <div className="row pt-4">
                    <h3 class="text-center">Register form</h3>
                    {/* <div class="col-lg-4 pt-4"></div> */}
                    <div class="col-lg-4 pt-4">
                        <form class="form-valide-with-icon needs-validation" onSubmit={handleRegister}>
                            <div class="card">
                                <div class="card-body text-black fw-bold fs-6">
                                    <h4 className='card-title'>Register form</h4><hr />
                                    <div class="row form-material">
                                        <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                            <label class="form-label">Name</label>
                                            <input type="text" class="form-control" value={registrationData.name} onChange={(e) => setRegistrationData({ ...registrationData, name: e.target.value })} required/>
                                        </div>
                                        <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                            <label class="form-label">Email</label>
                                            <input type="text" class="form-control" value={registrationData.email} onChange={(e) => setRegistrationData({ ...registrationData, email: e.target.value })} />
                                        </div>
                                        <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                            <label class="form-label">mobile</label>
                                            <input type="text" class="form-control" value={registrationData.mobile} onChange={(e) => setRegistrationData({ ...registrationData, mobile: e.target.value })} />
                                        </div>
                                        <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                            <label class="form-label">Gender</label>
                                            <input type="text" class="form-control"  value={registrationData.gender} onChange={(e) => setRegistrationData({ ...registrationData, gender: e.target.value })} />
                                        </div>
                                        <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                            <label class="form-label">Address</label>
                                            <input type="text" class="form-control" value={registrationData.address} onChange={(e) => setRegistrationData({ ...registrationData, address: e.target.value })} />
                                        </div>
                                        <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                            <label class="form-label">Zipcode</label>
                                            <input type="text" class="form-control" value={registrationData.zipcode} onChange={(e) => setRegistrationData({ ...registrationData, zipcode: e.target.value })} />

                                        </div>
                                       
                                        <div class="col-xl-3 col-xxl-12 col-md-6 mb-3">
                                            <label class="form-label">Password</label>
                                            <input type="text" class="form-control"  value={registrationData.password} onChange={(e) => setRegistrationData({ ...registrationData, password: e.target.value })} />
                                        </div>
                                        <div className="text-end">
                                            <button type="submit"  class="bg-black text-white fw-bold mb-3" style={{ width: "100px" }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div class="col-lg-8 pt-4">
                    <div class="table-responsive">
                                <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>Gender</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                            </table>
                    </div> </div> */}
                </div>
            </div>
        </div>
  );
}

export default Register;