import React from 'react';
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../parent-helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../Loader';
function ApplyBook() {
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [loading, setLoading] = useState(true);
    const [student, setStudent] = useState({});
    const [book, setBook] = useState([]);
    const [bookupdate, setBookupdate] = useState({
        ids: '',
        bookname: '',
    });
    const [SubmitData, setSubmitData] = useState({
        name: student.name,
        sclass: student.sclass,
        rollno: student.rollno,
        section: student.section,
        bookname: "",
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadSubmit(true)
        try {
            const response = await axiosInstance.post('/parent/Applyforbook', SubmitData);
            console.log(response)
            if (!response.data.Status === 'success') {
                throw new Error('Book request failed');
            }

            toast.success('Book requested successfully!', {
                position: toast.POSITION.TOP_RIGHT,
            });
            setSubmitData({ bookname: ""});
            setLoadSubmit(false)
            fetchList();
        } catch (error) {
            setLoadSubmit(false)
            toast.error('Book request failed', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    const fetchList = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/parent/GetApplyforbookDetail");
            setBook(response.data.Payload);
        } catch (error) {
            window.location.href = "/parent";
        }finally{
            setLoading(false);
        }
    }
    const fetchStudent = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/Parent/Getstudentdetail")
            setStudent(response.data);
            setSubmitData({ ...SubmitData, name: response.data.studentname, rollno: response.data.rollno, sclass: response.data.sclass, section: response.data.section });
        } catch (error) {
            window.location.href = "/parent";
        }finally{
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchStudent();
        fetchList();
    }, []);
    // Edit table data..
    const [editingId, setEditingId] = useState(null);
    const handleEditClick = (id) => {
        setEditingId(id);
    };
    const handleSaveClick = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post("/parent/UpdateApplyforbook", bookupdate);
            if (!response.data.status === 'success') {
                throw new Error("Book name update failed");
            }
            toast.success("Book name updated successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            toast.error("Book name update failed", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setEditingId(null);
    };
    const handleCancelClick = () => {
        setEditingId(null);
    };
    const handleInputChange = (e, id, key) => {
        const newData = book.map((item) =>
            item.id === id ? { ...item, [key]: e.target.value } : item
        );
        setBookupdate((prev) => ({ ...prev, ids: id, bookname: e.target.value }));
        setBook(newData);
    };
    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(book?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (<>
        <div className="container-fluid">
            <div className="row">
                <div class="card-header">
                    <h4 class="card-title">Library</h4>
                </div>
                
                        <div class="col-md-4">
                            <form onSubmit={handleSubmit}>
                                <div class="card custom-card mb-3">
                                    <div class="card-header justify-content-between"><div class="card-title">Apply For Book</div></div>
                                    <div class="card-body text-black fw-bold fs-6">
                                        <div class="row form-material">
                                            <div class=" col-md-12 mb-3">
                                                <label class="form-label">Name</label>
                                                <input type="text" class="form-control" name="name" value={student.studentname} onChange={(e) => setSubmitData({ ...SubmitData, name: e.target.value })} disabled />
                                            </div>
                                            <div class=" col-md-12">
                                                {/* <label class="form-label">Roll No.</label> */}
                                                <input type="hidden" class="form-control" name="rollno" value={student.rollno} onChange={(e) => setSubmitData({ ...SubmitData, rollno: e.target.value })} disabled />
                                            </div>
                                            <div class=" col-md-12">
                                                {/* <label class="form-label">Class</label> */}
                                                <input type="hidden" class="form-control" name="sclass" value={student.sclass} onChange={(e) => setSubmitData({ ...SubmitData, sclass: e.target.value })} disabled />
                                            </div>
                                            <div class=" col-md-12">
                                                {/* <label class="form-label">Section</label> */}
                                                <input type="hidden" class="form-control" name="section" value={student.section} onChange={(e) => setSubmitData({ ...SubmitData, section: e.target.value })} disabled />
                                            </div>
                                            <div class=" col-md-12 mb-3">
                                                <label class="form-label">Book Name</label>
                                                <input type="text" class="form-control" name="bookname" placeholder="Enter book name.." value={SubmitData.bookname} onChange={(e) => setSubmitData({ ...SubmitData, bookname: e.target.value })} required />
                                            </div>
                                            <div className="text-end">
                                                <button type="submit" class="btn btn-primary mb-3 btn-block" >
                                                    {
                                                        loadSubmit ? (<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>) : "Submit"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-8">
                            <div className="card custom-card mb-3">
                                <div className='card-header justify-content-between'>
                                    <div class="card-title">Apply Book List</div>
                                </div>
                                <div class="card-body py-3 px-3">
                                    <div class="table-responsive">
                                    {loading ? (
                   <Loader/>
                ) : (
                    <>
                                        <table class="table table-hover table-bordered">
                                            <thead className="">
                                                <tr>
                                                    {/* <th>#</th> */}
                                                    <th>Book Name</th>
                                                    <th>Status</th>
                                                    <th>Apply Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {book ?
                                                    book
                                                        .slice(
                                                            currentPage * dataPerPage,
                                                            (currentPage + 1) * dataPerPage
                                                        ).map((row) => (
                                                            <tr className="text-black fs-5" key={row.id}>
                                                                {/* <td>{row.id}</td> */}
                                                                <td>{editingId === row.id ? (<input type="text" className='form-control form-control-sm' value={row.bookname} onChange={(e) => handleInputChange(e, row.id, 'bookname')} />) : (row.bookname)}</td>
                                                                <td>{row.status == 0 ? (
                                                                    <span class="badge badge-xs badge-info">Process</span>
                                                                ) : row.status == 1 ? (
                                                                    <span class="badge badge-xs badge-primary">Approved</span>
                                                                ) : row.status == 2 ? (
                                                                    <span class="badge badge-xs badge-success">Received</span>
                                                                ) : (
                                                                    <span class="badge badge-xs badge-danger">Reject</span>
                                                                )}</td>
                                                                <td>{row.createdate}</td>
                                                                <td>{editingId === row.id ? (<>
                                                                    <div class="d-flex">
                                                                        <span class="btn btn-primary shadow btn-xs sharp me-1" onClick={handleSaveClick}> <i class="bi bi-check2-all"></i></span> {' '}
                                                                        <span class="btn btn-danger shadow btn-xs sharp" onClick={handleCancelClick}> <i class="bi bi-x"></i></span></div>
                                                                </>
                                                                ) : (
                                                                    <span class="btn btn-primary shadow btn-xs me-1" onClick={() => handleEditClick(row.id)}><i class="bi bi-pencil-square"></i> Edit</span>
                                                                )}
                                                                </td>
                                                            </tr>
                                                        )) :
                                                    <p>No Data Available</p>}
                                            </tbody>
                                        </table>
                                        </>
                )}
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item">
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            currentPage === 0 ? "none" : "auto",
                                                        opacity: currentPage === 0 ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                >
                                                    Previous
                                                </a>
                                            </li>
                                            {pageIndex
                                                .slice(
                                                    Math.max(0, currentPage - 2),
                                                    Math.min(numberOfPage, currentPage + 2)
                                                )
                                                .map((page) => (
                                                    <li
                                                        class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                            }`}
                                                        key={page}
                                                    >
                                                        <a
                                                            class="page-link"
                                                            style={{
                                                                pointerEvents:
                                                                    page - 1 === currentPage
                                                                        ? "none"
                                                                        : "auto",
                                                                opacity: page - 1 === currentPage ? 0.5 : 1,
                                                            }}
                                                            onClick={() => handlePageChange(page - 1)}
                                                            href="#"
                                                        >
                                                            {page}
                                                        </a>
                                                    </li>
                                                ))}
                                            <li class="page-item">
                                                <a
                                                    class="page-link"
                                                    style={{
                                                        pointerEvents:
                                                            currentPage === numberOfPage - 1
                                                                ? "none"
                                                                : "auto",
                                                        opacity:
                                                            currentPage === numberOfPage - 1 ? 0.5 : 1,
                                                    }}
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                   
            </div>
        </div>
    </>);
}

export default ApplyBook;