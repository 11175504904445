import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../admin-helper";
import { useNavigate, useParams } from "react-router-dom";
import html2pdf from 'html2pdf.js';

function ExpenseReport() {
    const Navigate = useNavigate()
    const [expenseDetail, setExpenseDetail] = useState({});
    const { expenid } = useParams();
    const [loading, setLoading] = useState(true)

    
    const generatePDF = () => {
        const content = document.getElementById('printableCard');
        // console.log(content);
        const pdfOptions = { filename: 'receipt.pdf' };
        html2pdf(content, pdfOptions);
    };

    // Fetching Expense details
    useEffect(() => {
        const fetchExpenseDetails = async () => {
            try {
                const response = await axiosInstance.get(
                    `/Admin/GetExpenseReceipt?expenseid=${expenid}`
                );
                const expense = response.data;
                setExpenseDetail(expense);
                setLoading(false)
            } catch (error) {
                setLoading(false)
                // Navigate('/')
            }
        };
        fetchExpenseDetails();
    }, [expenid]);

    // Print Function
    function printCard() {
        window.print();
    }

     // Print Function
    //  function printCard() {
    //     const elementsToHide = document.querySelectorAll("body  *:not(#printableCard)");
    //     elementsToHide.forEach(element => {
    //         element.style.display = "block";
    //     });

    //     const printableCard = document.getElementById("printableCard");
    //     if (printableCard) {
    //         window.print();
    //     }

    //     elementsToHide.forEach(element => {
    //         element.style.display = "";
    //     });
    // }

    return (
        <>
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div class="card-header">
                            <h4 class="card-title">Manage Expense</h4>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <div class=" col-md-12">
                            {
                                loading ?
                                    (
                                        <div
                                            className="container text-center"
                                            style={{ paddingTop: "100px" }}
                                        >
                                            <div class="spinner-grow text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-secondary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-success" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-danger" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-warning" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-info" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-light" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-dark" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) :
                                    (<>
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="card" id="printableCard">
                                                    <>
                                                        <div class="card-header border-0 flex-wrap align-items-start">
                                                            <div class="col-md-6">
                                                                <div class="user d-sm-flex d-block pe-0">
                                                                    <img src="/images/logo-mnb-schools.png" alt="" />
                                                                    <div class="ms-sm-3 ms-0 me-md-5 md-0">
                                                                        <h5 class="mb-1 font-w500 fs-4 text-black" ></h5>
                                                                        <div class="listline-wrapper mb-2">
                                                                            <h4 class="card-title">MNB Soft Solution Pvt. Ltd.</h4>
                                                                            <span class="item">D-160, SECTOR-10 Gautam Buddha Nagar, Noida</span>
                                                                            <span class="item">867567575</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-12 text-end">
                                                                <h4 class="card-title">Vendor Details</h4>
                                                                <span class="item">{expenseDetail.name}</span><br />
                                                                <span class="item">{expenseDetail.mobile}</span> <br />
                                                                <span class="item">{expenseDetail.email}</span><br />
                                                                <span class="item">{expenseDetail.address}</span>
                                                            </div>
                                                        </div>
                                                        <div class="card-body pt-0">
                                                            <div class="row">
                                                                <div class="col-xl-12 col-md-12">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Item</th>
                                                                                <th scope="col">Item Cost</th>
                                                                                <th scope="col">Qty</th>
                                                                                <th scope="col">GST</th>
                                                                                <th scope="col">Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                    {
                                                                        expenseDetail ? (expenseDetail.Itemlist.map((row)=>{
                                                                            return(
                                                                                <tbody class="table-group-divider" key={row.id}>
                                                                                    <tr>
                                                                                        <th scope="row">{row.id}</th>
                                                                                        <td>{row.item_name}</td>
                                                                                        <td>{row.price}</td>
                                                                                        <td>{row.quantity}</td>
                                                                                        <td>{row.gst_percent}</td>
                                                                                        <td>{row.totalamt}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            )
                                                                        })) : <p>No Data Available</p>
                                                                    }
                                                                    </table>
                                                                    <div class="text-end">
                                                                        <span>Total GST : <label>{expenseDetail.totalgst}</label></span>
                                                                        <p>Total Amount : <label>{expenseDetail.totalamount}</label></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                                <div className="card-footer text-center">
                                                    <button  onClick={generatePDF}>Receipt</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ExpenseReport;
