import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";

function ManageTransport() {
  const Navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { studentid, id } = useParams();
  const [routeNo, setRouteno] = useState();
  const [studentDetail, setStudentDetail] = useState([]);
  const [SubmitData, setSubmitData] = useState({
    studentid: studentid,
    routeno: "",
    routefee: "",
    remark: ''
  });
  useEffect(() => {
    // :: fetching routeno, routefee, remarks from master
    const fetchRouteno = async () => {
      try {
        const response = await axiosInstance.get(`/Master/GetTransportFee`);
        setRouteno(response.data.Payload);
      } catch (error) {
        Navigate("/");
      }
    };
    fetchRouteno();

    // fetching Transport details from view page details

    const fetchTransportDetails = async () => {
      try {
        const response = await axiosInstance.get(
          "/student/GetStudentInformation",
          {
            params: {
              studentid,
              id,
            },
          }
        );
        const tranportDetail = response.data.TransportDetail[0];
        setSubmitData({
          ids: tranportDetail.id,
          studentid: tranportDetail.studentid,
          routeno: tranportDetail.routeno,
          routefee: tranportDetail.routefee,
          remark: tranportDetail.remark,
        });
      } catch (error) {
        window.location.href = "/";
      }
    };
    const fetchStudentDetail = async () => {
      try {
        const response = await axiosInstance.get(`/Student/Getstudentdetail?studentid=${studentid}`)
        setStudentDetail(response.data?.Payload[0])
      } catch (error) {
        console.error(error)
      }
    }
    fetchStudentDetail()
    if (id) {
      fetchTransportDetails();
    }

    // loader for button

    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, [id, studentid]);

  const handleInputChange = (e) => {
    setSubmitData({
      ...SubmitData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectchange = (e) => {
    if(e.target.value !== ""){
    const fee = routeNo?.find((r) => r.routeno === e.target.value).routefee;
      setSubmitData({
        ...SubmitData,
        routeno: e.target.value,
        routefee: fee,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    let url = "/Student/CreateTransportDetail";
    if (id) {
      url = "/Student/UpdateTransportDetail";
    }
    try {
      const response = await axiosInstance.post(url, SubmitData);
      if (response.data.Status === "success") {
        toast.success(
          `${id ? "Transport Details Updated" : "Transport Details Added"
          } Successfully!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setButtonLoading(false);
        if (!id) {
          setSubmitData({
            studentid: null,
            routeno: "",
            routefee: "",
            remark: "",
          });
        }
        Navigate(`/admin/students/detail/${studentid}`);
      } else {
        throw new Error(`Transport Detail ${id ? "Update" : "Add"} Failed`);
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error(`Transport Detail ${id ? "Update" : "Add"} failed`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const path = document.location.pathname;
  return (
    <div className="mt-4">
      <div className="container-fluid">
        <div className="row">
          <h4 class="card-title">
            {path === `/admin/students/manage-transport/${studentid}`
              ? "Manage Student"
              : "Edit Transport Detail"}
          </h4>
          <div className="col-lg-12">
            {loading ? (
              <div
                className="container text-center"
                style={{ paddingTop: "200px" }}
              >
                <div class="spinner-grow text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="row pt-4">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card">
                        <div class="table-responsive">
                          <div className="pt-3 p-2">
                            <li class="list-group-item">
                              <span class="custom-label fs-5">&nbsp;&nbsp;<b>Student Name</b> : &nbsp;{studentDetail.studentname ?? ''} </span>
                              <span class="custom-label fs-5">&nbsp;&nbsp;<b>Father Name</b> : &nbsp;{studentDetail.fathername ?? ''} </span>
                              <span class="custom-label fs-5">&nbsp;&nbsp;<b>Mobile</b> : &nbsp;{studentDetail.mobile ?? ''} </span>
                              {studentDetail.rollno ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Rollno</b> : &nbsp; {studentDetail.rollno}</span> : ''}
                              {studentDetail.sclass ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Class</b> : &nbsp; {studentDetail.sclass}</span> : ''}
                              {studentDetail.section ? <span class="custom-label fs-5">&nbsp;&nbsp;<b>Section</b> : &nbsp; {studentDetail.section}</span> : ''}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Gender</b> : &nbsp; {studentDetail.gender ?? ''}</span> */}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>DOB</b> : &nbsp; {studentDetail.dob ?? ''}</span> */}
                              {/* <span class="custom-label fs-5">&nbsp;&nbsp;<b>Address</b> : &nbsp; {studentDetail.address ?? ''}</span> */}
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className="card custom-card mt-3">
                        <div className='card-header justify-content-between'>
                          <div class="card-title">Transport Details</div>
                        </div>
                      </div>

                      <div class="card-body text-black fw-bold fs-6">
                        <div class="row form-material">
                          <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                            <label class="form-label">Route No</label>
                            {id ? (
                              <select
                                class="form-control"
                                name="routeno"
                                value={SubmitData.routeno}
                                onChange={handleSelectchange}
                                required
                              >
                                {routeNo ? (
                                  routeNo.map((row) => (
                                    <option
                                      value={row.routeno}
                                      key={row.routeno}
                                    >
                                      {row.routeno}
                                    </option>
                                  ))
                                ) : (
                                  <p>No data available</p>
                                )}
                              </select>
                            ) : (
                              <select
                                class="form-control"
                                name="routeno"
                                defaultValue={SubmitData.routeno}
                                onChange={handleSelectchange}
                                required
                              >
                                <option value="">Choose...</option>
                                {routeNo ? (
                                  routeNo.map((row) => (
                                    <option
                                      value={row.routeno}
                                      key={row.routeno}
                                    >
                                      {row.routeno}
                                    </option>
                                  ))
                                ) : (
                                  <p>No data available</p>
                                )}
                              </select>
                            )}
                          </div>
                          <div class="col-xl-3 col-xxl-6 col-md-6 mb-3">
                            <label class="form-label">Route Fee</label>
                            <input
                              type="text"
                              class="form-control"
                              name="routefee"
                              placeholder="Enter Route Fee..."
                              value={SubmitData.routefee}
                              disabled
                            />
                          </div>
                          {/* <div class="col-xl-3 col-xxl-4 col-md-6 mb-3">
                            <label class="form-label">Remarks</label>
                            {id ? (
                              <textarea type="text" class="form-control row-1" >{SubmitData.remark}</textarea>
                            ) : (<textarea type="text" class="form-control row-1" name="remark" onChange={handleInputChange} required >{SubmitData.remark}</textarea>)}
                          </div> */}
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              style={{ width: "100px" }}
                            >
                              {buttonLoading ? (
                                <span
                                  class="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : id ? (
                                "Edit"
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageTransport;
