import React from 'react';
import { Link } from 'react-router-dom';
const TodayAttendance = () => {
    return (
        
            <div className="container-fluid">
                <div className="row">
                <div class="card-header">
                        <h4 class="card-title">Mark Attendance</h4>
                        
                    </div>
                   
                    <div class="col-lg-4">
                        
                          
                                    <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Select Criteria</div></div>
                  <div class="card-body py-3 px-3">
                                    <div class="form-material">
                                        <div class="col-md-12 mb-3">
                                            <label class="form-label">Class</label>
                                            <select class="form-control" id="autoSizingSelect">
                                                <option selected>Select class...</option>
                                                <option value="Nursery">Nursery</option>
                                                <option value="LKG">LKG</option>
                                                <option value="UKG">UKG</option>
                                            </select>                                             </div>
                                        <div class="col-md-12 mb-3">
                                            <label class="form-label">Section</label>
                                            <select class="form-control" id="autoSizingSelect">
                                                <option selected>Select section...</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                            </select>                                                 </div>
                                        <div className="text-end">
                                            <button type="button" class="btn btn-primary btn-block fw-bold mb-3" > Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                   
                    </div>
                    <div class="col-lg-8">
                    <div class="card custom-card mb-3">
                <div class="card-header justify-content-between"><div class="card-title">Today Attendance</div></div>
                  <div class="card-body py-3 px-3">
                                    
                                <div class="table-responsive">
                                <table class="table table-hover table-bordered">
                                        <thead className="">
                                            <tr>
                                                <th>#</th>
                                                <th>Roll No.</th>
                                                <th>Name</th>
                                                <th>Attendance</th>
                                                <th>Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="text-black fs-5">
                                                <td>1</td>
                                                <td>100001</td>
                                                <td>Ronak</td>
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio1" value="option1" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio1">Present</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio2" value="option2" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio2">Absent</label>
                                                    </div>
                                                </td>
                                                <td> <input type="text" class="form-control form-control-sm" placeholder="" /></td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                            <td>2</td>
                                                <td>100002</td>
                                                <td>Ankit</td>
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio3" value="option1" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio3">Present</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio4" value="option2" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio4">Absent</label>
                                                    </div>
                                                </td>
                                                <td> <input type="text" class="form-control form-control-sm" placeholder="" /></td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>3</td>
                                                <td>100003</td>
                                                <td>Rohit</td>
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio5" value="option1" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio5">Present</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio6" value="option2" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio6">Absent</label>
                                                    </div>
                                                </td>
                                                <td> <input type="text" class="form-control" placeholder="" /></td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>4</td>
                                                <td>100004</td>
                                                <td>Abhi</td>
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio7" value="option1" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio7">Present</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio8" value="option2" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio8">Absent</label>
                                                    </div>
                                                </td>
                                                <td> <input type="text" class="form-control form-control-sm" placeholder="" /></td>
                                            </tr>
                                            <tr className="text-black fs-5">
                                                <td>5</td>
                                                <td>100005</td>
                                                <td>Kiyara</td>
                                                <td>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio9" value="option1" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio9">Present</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio10" value="option2" />
                                                        <label class="form-check-label mt-0 mb-0" for="inlineRadio10">Absent</label>
                                                    </div>
                                                </td>
                                                <td> <input type="text" class="form-control form-control-sm" placeholder="" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="text-end mb-3"><button type="submit" class="btn btn-sm btn-primary" style={{ width: "100px" }}>Submit</button></div>
                                
                                       
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <a class="page-link">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
    );
};

export default TodayAttendance;