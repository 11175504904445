const Error404 = () => {
    return (<>
        <div class="position-absolute start-0 end-0 start-0 bottom-0 w-100 h-100">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800 800">
                <g fill-opacity="0.22">
                    <circle style={{ fill: "rgba(var(--ct-primary-rgb), 0.1)", cx: "40", cy: "40", r: "60" }}></circle>
                    <circle style={{ fill: "rgba(var(--ct-primary-rgb), 0.2)", cx: "40", cy: "40", r: "50" }}></circle>
                    <circle style={{ fill: "rgba(var(--ct-primary-rgb), 0.3)", cx: "40", cy: "40", r: "30" }}></circle>
                    <circle style={{ fill: "rgba(var(--ct-primary-rgb), 0.4)", cx: "40", cy: "40", r: "20" }}></circle>
                    <circle style={{ fill: "rgba(var(--ct-primary-rgb), 0.5)", cx: "40", cy: "40", r: "10" }}></circle>
                </g>
            </svg>
        </div>
        <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-3 position-relative">
            <div class="container pt-5">
                <div class="text-center w-75 m-auto">
                    <h1 class="text-error">4 <i class="bi bi-emoji-frown"></i> 4</h1>
                    <h4 class="text-uppercase text-danger mt-3">Page Not Found</h4>
                </div>
            </div>
        </div>
    </>)
}
export default Error404;