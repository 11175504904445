import React from 'react';
function BookList() {
    return (<>
        <div className="mt-4">
            <div className="container-fluid">
                <div className="row">
                    <h3 class="text-center">Library</h3>
                    <div class="col-lg-12 pt-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row form-material">
                                    <div class="col-xl-3 col-xxl-9 col-md-6 mb-3">
                                        <h3 class="card-title">Book List</h3>
                                    </div>
                                    <div class="col-xl-3 col-xxl-3 col-md-6 mb-3">
                                        <form class="d-flex" role="search">
                                            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                        </form>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table header-border table-responsive-sm">
                                        <thead className="bg-black text-white">
                                            <tr>
                                                <th>#</th>
                                                <th>Library Slab</th>
                                                <th>Book No</th>
                                                <th>Book Name</th>
                                                <th>Class</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                <tr className="text-black fs-5">
                                                    <td>1</td>
                                                    <td>Slab-I</td>
                                                    <td>10001</td>
                                                    <td>English Lucent</td>
                                                    <td>LKG</td>
                                                    <td><span class="badge square-pill bg-green">Available</span></td>
                                                </tr>
                                                <tr className="text-black fs-5">
                                                    <td>2</td>
                                                    <td>Slab-II</td>
                                                    <td>10006</td>
                                                    <td>History Lucent</td>
                                                    <td>UKG</td>
                                                    <td><span class="badge square-pill bg-danger">Unavailable</span></td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled">
                                            <a class="page-link">Previous</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default BookList;