import React, { Suspense } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from './routes';
import Header from './Components/Header';
import { ToastContainer } from 'react-toastify';
// import jwt_decode from 'jwt-decode'; // Correct import
import { jwtDecode } from 'jwt-decode';

import axios from 'axios';
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token') || '';

const AppRoutes = (props) => {
    let path = document.location.pathname; 
    let layout = 'public'; 

    // Check route
    if (/^\/admin/i.test(path)) {
        layout = 'admin';
    } else if (/^\/user/i.test(path)) {
        layout = 'student';
    } else if (/^\/parent/i.test(path)) {
        layout = 'parent';
    } else if (/^\/teacher/i.test(path)) {
        layout = 'teacher';
    } else if (/^\/super-admin/i.test(path)) {
        layout = 'superAdmin';
    } else if (/^\//i.test(path)) {
        layout = '/';
    }
    return useRoutes(routes);
};

axios.interceptors.response.use(
    (res) => res, 
    (err) => Promise.reject(err)
);

function App() {
    let user = {};
    const token = localStorage.getItem('token') || ' ';
    if (token !== ' ') {
        // try {
            const decoded = jwtDecode(token); // Correct function call
            if (decoded.exp && Date.now() <= decoded.exp * 1000 ) {
                user = decoded;
                user.token = token;
            } 
            // else {
            //     window.location.href = '/';
            // }
        // } catch (error) {
        //     // window.location.href = '/';
        // }
    // } else {
        // window.location.href = '/';
    }

    let path = document.location.pathname; 
    let layout = 'public'; 

    // Check route
    if (/^\/admin/i.test(path)) {
        layout = 'admin';
    } else if (/^\/user/i.test(path)) {
        layout = 'student';
    } else if (/^\/parent/i.test(path)) {
        layout = 'parent';
    } else if (/^\/teacher/i.test(path)) {
        layout = 'teacher';
    } else if (/^\/super-admin/i.test(path)) {
        layout = 'superAdmin';
    } else if (/^\//i.test(path)) {
        layout = '/';
    }

    return (
        <>
            <ToastContainer />
            <BrowserRouter>
                {(layout && (layout === 'student' || layout === '/')) ? '' : (
                    (path && path === '/parent') ? '' : ((path && path === '/teacher') ? '' : <Header layout={layout} user={user} />)
                )}
                <main className={"page-wrapper"}>
                    <Suspense fallback={<div style={{ height: 'calc( 100vh - var( --fixed-top-spacing ) - 2.5rem )', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>}>
                        <AppRoutes layout={layout} user={user} />
                    </Suspense>
                </main>
            </BrowserRouter>
        </>
    );
}

export default App;
