import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from '../../helper';

interface RegistrationData {
    ids:string;
    name: string;
    email: string;
    mobile: string;
    password: string;
    city: string;
    state: string;
    pinCode: string;
    address: string;
    dbName: string;
    dbUserName: string;
    dbPassword: string;
}

const AdminReg: React.FC = () => {
    const { userID } = useParams();
    const [isLoading, setLoading] = useState<boolean>(false);
    // const [data, setData] = useState<string[]>([]);
    const [data, setData] = useState<Record<string, string>>({}); // Initialize with an empty object
    const [registrationData, setRegistrationData] = useState<RegistrationData>({
        ids:'',
        name: '',
        email: '',
        mobile: '',
        password: '',
        city: '',
        state: '',
        pinCode: '6541',
        address: '',
        dbName: '',
        dbUserName: '',
        dbPassword: ''
    });

    const history = useNavigate();

    // LOADING 
    const Loading: React.FC = () => {
        const placeholders: number[] = [0, 1, 2, 3, 4, 5, 6, 7];
        return (<>
            {placeholders.map((i) => (
                <p className="placeholder-wave mb-0" key={i}>
                    <span className="placeholder col-12 bg-secondary"></span>
                </p>
            ))}
        </>)
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            let url = '/Admin/SuperAdminRegistration';
            if(userID){
                url = "/Admin/UpdateSuperAdmin"
            }

            const response = await axiosInstance.post(url, registrationData);
            console.log(response.data);

            if (response.data.Status === "success") {
                // throw new Error('Registration failed');
                let message = "Admin has been created successfully";
                if(userID){
                    message = "Admin has been updated successfully";
                }
                toast.success(message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
    
                history('/super-admin/admins');
            }else{
                toast.error(response.data.Status, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }

        } catch (error) {
            toast.error('Registration failed', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    // handle input change
    const handleChange = (e)=>{
        let name = e.target.name;
        let value = e.target.value;
        setRegistrationData((prev)=> ({...prev, [name]: value}));
    }

     // FETCH ADMINS 
     const fetchAdmin = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`/admin/GetSuperAdminDetail?Userid=${userID}`);
            setData(response.data.Payload[0]);
            setRegistrationData({
                ids:response.data.Payload[0].Id,
                name: response.data.Payload[0].Name,
                email: response.data.Payload[0].EmailId,
                mobile: response.data.Payload[0].MobileNo,
                password: response.data.Payload[0].Password,
                city: response.data.Payload[0].City,
                state: response.data.Payload[0].State,
                pinCode: response.data.Payload[0].PinCode,
                address: response.data.Payload[0].Address,
                dbName: response.data.Payload[0].dbName,
                dbUserName: response.data.Payload[0].dbUserName,
                dbPassword: response.data.Payload[0].dbPassword
            })

            setLoading(false);
        } catch (error) {
            toast.error("Authentication failed !");
        }
    };

    useEffect(() => {
        if(userID){
            fetchAdmin();
        }
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className='col-12 mx-auto'>
                    <div className='card'>
                        <div className="card-header" style={{"background":"#757575"}}>
                            <h4 className="card-title text-white">Add new Account </h4>
                        </div>
                    </div>
                </div>
                {
                    isLoading ?  <Loading />
                    :
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <div className="justify-content-between">
                                        <div className='card'>
                                            <h4 className="card-title ms-2 mt-2 text-center">Admin Details</h4>
                                        </div>
                                    </div>
                                    <div className="card-body py-3 px-3 ">
                                        <div className="row form-material">
                                            <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                <label className="form-label">Full Name <span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter full name.."
                                                    name="name"
                                                    onChange={handleChange}
                                                    value={registrationData.name}
                                                    required
                                                />
                                            </div>
                                            <div className="col-xl-3 col-xxl-6 col-md-6">
                                                <label className="form-label">Email<span className='text-danger'>*</span></label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter email.."
                                                    name="email"
                                                    onChange={handleChange}
                                                    value={registrationData.email}
                                                    required
                                                />
                                            </div>
                                            <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                <label className="form-label">Mobile<span className='text-danger'>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter mobile number.."
                                                    name="mobile"
                                                    onChange={handleChange}
                                                    value={registrationData.mobile}
                                                    required
                                                />
                                            </div>
                        
                                            <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                <label className="form-label">Password<span className='text-danger'>*</span></label>
                                                <input type="password" className="form-control" name="password" onChange={handleChange} value={registrationData.password}  required />
                                            </div>
                                            <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                <label className="form-label">State<span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control" name="state" onChange={handleChange} value={registrationData.state} required />
                                            </div>
                                            <div className="col-xl-3 col-xxl-6 col-md-6 mb-3">
                                                <label className="form-label">City<span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control" name="city" onChange={handleChange} value={registrationData.city}  required />
                                            </div>
                                            <div className="col-xl-3 col-xxl-12 col-md-12 mb-3">
                                                <label className="form-label">Address<span className='text-danger'>*</span></label>
                                                <textarea
                                                    className="form-control"
                                                    id="exampleFormControlTextarea1"
                                                    rows={2}
                                                    name="address"
                                                    required
                                                    onChange={handleChange}
                                                    value={registrationData.address} 
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className=" mb-3">
                                    <div className="justify-content-between">
                                        <div className='card'>
                                            <h4 className="card-title ms-2 mt-2 text-center">Databsae Details</h4>
                                        </div>
                                    </div>
                                    <div className="card-body py-3 px-3 ">
                                        <div className="row form-material">
                                            <div className="col-xl-12 col-xxl-12 col-md-12 mb-3">
                                                <label className="form-label">DB name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Databse name"
                                                    name="dbName"
                                                    onChange={handleChange}
                                                    value={registrationData.dbName} 
                                                    // required
                                                />
                                            </div>
                                            <div className="col-xl-12 col-xxl-12 col-md-12 mb-3">
                                                <label className="form-label">DB User Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Database user name"
                                                    name="dbUserName"
                                                    onChange={handleChange}
                                                    value={registrationData.dbUserName} 
                                                    // required
                                                />
                                            </div>
                                            <div className="col-xl-12 col-xxl-12 col-md-12 mb-3">
                                                <label className="form-label">DB Password</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter mobile Database password"
                                                    name="dbPassword"
                                                    onChange={handleChange}
                                                    value={registrationData.dbPassword} 
                                                    // required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="text-end">
                                <button type="submit" className="btn btn-sm btn-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-save2" viewBox="0 0 16 16">
                                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"/>
                                    </svg>
                                    <span className='fs-5'> Submit</span>
                                </button>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </div>
    );
}

export default AdminReg;