import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from '../../helper';

const Admins: React.FC = () => {
    const [data, setData] = useState<string[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    
    // LOADING 
    const Loading: React.FC = () => {
        const placeholders: number[] = [0, 1, 2, 3, 4, 5, 6, 7];
        return (<>
            {placeholders.map((i) => (
                <p className="placeholder-wave mb-0" key={i}>
                    <span className="placeholder col-12 bg-secondary"></span>
                </p>
            ))}
        </>)
    }

    // FETCH ADMINS 
    const fetchAdmins = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`/Admin/GetAllSuperAdminDetail`);
            setData(response.data.Payload);
            setLoading(false);
        } catch (error) {
            toast.error("Authentication failed !");
        }
    };

    useEffect(() => {
        fetchAdmins();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="card-header">
                    <h4 className="card-title">Admins</h4>
                    <div className="flex flex-wrap gap-2">
                        <Link className="btn btn-primary" to="/super-admin/create/admin">Create new Admin<span className="btn-icon-end"><i className="fa fa-solid fa-plus"></i></span></Link>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card custom-card mb-3">
                        <div className='card-header justify-content-between'>
                            <div className="card-title">Admins</div>
                        </div>
                        {
                            isLoading ?  <Loading />
                            :
                            <div className="card-body py-3 px-3">
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered">
                                        <thead className="">
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>Address</th>
                                                <th>Password</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { data.length && data.map((item, index) => (
                                                <tr key={index} className="text-black fs-5">
                                                    <td>{index + 1}</td>
                                                    <td>{item.Name}</td>
                                                    <td>{item.EmailId}</td>
                                                    <td>{item.MobileNo}</td>
                                                    <td>{item.Address}</td>
                                                    <td>{item.Password}</td>
                                                    <td>
                                                        <Link to={`/super-admin/edit/admin/${item.UserId}` }><i className="bi bi-pencil-square fs-3"></i></Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Admins