import React, { useEffect } from 'react';
import Header from "./Header";
import { useState } from 'react';
import { axiosInstance } from '../admin-helper';
import { Link } from 'react-router-dom';
import { PieChart, Pie, Legend, Tooltip, Cell, CartesianGrid, YAxis, XAxis, Bar, BarChart, ResponsiveContainer } from 'recharts';

function Dashboard() {
    const COLORS = ['#6610f2', '#4cb32b'];
    const [dashboardsData, setDashboardData] = useState()
    const [data, setData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [charts, setClasses] = useState([]);
    const [birth, setBirth] = useState([]);
    const [counts, setCount] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/admin/GetPendingFeelist`);
            setData(response.data.Payload);
        } catch (error) {
            window.location.href = "/";
        }
        finally {
            setLoading(false);
        }
    };
    const fetchDatas = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/admin/GetCountBYGender");
            setDatas(response.data.Payload);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            setLoading(false);
        }
    }
    const fetchClasses = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/admin/GetCountBYClass");
            setClasses(response.data.Payload);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }
    const fetchBirth = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/Admin/GetBirthdayList");
            setBirth(response.data.Payload);
            setCount(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchBirth();
        fetchClasses();
        fetchDatas();
        fetchData();
        const fetchDashboardData = async () => {
            try {
                const response = await axiosInstance.get("/Admin/GetDashboardDetail")
                setDashboardData(response.data.Payload[0]);
            } catch (error) {
                window.location.href = "/";
            }
        }
        fetchDashboardData()
    }, [])
    // -- pagination Code -- //
    const dataPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const numberOfPage = Math.ceil(data?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (<>
        <div className="">
            {loading ? (
                <div
                    className="container text-center"
                    style={{ paddingBottom: "150px", paddingTop: "200px" }}
                >
                    <div class="spinner-grow text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-danger" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow text-dark" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="container-fluid">
                    <div className="row">
                        <div class="card-header">
                            <h4 class="card-title">Dashboard</h4>
                            <div class="flex flex-wrap gap-2">
                                <Link className="btn btn-primary" to="/admin/master/manage-notice">Add Notice<span class="btn-icon-end"><i class="fa fa-solid fa-plus"></i></span></Link>
                                &nbsp; <Link to="/admin/IDCard/IdCard-list"><span class="btn btn-sm btn-primary ">Student ID Card</span></Link>
                            </div>
                        </div>
                        <div class="col-xl-9">
                            <div className='row'>
                                <div className="col-sm-4 animate__animated animate__zoomIn">
                                    <Link to="/admin/students/list">
                                        <div className="widget-stat card">
                                            <div className="card-body  p-4">
                                                <div className="media">
                                                    <span className="me-3 text-black bg-light-green" >
                                                        <i class="ri-team-line text-green"></i>
                                                    </span>
                                                    <div className="text-white">
                                                        <h6 className="mb-1 text-muted">Total Students</h6>
                                                        <h3>{dashboardsData?.totalstudent}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-4 animate__animated animate__zoomIn">
                                    <Link to="/admin/teachers">
                                        <div className="widget-stat card">
                                            <div className="card-body  p-4">
                                                <div className="media">
                                                    <span className="me-3 text-black bg-light-red" >
                                                        <i class="ri-shield-user-line text-red"></i>
                                                    </span>
                                                    <div className="text-white">
                                                        <h6 className="mb-1 text-muted">Total Teachers</h6>
                                                        <h3>{dashboardsData?.totalteacher}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-4 animate__animated animate__zoomIn">
                                    <Link to="/admin/enquiries">
                                        <div className="widget-stat card">
                                            <div className="card-body  p-4">
                                                <div className="media">
                                                    <span className="me-3 text-black bg-light-blue" >
                                                        <i class="ri-file-list-3-line text-blue"></i>
                                                    </span>
                                                    <div className="text-white">
                                                        <h6 className="mb-1 text-muted">Total Enquiries</h6>
                                                        <h3>{dashboardsData?.totalenquery}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-4 animate__animated animate__zoomIn">
                                    <Link to="/admin/registration/manage-registration">
                                        <div className="widget-stat card">
                                            <div className="card-body  p-4">
                                                <div className="media">
                                                    <span className="me-3 text-black bg-light-yellow" >
                                                        <i class="ri-file-list-2-line text-orange"></i>
                                                    </span>
                                                    <div className="text-white">
                                                        <h6 className="mb-1 text-muted">Total Registration</h6>
                                                        <h3>{dashboardsData?.totalregister}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-4 animate__animated animate__zoomIn hide">
                                    <div className="widget-stat card">
                                        <div className="card-body  p-4">
                                            <div className="media">
                                                <span className="me-3 text-black" style={{ backgroundColor: "#f3f0d2" }}>
                                                    <i class="bi bi-house"></i>
                                                </span>
                                                <div className="text-white">
                                                    <h6 className="mb-1 text-muted">Total Branches</h6>
                                                    <h3>{dashboardsData?.totalbranch}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 animate__animated animate__zoomIn">
                                    <div className="widget-stat card">
                                        <div className="card-body  p-4">
                                            <div className="media">
                                                <span className="me-3 text-black bg-light-green" >
                                                    {/* <i class="bi bi-graph-up text-green"></i> */}
                                                    <i class="ri-money-rupee-circle-line text-green"></i>
                                                </span>
                                                <div className="text-white">
                                                    <h6 className="mb-1 text-muted">Monthly Income</h6>
                                                    <h3>{dashboardsData?.totalincome}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 animate__animated animate__zoomIn">
                                    <div className="widget-stat card">
                                        <div className="card-body  p-4">
                                            <div className="media">
                                                <span className="me-3 text-black bg-light-red" >
                                                    <i class="ri-money-rupee-circle-line text-red"></i>
                                                </span>
                                                <div className="text-white">
                                                    <h6 className="mb-1 text-muted">Monthly Expense</h6>
                                                    <h3>{dashboardsData?.totalexpense}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8 animate__animated animate__zoomIn">
                                    <div className="widget-stat card">
                                        <div className="card-header border-0 pb-0 flex-wrap">
                                            <h4 className="card-title mb-0">Class Wise Students</h4>
                                        </div>
                                        <div className="card-body p-1">
                                            <ResponsiveContainer width="100%" height={300}>
                                                <BarChart data={charts}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="value" fill="#6610f2" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 animate__animated animate__zoomIn">
                                    <div className="widget-stat card">
                                        <div className="card-header border-0 pb-0 flex-wrap">
                                            <h4 className="card-title mb-0">Student Category</h4>
                                        </div>
                                        <div className="card-body text-center p-4">
                                            <ResponsiveContainer width="100%" height={260}>
                                                <PieChart>
                                                    <Pie
                                                        data={datas} // Pass fetched data to the Pie component
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                                                            const RADIAN = Math.PI / 180;
                                                            const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                                            const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                            const y = cy + radius * Math.sin(-midAngle * RADIAN);
                                                            return (
                                                                <text
                                                                    x={x}
                                                                    y={y}
                                                                    fill="#8884d8"
                                                                    textAnchor={x > cx ? 'start' : 'end'}
                                                                    dominantBaseline="central"
                                                                >
                                                                    {datas[index].name}: {value}
                                                                </text>
                                                            );
                                                        }}
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {datas.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip />
                                                    <Legend />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card custom-card mb-3" >
                                        <div class="card-header justify-content-between">
                                            <div class="card-title">
                                                Unpaid Student Fee
                                            </div>
                                            {/* <div class="flex flex-wrap gap-2"><input class="form-control form-control-sm" type="text" placeholder="Search Here" aria-label=".form-control-sm example" /></div> */}
                                        </div>
                                        <div class="card-body py-3 px-3">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Adm No</th>
                                                            <th>Name</th>
                                                            <th>Class</th>
                                                            <th>Roll No</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data && data.length > 0 ? (
                                                            data
                                                                .slice(
                                                                    currentPage * dataPerPage,
                                                                    (currentPage + 1) * dataPerPage
                                                                )
                                                                .map((row,index) => (
                                                                    <tr class="text-black fs-5">
                                                                        <td>{currentPage * dataPerPage + index+1}</td>
                                                                        <td>{row.studentid}</td>
                                                                        <td><div class="trans-list">
                                                                            <img src="https://akademi-python-django-school-admin-template.dexignlab.com/static/akademi/images/trans/2.jpg" alt="" class="avatar avatar-sm me-3" />
                                                                            <span>{row.studentname}</span>
                                                                        </div></td>
                                                                        <td class="text-primary mb-0 font-w500">{row.sclass}</td>
                                                                        <td>{row.mobileno}</td>
                                                                        <td>
                                                                            <Link class="btn btn-xs btn-primary" to={`/admin/students/detail/${row.studentid}`}><i class="bi bi-eye"></i> View</Link>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        ) : (
                                                            <p>No data available</p>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <nav aria-label="Page navigation example">
                                                <ul class="pagination justify-content-end">
                                                    <li class="page-item">
                                                        <a
                                                            class="page-link"
                                                            style={{
                                                                pointerEvents:
                                                                    currentPage === 0 ? "none" : "auto",
                                                                opacity: currentPage === 0 ? 0.5 : 1,
                                                            }}
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                        >
                                                            Previous
                                                        </a>
                                                    </li>
                                                    {pageIndex
                                                        .slice(
                                                            Math.max(0, currentPage - 2),
                                                            Math.min(numberOfPage, currentPage + 2)
                                                        )
                                                        .map((page) => (
                                                            <li
                                                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                                    }`}
                                                                key={page}
                                                            >
                                                                <a
                                                                    class="page-link"
                                                                    style={{
                                                                        pointerEvents:
                                                                            page - 1 === currentPage
                                                                                ? "none"
                                                                                : "auto",
                                                                        opacity: page - 1 === currentPage ? 0.5 : 1,
                                                                    }}
                                                                    onClick={() => handlePageChange(page - 1)}

                                                                >
                                                                    {page}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    <li class="page-item">
                                                        <a
                                                            class="page-link"
                                                            style={{
                                                                pointerEvents:
                                                                    currentPage === numberOfPage - 1
                                                                        ? "none"
                                                                        : "auto",
                                                                opacity:
                                                                    currentPage === numberOfPage - 1 ? 0.5 : 1,
                                                            }}
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                        >
                                                            Next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 animate__animated animate__zoomIn">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="card">
                                        <div class="card-header bg-blue">
                                            <div class="researcher">
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <span class="text-center  d-block block">
                                                <div className='school-logo'> <img src="../images/mnbschool.png" alt="" class="me-3 rounded" /></div>
                                            </span>
                                            <h4 class="fs-20 mb-0 text-center">MNB Public School</h4>
                                            <span class="text-primary mb-3 d-block text-center">Greater Noida</span>
                                            <hr></hr><div>
                                                <span class="mb-2 d-flex"><i class="fas fa-phone orange me-3 mt-1"></i>+91 999 0000 999  </span>
                                                <span class="mb-2 d-flex"><i class="fas fa-envelope orange me-3 mt-1"></i>Info@mnbschools.com</span>
                                                <span class="mb-2 d-flex"><i class="fas fa-location-dot orange me-3 mt-1"></i>D-160, Dallupura Rd, D Block, Sector 10, Noida, Uttar Pradesh 201301 </span>
                                            </div>
                                        </div>
                                        <div class="card-header  d-block block border-0 pt-0 pb-2">
                                            <h4 class="card-title  mb-0 fs-16">Today's Student Birthday</h4>
                                            <span>Total  <span class="font-w600">{counts.count}</span> Students Birthday</span>
                                        </div>
                                        <div class="card-body pt-2">
                                            <marquee behavior='scroll' direction="up">
                                                <div class="d-flex align-items-center student">
                                                    {birth ? (
                                                        birth.map((row) => (
                                                            <>
                                                                <span class="dz-media">
                                                                    <img src="https://akademi-python-django-school-admin-template.dexignlab.com/static/akademi/images/profile/small/pic1.jpg" alt="" class="avatar" />
                                                                </span>
                                                                <div class="user-info">
                                                                    <Link to={`/admin/students/detail/${row.studentid}`}><h6 class="name">{row.name}</h6></Link>
                                                                    <Link to={`/admin/students/detail/${row.studentid}`}><span class="fs-14 font-w400 text-wrap">Class : {row.sclass}, Section : {row.section}</span></Link>
                                                                </div>
                                                            </>
                                                        ))) : (
                                                        <p class="text-red">No data found!</p>)}
                                                    <div class="icon-box st-box ms-auto">
                                                        <a href="/">
                                                        </a>
                                                    </div>
                                                </div>

                                            </marquee>
                                        </div>
                                        <div class="card-footer border-0 pt-0">
                                            {/* <div class="d-flex justify-content-between align-items-center">
                                            <a href="" class="btn btn-primary btn-rounded">APPLY JOB</a>
                                            <a href="" class="btn btn-secondary  btn-sm btn-rounded light"><i class="far fa-bookmark fs-20"></i></a>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12">
                                    <div class="card massage  mb-0">
                                        <div class="card-header border-0 pt-3 pb-3 px-3">
                                            <div>
                                                <h2 class="card-title mb-0 fs-16">Parents Messages</h2>
                                            </div>
                                        </div>
                                        <div class="card-body height450 dlab-scroll p-0 px-3" id="messageContent">
                                            <div class="d-flex student border-bottom">
                                                <div class="d-flex align-items-center">
                                                    <span class="dz-media">
                                                        <img src="https://akademi-python-django-school-admin-template.dexignlab.com/static/akademi/images/profile/small/pic1.jpg" alt="" class="avatar" />
                                                    </span>
                                                    <div class="user-info">
                                                        <h6 class="name"><a href="/app-profile/">Samantha William</a></h6>
                                                        <span class="fs-14 font-w400 text-wrap">Lorem ipsum dolor sit</span>
                                                    </div>
                                                </div>
                                                <div class="indox  ms-auto">
                                                    <span class="fs-14 font-w400 text-wrap">12:45 PM</span>
                                                </div>
                                            </div>
                                            <div class="d-flex student border-bottom">
                                                <div class="d-flex align-items-center">
                                                    <span class="dz-media">
                                                        <img src="https://akademi-python-django-school-admin-template.dexignlab.com/static/akademi/images/profile/small/pic2.jpg" alt="" class="avatar" />
                                                    </span>
                                                    <div class="user-info">
                                                        <h6 class="name"><a href="/app-profile/">Tony Soap</a></h6>
                                                        <span class="fs-14 font-w400 text-wrap">Lorem ipsum dolor sit</span>
                                                    </div>
                                                </div>
                                                <div class="indox  ms-auto">
                                                    <span class="fs-14 font-w400 text-wrap">12:45 PM</span>
                                                </div>
                                            </div>
                                            <div class="d-flex student border-bottom">
                                                <div class="d-flex align-items-center">
                                                    <span class="dz-media">
                                                        <img src="https://akademi-python-django-school-admin-template.dexignlab.com/static/akademi/images/profile/small/pic2.jpg" alt="" class="avatar" />
                                                    </span>
                                                    <div class="user-info">
                                                        <h6 class="name"><a href="/app-profile/">Tony Soap</a></h6>
                                                        <span class="fs-14 font-w400 text-wrap">Lorem ipsum dolor sit</span>
                                                    </div>
                                                </div>
                                                <div class="indox  ms-auto">
                                                    <span class="fs-14 font-w400 text-wrap">12:45 PM</span>
                                                </div>
                                            </div>
                                            <div class="d-flex student border-bottom">
                                                <div class="d-flex align-items-center">
                                                    <span class="dz-media">
                                                        <img src="https://akademi-python-django-school-admin-template.dexignlab.com/static/akademi/images/profile/small/pic3.jpg" alt="" class="avatar" />
                                                    </span>
                                                    <div class="user-info">
                                                        <h6 class="name"><a href="/app-profile/">Jordan Nico</a></h6>
                                                        <span class="fs-14 font-w400 text-wrap">Lorem ipsum dolor sit</span>
                                                    </div>
                                                </div>
                                                <div class="indox  ms-auto">
                                                    <span class="fs-14 font-w400 text-wrap">12:45 PM</span>
                                                </div>
                                            </div>
                                            <div class="d-flex student border-bottom">
                                                <div class="d-flex align-items-center">
                                                    <span class="dz-media">
                                                        <img src="https://akademi-python-django-school-admin-template.dexignlab.com/static/akademi/images/profile/small/pic4.jpg" alt="" class="avatar" />
                                                    </span>
                                                    <div class="user-info">
                                                        <h6 class="name"><a href="/app-profile/">Nadila Adja</a></h6>
                                                        <span class="fs-14 font-w400 text-wrap">Lorem ipsum dolor sit</span>
                                                    </div>
                                                </div>
                                                <div class="indox  ms-auto">
                                                    <span class="fs-14 font-w400 text-wrap">12:45 PM</span>
                                                </div>
                                            </div>
                                            <div class="d-flex student border-bottom">
                                                <div class="d-flex align-items-center">
                                                    <span class="dz-media">
                                                        <img src="https://akademi-python-django-school-admin-template.dexignlab.com/static/akademi/images/profile/small/pic4.jpg" alt="" class="avatar" />
                                                    </span>
                                                    <div class="user-info">
                                                        <h6 class="name"><a href="/app-profile/">Nadila Adja</a></h6>
                                                        <span class="fs-14 font-w400 text-wrap">Lorem ipsum dolor sit</span>
                                                    </div>
                                                </div>
                                                <div class="indox  ms-auto">
                                                    <span class="fs-14 font-w400 text-wrap">12:45 PM</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-center border-0 px-3 pb-3">
                                            <a href="" class="btn btn-block btn-primary light btn-rounded dlab-load-more" id="message" rel="/static/akademi/ajax/message-content.html">View More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </>);
}

export default Dashboard;