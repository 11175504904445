import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../admin-helper";
import Loader from "../../Loader";
function Block_list() {
    const [loading, setLoading] = useState(true);
    const [getdata, setGetData] = useState([]);
    const [classData, setClassData] = useState([]);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/Admin/Getblockteacherlist');
            setGetData(response.data.Payload);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    // fetchClass Data
    const fetchClass = async () => {
        try {
            const response = await axiosInstance.get(`/Master/GetClass`);
            setClassData(response.data.Payload);
        } catch (error) {
            window.location.href = "/";
        }
    };
    useEffect(() => {
        fetchClass();
        fetchData();
    }, []);



    // -- pagination Code -- //

    const dataPerPage = 10;

    const [currentPage, setCurrentPage] = useState(0);

    const numberOfPage = Math.ceil(getdata?.length / dataPerPage);
    const pageIndex = Array.from(
        { length: numberOfPage },
        (_, index) => index + 1
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const changeBlock = async (teacherID, blockstatus) => {
        try {
            const response = await axiosInstance.post("/Teacher/BlockTeacher", {
                teacherid: teacherID,
                type: blockstatus
            });
            if (response.data.Status === "success") {
                toast.success("Teacher block status updated successfully!");
            } else {
                toast.error("Failed to update teacher block status.");
            }
        } catch (error) {
            toast.error("Failed to update teacher block status.");
        } finally {
            fetchData();
        }
    };
    return (
        <>
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div class="card-header">
                            <h4 class="card-title">Manage Teacher</h4>
                        </div>
                        <div class="col-lg-12">
                            <div className="card custom-card mb-3">
                                <div className="card-header justify-content-between">
                                    <div class="card-title">Block List</div>
                                </div>
                                <div class="card-body py-2 px-2">
                                    {loading ? (
                                       <Loader/>
                                    ) : (

                                        <div class="table-responsive">
                                            <table class="table text-nowrap table-hover border table-bordered">
                                                <thead className="">
                                                    <tr>
                                                        <th>Teacher ID</th>
                                                        <th>Name</th>
                                                        <th>Email ID</th>
                                                        <th>Mobile No</th>
                                                        <th>Incharge Class</th>
                                                        <th>Subject Handling</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getdata && getdata.length > 0 ? (
                                                        getdata
                                                            .slice(
                                                                currentPage * dataPerPage,
                                                                (currentPage + 1) * dataPerPage
                                                            )
                                                            .map((row) => (
                                                                <tr className="text-black fs-5" key={row.id}>
                                                                    <td>{row.teacherid}</td>
                                                                    <td>{row.name}</td>
                                                                    <td>{row.emailid}</td>
                                                                    <td>{row.mobileno}</td>
                                                                    <td>{row.incharge_class}</td>
                                                                    <td>{row.subject_handling}</td>
                                                                    <td>
                                                                        {row.blockstatus === 0 ? (
                                                                            <Link class="btn btn-danger btn-sm content-icon" onClick={() => changeBlock(row.teacherid, row.blockstatus === 0 ? 1 : 0)}>
                                                                                <i class="bi bi-lock"></i>
                                                                            </Link>
                                                                        ) : (
                                                                            <Link class="btn btn-danger btn-sm content-icon" onClick={() => changeBlock(row.teacherid, row.blockstatus === 1 ? 0 : 1)}>
                                                                                <i class="bi bi-unlock"></i>
                                                                            </Link>
                                                                        )}
                                                                        &nbsp;
                                                                        <Link
                                                                            to={`/admin/teachers/view/${row.teacherid}`}
                                                                            class="btn btn-primary  btn-sm content-icon"
                                                                        >
                                                                            <i class="fa fa-eye"></i>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                    ) : (
                                                        <p>No data available</p>
                                                    )}
                                                </tbody>
                                            </table>
                                            <nav aria-label="Page navigation example">
                                                <ul class="pagination justify-content-end">
                                                    <li class="page-item">
                                                        <a
                                                            class="page-link"
                                                            style={{
                                                                pointerEvents:
                                                                    currentPage === 0 ? "none" : "auto",
                                                                opacity: currentPage === 0 ? 0.5 : 1,
                                                            }}
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                        >
                                                            Previous
                                                        </a>
                                                    </li>
                                                    {pageIndex
                                                        .slice(
                                                            Math.max(0, currentPage - 2),
                                                            Math.min(numberOfPage, currentPage + 2)
                                                        )
                                                        .map((page) => (
                                                            <li
                                                                class={`page-item ${page === currentPage + 1 ? "active" : ""
                                                                    }`}
                                                                key={page}
                                                            >
                                                                <a
                                                                    class="page-link"
                                                                    style={{
                                                                        pointerEvents:
                                                                            page - 1 === currentPage
                                                                                ? "none"
                                                                                : "auto",
                                                                        opacity: page - 1 === currentPage ? 0.5 : 1,
                                                                    }}
                                                                    onClick={() => handlePageChange(page - 1)}
                                                                    href="#"
                                                                >
                                                                    {page}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    <li class="page-item">
                                                        <a
                                                            class="page-link"
                                                            style={{
                                                                pointerEvents:
                                                                    currentPage === numberOfPage - 1
                                                                        ? "none"
                                                                        : "auto",
                                                                opacity:
                                                                    currentPage === numberOfPage - 1 ? 0.5 : 1,
                                                            }}
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                        >
                                                            Next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default Block_list;